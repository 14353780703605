import React from "react";
import PropTypes from "prop-types";

import { Box, Typography, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  label: {
    fontWeight: "bold",
    margin: theme.spacing(1),
  },
}));

const FilterInputWrapper = (props) => {
  const classes = useStyles();
  const { label, permitpad } = props;

  return (
    <Box p={permitpad ? permitpad : 2}>
      {label && (
        <Typography variant="subtitle1" className={classes.label}>
          {props.label}
        </Typography>
      )}
      {props.children}
    </Box>
  );
};

FilterInputWrapper.propTypes = {
  label: PropTypes.string,
  permitpad: PropTypes.number,
};

export default FilterInputWrapper;
