/* eslint-disable no-underscore-dangle */
import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';

import {
  Card,
  Button,
  Grid,
  Table,
  CircularProgress,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Chip,
} from '@material-ui/core';

import Api from '../../../shared/api';
import toast from '../../../shared/toast';
import Config from '../../../config/app-config';

import { FormIoForm, FormIoResource, Location } from '../../../types/prop-types';
import useAbilities from '../../../hooks/auth/useAbilities';
import DefinitionsState from '../../../actions/definitions';

const useStyles = makeStyles((theme) => {
  return {
    card: {
      position: 'relative',
      marginRight: theme.spacing(1),
      marginBottom: theme.spacing(2),
    },
    cardContent: {
      padding: theme.spacing(2),
    },
    successButton: {
      marginRight: theme.spacing(1),
      backgroundColor: theme.palette.success.main,
      color: theme.palette.common.white,
    },
    metadataButton: {
      marginRight: theme.spacing(1),
    },
    header: { marginBottom: theme.spacing(3) },
    noFormText: { marginTop: theme.spacing(3) },
    headerRight: { textAlign: 'right' },
    subtitle: { fontSize: 14 },
    table: { minWidth: 650 },
    savingOverlay: {
      position: 'absolute',
      width: '100%',
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
      zIndex: 100,
      marginBottom: theme.spacing(-4),
    },
  };
});

const FormCard = (props) => {
  const classes = useStyles();
  const abilities = useAbilities();
  const dispatch = useDispatch();
  const { site, form, resources, definition } = props;

  const hasDefinition = definition !== undefined;
  const hasForm = form !== undefined;

  const [saving, setSaving] = React.useState(false);

  const [definitionLastUpdated, setDefinitionLastUpdated] = React.useState(
    hasDefinition
      ? moment(definition.EffectiveDate)
      : null,
  );

  const hasUpdatedResources = resources
    && resources.find((r) => moment(r.modified).isAfter(definitionLastUpdated));

  const handlePublishLatest = () => {
    setSaving(true);
    Api.put(`/formio/publish/${form._id}`)
      .then(() => {
        toast.success('Updated successfully');
        setDefinitionLastUpdated(moment());
        setSaving(false);
      })
      .catch(() => {
        setSaving(false);
      });
  };

  const handleMetadataRebuild = () => {
    setSaving(true);
    Api.patch(`/definition/${definition.PermitDefinitionId}/metadata`)
      .then(() => {
        toast.success('Metadata rebuilt successfully');
        dispatch(DefinitionsState.actions.updateCachedDefinition(definition.PermitDefinitionId));
        setSaving(false);
      })
      .catch(() => {
        setSaving(false);
      });
  };

  const renderFormDetails = () => (
    <>
      <Typography className={classes.subtitle} color="textSecondary" gutterBottom>
        <b>Form:</b>
        {' '}
        {form.title}
      </Typography>
      <Typography className={classes.subtitle} color="textSecondary" gutterBottom>
        <b>Last Updated in Form.IO:</b>
        {' '}
        {moment(form.modified).format('YYYY-MM-DD HH:ss')}
      </Typography>
    </>
  );

  const renderNoFormMessage = () => (
    <Typography className={classes.noFormText} color="textSecondary">
      No form set for this location
    </Typography>
  );

  const renderActionButtons = () => (
    <>
      {(
        // has abilities to edit forms
        abilities.forms.edit
        && (
          // A form exists, but no definition has been created in Epermitting for the site
          (hasForm && !hasDefinition)
          // a form and resources exists, and one or more resources has been updated
          || (hasForm && hasUpdatedResources)
          // a form exists and it has been updated
          || (hasForm && hasDefinition && definitionLastUpdated.isBefore(moment(form.modified)))
        )
      ) && (
        <Button
          variant="contained"
          size="small"
          className={classes.successButton}
          onClick={handlePublishLatest}
        >
          Publish Changes
        </Button>
      )}
      {hasForm && (
        <>
          {abilities.forms.edit && (
            <Button
              className={classes.metadataButton}
              onClick={handleMetadataRebuild}
              variant="contained"
              size="small"
              color="primary"
            >
              Rebuild Metadata
            </Button>
          )}
          <Button
            component="a"
            href={`${Config.FormIO.serverUrl}/#/project/${form.project}/form/${form._id}/edit`}
            target="_blank"
            variant="contained"
            size="small"
            color="primary"
          >
            View in form.io
          </Button>
        </>
      )}
    </>
  );

  return (
    <Card className={classes.card}>
      {saving && (
        <div className={classes.savingOverlay}>
          <CircularProgress color="primary" />
        </div>
      )}
      <div className={classes.cardContent}>
        <Grid container className={classes.header}>
          <Grid item xs={12} md={6}>
            <Typography className={classes.subtitle} color="textSecondary" gutterBottom>
              <b>LocationId:</b>
              {' '}
              {site.SiteId}
            </Typography>
            <Typography variant="h5">{site.Title}</Typography>
          </Grid>
          <Grid item xs={12} md={6} className={classes.headerRight}>
            {hasForm ? renderFormDetails() : renderNoFormMessage()}

            {hasForm && hasDefinition && definitionLastUpdated && (
              <Typography className={classes.subtitle} color="textSecondary" gutterBottom>
                <b>Last Published:</b>
                {' '}
                {definitionLastUpdated.format('YYYY-MM-DD HH:ss')}
              </Typography>
            )}

            {renderActionButtons()}
          </Grid>
        </Grid>
        {resources.length > 0 && (
          <Table className={classes.table} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Nested Form</TableCell>
                <TableCell align="center">Latest Form.IO Revision</TableCell>
                <TableCell align="center">Last Updated</TableCell>
                <TableCell align="center" />
                <TableCell align="center" />
              </TableRow>
            </TableHead>
            <TableBody>
              {resources.map((resource) => (
                <TableRow key={resource._id}>
                  <TableCell component="th" scope="row">
                    {resource.title}
                  </TableCell>
                  <TableCell align="center">{resource._vid}</TableCell>
                  <TableCell align="center">{moment(resource.modified).format('YYYY-MM-DD HH:ss')}</TableCell>
                  <TableCell align="center">
                    {
                      hasDefinition && definitionLastUpdated.isBefore(moment(resource.modified))
                        ? <Chip label="Not Published" />
                        : <Chip label="Published" className={classes.successButton} />
                    }
                  </TableCell>
                  <TableCell align="right">
                    <Button
                      component="a"
                      href={`${Config.FormIO.serverUrl}/#/project/${resource.project}/resource/${resource._id}/edit`}
                      target="_blank"
                      size="small"
                      color="primary"
                    >
                      View in form.io
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        )}
      </div>
    </Card>
  );
};

FormCard.defaultProps = {
  resources: [],
};

FormCard.propTypes = {
  site: Location.isRequired,
  form: FormIoForm.isRequired,
  resources: PropTypes.arrayOf(FormIoResource),
  definition: PropTypes.shape({
    EffectiveDate: PropTypes.string.isRequired,
  }).isRequired,
};
export default FormCard;
