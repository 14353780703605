import React from 'react';
import { useDispatch } from 'react-redux';

import {
  Button,
  CircularProgress,
  makeStyles,
  TextField,
} from '@material-ui/core';

import TemplatesState from '../../../../actions/admin/templates';

type Props = {
  PermitTemplateId: string;
  onSave: () => void;
  onCancel: () => void;
};

const useStyles = makeStyles((theme) => ({
  formWrapper: {
    marginTop: theme.spacing(2),
  },
  buttonWrapper: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: theme.spacing(2),
    alignItems: 'center',
  },
  saving: {
    marginLeft: theme.spacing(1),
    marginTop: 0,
  },
}));

const AddCommentForm: React.FC<Props> = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { onCancel, onSave, PermitTemplateId } = props;

  const [commentText, setCommentText] = React.useState<string>('');
  const [saving, setSaving] = React.useState<boolean>(false);

  const handleAddClick = async () => {
    setSaving(true);

    await dispatch(TemplatesState.actions.addComment(
      PermitTemplateId,
      commentText,
    ));

    onSave();
    setSaving(false);
  };

  return (
    <div className={classes.formWrapper}>
      <TextField
        variant="outlined"
        multiline
        rows={2}
        style={{ width: '100%' }}
        value={commentText}
        onChange={(e) => setCommentText(e.target.value)}
        disabled={saving}
      />
      <div className={classes.buttonWrapper}>
        {saving ? <CircularProgress className={classes.saving} size="20px" /> : (
          <>
            <Button
              size="small"
              color="primary"
              onClick={handleAddClick}
              disabled={saving}
            >
              Add Comment
            </Button>
            <Button size="small" onClick={onCancel}>
              Cancel
            </Button>
          </>
        )}
      </div>
    </div>
  );
};

AddCommentForm.defaultProps = {

};

AddCommentForm.propTypes = {

};

export default AddCommentForm;
