import React from 'react';
import { Autocomplete } from '@material-ui/lab';
import { TextField } from '@material-ui/core';

import { Template } from '../../../../types/template';
import useDefinitionForTemplates from '../../../../hooks/admin/useDefinitionForTemplate';

type Props = {
  onChange: (value: Template | undefined) => void;
}

const TemplateTypeAheadSearch: React.FC<Props> = (props) => {
  const { onChange } = props;

  const { forms } = useDefinitionForTemplates();

  const [inputValue, setInputValue] = React.useState<string>('');
  const [selectedTemplate, setSelectedTemplate] = React.useState<Template>();
  const [options] = React.useState<Template[]>([]);

  // const fetch = React.useMemo(
  //   () => _throttle((request, callback) => {
  //     queryElasticSearchWorkOrders(request, {}, homeLocation.SiteId).then(callback);
  //   }, 200),
  //   [homeLocation.SiteId],
  // );

  React.useEffect(() => {
    if (inputValue !== '') {
      onChange(selectedTemplate);
    }

  }, [onChange, inputValue, selectedTemplate]);

  React.useEffect(() => {
    const searchTemplates = async () => {
      // if (!inputValue) return;

      // const query = {
      //   size: 20,
      //   _source: ['PermitTemplateId', 'FormKey', 'Name'],
      //   query: {
      //     bool: {
      //       must: [
      //         {
      //           // this needs to have location as well as other already selected permits
      //           multi_match: {
      //             query: inputValue,
      //             operator: 'and',
      //             type: 'phrase_prefix',
      //             fields: ['Name', 'FormKey'],
      //           },
      //         },
      //         { term: { Type: 'permit' } },
      //         // { term: { State: 'APPROVED' } },
      //       ],
      //     },

      //   },
      // };


      // const result: any = await api.post('/templates/search', JSON.stringify(query));

      // if (result.hits) {
      //   const nextOptions = result.hits.hits.map((h: any) => h._source);
      //   setOptions(nextOptions)
      // }
    };

    searchTemplates();
  }, [inputValue]);

  return (
    <Autocomplete
      freeSolo
      options={options}
      onChange={(event, newValue) => {
        // setOptions(newValue ? [newValue, ...options] : options);
        setSelectedTemplate(newValue as Template);
      }}
      groupBy={(template: Template) => {
        const form = forms.find((f) => f.Name === template.FormKey);
        return form ? form.PermitType : (template.FormKey || '');
      }}
      getOptionLabel={(template: string | Template) => (
        typeof template === 'string' ? template : template.Name
      )}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      renderInput={(params) => <TextField {...params} label="Template" variant="outlined" />}
    />
  );
};

TemplateTypeAheadSearch.defaultProps = {

};

TemplateTypeAheadSearch.propTypes = {

};

export default TemplateTypeAheadSearch;
