import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { Button, CircularProgress } from '@material-ui/core';

import { ActionStatus } from '../../../actions/shared/status';
import AppState from '../../../actions/app';

const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
  },
}));

const ActionButton = (props) => {
  const classes = useStyles();

  const {
    disabled,
    label,
    loadingAction,
    onClick,
    secondary,
  } = props;

  const loadingState = useSelector(AppState.selectors.status(loadingAction));

  const handleOnClick = () => {
    onClick();
  };

  const loading = loadingState === ActionStatus.pending;
  const isDisabled = disabled || loading;

  return (
    <Button
      className={classes.button}
      color={secondary ? 'secondary' : 'primary'}
      disabled={isDisabled}
      size="small"
      onClick={handleOnClick}
      variant="contained"
    >
      {loading ? <CircularProgress size={22} /> : label}
    </Button>
  );
};

ActionButton.defaultProps = {
  disabled: false,
  loadingAction: undefined,
  secondary: false,
};

ActionButton.propTypes = {
  disabled: PropTypes.bool,
  loadingAction: PropTypes.string,
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  secondary: PropTypes.bool,
};

export default ActionButton;
