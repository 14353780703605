import { createStore, applyMiddleware, compose } from 'redux';
import { routerMiddleware } from 'connected-react-router';
import logger from 'redux-logger'
import thunk from 'redux-thunk';
import { createBrowserHistory } from 'history';
import { createOffline } from '@redux-offline/redux-offline';
import offlineConfig from '@redux-offline/redux-offline/lib/defaults';
import reducers from '../reducers/index';

import appMiddlewares from './middleware/app';
import permitsMiddlewares from './middleware/permits';
import analyticsMiddleware from './middleware/analytics';

import { apiWithAuth } from '../shared/api';

const history = createBrowserHistory();

const effectFn = (effect, _action) => {

  if (effect.customFnToRun) {
    return (new Promise((resolveEffect, rejectEffect) => {
      effect.customFnToRun(_action, resolveEffect, rejectEffect)
    }))
  }

  return apiWithAuth(effect)
    .then(({ data, status }) => ({ data, status }));
};

const discardFn = (error, _action, _retries) => {
  const { response } = error;
  return response && 400 <= response.status && response.status < 500;
};

const {
  middleware: offlineMiddleware,
  enhanceReducer: offlineEnhanceReducer,
  enhanceStore: offlineEnhanceStore,
} = createOffline({
  ...offlineConfig,
  persist: false,
  effect: effectFn,
  discard: discardFn,
});



const middleware = [
  thunk,
  routerMiddleware(history),
  offlineMiddleware,
  ...analyticsMiddleware,
  ...appMiddlewares,
  ...permitsMiddlewares,
  logger,
];

const composedEnhancers = compose(
  offlineEnhanceStore,
  applyMiddleware(...middleware),
);

const configureStore = () => {
  const store = createStore(
    offlineEnhanceReducer(reducers(history)),
    {},
    composedEnhancers,
  );

  return {
    store,
    history,
  };
};

export default configureStore();
