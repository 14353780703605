import React, { useEffect, useState } from 'react';
import {
  List,
  ListItem,
  ListItemText,
  Typography,
  Grid,
  makeStyles,
  Button,
  Checkbox
} from '@material-ui/core';
import { FixedSizeList } from "react-window";
import { useUpdateMember } from '../../../../../hooks/admin/useFetachGroupData';

const useStyles = makeStyles((theme) => ({
  listConatiner: {
    maxHeight: 500,
    minHeight: 200,
  },
  title: {
    display: 'inline',
    padding: theme.spacing(1.5),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(0.5),
    }
  },
  titleContainer: {
    borderBottom: '1px solid rgba(224, 224, 224, 1)',
    alignItems: 'center',
    display: 'flex',
    padding: theme.spacing(1),
  },
  noRowData: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    maxHeight: 500,
    minHeight: 200
  },
  actionBtn: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'end',
    '& button': {
      margin: theme.spacing(1),
      width: 120
    },
    [theme.breakpoints.down('sm')]: {
      '& button': {
        width: 'auto'
      },
    }
  },
  listItem: {
    '& li:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.04)'
    },
    cursor: 'Pointer'
  },
  box: {
    border: '1px solid rgba(224, 224, 224, 1)',
  },
  end: {
    display: 'flex',
    justifyContent: 'end'
  }
}));

const UserList = (props) => {

  const {selectedRow, fetchNewGroupData } = props;

  const classes = useStyles();

  const { Members } = selectedRow;

  const [selectedMembers, updateselectedMembers] = useState([]);
  const { updateMember, success, isLoading } = useUpdateMember();


  const onGroupSelection = (checked, item) => {
    if(checked) {
      updateselectedMembers([...selectedMembers, item])
    } else {
      updateselectedMembers(selectedMembers.filter(member => member.PersonnelId !== item.PersonnelId))
    }
  }

  const onRemoveUser = () => {
    const filtered = Members.filter(member => !selectedMembers.includes(member))
    const filteredMembers = filtered.map(data => data.PersonnelId);
    updateMember({...selectedRow,  Members: filteredMembers})
  }

  const onRemoveAllUser = () => {
    updateMember({...selectedRow, Members: []})
  }

  useEffect(() => {
    if (success) {
      fetchNewGroupData();
    }
  }, [success]);

  useEffect(() => {
    updateselectedMembers([]);
  }, [Members]);


  const GroupRow = (props) => {
    const { index, style} = props;
    const item = Members[index];
    const checked = !!selectedMembers.find(member => member.PersonnelId === item.PersonnelId);
    return(
      <div className={classes.listItem} onClick={() => onGroupSelection(!checked, item)}>
        <ListItem style={style} key={`item-${item.PersonnelId}`}>
        <ListItemText primary={`${item.FirstName} ${item.LastName}`} />
        <Checkbox
          color="primary"
          edge="end"
          checked={checked}
        />
      </ListItem>
      </div>
    )
  }

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={12}>
        <div className={classes.box}>
          <div className={classes.titleContainer}>
            <Grid item md={3} xs={12}>
              <Typography className={classes.title} variant="button" display="block" gutterBottom>Group Members</Typography>
            </Grid>
            <Grid item md={9} xs={12} className={classes.actionBtn}>
              <Button disabled={!selectedMembers.length} variant="contained" color="primary" onClick={onRemoveUser}>Remove</Button>
              <Button disabled={!Members.length} variant="contained" color="primary" onClick={onRemoveAllUser}>Remove All</Button>
            </Grid>
          </div>
          <List
            className={classes.listConatiner}
            sx={{
              width: '100%',
              maxWidth: 360,
              bgcolor: 'background.paper',
              position: 'relative',
              overflow: 'auto',
              maxHeight: 500,
              '& ul': { padding: 0 },
            }}
            subheader={<li />}
          >{
            Members.length ? (
              <FixedSizeList
                className="List"
                height={500}
                itemCount={Members.length}
                itemSize={45}
              >
              {GroupRow}
            </FixedSizeList>
            ) : <div className={classes.noRowData}>No Group Member Found.</div>
          }
          </List>
        </div>
      </Grid>
      
    </Grid>
  );
};

export default UserList;
