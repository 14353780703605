import React from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import AppState from '../../actions/app';
import PermitsState from '../../actions/permits';
import { ActionStatus } from '../../actions/shared/status';

/**
 * Retrieve a permit
 * @param {string} PermitInstanceId - Id of permit instance to update
 * @param {boolean} fetchApprovals - should approvals be fetched. Defaults to true
 * @return {{ error?: any; loading: boolean, permit: Permit }}
 */
const useGetPermit = (PermitInstanceId, fetchApprovals = true) => {
  const { actions, selectors, types } = PermitsState;

  const dispatch = useDispatch();
  const permit = useSelector(selectors.selected(), shallowEqual);
  const loadingState = useSelector(AppState.selectors.status(types.getSinglePermit), shallowEqual);

  React.useEffect(() => {
    if (!PermitInstanceId) return;
    dispatch(actions.getPermitById(PermitInstanceId, fetchApprovals));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [PermitInstanceId]);
  return {
    error: loadingState === ActionStatus.rejected,
    loading: loadingState === ActionStatus.pending,
    permit,
  };
};

export default useGetPermit;
