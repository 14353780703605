import React from "react";
import { useHistory } from "react-router-dom";
import { v4 as uuid } from "uuid";
import { Button, Typography } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import moment from "moment";

import Dialog from "../shared/Dialog";
import WorkOrderTypeAheadInput from "../shared/WorkOrderTypeAheadInput";
import VirtualizedSelectList from "../shared/VirtualizedSelectList";
import useOffline from "../../hooks/shared/useOffline";
import { WorkOrder } from "../../types/work-order";
import useWorkOrder from "../../hooks/work-orders/useWorkOrder";

type Props = {
  workOrder?: string;
  disableClose?: boolean;
  onClose: () => void;
  onChange: (workOrder: WorkOrder) => void;
}

const SearchWorkOrderDialog = (props: Props) => {
  const { disableClose, onClose, onChange, workOrder } = props;
  const [selected, setSelected] = React.useState<WorkOrder>();

  const { workOrder: selectedWorkOrder } = useWorkOrder(workOrder as string)

  const { online } = useOffline();

  React.useEffect(() => {
    setSelected(selectedWorkOrder)
  }, [selectedWorkOrder])

  const dialogActions = React.useMemo(() => {
    const selectAction = {
      text: 'Select work order',
      color: 'primary',
      onClick: () => onChange(selected as WorkOrder),
    };

    const cancelAction = {
      onClick: () => onClose(),
      text: 'Cancel',
     };

    return selected
      ? [selectAction, cancelAction]
      : [cancelAction];
  }, [selected, onClose, onChange]);

  return (
    <Dialog
      visible
      disableClose={disableClose}
      onClose={onClose}
      title="Select Work Order"
      actions={dialogActions}
    >
      {!!selected ? (
        <>
          <Typography variant="caption">Work order number</Typography>
          <Typography gutterBottom>
            <b>{selected.workOrderNumber}</b>
          </Typography>
          <Typography variant="caption">Work order description</Typography>
          <Typography gutterBottom>{selected.workOrderDescription}</Typography>
          <Typography variant="caption">Scheduled start date</Typography>
          <Typography gutterBottom>{selected.scheduledStart}</Typography>
          <Button
            style={{ marginLeft: -8 }}
            color="secondary"
            size="small"
            onClick={() => setSelected(undefined)}
          >
            Select a different WorkOrder
          </Button>
        </>
      ) : (
        <>
          <Typography gutterBottom>
            Choose from recent work orders or search for the workorder
            associated with this permit
          </Typography>
          {online ? (
            <WorkOrderTypeAheadInput onChange={(w: WorkOrder) => setSelected(w)} />
          ) : (
            <>
              <VirtualizedSelectList
                onChange={(workOrder) => setSelected(workOrder as WorkOrder)}
              />

              <Alert severity="info" style={{ marginTop: 20 }}>
                Only work orders with a scheduled start date between{" "}
                {moment().subtract(3, "week").format("YYYY-MM-DD")} -{" "}
                {moment().add(1, "week").format("YYYY-MM-DD")} are available
                offline
              </Alert>
            </>
          )}
        </>
      )}
    </Dialog>
  );
};

export default SearchWorkOrderDialog;
