import React, { useEffect, useState }  from "react";
import { useSelector } from 'react-redux';
import AuthState from '../../actions/auth';
import useQuery from '../../hooks/shared/useQuery';

export const useGetScreenFilters = ({screen}) => {
  const preferences = useSelector(AuthState.selectors.preferences()) || {};
  const [filterData, updateFilterData] = useState([]);
  const urlQuery = useQuery();

  useEffect(() => {
    const data = preferences && preferences[screen] || [];
    updateFilterData(data);
  }, [screen, preferences]);

  const activeFilterData = () => {
    if(fromReporting) return {};
    return filterData && filterData.find(data => data.Active) || filterData && filterData.length && filterData[0]
  }

  const fromReporting = urlQuery.from === 'reporting';

  return {
    activeFilter: activeFilterData(),
    filterList: fromReporting ? [] : filterData,
    fromReporting,
    query: urlQuery
  }
};
