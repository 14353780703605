import React, { useEffect, useState, useCallback } from 'react';
import { debounce } from 'lodash';
import {
  List,
  ListItem,
  ListItemText,
  Typography,
  Grid,
  makeStyles,
  Button,
  Checkbox,
  Box,
  TextField,
  Paper,
  FormControlLabel,
  IconButton,
  InputAdornment
} from '@material-ui/core';

import SearchIcon from "@material-ui/icons/Search";

import { FixedSizeList } from "react-window";
import { useSearchUser, useUpdateMember } from '../../../../../hooks/admin/useFetachGroupData';

const useStyles = makeStyles((theme) => ({
  listConatiner: {
    maxHeight: 500,
    minHeight: 200,
  },
  titleContainer: {
    borderBottom: '1px solid rgba(224, 224, 224, 1)',
    alignItems: 'center',
    display: 'flex',
    padding: theme.spacing(1),
  },
  noRowData: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    maxHeight: 500,
    minHeight: 200
  },
  actionBtn: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'end',
    '& button': {
      margin: theme.spacing(1),
      width: 120
    },
    [theme.breakpoints.down('sm')]: {
      '& button': {
        width: 'auto'
      },
    }
  },
  listItem: {
    '& li:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.04)'
    },
    cursor: 'Pointer'
  },
  box: {
    border: '1px solid rgba(224, 224, 224, 1)',
  },
  end: {
    display: 'flex',
    justifyContent: 'end'
  },
  container: {
    borderBottom: '1px solid rgba(224, 224, 224, 1)',
  },
  checkbox: {
    marginLeft: -9,
    paddingRight: 30
  },
  fieldContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingTop: theme.spacing(3),
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      display: 'block'
    },
    '& > *': {
      minWidth: 150,
      marginBottom: theme.spacing(2),
      [theme.breakpoints.down('sm')]: {
        display: 'block'
      },
    },
    '& > label': {
      display: 'inline-block',
      padding: 0,
      margin: 0
    },
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),

  },
  title: {
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(0.5),
    },
    paddingLeft: 12,
    margin: 0
  }
}));

const UserList = (props) => {

  const { selectedRow, fetchNewGroupData, title } = props;
  const { Members } = selectedRow;

  const classes = useStyles();

  const [selectedUser, updateSelectedUser] = useState([]);
  const [userList, updateUsers] = useState([]);
  const { updateMember, success } = useUpdateMember();


  const [serachTerm, updateSerachTerm] = useState({ FirstName: '', LastName: '', isOnlySiteSearch: true });
  const { FirstName, LastName, isOnlySiteSearch } = serachTerm;
  const { users: searchedUsers, error, isLoading: isUserLoading, fetchUsers } = useSearchUser();

  const onFieldChange = e => {
    updateSelectedUser([]);
    updateSerachTerm({ ...serachTerm, [e.target.name]: e.target.value })
  }

  const onCheckBoxChange = e => {
    updateSelectedUser([])
    updateSerachTerm({ ...serachTerm, [e.target.name]: e.target.checked })
  }

  const onUserSearch = () => {
    fetchUsers(serachTerm)
  }

  const debouncedOnUserSearch = useCallback(debounce((serachTerm) => fetchUsers(serachTerm), 200), []);

  useEffect(() => {
    debouncedOnUserSearch(serachTerm);
  }, [FirstName, LastName, isOnlySiteSearch, serachTerm])

  useEffect(() => {
    if (success) {
      onUserSearch();
      fetchNewGroupData();
    }
  }, [success])

  useEffect(() => {
    updateSelectedUser([]);
    updateUsers([]);
    updateSerachTerm({ FirstName: '', LastName: '', isOnlySiteSearch: true })
  }, [Members]);

  useEffect(() => {
    updateUsers(searchedUsers)
  }, [searchedUsers]);


  const onUserSelection = (checked, item) => {
    if (checked) {
      updateSelectedUser([...selectedUser, item])
    } else {
      updateSelectedUser(selectedUser.filter(user => user.PersonnelId !== item.PersonnelId))
    }
  }

  const onAddUser = () => {
    const filteredMembers = [...selectedUser, ...Members].map(data => data.PersonnelId);
    updateMember({ ...selectedRow, Members: filteredMembers })
  }

  const onAddAllUser = () => {
    const filteredMembers = [...userList, ...Members].map(data => data.PersonnelId);
    updateMember({ ...selectedRow, Members: filteredMembers })
  }


  const UserRow = (props) => {
    const { index, style } = props;
    const item = userList[index];
    const checked = !!selectedUser.find(user => user.PersonnelId === item.PersonnelId);
    return (
      <div className={classes.listItem} onClick={() => onUserSelection(!checked, item)}>
        <ListItem style={style} key={`item-${item.PersonnelId}`}>
          <Grid item xs={5}>
            <ListItemText primary={`${item.FirstName} ${item.LastName}`} />
          </Grid>
          <Grid item xs={6}>
            <ListItemText secondary={`${item.SiteName}`} />
          </Grid>
          <Grid item xs={1}>
            <Checkbox className={classes.end} color="primary" edge="end" checked={checked} />
          </Grid>
        </ListItem>
      </div>
    )
  }

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={12}>
        <div className={classes.box}>
          <div className={classes.titleContainer}>
            <Grid item xs={3} md={3}>
              <Typography className={classes.title} variant="button" display="block" gutterBottom>Users</Typography>
            </Grid>
            <Grid item xs={9} md={9} className={classes.actionBtn}>
              <Button disabled={!selectedUser.length} variant="contained" color="primary" onClick={onAddUser}>Add</Button>
              <Button disabled={!selectedUser.length} variant="contained" color="primary" onClick={onAddAllUser}>Add All</Button>
            </Grid>
          </div>
          <Paper elevation={0}>
            <Box
              component="form"
              noValidate
              className={classes.container}
            >
              <Box className={classes.fieldContainer}>
                <TextField
                  name="FirstName"
                  variant="standard"
                  value={FirstName}
                  label="First Name"
                  onChange={onFieldChange}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton>
                          <SearchIcon />
                        </IconButton>
                      </InputAdornment>
                    )
                  }}
                />
                <TextField
                  maxRows={2}
                  name="LastName"
                  variant="standard"
                  value={LastName}
                  label="Last Name"
                  onChange={onFieldChange}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton>
                          <SearchIcon />
                        </IconButton>
                      </InputAdornment>
                    )
                  }}
                />
                <FormControlLabel
                  className={classes.checkbox}
                  value="start"
                  control={
                    <Checkbox
                      name="isOnlySiteSearch"
                      onChange={onCheckBoxChange}
                      color='primary'
                      checked={!!isOnlySiteSearch}
                    />
                  }
                  label={`Only ${title} users`}
                  labelPlacement="end"
                />
              </Box>
            </Box>
          </Paper>
          <List
            className={classes.listConatiner}
            sx={{
              width: '100%',
              maxWidth: 360,
              bgcolor: 'background.paper',
              position: 'relative',
              overflow: 'auto',
              maxHeight: 500,
              '& ul': { padding: 0 },
            }}
            subheader={<li />}
          >{
              userList.length ? (
                <FixedSizeList
                  className="List"
                  height={500}
                  itemCount={userList.length}
                  itemSize={45}
                >
                  {UserRow}
                </FixedSizeList>
              ) : <div className={classes.noRowData}>{isUserLoading ? 'Loading ......' : 'No User Found.'}</div>
            }
          </List>
        </div>
      </Grid>
    </Grid>
  );
};

export default UserList;
