import React from 'react';

import useQuery from '../../../hooks/shared/useQuery';
import { TemplateType } from '../../../types/template';

import CreateWorkTemplate from './CreateWorkTemplate';
import CreateEditTemplate from './CreateEditTemplate';

const CreateTemplate: React.FC = () => {
  const { type } = useQuery<{ type: TemplateType }>();

  return type === 'work'
    ? <CreateWorkTemplate />
    : <CreateEditTemplate />;
};

export default CreateTemplate;
