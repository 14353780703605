import { ListItem } from '../../../types/list-item';

export const TYPES = {
  getListItems: 'ADMIN_LIST_ITEMS_GET',
  setListItems: 'ADMIN_LIST_ITEMS_SET',
  setListsList: 'ADMIN_LISTS_LIST_SET',
};

export interface SetListItemsAction {
  type: typeof TYPES.setListItems,
  payload: {
    fieldKey: string;
    items: ListItem[];
  }
}

export interface SetListsListAction {
  type: typeof TYPES.setListsList,
  payload: string[];
}

export type ListsActionTypes = SetListItemsAction | SetListsListAction;
