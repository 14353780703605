import { combineReducers } from 'redux';

import lists from './lists';
import templates from './templates';
import externalUsers from './external-users';

const adminReducer = combineReducers({
  lists,
  templates,
  externalUsers,
});

export default adminReducer;
