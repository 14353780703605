import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, useTheme } from '@material-ui/core/styles';

import {
  Chip,
  FormControl,
  Input,
  InputLabel,
  MenuItem,
  Select,
} from '@material-ui/core';
import { generateRandomString } from '../../../shared/string';

const useStyles = makeStyles((theme) => ({
  formControl: {
    flexGrow: 1,
    minWidth: '100%',
  },
  chips: {
    display: 'flex',
    flexGrow: 1,
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 'auto',
    },
  },
};

function getStyles(name, selected, theme) {
  if (!selected) {
    return {};
  }

  return {
    fontWeight:
      selected.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

const MultipleSelect = (props) => {
  const { className, label, options, onSelect, defaultValue } = props;
  const classes = useStyles();
  const theme = useTheme();

  const [selected, setSelected] = React.useState(defaultValue);
  const ids = React.useRef({
    label: generateRandomString(),
    input: generateRandomString(),
    select: generateRandomString(),
  });

  React.useEffect(() => {
    if (!Array.isArray(defaultValue)) {
      throw new Error('defaultValue must be an array');
    }

    setSelected(defaultValue);
  }, [defaultValue]);

  const handleChange = (event) => {
    setSelected(event.target.value);
    onSelect(event.target.value);
  };

  return (
    <div className={className}>
      <FormControl className={classes.formControl}>
        <InputLabel id={ids.current.label}>{label}</InputLabel>
        <Select
          labelId={ids.current.label}
          id={ids.current.select}
          multiple
          value={selected}
          onChange={handleChange}
          input={<Input id={ids.current.input} />}
          renderValue={(items) => (
            <div className={classes.chips}>
              {items.map((value) => {
                const option = options.find((op) => op.value === value);
                return <Chip key={value} label={(option && option.label) || value} className={classes.chip} />;
              })}
            </div>
          )}
          MenuProps={MenuProps}
        >
          {options.map((option) => (
            <MenuItem key={option.value} value={option.value} style={getStyles(option.value, selected, theme)}>
              {option.label || option.value}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
};

MultipleSelect.defaultProps = {
  className: undefined,
};

MultipleSelect.propTypes = {
  label: PropTypes.string.isRequired,
  defaultValue: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  onSelect: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string,
    }),
  ).isRequired,
  className: PropTypes.string,
};

export default MultipleSelect;
