import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { get as _get } from 'lodash';

import DefinitionsState from '../../actions/definitions';
import Collections from '../../store/indexeddb';
import useLocations from '../locations/useLocations';
import { endsWithNumber } from '../../shared/string';
import { recordEvent } from '../../shared/analytics';

/**
 * Checks if a string ends in an number
 * From: https://stackoverflow.com/a/14294295
 * @param {boolean} filterMultiples - Filter out permit types that are numbered multiples
 * - eg return "Fall Protection" but not "Fall Protection 1"
 * @return {PermitTypeForm} - array of permit type forms
 */

const usePermitTypes = (filterMultiples = false) => {
  const [types, setTypes] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const { selected } = useLocations();
  const siteDefinitionId = useSelector(DefinitionsState.selectors.definitionIdForSite(selected.LocationId));

  useEffect(() => {
    const getTypes = async () => {
      let definition = await Collections.Definitions.get(siteDefinitionId);

      if (!definition) {
        // there is an edge case where if the user loads the app straight to a page where this hook is used
        // the definition may not yet have loaded in the background. In that case, we wait 3 seconds and try loading it again
        await new Promise((resolve) => setTimeout(resolve, 2000));
        definition = await Collections.Definitions.get(siteDefinitionId);

        // Check again and check logs
        if (!definition) {
          recordEvent({ data: 'Hooks: usePermitTypes - definition not loaded even after 2 sec'});
          await new Promise((resolve) => setTimeout(resolve, 2000));
          definition = await Collections.Definitions.get(siteDefinitionId);
          if (!definition) {
            recordEvent({ data: 'Hooks: usePermitTypes - definition not loaded even after 4 sec'});
          } else {
            recordEvent({ data: 'Hooks: usePermitTypes - definition loaded after 4 sec'});
          }
        }
      }

      let formsInDefinition = _get(definition, 'definition.DefinitionMetadataJSON.Forms', []);

      if (filterMultiples) {
        formsInDefinition = formsInDefinition.filter((fo) => !endsWithNumber(fo.PermitType));
      }

      setTypes(formsInDefinition);
      setLoading(false);
    };

    setLoading(true);
    getTypes();
  }, [filterMultiples, selected, siteDefinitionId]);

  return { loading, types };
};

export default usePermitTypes;
