import { toast } from 'react-toastify';

const defaultConfig = {
  position: toast.POSITION.TOP_CENTER,
};

const success = (message) => {
  toast.success(message, { ...defaultConfig });
};

const error = (message) => {
  toast.error(message, { ...defaultConfig });
};

export default {
  success,
  error,
};
