import React from "react";

import {
  makeStyles,
  Button,
  ButtonProps,
  Dialog as MuiDialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Typography,
} from "@material-ui/core";

import { Close as CloseIcon } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}));

interface DialogAction extends ButtonProps {
  text: string;
}

interface Props {
  disableClose?: boolean;
  onCancel?: () => void;
  onClose?: () => void;
  visible?: boolean;
  actions?: DialogAction[];
  title: string;
  footerLeft?: React.ReactNode;
}

const Dialog: React.FC<Props> = (props) => {
  const { onCancel, footerLeft } = props;

  const classes = useStyles();
  const [open, setOpen] = React.useState(props.visible || false);

  const handleClose = () => {
    if (!props.disableClose) {
      setOpen(false);

      if (props.onClose) {
        props.onClose();
      }
    }
  };

  return (
    <MuiDialog
      onClose={props.disableClose ? undefined : handleClose}
      aria-labelledby="customized-dialog-title"
      open={open}
      fullWidth={true}
    >
      <DialogTitle disableTypography className={classes.root}>
        <Typography variant="h6">{props.title}</Typography>
        {!props.disableClose && handleClose && (
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={handleClose}
          >
            <CloseIcon />
          </IconButton>
        )}
      </DialogTitle>

      {props.children && (
        <DialogContent dividers>{props.children}</DialogContent>
      )}
      {props.actions && (
        <DialogActions>
          {footerLeft}
          {props.actions.map(({ text, ...action }, i) => (
            <Button {...action} key={`${i}-${text}`}>
              {text}
            </Button>
          ))}
          {onCancel && (
            <Button onClick={() => onCancel()} color="secondary">
              Cancel
            </Button>
          )}
        </DialogActions>
      )}
    </MuiDialog>
  );
};

export default Dialog;
