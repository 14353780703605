import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import PermitsState from '../../actions/permits';
import AppState from '../../actions/app';
import { ActionStatus } from '../../actions/shared/status';

const useDrafts = () => {
  const dispatch = useDispatch();
  const { actions, selectors, types } = PermitsState;

  const drafts = useSelector(selectors.draftsList());
  const loading = useSelector(AppState.selectors.status(types.getDrafts));

  React.useEffect(() => {
    dispatch(actions.getDrafts());

    return () => {
      dispatch(AppState.actions.resetStatus(types.getDrafts));
      dispatch(actions.clearDraftsList());
    };
    // disabling as it should only ever execute exactly once
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    drafts,
    loading: loading === ActionStatus.pending,
  };
};

export default useDrafts;
