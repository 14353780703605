import React from 'react';
import moment from 'moment';
import { Button, Typography, Theme, createStyles, makeStyles } from '@material-ui/core';
import { Alert } from "@material-ui/lab";

import BaseAccordian from './BaseAccordian';
import WorkOrderTypeAheadInput from '../../shared/WorkOrderTypeAheadInput';
import { WorkOrder } from '../../../types/work-order';
import useOffline from '../../../hooks/shared/useOffline';
import VirtualizedSelectList from '../../shared/VirtualizedSelectList';
import { isEqual } from 'lodash';


const useStyles = makeStyles((theme: Theme) => createStyles({
  headerRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
    [theme.breakpoints.up("md")]: {
      justifyContent: 'inherit',
      '&> *': {
        marginRight: theme.spacing(8)
      }
    },
  },
  wrapper: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column'
  }
}));

type Props = {
  toggleAccordian: (name?: string) => any;
  expanded: boolean;
  onChange: (workOrder?: WorkOrder) => void;
  workOrder?: WorkOrder
  name: string;
}

const WorkOrderAccordian: React.FC<Props> = (props) => {
  const {
    expanded,
    name,
    onChange,
    toggleAccordian,
    workOrder
  } = props;

  const classes = useStyles();
  const { online } = useOffline();

  const [selectedWorkOrder, setSelectedWorkOrder] = React.useState<WorkOrder | undefined>(workOrder);

  React.useEffect(() => {
    onChange(selectedWorkOrder);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedWorkOrder]);

  const handleWorkOrderSelected = (nextWorkOrder?: WorkOrder) => {
    if (!isEqual(selectedWorkOrder, nextWorkOrder)) {
      setSelectedWorkOrder(nextWorkOrder)
    }
  };

  const actions = (
    <>
      {selectedWorkOrder && (
        <Button
          style={{ marginLeft: -8, maxWidth: 230 }}
          color="secondary"
          size="small"
          onClick={() => handleWorkOrderSelected(undefined)}
        >
          Clear selected work order
        </Button>
      )}
      <Button size="small" onClick={() => toggleAccordian()}>Close</Button>
    </>
  );

  return (
    <BaseAccordian
      actions={actions}
      expanded={expanded}
      name={name}
      heading="Work order"
      onChange={toggleAccordian}
      secondaryHeading={selectedWorkOrder ? selectedWorkOrder.workOrderNumber : "None Selected"}
    >
      <div className={classes.wrapper}>
        <Typography>
          Choose from recent work orders or search for the workorder
          associated with this permit
        </Typography>

        {online ? (
          <WorkOrderTypeAheadInput
            value={selectedWorkOrder}
            onChange={handleWorkOrderSelected}
          />
        ) : (
          <>
            <VirtualizedSelectList
              onChange={handleWorkOrderSelected}
            />

            <Alert severity="info" style={{ marginTop: 20 }}>
              Only work orders with a scheduled start date between{" "}
              {moment().subtract(3, "week").format("YYYY-MM-DD")} -{" "}
              {moment().add(1, "week").format("YYYY-MM-DD")} are available
                offline
              </Alert>
          </>
        )}

        {selectedWorkOrder && (
          <>
            <div className={classes.headerRow}>
              <div>
                <Typography variant="caption">Work order number</Typography>
                <Typography gutterBottom>
                  <b>{selectedWorkOrder.workOrderNumber}</b>
                </Typography>
              </div>
              <div>
                <Typography variant="caption">Scheduled start date</Typography>
                <Typography gutterBottom>{selectedWorkOrder.scheduledStart}</Typography>
              </div>

            </div>
            <Typography variant="caption">Work order description</Typography>
            <Typography gutterBottom>{selectedWorkOrder.workOrderDescription}</Typography>
          </>
        )}

      </div>
    </BaseAccordian>
  );
}

export default WorkOrderAccordian;
