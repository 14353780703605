import React from 'react';
import { Formio } from 'formiojs';
import { useDispatch } from 'react-redux';

import PermitsState from '../../actions/permits';
import Config from '../../config/app-config';

import 'formiojs/dist/formio.form.min.css';
import useAnalytics from '../shared/useAnalytics';

type onChange = (formData: any, formRef: any) => void;
type onSubmit = (submission: any, formRef: any) => void | undefined


const useForm = (elementId:string, components:any, formData = {}, onChange: onChange, onSubmit?: onSubmit, state?: any, draftId?: string) => {
  
  const formRef = React.useRef<any>();
  const dispatch = useDispatch();
  const recordEvent = useAnalytics();

  const [formId, setFormId] = React.useState();
  const [ready, setReady] = React.useState(false);

  // effect that creates and manages the interval for regularly saving a draft permit
  React.useEffect(() => {
    if (!draftId) return undefined;
    const interval = setInterval(
      () => {
        if (formRef.current) {
          dispatch(
            PermitsState.actions.saveDraft(draftId, formRef.current.submission),
          );
        }
      },
      Config.Permits.saveDraftInterval,
    );

    return () => {
      if (formRef.current) {
        formRef.current.destroy(true)
        formRef.current = undefined;
      }
      setReady(false);
      clearInterval(interval);
      dispatch(PermitsState.actions.clearDraft());
    };
  }, [dispatch, draftId]);

  // effect for create and managing the Form.io form
  React.useEffect(() => {
    if (!components) return;

    recordEvent('Build of form.io form tiggered by change of components or elementId');

    Formio.createForm(
      document.getElementById(elementId),
      { components },
      { nosubmit: true, noAlerts: true },
    ).then((form) => {
      if (formData && Object.keys(formData).length > 0) {
        // eslint-disable-next-line no-param-reassign
        form.submission = formData;
      }

      if (onChange) {
        form.on('change', (event: any) => onChange(event, formRef.current));
      }

      if (onSubmit) {
        form.on('submit', (submission: any) => onSubmit(submission, formRef.current));
      }

      form.nosubmit = true;

      if (formRef.current) {
        formRef.current.destroy(true)
        formRef.current = undefined;
      }

      formRef.current = form;
      setFormId(formRef.current.id);
    }).finally(() => {
      setReady(true);
    });

    // disabling here because we only want to rebuild the full form when the components change
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [elementId, components]
  );

  // efect for handling the onChange event
  React.useEffect(() => {
    if (!ready || !formRef?.current) return;

    recordEvent('Form.io: onChange handler changed, updating handler with new value');
    // clear out the existing onChange function before adding the updated one
    formRef.current.off('change');
    formRef.current.on('change', (event: any) => onChange(event, formRef.current));
  }, [onChange, ready]);

  // effect for handling the onSubmit event
  React.useEffect(() => {
    if (!ready || !formRef?.current) return;

    recordEvent('Form.io: onSubmit handler changed, updating handler with new value');

    // clear out the existing onSubmit function before adding the updated one
    formRef.current.off('submit');
    formRef.current.on('submit', (submission: any) => {
      onSubmit && onSubmit(submission, formRef.current)
    })

  }, [onSubmit, ready]);

  React.useEffect(() => {
    if (ready && formRef && formRef.current && formData && Object.keys(formData).length > 0) {
      recordEvent({
        data: 'Form.io: Intial form data changed, setting form submission value',
        formData: { ...formData }
      });

      formRef.current.submission = { ...formData };
    }
  }, [formData, ready]);

  return { form: formRef.current, ready, formId };
};

export default useForm;
