import React from 'react';
import { Route } from 'react-router-dom';

import OpenPermitsReport from './open-permits';

const ReportsRoutes = () => {
  return (
    <div className="reports">
      <Route path="/reports/open-permits" component={OpenPermitsReport} />
    </div>
  );
};

export default ReportsRoutes;
