import React from 'react';
import PropTypes from 'prop-types';
import Leaflet from 'leaflet';
import ReactDOM from 'react-dom';
import ReactDOMServer from 'react-dom/server';
import { get as _get, find as _find } from 'lodash';

import { push } from 'connected-react-router';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Button, makeStyles, Typography } from '@material-ui/core';
import { RotateLeft as RotateLeftIcon } from '@material-ui/icons';

import PermitStateChip from '../permits/shared/PermitStateChip';

import AppState from '../../actions/app';
import LocationsState from '../../actions/locations';

import PermitsConfig from '../../config/permits';

const useStyles = makeStyles((theme) => ({
  resetZoomWrapper: {
    position: 'absolute',
    width: 30,
    height: 30,
    top: 80,
    left: 12,
    zIndex: 999,
  },
  resetZoomButton: {
    width: 30,
    height: 30,
    minWidth: 'unset',
    background: 'white',
  },
  workDescription: {
    display: 'block',
    marginBottom: 0,
    marginTop: 0,
    fontSize: theme.typography.pxToRem(14),
  },
}));

const Icon = ({ fill }) => (
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 365 560"
    enableBackground="new 0 0 365 560"
  >
    <g>
      <path
        fill={fill}
        // eslint-disable-next-line max-len
        d="M182.9,551.7c0,0.1,0.2,0.3,0.2,0.3S358.3,283,358.3,194.6c0-130.1-88.8-186.7-175.4-186.9 C96.3,7.9,7.5,64.5,7.5,194.6c0,88.4,175.3,357.4,175.3,357.4S182.9,551.7,182.9,551.7z M122.2,187.2c0-33.6,27.2-60.8,60.8-60.8 c33.6,0,60.8,27.2,60.8,60.8S216.5,248,182.9,248C149.4,248,122.2,220.8,122.2,187.2z"
      />
    </g>
  </svg>
);

Icon.propTypes = {
  fill: PropTypes.string.isRequired,
};

const workIcon = (permitState) => Leaflet.divIcon({
  html: ReactDOMServer.renderToString(<Icon fill={PermitsConfig[permitState].color} />),
  className: 'icon-wrapper',
  iconSize: [28, 40],
  popupAnchor: [10, -41],
});

const LeafletMap = (props) => {
  const classes = useStyles();
  const [error, setError] = React.useState();
  const homeLocation = useSelector(AppState.selectors.homeLocation());

  const { PlotPlans = [], CurrentPlotPlan } = homeLocation;
  const plotplan = _find(PlotPlans, (data) => (data.Version === CurrentPlotPlan)) || {};
  
  const { ZoomLevel, Center, Maximum, Minimum } = plotplan;

  const plotPlanImageUrl = useSelector(LocationsState.selectors.plotPlanImage(homeLocation.LocationId));

  const dispatch = useDispatch();
  const mapRef = React.useRef();

  const renderResetZoomButton = () => (
    <Box boxShadow={3} className={classes.resetZoomWrapper}>
      <Button
        variant="contained"
        size="small"
        className={classes.resetZoomButton}
        onClick={() => {
          mapRef.current.flyTo(Center, ZoomLevel);
        }}
      >
        <RotateLeftIcon fontSize="small" />
      </Button>
    </Box>
  );

  React.useEffect(() => {
    if (!plotPlanImageUrl) {
      return;
    }

    if (mapRef.current && mapRef.current.remove) {
      mapRef.current.off();
      mapRef.current.remove();
    }

    mapRef.current = Leaflet.map('map', {
      crs: Leaflet.CRS.Simple,
      minZoom: Minimum,
      maxZoom: Maximum
    });

    const fetchData = async () => {
      const bounds = [[0, 0], [660, 1020]];
      const overlay = Leaflet.imageOverlay(plotPlanImageUrl, bounds).addTo(mapRef.current);

      overlay.on('error', () => {
        // disable all interations on the map
        // eslint-disable-next-line no-underscore-dangle
        mapRef.current._handlers.forEach((h) => h.disable());
        setError(true);
      });

      mapRef.current.fitBounds(bounds);
      if (ZoomLevel && Center?.length) {
        mapRef.current.setView({ lat: Center[0], lng: Center[1] }, ZoomLevel);
      }

      const handleMarkerDrag = (e) => {
        if (props.onMarkerMove) {
          props.onMarkerMove(e.latlng);
        }
      };

      if (props.useInitialMarker) {
        let initialLocation = mapRef.current.getCenter();

        if (Center?.length) {
          initialLocation = { lat: Center[0], lng: Center[1] };
        }
        const marker = Leaflet
          .marker(initialLocation, { icon: workIcon('DRAFT'), draggable: !props.readOnly })
          .addTo(mapRef.current);

        marker.on('drag', handleMarkerDrag);
      }

      props.markers.forEach((marker) => {
        const permitState = _get(marker, 'permit.State', 'DRAFT');
        const mark = Leaflet
          .marker(marker, { icon: workIcon(permitState), draggable: !props.readOnly })
          .addTo(mapRef.current)

        if (marker.permit) {
          const markerContent = document.createElement('div');

          ReactDOM.render(
            <div>
              <PermitStateChip state={permitState} />
              <p>
                <b>
                  #
                  {marker.permit.PermitInstanceReferenceId}
                </b>
                <br />
              </p>
              {(marker.permit.workDescription || []).map((description) => (
                <div key={description.parentForm}>
                  <Typography component="span" className={classes.workDescription}>
                    <b>{description.parentForm}</b>
                  </Typography>
                  <Typography component="span" className={classes.workDescription}>
                    {description.workDescription}
                  </Typography>
                </div>
              ))}
              <Button
                onClick={() => dispatch(push(`/permits/${marker.permit.PermitInstanceId}/edit`))}
                className="sitemap-pointer-link"
                role="document"
              >
                View Permit
              </Button>
            </div>,
            markerContent,
          );

          mark.bindPopup(markerContent);
        }

        if (marker.onMarkerMove) {
          mark.on('drag', (e) => marker.onMarkerMove(e.latlng));
        }
      });
    };

    fetchData();
    // disabling as we only ever want this to be called exactly once
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [plotPlanImageUrl, Maximum, Minimum]);

  if (error) {
    return (
      <div style={{ minHeight: '30vh', textAlign: 'center', paddingTop: '40px' }}>
        There was a problem loading the site map at this location
      </div>
    );
  }

  return (
    <>
      <div id="map" style={{ height: '50vh', width: '100%' }}>
        {renderResetZoomButton()}
      </div>
    </>
  );
};

LeafletMap.defaultProps = {
  readOnly: true,
  onMarkerMove: undefined,
  markers: [],
  useInitialMarker: false,
};

LeafletMap.propTypes = {
  useInitialMarker: PropTypes.bool,
  markers: PropTypes.arrayOf(
    PropTypes.shape({
      lat: PropTypes.number.isRequired,
      lng: PropTypes.number.isRequired,
      permit: PropTypes.shape({
        PermitInstanceId: PropTypes.string.isRequired,
        workDescription: PropTypes.arrayOf(PropTypes.shape({
          parentForm: PropTypes.string.isRequired,
          workDescription: PropTypes.string.isRequired,
        })).isRequired,
        PermitInstanceReferenceId: PropTypes.string.isRequired,
        State: PropTypes.string.isRequired,
      }),
      onMarkerMove: PropTypes.func,
    }),
  ),
  onMarkerMove: PropTypes.func,
  readOnly: PropTypes.bool,
};

export default LeafletMap;
