import { Analytics } from 'aws-amplify';
import Config, { SessionId } from '../config/app-config';
import { RootState } from '../store/RootState';

import store from '../store';

console.log('SessionId:', SessionId)
export enum LogLevel {
  DEBUG = "DEBUG",
  ERROR = "ERROR",
  INFO = "INFO",
  WARN = "WARN",
  VERBOSE = "VERBOSE"
}

enum LogLevelDictionary {
  'VERBOSE' = 0,
  'DEBUG' = 1,
  'INFO' = 2,
  'WARN' = 3,
  'ERROR' = 4,
};

enum AllowedEventTypes {
  /* called when an error is thrown in the application */
  'APPLICATION_ERROR' = 'APPLICATION_ERROR',
  /* Amplify Authentication Event */
  'AUTH_EVENT' = 'AUTH_EVENT',
  /* called when app.ts renders for the first time */
  'APPLICATION_LOADED' = 'APPLICATION_LOADED',
  /* redux action was dispatched */
  'REDUX_ACTION_DISPATCHED' = 'REDUX_ACTION_DISPATCHED',
  /* route in react router changed (page change/filtering etc) */
  'ROUTE_CHANGED' = 'ROUTE_CHANGED',
  /* A common message */
  'MESSAGE' = 'MESSAGE',
};

type AnalyticsMessage = {
  AppInitialized: boolean;
  SessionId: string;
  TimeStamp: string;
  IsError?: boolean;
  Message: Record<any, any>;
  IsOffline?: boolean;
  User: {
    Email: string;
    Name: string;
    PersonnelId: number;
    HomeLocation: number;
  } | null;
}

export const constructAnalyticsMessage = (state: RootState, data: Record<any, any> | string): AnalyticsMessage => {
  const { appState, offline, auth } = state;

  let message: AnalyticsMessage = {
    SessionId,
    AppInitialized: false,
    TimeStamp: new Date().toISOString(),
    Message: typeof data === 'string' ? { data } : data,
    User: null,
  };

  if (appState?.initilized) {
    message = {
      ...message,
      AppInitialized: true,
      IsOffline: !offline.online,
      User: {
        Email: auth.profile.email,
        Name: `${auth.profile.givenName} ${auth.profile.surname}`,
        PersonnelId: auth.profile.PersonnelId,
        HomeLocation: appState.homeLocation.LocationId,
      }
    }
  };

  return message;
}

export const Events = AllowedEventTypes;
export type ValidEventTypes = keyof typeof AllowedEventTypes;

export const recordEvent = async (
  state: RootState,
  data: Record<any, any> | string,
  eventType: ValidEventTypes = AllowedEventTypes.MESSAGE,
  logLevel: LogLevel | string = 'INFO',
  isError: boolean = false
) => {
  const message = constructAnalyticsMessage(state, data);
  await logEvent(eventType, message, logLevel, isError);
}

export const logEvent = async (
  eventType: ValidEventTypes,
  data: Record<any, any>,
  logLevel: LogLevel | string = 'DEBUG',
  isError: boolean = false
) => {
  try {
    if (!Object.keys(AllowedEventTypes).includes(eventType)) {
      throw new Error(`Invalid event type [${eventType}]`)
    }

    const isSufficientLogLevel = LogLevelDictionary[logLevel as any] >= LogLevelDictionary[Config.App.logLevel];


    if (isSufficientLogLevel) {
      await Analytics.record(
        {
          data: {
            ...data,
            ...(isError ? { Error: true } : {}),
            Type: eventType,
          },
          streamName: Config.App.kinesisStreamName
        },
        'AWSKinesis'
      );
    }
  }
  catch (error) {
    console.log('error:', error)
  }
}



export const logError = async (error: any, unhandled: boolean) => {
  const state = store.store.getState();

  const message = constructAnalyticsMessage(
    state,
    {
      Message: error.message || error,
      StackTrace: error.stack,
      IsUnhandled: unhandled
    }
  );

  await logEvent('APPLICATION_ERROR', message, LogLevel.ERROR, true);
}

export const logErrors = async () => {
  window.onerror = async (error) => {
    await logError(error, false);
  };

  window.onunhandledrejection = async (error) => {
    await logError(error.reason || error, true)
  };
}
