import React from 'react';
import PropTypes from 'prop-types';
import * as jsonPatch from 'fast-json-patch';

import { useDispatch, useSelector } from 'react-redux';
import { Typography } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';

import PermitsState from '../../actions/permits';
import useGetPermit from '../../hooks/permits/useGetPermit';
import useSetPageName from '../../hooks/shared/useSetPageName';
import useWithFormCustomizations from '../../hooks/permits/useWithFormCustomizations';
import useQuery from '../../hooks/shared/useQuery';
import useForm from '../../hooks/forms/useForm';
import { collapseAllPanels, expandAllPanels } from '../../shared/form-io';
import useAnalytics from '../../hooks/shared/useAnalytics';
import ErrorStickyBar from './ErrorStickyBar';

import {
  disabledFields,
  updateFieldsOnWorkOrderChange,
  flagFields,
  updateLinkedPermitFields,
  updateSelectedWorkOrderInForm,
  populateWorkOrderFieldsOnFormAttach,
  populatedOperationsSelect,
  updateOperationsFields,
  applyDisableEffectToPermit,
  updateFormFieldToSubForm,
  getErrorList,
  hideComponent
} from './effects';

import toast from '../../shared/toast';

import PermitActionsButtons from './shared/PermitActionsButtons';
import PermitSubmittedDialog from './shared/PermitSubmittedDialog';
import SavingOverlay from './shared/SavingOverlay';
import InlineLoader from '../shared/InlineLoader';
import useAbilities from '../../hooks/auth/useAbilities';
import PermitFooterBar from './shared/PermitFooterBar';
import useWorkOrder from '../../hooks/work-orders/useWorkOrder';

import AppState from '../../actions/app';
import { TYPES } from '../../actions/definitions';
import { ActionStatus } from '../../actions/shared/status';
import { expandErrorPanels } from '../../shared/form-io';


const EditPermit = (props) => {
  useSetPageName('Edit Permit');
  const { PermitInstanceId } = props;

  const dispatch = useDispatch();
  const abilities = useAbilities();
  const recordEvent = useAnalytics();
  const { staleDefinition, redirectFromCreate } = useQuery();
  const [errors, setErrors] = React.useState([]);
  const [total, setTotalError] = React.useState(0);
  const [showErrors, updateShowErrors] = React.useState(false);
  const [showSidebarBtn, updateSidebarBtn] = React.useState(false);


  const [submittedDialogVisible, setSubmittedDialogVisible] = React.useState(false);
  const [submitting, setSubmitting] = React.useState(false);
  const [lastAction, setLastAction] = React.useState(redirectFromCreate ? 'create' : null);
  const [selectedWorkOrder, setSelectedWorkOrder] = React.useState();
  const loadingState = useSelector(AppState.selectors.status(TYPES.getDefinition));
  // we don't want to fetch approvals if the last action was create
  // beause this form renders before the creation call happens. Approvals are fetched
  // after a successful create from the getApprovalsOnPermitSave middleware
  const { loading: permitLoading, permit } = useGetPermit(PermitInstanceId, lastAction !== 'create');
  const { loading: woLoading, workOrder } = useWorkOrder(permit?.instance?.ContentJSON?.data?.workOrder);

  React.useEffect(() => {
    setSelectedWorkOrder(workOrder);
  }, [workOrder])

  React.useEffect(() => {
    if (permit.instance.ContentJSON?.data?.workOrder) {
      setSelectedWorkOrder({ workOrderNumber: permit.instance.ContentJSON.data.workOrder });
    }
  }, [permit.instance.ContentJSON?.data?.workOrder])

  const customizations = React.useMemo(() => [
    flagFields(permit.instance.State),
    disabledFields(permit.instance.State, abilities.permits.edit,permit.instance,permit.instance),
  ], [permit.instance, abilities])

  const components = useWithFormCustomizations(
    permit.permitFormDefinition.components,
    customizations,
  );

  const formDataObserver = jsonPatch.observe(permit.instance.ContentJSON || {});

  const handleFormChange = React.useMemo(() => async (event, form) => {
    if (form && event) {
      hideComponent(form,event);
      updateFieldsOnWorkOrderChange(form, event, permit.erpFields);
      populateWorkOrderFieldsOnFormAttach(form, event, permit.erpFields, selectedWorkOrder);
      populatedOperationsSelect(form, event, permit.erpFields, selectedWorkOrder);
      updateOperationsFields(form, event, permit.erpFields, selectedWorkOrder);
      await updateLinkedPermitFields(form, event, permit.instance.LocationId);
      await updateFormFieldToSubForm(form, event);
      if (showErrors) {
        const { total,  result } = getErrorList(form);
        setErrors(result);
        setTotalError(total);
      }
    }
  }, [permit.erpFields, selectedWorkOrder, permit.instance.LocationId, showErrors]);

  const handleFormSubmit = React.useMemo(() => async (submission, form) => {
    window.scrollTo(0, 0);
    form.submitting = true;
    form.disabled = true;
    setSubmitting(true);

    const updates = await jsonPatch.generate(formDataObserver);

    dispatch(PermitsState.actions.updatePermitContent(PermitInstanceId, updates))
      .then(() => {
        setLastAction('edit');
        setSubmittedDialogVisible(true);
        form.submitting = false;
        form.disabled = false;
        setSubmitting(false);
      });
  }, [formDataObserver, PermitInstanceId, dispatch]);

  const { form, ready } = useForm(
    'form',
    components,
    permit.instance.ContentJSON,
    handleFormChange,
    handleFormSubmit,
    permit.instance.State,
  );

  const handleForm = async () => {
    try {
      setErrors([]);
      setTotalError(0);
      await form.submit();

    } catch (error) {
      if (Array.isArray(error)) {
        expandErrorPanels(form)
        const errors = getErrorList(form);
        setErrors(errors.result);
        setTotalError(errors.total);
        updateShowErrors(true);
        toast.error(`Please check the form and correct all (${errors.total}) errors before submitting.`)
      }
      recordEvent(`Edit Permit form submit error ${JSON.stringify(error)}`)
      return { success: false, data: error }
    }
  }
  // Form onLoad deisbale attached permit
  React.useEffect(() => {
    if (form && permit?.instance?.ContentJSON?.data) {
      applyDisableEffectToPermit(form, permit.instance.ContentJSON.data)
    }
  }, [ready, permit])

  const handleWorkOrderChange = (workOrder) => {
    setSelectedWorkOrder(workOrder);
  };

  // when the selected work order changes, update the work order related fields
  // in the form. By doing it in an effect, we can use the form ref from useForm() and
  // update the fields rather than having to completely rebuild the component tree and
  // rerender the form
  React.useEffect(() => {
    if (form && selectedWorkOrder) {
      updateSelectedWorkOrderInForm(form, selectedWorkOrder);
    }
  }, [form, selectedWorkOrder]);

  const isLoading = permitLoading || woLoading;

  React.useEffect(() => {
    if (lastAction === 'create') {
      setSubmittedDialogVisible(true);
    }
  }, [lastAction]);

  // eslint-disable-next-line arrow-body-style
  React.useEffect(() => {
    return () => {
      dispatch(PermitsState.actions.clearSelected());
    };
  }, [dispatch]);

  const renderStalePermitAlert = () => (
    <Alert severity="warning" style={{ marginBottom: '1rem' }}>
      <Typography>
        This permit was copied from a permit created with an older version of
        {' '}
        this form. Some fields may have not been copied from the original permit.
      </Typography>
    </Alert>
  );

  const renderPermitLoadFail = () => (
    <Alert severity="error" style={{ marginBottom: '1rem' }}>
      <AlertTitle>Server Error</AlertTitle>
      <Typography>
        Unfortunately, We are unable to load Permit Defination. Please try to refresh page.
      </Typography>
    </Alert>
  )

  return (
    <>
      {submittedDialogVisible && (
        <PermitSubmittedDialog
          onClose={() => setSubmittedDialogVisible(false)}
          visible={submittedDialogVisible}
          updateType={lastAction}
        />
      )}

      {isLoading ? <InlineLoader /> : (
        <>
          {submitting && <SavingOverlay />}
          {staleDefinition && renderStalePermitAlert()}
          {!permit.instance.ContentJSON && loadingState === ActionStatus.rejected && renderPermitLoadFail()}
          {permit.instance.ContentJSON && <PermitActionsButtons
            LocationId={permit.instance.LocationId}
            ReferenceId={permit.instance.PermitInstanceReferenceId}
            workLocation={{ lat: permit.instance.Latitude, lng: permit.instance.Longitude }}
            PermitInstanceId={permit.instance.PermitInstanceId}
            PermitDefinitionId={permit.instance.PermitDefinitionId}
            State={permit.instance.State}
            workOrder={selectedWorkOrder && selectedWorkOrder.workOrderNumber}
            onWorkOrderChange={handleWorkOrderChange}
          />}
        </>
      )}
      <div id="form" style={{ display: isLoading ? 'none' : 'block' }} />
      <ErrorStickyBar
        showErrors={showErrors}
        form={form}
        total={total}
        errors={errors}
        showSidebarBtn={showSidebarBtn}
        updateSidebarBtn={updateSidebarBtn}
      />
      <PermitFooterBar
        handleSubmit={() => ready && handleForm()}
        onCollapsePermits={() => collapseAllPanels(form)}
        onExpandPermits={() => expandAllPanels(form)}
        loading={submitting || isLoading}
        PermitInstanceReferenceId={permit.instance.PermitInstanceReferenceId}
        State={permit.instance.State}
      />
    </>
  );
};

EditPermit.propTypes = {
  PermitInstanceId: PropTypes.string.isRequired,
};

export default EditPermit;
