import React from 'react';
import { Route } from 'react-router-dom';
import WorkOrdersList from './WorkOrdersList';
import SearchWorkOrders from './SearchWorkOrders';

const WorkOrders = () => (
  <div className="workOrders">
    <Route path="/workorders/list" component={WorkOrdersList} />
    <Route path="/workorders/search" component={SearchWorkOrders} />
  </div>
);

export default WorkOrders;
