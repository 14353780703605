import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import PermitsState from '../../actions/permits';
import AppState from '../../actions/app';
import { ActionStatus } from '../../actions/shared/status';


const usePermitSearch = () => {
  const dispatch = useDispatch();
  const { actions, selectors, types } = PermitsState;

  const searchData = useSelector(state => state.permits.permitsByState);
  const loading = useSelector(state => state.permits.permitsByStateLoading);
  const error = useSelector(state => state.permits.permitsByStateError);
  const loadingState = useSelector(AppState.selectors.status('revokeAllPermit'), shallowEqual);
  return [
    (...props) => dispatch(actions.getPermitsData(...props)),
    {
      searchData,
      loading,
      error,
      errorRevoke: loadingState === ActionStatus.rejected,
      loadingRevoke: loadingState === ActionStatus.pending,
    },
    () => dispatch(actions.clearResults()),
  ];
};

export default usePermitSearch;
