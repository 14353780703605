import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import PermitsState from '../../actions/permits';
import AppState from '../../actions/app';
import { ActionStatus } from '../../actions/shared/status';

const useDraft = (draftId) => {
  const dispatch = useDispatch();
  const { actions, selectors, types } = PermitsState;
  const { lastSaved, content } = useSelector(selectors.draft());

  const saving = useSelector(AppState.selectors.status(types.saveDraft));

  React.useEffect(() => {
    if (draftId) {
      dispatch(actions.getDraft(draftId));
    }

    return () => {
      dispatch(AppState.actions.resetStatus(types.saveDraft));
      dispatch(AppState.actions.resetStatus(types.getDraft));
      // dispatch(AppState.actions.clearDraft(types.getInitialPermitForSite));
    };
    // disabling as it should only ever execute exactly once
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    content,
    lastSaved,
    saving: saving === ActionStatus.pending,
  };
};

export default useDraft;
