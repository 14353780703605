import React from 'react';
import { Route, Switch } from 'react-router-dom';

import FormsList from './FormsList';

const Lists: React.FC = () => (
  <Switch>
    <Route path="/admin/forms" exact component={FormsList} />
  </Switch>
);

export default Lists;
