import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Chat as ChatIcon } from '@material-ui/icons';

import {
  Badge,
  Paper,
  makeStyles,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Tooltip,
  Typography,
} from '@material-ui/core';

import TemplatesTableMenu from './TemplatesTableMenu';
import { StateColors, Template } from '../../../../types/template';
import { PermitTemplate } from '../../../../types/prop-types/template';
import StateChip from '../../../shared/StateChip';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  createButton: {
    marginLeft: theme.spacing(1),
  },
  statusCell: {
    '& > *': {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
  },
}));

interface Props {
  templates: Template[];
}

const TemplatesList: React.FC<Props> = ({ templates }) => {
  const classes = useStyles();

  return (
    <TableContainer component={Paper} className={classes.wrapper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>Form</TableCell>
            <TableCell align="center">Created By</TableCell>
            <TableCell align="center">Approval</TableCell>
            <TableCell align="center">State</TableCell>
            <TableCell align="center" />
            <TableCell align="right" />
          </TableRow>
        </TableHead>
        <TableBody>
          {templates.map((template) => (
            <TableRow key={template.PermitTemplateId}>
              <TableCell component="th" scope="row">{template.Name}</TableCell>
              <TableCell>
                <Typography variant="body2" noWrap>
                  {template.FormKey}
                </Typography>
              </TableCell>
              <TableCell align="center">
                <Tooltip title={template.CreatedBy.EmailAddress} interactive>
                  <Typography variant="body2">
                    {`${template.CreatedBy.FirstName} ${template.CreatedBy.LastName}`}
                  </Typography>
                </Tooltip>
              </TableCell>
              <TableCell align="center">
                <div>
                  {
                    template.ApprovedBy
                      ? `${template.ApprovedBy.FirstName} ${template.ApprovedBy.LastName}`
                      : '-'
                  }
                </div>
                <div>
                  {template.ApprovedOn && moment(template.ApprovedOn).format('YYYY-MM-DD')}
                </div>
              </TableCell>
              <TableCell align="center" className={classes.statusCell}>
                <StateChip state={template.State} colors={StateColors} />
              </TableCell>
              <TableCell align="center">
                {Array.isArray(template.Comments) && template?.Comments.length > 0 && (
                  <Tooltip title={`${template.Comments.length} comment${template.Comments.length > 1 ? 's' : ''}`}>
                    <Badge badgeContent={template.Comments.length} color="secondary">
                      <ChatIcon />
                    </Badge>
                  </Tooltip>
                )}
              </TableCell>
              <TableCell align="right">
                <TemplatesTableMenu template={template} />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

TemplatesList.propTypes = {
  templates: PropTypes.arrayOf(PermitTemplate.isRequired).isRequired,
};

export default TemplatesList;
