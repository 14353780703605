import ExternalUsersStateActions,
{
  SetListAction,
  ExternalUsersActions,
} from '../../actions/admin/external-users';

import { ExternalUser } from '../../types/external-user';

export interface ExternalUsersState {
  readonly items: ExternalUser[];
}

const initialState: ExternalUsersState = {
  items: [],
};

const externalUsersReducer = (
  state = initialState,
  action: ExternalUsersActions,
): ExternalUsersState => {
  const { TYPES } = ExternalUsersStateActions;
  let typedAction;

  switch (action.type) {
    case TYPES.setList:
      typedAction = action as SetListAction;
      return {
        ...state,
        items: typedAction.payload,
      };
    default:
      return state;
  }
};

export default externalUsersReducer;
