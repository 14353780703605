const OracleStatuses = [
  'SCHED',
  'RESCHED',
  'INPROG',
  'STAGED',
  'WCLOSE',
  'Closed',
  'Complete',
  'Cancelled',
  'Cancelled By PM',
  'Draft',
  'Unreleased',
  'PLANNING',
  'CPLAN',
  'WENG',
  'WMOC',
  'WPLAN',
  'WPARTDC',
  'WSCHED',
];

const SAPStatuses = [
  'APRV (Approved)',
  'AVLB (Available Material/Parts)',
  'CAT2 (50% Electrical Inspection)',
  'CRDT (Created)',
  'CRTD (Created)',
  'ESTC (Estimated costs)',
  'GMCO (Goods movement in coll. order)',
  'GMPS (Goods movement posted)',
  'HLD (On Hold)',
  'IPLN (In Planning)',
  'MACM (Material committed)',
  'MANC (Mat.availability not checked)',
  'MSHD (WO Moved to T/A Schedule)',
  'MSPT (Material shortage)',
  'NMAT (No material components)',
  'PCNF (Partially confirmed)',
  'PLND (Planned)',
  'PPRT (Part printed)',
  'PRC (Pre-costed)',
  'PRT (Printed)',
  'RDY (Ready for Field Execution)',
  'REL (Released)',
  'SETC (Settlement rule created)',
  'WAIT (Waiting for Material/Parts)',
];

export const WorkOrderStatuses = [...OracleStatuses, ...SAPStatuses];

export interface WorkOrder {
  workOrderNumber: string;
  workOrderDescription: string;
  workOrderStatus?: string[] | null;
  assetNumber: string;
  assetDescription: string;
  orderType: string;
  plantCode: string;
  revisionNumber: string;
  equipmentNumber: string;
  scheduledStart: string;
  siteId: number;
  sourceSystem: string;
  workCenterName: string;
  operations?: WorkOrderOperation[] | null;
  workOrderChangedDate: string;
}
export interface WorkOrderOperation {
  operationStatus: string;
  operationDescription: string;
  operationSystemStatus: string;
  operationNumber: string;
  resources?: null;
  operationSystemStatusDescription: string;
  operationUserStatusDescription: string;
  operationUserStatus: string;
}
