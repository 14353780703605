import React from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';

import AuthState from '../../actions/auth';
import PermitsState from '../../actions/permits';

// eslint-disable-next-line import/prefer-default-export
const usePermitListFilters = (permitList) => {
  const { selectors } = AuthState;
  const { PersonnelId } = useSelector(selectors.profile());
  const filters = useSelector(PermitsState.selectors.filters());

  return React.useMemo(() => {
    const validFilters = Object.keys(filters).reduce((valid, key) => {
      if (![undefined, null, ''].includes(filters[key])) {
        // eslint-disable-next-line no-param-reassign
        valid[key] = filters[key];
      }
      return valid;
    }, {});

    const filterFns = [];

    if (validFilters.filter && validFilters.filter === 'myApprovalRequired') {
      const myApprovalRequired = (permit) => permit.approvalRequired !== undefined;
      filterFns.push(myApprovalRequired);
    }

    if (validFilters.filter && validFilters.filter === 'myPermits') {
      const hasMeAsOwner = (permit) => String(permit.CreatedBy) === String(PersonnelId);
      filterFns.push(hasMeAsOwner);
    }

    if (Array.isArray(validFilters.state) && validFilters.state.length) {
      const hasState = (permit) => validFilters.state.includes(permit.State.toUpperCase());
      filterFns.push(hasState);
    }

    if (validFilters.workDate) {
      const filterWorkDate = moment(Number(validFilters.workDate));

      const hasWorkDate = (permit) => permit.workDate
        && filterWorkDate.isValid()
        && moment(filterWorkDate).isSame(moment(permit.workDate), 'day');

      filterFns.push(hasWorkDate);
    }

    if (validFilters.text) {
      const term = validFilters.text.toLowerCase();

      const hasText = (permit) => {
        const validFields = [
          permit.PermitInstanceReferenceId,
          permit.workOrderNumber,
          permit.workLocation,
          permit.workDescription,
          permit.companyName,
          permit.assetNumber,
          permit.assetDescription,
          permit.permitIssuer,
        ];

        if (Array.isArray(permit.workDescription)) {
          const include = permit.workDescription.find(data => String(data.workDescription).toLowerCase().includes(term));
          if (include) return true;
        }

        return validFields.findIndex((el) => String(el).toLowerCase().includes(term)) > -1;
      };

      filterFns.push(hasText);
    }

    // eslint-disable-next-line arrow-body-style
    return permitList.filter((permit) => {
      // By ensuring include on each fn, it exits early for any aready failed matches and
      // doesn't compute further filter functions. This increases performance a lot
      return filterFns.reduce((include, fn) => include && fn(permit), true);
    });
  }, [filters, permitList, PersonnelId]);
};

export default usePermitListFilters;
