import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import { useDispatch } from 'react-redux';

import Dialog from '../../../shared/Dialog';

import TemplatesState from '../../../../actions/admin/templates';
import InlineLoader from '../../../shared/InlineLoader';

type Props = {
  onClose: () => void;
  PermitTemplateId: string;
}

const DeleteDialog: React.FC<Props> = (props) => {
  const { onClose, PermitTemplateId } = props;
  const dispatch = useDispatch();

  const [loading, setLoading] = React.useState<boolean>(false);

  const handleDeleteClick = async () => {
    setLoading(true);
    await dispatch(TemplatesState.actions.deleteTemplate(PermitTemplateId));
    onClose();
    setLoading(false);
  }

  return (
    <Dialog
      visible={true}
      onCancel={onClose}
      onClose={onClose}
      title="Delete Template"
      actions={[{
        text: 'Delete',
        color: 'primary',
        disabled: loading,
        onClick: handleDeleteClick
      }]}
    >
      {loading ? <InlineLoader /> : (
        <Typography gutterBottom>
          Are you sure you want to delete this template?
        </Typography>
      )}
    </Dialog>
  );
}

DeleteDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  PermitTemplateId: PropTypes.string.isRequired,
}

export default DeleteDialog;
