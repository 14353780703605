import { object, shape, string, number, oneOf, arrayOf } from 'prop-types';
import { TemplateTypes, TemplateStates, TemplateCommentTypes } from '../template';

export const TemplatePerson = shape({
  EmailAddress: string.isRequired,
  FirstName: string.isRequired,
  LastName: string.isRequired,
  PersonnelId: number.isRequired,
});

export const TemplateComment = shape({
  Comment: string.isRequired,
  CommentType: oneOf(TemplateCommentTypes).isRequired,
  CreatedOn: string.isRequired,
  CreatedBy: TemplatePerson.isRequired,
});

const baseTemplate = {
  Comments: arrayOf(TemplateComment.isRequired),
  ApprovedBy: TemplatePerson,
  ApprovedOn: string,
  CreatedBy: TemplatePerson.isRequired,
  CreatedOn: string.isRequired,
  LocationId: number.isRequired,
  Name: string.isRequired,
  SiteId: number.isRequired,
  PermitTemplateId: string.isRequired,
  State: oneOf(TemplateStates).isRequired,
  Type: oneOf(TemplateTypes).isRequired,
};

export const PermitTemplate = shape({
  ...baseTemplate,
  ContentJSON: object.isRequired,
  FormKey: string.isRequired,
});

const WorkPermitTemplate = shape({
  FormKey: string.isRequired,
});

export const WorkTemplate = shape({
  ...baseTemplate,
  PermitTemplates: arrayOf(WorkPermitTemplate).isRequired,
});
