import React from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";

const useStyles = makeStyles(theme => ({
  show: {
    display: "flex",
    "& > * + *": {
      marginLeft: theme.spacing(2)
    },
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
    backgroundColor: "#dedede",
    width: "100%",
    position: "absolute",
    zIndex: "9999",
    top: "0"
  }
}));

function Loader(props) {
  const classes = useStyles();
  const showLoading = props.enabled || props.isLoading;
  return (
    <div>
      {showLoading ? (
        <div className={classes.show}>
          <CircularProgress />
        </div>
      ) : null}
    </div>
  );
}

const mapStateToProps = (state, ownProps) => {
  return {
    isLoading: state.appState.isLoading,
    enabled: ownProps.enabled
  };
};

const mapDispatchToProps = dispatch => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Loader);
