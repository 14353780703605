import React from 'react';

import useSetPageName from '../../../hooks/shared/useSetPageName';

const LandingPage = () => {
  useSetPageName('E-Permitting Admin');

  return (
    <div />
  );
};

export default LandingPage;
