import React from 'react';

import {
  Accordion,
  AccordionDetails,
  AccordionActions,
  AccordionSummary,
  Theme, createStyles, makeStyles,
  Typography
} from '@material-ui/core';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      flexBasis: '33.33%',
      flexShrink: 0,
    },
    secondaryHeading: {
      color: theme.palette.text.secondary,
    },
    icon: {
      verticalAlign: 'bottom',
      height: 20,
      width: 20,
    },
    details: {
      alignItems: 'center',
    },
    column: {
      flexBasis: '33.33%',
    },
    helper: {
      borderLeft: `2px solid ${theme.palette.divider}`,
      padding: theme.spacing(1, 2),
    },
    link: {
      color: theme.palette.primary.main,
      textDecoration: 'none',
      '&:hover': {
        textDecoration: 'underline',
      },
    },
  }),
);

type Props = {
  actions?: React.ReactNode;
  expanded?: boolean;
  onChange: (name: string) => void;
  name: string;
  heading: string;
  secondaryHeading?: string;
}

const BaseAccordian: React.FC<Props> = (props) => {
  const {
    actions,
    children,
    expanded,
    heading,
    name,
    onChange,
    secondaryHeading,
  } = props;

  const classes = useStyles();

  return (
    <Accordion expanded={expanded} onChange={() => onChange(name)}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls={`accordian-${name}-content`}
        id={`accordian-${name}-header`}
      >
        <Typography className={classes.heading}>{heading}</Typography>
        {secondaryHeading && (
          <Typography className={classes.secondaryHeading}>
            {secondaryHeading}
          </Typography>
        )}
      </AccordionSummary>
      <AccordionDetails>
        {children}
      </AccordionDetails>
      {actions && (
        <AccordionActions>
          {actions}
        </AccordionActions>
      )}
    </Accordion>
  );
}

BaseAccordian.defaultProps = {

};

BaseAccordian.propTypes = {

};

export default BaseAccordian;
