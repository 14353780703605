import { get as _get } from 'lodash';
import * as jsonPatch from 'fast-json-patch';
import { OFFLINE_STATUS_CHANGED } from '@redux-offline/redux-offline/lib/constants';
import Collections from '../indexeddb';

import AppState from '../../actions/app';
import DefinitionsState from '../../actions/definitions';
import PermitsState from '../../actions/permits';
import { push } from 'connected-react-router';
import Api from '../../shared/api';

const { Definitions, Permits } = Collections;

export const getApprovalsOnPermitSave = ({ dispatch, getState }) => (next) => async (action) => {
  const result = next(action);
  if (action.type === PermitsState.types.createPermitSucceeded) {
    const { selected } = getState().permits;
    
    if (_get(selected, 'instance.PermitInstanceId')) {
      const approvals = await Api.get(`/permitinstancestatus?PermitInstanceId=${selected.instance.PermitInstanceId}`);

      await Collections.PermitApprovals.set(selected.instance.PermitInstanceId, approvals);

      dispatch({
        type: PermitsState.types.setApprovals,
        payload: approvals,
      });
    }
  }
  return result;
};

export const setPermitTypesInPermitListItem = ({ dispatch }) => (next) => async (action) => {
  const result = next(action);
  if (
    action.type === PermitsState.types.createPermitOptimistically
    || action.type === PermitsState.types.updateOne
  ) {
    const { instance } = action.payload;
    const definition = await Definitions.get(instance.PermitDefinitionId);

    if (!definition) return result;

    const formsInDefinition = _get(definition.definition, 'DefinitionMetadataJSON.Forms');

    const permitTypes = [];

    formsInDefinition.forEach((type) => {
      const exists = _get(instance.ContentJSON, type.ContentsPath);
      if (exists) {
        permitTypes.push({
          name: type.Name,
          type: type.PermitType,
          label: type.PermitType,
        });
      }
    });

    dispatch({
      type: PermitsState.types.updatePermitTypesInListPermit,
      payload: {
        PermitInstanceId: instance.PermitInstanceId,
        permitTypes,
      },
    });
  }

  return result;
};

export const updateCachedPermitOnOptimisticCreate = () => (next) => async (action) => {
  const result = next(action);
  if (action.type === PermitsState.types.createPermitOptimistically) {
    await Permits.set(
      action.payload.instance.PermitInstanceId,
      { instance: action.payload.instance },
    );
  }
  return result;
};

export const updateCachedPermitOnSuccessfulCreate = () => (next) => async (action) => {
  const result = next(action);
  if (action.type === PermitsState.types.createPermitSucceeded) {
    await Permits.get(action.payload.data.PermitInstanceId)
      .then((existing) => {
        return Permits.set(
          action.payload.data.PermitInstanceId,
          {
            ...existing,
            instance: {
              ...action.payload.data,
              ContentJSON: typeof action.payload.data.ContentJSON === 'string'
                ? JSON.parse(action.payload.data.ContentJSON)
                : action.payload.data.ContentJSON,
              PermitInstanceMetadataJSON: typeof action.payload.data.ContentJSON === 'string'
                ? JSON.parse(action.payload.data.PermitInstanceMetadataJSON)
                : action.payload.data.PermitInstanceMetadataJSON,
            },
          },
        );
      });
  }
  return result;
};

export const reconsileSelectedPermitOnSuccessfulCreate = ({ getState, dispatch }) => (next) => async (action) => {
  const result = next(action);
  if (action.type === PermitsState.types.createPermitSucceeded) {
    const { selected: { instance } } = getState().permits;
    const createdResponse = action.payload.data;

    createdResponse.ContentJSON = typeof createdResponse.ContentJSON === 'string'
      ? JSON.parse(createdResponse.ContentJSON)
      : createdResponse.ContentJSON;

    createdResponse.PermitInstanceMetadataJSON = typeof createdResponse.PermitInstanceMetadataJSON === 'string'
      ? JSON.parse(createdResponse.PermitInstanceMetadataJSON)
      : createdResponse.PermitInstanceMetadataJSON;

    if (instance.PermitInstanceId !== createdResponse.PermitInstanceId) {
      return result;
    }

    if (!instance.ContentJSON) {
      instance.ContentJSON = createdResponse.ContentJSON;
      return result;
    }

    // The backend performs modifications to the ContentJSON to populate some fields
    // including the safe work permit number with the PermitInstanceReferenceId.
    // As the user could have edited the form since it was created, we want to apply the
    // updates and keep any chances the user has made.
    const updates = jsonPatch.compare(instance.ContentJSON, createdResponse.ContentJSON);
    jsonPatch.applyPatch(instance.ContentJSON, updates);

    dispatch({
      type: PermitsState.types.updateOne,
      payload: { instance: createdResponse, backgroundUpdate: true },
    });
  }

  return result;
};

export const updateCachedPermitOnSuccessfulUpdate = () => (next) => async (action) => {
  const result = next(action);
  if (action.type === PermitsState.types.updateOne) {
    const existing = await Permits.get(action.payload.instance.PermitInstanceId);

    const updatedPermit = {
      ...existing,
      instance: action.payload.instance,
      meta: action.payload.meta,
    };

    await Permits.set(
      action.payload.instance.PermitInstanceId,
      updatedPermit,
    );
  }

  return result;
};

export const updateCachedPermitState = () => (next) => async (action) => {
  const result = next(action);
  if (action.type === PermitsState.types.updatePermitState) {
    await Permits.get(action.payload.PermitInstanceId)
      .then((existing) => {
        if (!existing) Promise.resolve();
        return Permits.set(
          action.payload.PermitInstanceId,
          {
            ...existing,
            instance: {
              ...existing.instance,
              State: action.payload.state,
            },
          },
        );
      });
  }
  return result;
};

export const updateCachedPermitWorkLocation = () => (next) => async (action) => {
  const result = next(action);
  if (action.type === PermitsState.types.updateWorkLocation) {
    await Permits.get(action.payload.PermitInstanceId)
      .then((existing) => {
        if (!existing) Promise.resolve();

        const { Latitude, Longitude } = action.payload;
        return Permits.set(
          action.payload.PermitInstanceId,
          {
            ...existing,
            instance: {
              ...existing.instance,
              Latitude,
              Longitude,
            },
          },
        );
      });
  }
  return result;
};

export const refreshCachedPermits = ({ getState, dispatch }) => (next) => async (action) => {
  const result = next(action);
  const { offline } = getState();
  if (action.type === AppState.types.refreshData) {
    await Collections.Permits.keys().then((PermitInstanceIds) => {
      if (!offline.online || !PermitInstanceIds || PermitInstanceIds.length === 0) {
        return Promise.resolve();
      }
      return Api.post('/lastchanged/instance', { PermitInstanceIds })
        .then(({ PermitInstanceList }) => {
          return Promise.all(
            PermitInstanceList.map(async (time) => {
              const permit = await Collections.Permits.get(time.PermitInstanceId);
              if (new Date(time.DateChanged) > new Date(permit.instance.DateChanged)) {
                dispatch(PermitsState.actions.updateCachedPermit(time.PermitInstanceId));
              }
            }),
          );
        });
    });
  }
  return result;
};

export const resetInitialPermitOnRefresh = ({ getState, dispatch }) => (next) => async (action) => {
  const result = next(action);
  if (action.type === DefinitionsState.types.setDefinitionForSite) {
    const { selected } = getState().permits;

    if (Object.keys(selected.instance).length && !selected.instance.PermitInstanceId) {
      dispatch(PermitsState.actions.getInitialPermitForSite());
    }
  }
  return result;
};

const handlePermitUpdate = async (permit, dispatch) => {
  const updates = jsonPatch.compare({}, permit.instance.ContentJSON);
  await Api.patch(`/permitcontent?PermitInstanceId=${permit.instance.PermitInstanceId}`, updates);
  const approvals = await Api.get(`/permitinstancestatus?PermitInstanceId=${permit.instance.PermitInstanceId}`);

  dispatch({
    type: PermitsState.types.updateOne,
    payload: {
      approvals,
      backgroundUpdate: true,
      instance: permit.instance,
      meta: { dirty: false },
    },
  });
};

export const syncDirtyPermitsWhenBackOnline = ({ dispatch }) => (next) => async (action) => {
  const result = next(action);
  if (action.type === OFFLINE_STATUS_CHANGED && action.payload.online) {
    const permits = await Permits.getAll();
    const dirtyPermits = permits.filter((permit) => permit.meta.dirty);

    await Promise.all(dirtyPermits.map((permit) => handlePermitUpdate(permit, dispatch)));
  }

  return result;
};

export default [
  setPermitTypesInPermitListItem,
  updateCachedPermitOnOptimisticCreate,
  reconsileSelectedPermitOnSuccessfulCreate,
  getApprovalsOnPermitSave,
  updateCachedPermitOnSuccessfulCreate,
  refreshCachedPermits,
  updateCachedPermitOnSuccessfulUpdate,
  updateCachedPermitState,
  updateCachedPermitWorkLocation,
  resetInitialPermitOnRefresh,
  syncDirtyPermitsWhenBackOnline,
];
