import { constructAnalyticsMessage, Events, logEvent, LogLevel } from '../../shared/analytics';
import { Middleware } from '../../types/redux';

const logAllDispatchedActions: Middleware = ({ getState }) => (next) => async (action) => {
  return next(action)
    .then(logEvent(
      Events.REDUX_ACTION_DISPATCHED,
      constructAnalyticsMessage(getState(), { action: action.type }),
      LogLevel.VERBOSE
    ));
}

const logRouteChange: Middleware = ({ getState }) => (next) => async (action) => {
  if (action.type === '@@router/LOCATION_CHANGE') {
    const { location } = action.payload;

    const data = {
      url: `${location.pathname}${location.search}`,
      state: location.state,
    }

    return next(action).then(logEvent(
      Events.ROUTE_CHANGED,
      constructAnalyticsMessage(getState(), data),
      LogLevel.INFO
    ));
  }

  return next(action);
}

const analyticsMiddleware = [
  logAllDispatchedActions,
  logRouteChange,
]

export default analyticsMiddleware;
