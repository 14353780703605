import React from 'react';
import { Auth } from 'aws-amplify';
import { Container, Paper, makeStyles, Typography, Divider, Button } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { Alert } from '@material-ui/lab';

import { performFederatedSignin } from '../../shared/auth';
import LoginForm from './LoginForm';

import Logo from '../../images/nutrien-logo.png';

const useStyles = makeStyles((theme) => {
  return {
    container: {
      display: 'flex',
      flexGrow: 1,
      minHeight: '100%',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column',
      marginTop: theme.spacing(10),
    },
    divider: {
      marginBottom: theme.spacing(2),
      marginTop: theme.spacing(3),
    },
    error: {
      marginBottom: theme.spacing(3),
    },
    wrapper: {
      display: 'flex',
      minWidth: '450px',
      justifyContent: 'center',
      flexDirection: 'column',
      padding: theme.spacing(5),
      marginTop: theme.spacing(6),
    },
    button: {
      marginBottom: theme.spacing(2),
    },
    header: {
      marginBottom: theme.spacing(3),
    },
    loginLink: {
      fontSize: theme.typography.fontSize,
      marginTop: theme.spacing(2),
    },
  };
});

const SignIn: React.FC = () => {
  const classes = useStyles();
  const history = useHistory();
  const [loginFormVisible, setLoginFormVisible] = React.useState<boolean>(false);
  const [error, setError] = React.useState<string | null>();

  const handleSignIn = React.useCallback(
    async (emailAddress: string, password: string) => {
      try {
        await Auth.signIn(emailAddress, password);
        history.push('/');
      } catch (e) {
        setError(e.message);
        throw error;
      }
    }, [error, history],
  );

  return (
    <Container maxWidth="sm" className={classes.container}>
      <img src={Logo} alt="Nutrien Logo" />
      <Paper elevation={3} className={classes.wrapper}>
        <Typography variant="h5" className={classes.header}>Sign in to E-Permitting </Typography>

        <Button
          onClick={performFederatedSignin}
          fullWidth
          variant="contained"
          color="primary"
          className={classes.button}
        >
          Nutrien Sign In
        </Button>

        <Button
          onClick={() => setLoginFormVisible(true)}
          fullWidth
          variant="contained"
        >
          External Contractor Sign in
        </Button>
        {loginFormVisible && (
          <>
            <Divider className={classes.divider} />
            {error && <Alert severity="error" className={classes.error}>Incorrect email address or password</Alert>}
            <LoginForm onSubmit={handleSignIn} />
          </>
        )}
      </Paper>
    </Container>
  );
};

export default SignIn;
