import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import useLocations from '../locations/useLocations';
import TemplatesState from '../../actions/admin/templates';
import { isValidTemplateType } from '../../shared/templates';
import { Template, TemplateType } from '../../types/template';

const useTemplates = (type: TemplateType) => {
  if (!isValidTemplateType(type)) {
    throw new Error(`[${type}] is not a valid template type. It must be one of [work|permit]`)
  }
  const dispatch = useDispatch();
  const { homeLocation } = useLocations();
  const { actions, selectors } = TemplatesState;

  const [loading, setLoading] = React.useState(false);
  const [templatesList, setTemplatesList] = React.useState<Template[]>([]);

  const templatesFromState = useSelector(selectors.list);

  React.useEffect(() => {
    setTemplatesList(templatesFromState);
    setLoading(false);
  }, [templatesFromState]);

  React.useEffect(() => {
    setLoading(true);
    dispatch(actions.getTemplates(homeLocation.SiteId, type));
  }, [
    actions,
    dispatch,
    homeLocation.SiteId,
    type,
  ]);

  return { loading, data: templatesList };
};

export default useTemplates;
