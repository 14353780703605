import React from 'react';
import { useSelector } from 'react-redux';
import { get as _get, partition as _partition } from 'lodash';
import useWithUserPermissions from '../auth/useWithUserPermissions';

import Api from '../../shared/api';

const useForms = () => {
  const { locations } = useSelector((st) => st.appState);
  const { isAdmin, permissions } = useWithUserPermissions();

  const fetchForms = React.useRef();
  const [loading, setLoading] = React.useState(true);
  const [forms, setForms] = React.useState([]);

  const sites = React.useMemo(() => {
    const moduleEditPermissions = permissions.find((p) => p.PermissionTypeId === 84);
    const sitesWithEditPermissions = _get(moduleEditPermissions, 'Locations', []);

    return locations.filter((l) => {
      return l.ParentLocationId === 0
        && l.LocationId !== 0
        && (isAdmin ? true : sitesWithEditPermissions.includes(l.SiteId));
    });
  }, [locations, isAdmin, permissions]);

  React.useEffect(() => {
    fetchForms.current = async () => {
      setLoading(true);

      const [formsList, definitions] = await Promise.all([
        Api.get('/formio/forms'),
        Api.get('/permitdefinitions'),
      ]);

      let filteredList = formsList;

      const result = sites.reduce((groupedForms, site) => {
        const [siteForms, remainder] = _partition(
          filteredList,
          (form) => form.properties && Number(form.properties.SiteId) === site.SiteId,
        );

        filteredList = remainder;

        const [frms, resources] = _partition(siteForms, (form) => form.type === 'form');

        groupedForms.push({
          definition: definitions.PermitDefinitionList.find((d) => d.LocationId === site.SiteId),
          form: frms[0],
          resources,
          site,
        });
        return groupedForms;
      }, []);

      setForms(result);
      setLoading(false);
    };

    fetchForms.current();
    // override lint as this should only run exactly once on load
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { forms, loading, reloadList: fetchForms.current };
};

export default useForms;
