import React from 'react';

import {
  getDescendantsByLocation,
  createLocationTree,
} from '../../../shared/location';

import { states } from '../../../config/permits';
import useLocations from '../../../hooks/locations/useLocations';
import { Location, LocationsState, LocationInTree } from '../../../types/location';
import { PermitState } from '../../../types/permit';
import { queryElasticSearchPermitsAsync } from '../../../shared/permits';

export const getPermitStateStats = async (locationsState: LocationsState) => {
  const { locations, homeLocation } = locationsState;
  const siteLocation = locations.find(l => l.LocationId === homeLocation.SiteId);

  if (!siteLocation) {
    return [];
  }

  const descendantLocations = getDescendantsByLocation(locations, siteLocation, true);

  const query = {
    size: 0,
    query: {
      terms: {
        LocationId: [siteLocation.LocationId, ...descendantLocations]
      },
    },
    aggs: {
      group_by_location: {
        terms: {
          field: 'LocationId',
        },
        aggs: {
          group_by_location: {
            terms: {
              field: 'State.keyword',
            },
          },
        },
      },
    },
  };

  const result = await queryElasticSearchPermitsAsync(query);

  if (!result || !result.aggregations || !result.aggregations.group_by_location) {
    return [];
  }

  const { buckets } = result.aggregations.group_by_location;

  return buckets.reduce(
    (sites: any, site: any) => {
      const loc = locations.find((l) => l.LocationId === site.key);

      sites[site.key] = {
        label: loc ? loc.Title : `Location not available for LocationId ${site.key}`,
        total: site.doc_count,
        location: loc,
        counts: site.group_by_location
          .buckets
          .reduce((states: any, state: any) => ({ ...states, [state.key]: state.doc_count }), {}),
      };

      return sites;
    },
    {},
  );
};

export type GraphStats = Record<PermitState | 'label', number>[];

export type TableStats = {
  location: Location;
  counts: Record<PermitState, number>;
}[];

type UsePermitStatesStatesResponse = {
  loading: boolean;
  data: {
    graph: GraphStats
    table: TableStats;
  }
}

export const usePermitStatesStats = (groupByLocation = false): UsePermitStatesStatesResponse => {
  const locations = useLocations();
  const { homeLocation } = locations;

  const [esStats, setEsStats] = React.useState<Record<number, any>>({});

  const [data, setData] = React.useState({ graph: [], table: [] });
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    const getData = async () => {
      setLoading(true);
      const stats = await getPermitStateStats(locations);
      setEsStats(stats);
      setLoading(false);
    }

    getData();

    // in this case, we only ever want to fetch this data once.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const siteLocation = React.useRef(locations.locations.find((l) => l.LocationId === homeLocation.SiteId));
  const locationTree = React.useRef(createLocationTree(locations.locations, siteLocation.current))

  React.useEffect(() => {
    if (!esStats || Object.keys(esStats).length === 0) return;

    // initial dictionary to populate
    const counts: Record<number, { [key in number | string]: number; }> = {};
    // the maximum depth to group down to. Eg maxDepth = 2 will aggregate permits at the site level (eg Trinidad) and below
    // as well as the first level sublocations of the site (eg 01/02/OSBL / Product Handling).
    const maxDepth = 2;
    // create an default structure of { [State]: 0, [State]: 0, ...etc } that includes all permit states
    const initialCounts = states.reduce((init, state) => ({ ...init, [state]: 0 }), {});

    // if groupByLocation is true, it will traverse the location tree starting at the root and keep track of the path taken.
    // at each step it increments the number of permits in each state for the first n number of path steps where n is the value
    // of maxDepth.
    if (groupByLocation) {
      const getPermitsCount = (location: LocationInTree, path: number[] = []) => {
        // is there any stats available for this location in ElasticSearch
        const hasStats = Object.keys(esStats).includes(String(location.LocationId));
        // build the path to this location based on the path provided from the previous recursion
        const nextPath = [...path, location.LocationId];

        // slice the path to the length of maxDepth as we only want to bubble the aggregation of permit counts
        // down to this depth.
        nextPath.slice(0, maxDepth).forEach((pathLocationId) => {
          // if this is the first time seeing this location, default it to be the inital set of counts (L98)
          if (!counts[pathLocationId]) counts[pathLocationId] = { ...initialCounts };

          if (hasStats) {
            // iterate over the counts from Elasticsearch and increment the counts by state  of the parent iteration (L113)
            Object.keys(esStats[location.LocationId].counts).forEach(state => {
              counts[pathLocationId][state] = counts[pathLocationId][state] + esStats[location.LocationId].counts[state]
            })
          }
        })

        // recurse over the children of this location to get their counts
        if (location.children) {
          location.children.map((child) => getPermitsCount(child, nextPath))
        }
      }

      // intitate the traversal of the location tree
      getPermitsCount(locationTree.current);
    } else {
      // if groupByLocation is not true, we need to perform a more basic iteration over the stats from ElasticSearch and
      // fill in any permit states that do not have any permits. That is because ElasticSearch only provided values for states
      // that have permits. This way we build an object with a number for every state.
      Object.keys(esStats).forEach((locationId) => {
        const lId = Number(locationId);
        counts[lId] = states.reduce((all, state) => ({ ...all, [state]: esStats[lId].counts[state] || 0 }), {});
      });
    }

    // Iterate over the counts and format the values for display in a novo/bar grapth and table
    const structuredForDisplay = Object.keys(counts).reduce((all: any, k) => {
      const key = Number(k);
      const location = locations.locations.find((loc: Location) => loc.LocationId === key);

      if (!location) {
        return all;
      }

      const structured = {
        location,
        counts: counts[key],
      };

      all.table.push(structured);
      all.graph.push({ label: location.Title, ...counts[key] })
      return all;
    }, { graph: [], table: [] });


    setData(structuredForDisplay);
  }, [esStats, groupByLocation, homeLocation.SiteId, locations.locations]);

  return { data, loading };
}
