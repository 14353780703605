import { ExternalUser } from '../../../types/external-user';
import { RootState } from '../../../store/RootState';
import * as actions from './actions';
import { TYPES } from './types';

export * from './types';

const ExteralUsersState = {
  actions,
  selectors: {
    list: (state: RootState): ExternalUser[] => state.admin.externalUsers.items,
  },
  TYPES,
};

export default ExteralUsersState;
