import React, { useMemo, useState } from 'react';

import { Button, makeStyles, Typography } from '@material-ui/core';
import useLocations from '../../../../../hooks/locations/useLocations';
import { useCopyLocation } from  '../../../../../hooks/admin/useFetachGroupData';
import { TreeView } from '@material-ui/lab';
import TreeItem from '../../../../shared/TreeItem';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

const list_to_tree = (list) => {
  var map = {}, node, roots = [], i;
  
  for (i = 0; i < list.length; i += 1) {
    map[list[i].LocationId] = i; // initialize the map
    list[i].children = []; // initialize the children
  }
  
  for (i = 0; i < list.length; i += 1) {
    node = list[i];
    if (node.ParentLocationId !== 0) {
      // if you have dangling branches check that map[node.parentId] exists
      list[map[node.ParentLocationId]].children.push(node);
    } else {
      roots.push(node);
    }
  }
  return roots;
}

const useStyles = makeStyles((theme) => ({
  btnContainer: {
   display: 'flex',
   flexDirection: 'row-reverse',
   paddingBottom: theme.spacing(2),
   paddingTop: theme.spacing(1),
   '& button': {
    marginLeft: theme.spacing(1),
   }
  },
  treeContainer: {
    border: '1px solid rgba(224, 224, 224, 1)',
  },
}))

const CopyGroup = ({GroupId}) => {
  const { locations } = useLocations();
  const classes = useStyles();
  const [selectedLoations, updateSelectedLoations] = useState([]);
  // const [expanded, setExpanded] = useState(locations.map(location => `${location.LocationId}`));
  const [expanded, setExpanded] = useState([]);
  const { copyLocations, error, isLoading } = useCopyLocation();
  
  const treeData = useMemo(() => {
    const updatedLocations = locations.map(location => ({ ...location, selected: selectedLoations.includes(location.LocationId)}));
    return list_to_tree(updatedLocations)
  }, [locations, selectedLoations])

  const handleSelect = (checked, data) => {
    if (checked) {
      updateSelectedLoations([...selectedLoations, data.LocationId])
    } else {
      updateSelectedLoations(selectedLoations.filter(locationId => locationId !== data.LocationId));
    }
  };

  const handleToggle = (event, nodeIds) => {
    setExpanded(nodeIds);
  };

  const handleExpandClick = () => {
    setExpanded(locations.map(location => `${location.LocationId}`))
  }

  const handleCollapseClick = () => {
    setExpanded([]);
  }

  const onSavePermissions = () => {
    copyLocations({LocationIds: selectedLoations, GroupId})
    console.log(selectedLoations, 'selectedLoations')
  }

  const renderTree = (nodes) => (
    <TreeItem
      handleSelect={handleSelect} 
      data={nodes} 
      key={nodes.LocationId} 
      nodeId={`${nodes.LocationId}`} 
      labelText={nodes.Description} 
      selected={nodes.selected}>
      {Array.isArray(nodes.children)
        ? nodes.children.map((node) => renderTree(node))
        : null}
    </TreeItem>
  );

  return(
    <div>
      <Typography variant="subtitle2" color="textSecondary" gutterBottom>
        Note: The selected group will be copied to all locations you have checked here. 
        If a location cannot be checked, it is either because you do not have sufficient rights 
        at the location or because it is the current location.
      </Typography>
      <div className={classes.btnContainer}>
        <Button onClick={handleExpandClick} variant="contained" color="secondary">Expand all</Button>
        <Button onClick={handleCollapseClick} variant="contained" color="primary">Collapse all</Button>
        <Button disabled={!selectedLoations.length} onClick={onSavePermissions} variant="contained" color="primary">Copy Group</Button>
      </div>
      <TreeView
        className={classes.treeContainer}
        defaultCollapseIcon={<ExpandMoreIcon />}
        defaultExpandIcon={<ChevronRightIcon />}
        sx={{ height: 110, flexGrow: 1, maxWidth: 400, overflowY: 'auto' }}
        multiSelect
        expanded={expanded}
        onNodeToggle={handleToggle}
        >
        {
          treeData.filter(data => data.LocationId !== 0).map(data => renderTree(data))
        }
        </TreeView>
    </div>
  )
};
export default CopyGroup;