import React, { useState, useCallback, useEffect } from 'react';
import { Auth } from 'aws-amplify';
import { Alert } from '@material-ui/lab';
import { Container, Paper, Button, makeStyles, TextField, Typography, Divider } from '@material-ui/core';
import { useHistory, Link } from 'react-router-dom';
import toast from '../../shared/toast';
import Logo from '../../images/nutrien-logo.png';

const useStyles = makeStyles((theme) => {
  return {
    container: {
      display: 'flex',
      flexGrow: 1,
      minHeight: '100%',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column',
      marginTop: theme.spacing(10),
    },
    divider: {
      marginBottom: theme.spacing(2)
    },
    error: {
      marginBottom: theme.spacing(3),
    },
    wrapper: {
      display: 'flex',
      minWidth: '450px',
      justifyContent: 'center',
      flexDirection: 'column',
      padding: theme.spacing(5),
      marginTop: theme.spacing(6),
    },
    button: {
      marginTop: theme.spacing(3),
    },
    header: {
      marginBottom: theme.spacing(3),
    },
    buttonCancel: {
      marginTop: theme.spacing(2),
    },
    forgotpassword: {
      paddingTop: theme.spacing(2),
      alignItems: 'left'
    }
  };
});

export const NutrienContainer = ({children}) => {
  const classes = useStyles();
  return (
    <Container maxWidth="sm" className={classes.container}>
      <img src={Logo} alt="Nutrien Logo" />
      <Paper elevation={3} className={classes.wrapper}>
        {children}
      </Paper>
    </Container>
  )
}


const ForgotPassword = () => {
  const classes = useStyles();
  const history = useHistory();
  const [emailAddress, setEmailAddress] = useState('');
  const [error, setError] = React.useState(false);

  const onCancel = () => {
    history.push('/auth/signin');
  }

  const onContinue = useCallback(async () => {
    try {
      await Auth.forgotPassword(emailAddress)
      history.push('/auth/entercode');
      toast.success('Please check your email and enter new password with code.')
    } catch (e) {
      setError(e.message);
      toast.error(e.message)
      throw error;
    }
  });

  const formDisabled = !emailAddress;
  
  useEffect(() => {
    function handleEnterPress(event) {
      if (event.key === 'Enter' && !formDisabled) {
        onContinue();
      }
    }

    window.addEventListener('keyup', handleEnterPress);
    return () => window.removeEventListener('keyup', handleEnterPress);
    
  }, [formDisabled, onContinue]);

  return (
    <NutrienContainer>
      <Typography variant="h5" className={classes.header}>Forgot your password? </Typography>
      <Divider className={classes.divider} />
      {error && <Alert severity="error" className={classes.error}>Something went wrong.</Alert>}
      <TextField
        required
        label="Email Address"
        type="email"
        variant="outlined"
        value={emailAddress}
        onChange={(event) => setEmailAddress(event.target.value)}
        className={classes.input}
      />
      <div className={classes.forgotpassword}>
        <Link to="/auth/entercode">Already have code?</Link>
      </div>
      <Button
        variant="contained"
        fullWidth={false}
        disabled={!emailAddress.length}
        onClick={onContinue}
        color="primary"
        className={classes.button}
      >
        Continue
      </Button>
      <Button
        variant="contained"
        fullWidth={false}
        onClick={onCancel}
        className={classes.buttonCancel}
      >
        Cancel
      </Button>
    </NutrienContainer>
  );
}

export default ForgotPassword;
