import React, { useEffect, useMemo, useState } from 'react';
import Breadcrumbs from '../../Breadcrumbs';
import Dialog from '../../../shared/Dialog';
import { CSVLink } from 'react-csv';
import AppState from '../../../../actions/auth'

import {
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  makeStyles,
  Typography,
  Button,
  Card,
  CardContent,
  Grid,
  useMediaQuery,
  IconButton,
  CircularProgress,
} from '@material-ui/core';

import Alert from '@material-ui/lab/Alert'

import {
  EditRounded as EditOutlinedIcon,
  DeleteRounded as DeleteOutlinedIcon,
  GetApp as DownloadIcon
} from '@material-ui/icons';

import EDitGroup from './EditGroupModal';
import GroupPanel from '../GroupPanel';
import { ACTIONS, useFetchGroupData, useUpdateGroupData } from '../../../../hooks/admin/useFetachGroupData';
import useLocations from '../../../../hooks/locations/useLocations';
import { useSelector } from 'react-redux';

const columns = [
  { label: 'Name', field: 'Name', width: '30%', type: "text", showFilter: true },
  { label: 'Description', field: 'Description', type: "text", width: '60%', showFilter: true },
  { label: 'Crew', field: 'CrewFlag', width: '5%', type: "boolean", showFilter: false },
  { label: 'SEU', field: 'SiteExpertUserFlag', width: '5%', type: "boolean", showFilter: false }
];

const useStyles = makeStyles((theme) => ({
  container: {
    minHeight: 200,
    maxHeight: 610,
    overflow: 'auto',
    position: 'relative',
  },
  loadingClass: {
    background: 'white',
    pointerEvents: 'none'
  },
  filterInputField: {
    width: '100%',
    border: 0,
    borderBottom: '1px solid black',
    background: 'inherit',
    outline: 'none',
  },
  IconHover: {
    padding: 0,
    "&:hover": {
      cursor: 'pointer',
    }
  },
  alignLeft: {
    '& > div': {
      textAlign: 'center',
    },
    textAlign: 'left',
  },
  titleWrapper: {
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
    background: 'white',
    border: '1px solid rgba(224, 224, 224, 1)',
    display: 'flex',
    justifyContent: 'space-between',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      justifyContent: 'flex-end',
      '& h6': {
        marginBottom: theme.spacing(2),
      },
    },

  },
  actionsBtn: {
    display: 'flex',
    justifyContent: 'space-between',
    '& a': {
      marginTop: 0,
      marginLeft: theme.spacing(2),
    },
    [theme.breakpoints.down('sm')]: {
      '& button': {
        marginLeft: 0
      },
      justifyContent: 'flex-end'
    }
  },
  exportButton: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: theme.spacing(2),
  },
  card: {
    marginBottom: theme.spacing(2),
  },
  cardContent: {
    display: 'block',
  },
  btnGroup: {
    "& button": {
      marginRight: theme.spacing(1),
    },
    marginTop: theme.spacing(2),
  },
  loader: {
    position: 'absolute',
    top: '50%',
    right: '50%'
  },
  selectedRow: {
    background: '#d0eafe'
  },
  noPermission: {
    height: 300,
    padding: theme.spacing(2)
  }
}));

const GroupPermission = () => {

  const { groupAdministration = {} } = useSelector(AppState.selectors.abilities());

  const { delete: Delete, view, edit } = groupAdministration;

  const classes = useStyles();

  const [filterState, updateFilter] = useState({});
  const [actionType, updateActionType] = useState('')

  const { rowData, error, isLoading, fetchData } = useFetchGroupData();
  const { success, loading, updateGroup } = useUpdateGroupData();
  const { homeLocation: { LocationId, Title } } = useLocations();

  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [selectedRow, updateSelectedRow] = useState({});
  const [selectedGroup, updateSelectedGroup] = useState({});
  // const isMobile = useMediaQuery('@media (max-width:700px)');
  const isMobile = false;
  const [showEditGroupDialog, setShowEditGroupDialog] = useState(false);

  const onFilterChange = (e) => {
    updateFilter({ ...filterState, [e.target.name]: e.target.value })
  }

  const filteredData = useMemo(() => {
    if (Object.keys(filterState).length) {
      const data = rowData.filter(data => {
        let hasValue = true;
        Object.keys(filterState).forEach(key => {
          if (filterState[key] && !(data[key] && (data[key].toLowerCase()).includes(filterState[key].toLowerCase()))) {
            hasValue = false;
          }
        });
        return hasValue;
      });
      return data;
    };

    return rowData;

  }, [filterState, rowData])


  const onRowClicked = (data) => {
    updateActionType('');
    updateSelectedGroup(data)
  };

  useEffect(() => {
    if (success) {
      fetchData();
    }
  }, [success]);

  useEffect(() => {
    updateFilter({});
    updateActionType('');
    setShowDeleteDialog(false);
    updateSelectedRow({});
    updateSelectedGroup({});
    setShowEditGroupDialog(false);
    fetchData();
  }, [LocationId]);

  const fetchNewGroupData = () => {
    fetchData();
  }


  const onEditComplete = (data, actionType) => {
    updateGroup(data, actionType);
    updateActionType('');
    setShowEditGroupDialog(false);
    updateSelectedRow({});
  }

  const onEditGroup = (e, data, actionType) => {
    e.stopPropagation();
    updateActionType(actionType);
    updateSelectedRow(data);
    setShowEditGroupDialog(true)
  };

  const openDeleteDialog = (e, data, actionType) => {
    e.stopPropagation();
    updateActionType(actionType);
    updateSelectedRow(data);
    setShowDeleteDialog(true);
  }

  const onDeleteGroup = (data) => () => {
    updateGroup(data, ACTIONS.DELETE);
    updateActionType('');
    onCloseDeleteDialog();
  };

  const onCloseDeleteDialog = () => {
    setShowDeleteDialog(false);
    updateSelectedRow({});
  }
  const onCloseEditGroupDialog = () => {
    setShowEditGroupDialog(false);
    updateSelectedRow({});
  }

  useEffect(() => {
    const updatedData =  rowData.find(data => data.GroupId === selectedGroup.GroupId) || {};
    updateSelectedGroup(updatedData)
  }, [rowData])

  const csvData = useMemo(() => {
    const headers = columns.map(col => col.label);
    const data = filteredData.map(rowData => {
      const row = [];
      columns.forEach(col => {
        row.push(col.type !== 'boolean' ? rowData[col.field] : (
          rowData[col.field] ? 'true' : 'false'
        ))
      });
      return row;
    })
    return [headers, ...data]
  }, [filterState, columns, filteredData]);

  return (
    <div>
      <Breadcrumbs crumbs={[{ label: 'Group Permissions' }]} />
      {
        view ?
          <>
            <div className={classes.titleWrapper}>
              <Typography variant="h6">Groups</Typography>
              <div className={classes.actionsBtn}>
                {edit && <Button disabled={!!isLoading} variant="contained" color="primary" onClick={(e) => onEditGroup(e, {}, ACTIONS.CREATE)}>Add Group</Button>}
                <CSVLink className={classes.exportButton} filename="group.csv" data={csvData}>
                  <Button disabled={!filteredData.length} variant="contained" color="primary" startIcon={<DownloadIcon />}>
                    Export to Excel
                  </Button>
                </CSVLink>
              </div>
            </div>
            {
              isMobile ?
                <div>
                  {
                    filteredData.map((data, i) => (
                      <Card key={i} className={classes.card}>
                        <CardContent className={classes.cardContent}>
                          {
                            columns.map((col, index) => (
                              <Grid container key={index}>
                                <Typography variant="subtitle2" component="h6">{col.label}:&nbsp;</Typography>
                                <Typography variant="body2">
                                  {`${data[col.field]}`}
                                </Typography>
                              </Grid>
                            )
                            )
                          }
                          <div className={classes.btnGroup}>
                            <Button disabled={!edit} variant="contained" size="small" color="primary" onClick={(e) => onEditGroup(e, data, ACTIONS.UPDATE)}>Edit</Button>
                            <Button disabled={!Delete} variant="contained" size="small" color="secondary" onClick={(e) => openDeleteDialog(e, data, ACTIONS.DELETE)}>Delete</Button>
                          </div>
                        </CardContent>
                      </Card>
                    ))
                  }
                </div> :
                <Paper className={`${classes.container} ${(loading || isLoading) ? classes.loadingClass : {}}`}>
                  <div>{(loading || isLoading) && <CircularProgress className={classes.loader} />}</div>
                  <Table stickyHeader>
                    <TableHead>
                      <TableRow>
                        {edit && <TableCell key="edit" align="center" width="5%">EDIT</TableCell>}
                        {Delete && <TableCell key="delete" align="center" width="5%">DELETE</TableCell>}
                        {columns.map((col) => <TableCell width={col.width} key={col.field} align="center">
                          <div>{col.label}</div>
                          <div className={classes.alignLeft}>
                            {col.showFilter &&
                              <input
                                name={col.field}
                                onChange={(e) => onFilterChange(e, col)}
                                className={classes.filterInputField}
                                placeholder='Filter'
                                type="text"
                                value={filterState[col.field] || ''} />}
                          </div>
                        </TableCell>)}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {!error ?
                        filteredData.map((data, i) => (
                          <TableRow className={data.GroupId === selectedGroup.GroupId ? classes.selectedRow : ''} onClick={() => onRowClicked(data, i)} hover tabIndex={-1} key={data.rowId}>
                            {
                              edit && (
                                <TableCell key="edit" align="center" width="5%">
                                  <IconButton disabled={!edit} className={classes.IconHover} onClick={(e) => onEditGroup(e, data, ACTIONS.UPDATE)}>
                                    <EditOutlinedIcon />
                                  </IconButton>
                                </TableCell>
                              )
                            }
                            {
                              Delete && (
                                <TableCell key="delete" align="center" width="5%">
                                  <IconButton disabled={!Delete} className={classes.IconHover} onClick={(e) => openDeleteDialog(e, data, ACTIONS.DELETE)}>
                                    <DeleteOutlinedIcon />
                                  </IconButton>
                                </TableCell>
                              )
                            }

                            {columns.map(column => (
                              <TableCell key={column.field} width={column.width}>
                                {column.type !== 'boolean' ? data[column.field] : (
                                  data[column.field] ? 'true' : 'false'
                                )}
                              </TableCell>
                            )
                            )}
                          </TableRow>
                        )) :
                        <TableRow>
                          <TableCell colSpan={6}>There was a problem while loading groups, please try again</TableCell>
                        </TableRow>
                      }
                      <TableRow>
                      </TableRow>
                    </TableBody>
                  </Table>
                </Paper>
            }

            {edit && <GroupPanel title={Title} fetchNewGroupData={fetchNewGroupData} selectedRow={selectedGroup}  />}

            {showDeleteDialog &&
              <Dialog
                visible={true}
                onCancel={onCloseDeleteDialog}
                onClose={onCloseDeleteDialog}
                title="Delete Group"
                actions={[{
                  text: 'Ok',
                  color: 'primary',
                  onClick: onDeleteGroup(selectedRow)
                }]}
              >
                <Typography gutterBottom>
                  <span>Are you sure you want to delete</span><b>{` ${selectedRow.Name} `}</b><span>group?</span>
                </Typography>
              </Dialog>
            }
            {showEditGroupDialog &&
              <EDitGroup
                actionType={actionType}
                selectedRow={selectedRow}
                onCloseEditGroupDialog={onCloseEditGroupDialog}
                onEditComplete={onEditComplete}
              />
            }
          </>
          :
          <Paper className={classes.noPermission}>
            <Alert severity="error">
              <Typography>You do not have enough permissions to view this module</Typography>
            </Alert>
          </Paper>}
    </div>
  )
};

export default GroupPermission;
