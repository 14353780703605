import React from 'react';
import PropTypes from 'prop-types';
import { Button, makeStyles } from '@material-ui/core';
import { useHistory } from 'react-router-dom';

import { Template, TemplateStateAction } from '../../../../types/template';
import { PermitTemplate } from '../../../../types/prop-types';

import CommentsDialog from '../shared/CommentsDialog';
import ActionDialog from '../shared/ActionDialog';

const Modes = ['edit', 'view'];
type Mode = typeof Modes[number];

type Props = {
  template: Template;
  mode: Mode;
};

const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: 'flex',
    marginBottom: theme.spacing(2),
    justifyContent: 'flex-end',
    '& > *': {
      marginLeft: theme.spacing(2),
    },
  },
}));

const ActionButtons: React.FC<Props> = (props) => {
  const classes = useStyles();
  const history = useHistory();

  const { mode, template } = props;

  const [dialogOpen, setDialogOpen] = React.useState<'comments' | undefined>();
  const [openDialog, setOpenDialog] = React.useState<TemplateStateAction>();

  const renderActionButton = (
    actionType: TemplateStateAction,
    label: string,
    color: 'primary' | 'secondary' = 'primary',
  ) => (
    <Button
      color={color}
      variant="contained"
      size="small"
      onClick={() => setOpenDialog(actionType)}
    >
      {label}
    </Button>
  );

  return (
    <div className={classes.wrapper}>
      {template.State === 'DRAFT' && mode === 'view' && (
        <Button
          color="primary"
          variant="contained"
          size="small"
          onClick={() => history.push(`/admin/templates/${template.PermitTemplateId}/edit`)}
        >
          Edit
        </Button>
      )}

      {template.State === 'DRAFT' && renderActionButton(TemplateStateAction.Review, 'Submit for Review')}
      {template.State === 'REVIEW' && renderActionButton(TemplateStateAction.Approve, 'Approve')}
      {template.State === 'REVIEW' && renderActionButton(TemplateStateAction.Reject, 'Reject', 'secondary')}
      {template.State === 'APPROVED' && renderActionButton(TemplateStateAction.Discontinue, 'Discontinue', 'secondary')}

      <Button
        variant="contained"
        size="small"
        color="primary"
        onClick={() => setDialogOpen('comments')}
      >
        Comments
      </Button>

      {dialogOpen === 'comments' && template && (
        <CommentsDialog
          template={template}
          onClose={() => setDialogOpen(undefined)}
        />
      )}

      {openDialog && (
        <ActionDialog
          action={openDialog}
          onClose={() => setOpenDialog(undefined)}
          PermitTemplateId={template.PermitTemplateId}
        />
      )}
    </div>
  );
};

ActionButtons.propTypes = {
  mode: PropTypes.oneOf(['edit', 'view']).isRequired,
  template: PermitTemplate.isRequired,
};

export default ActionButtons;
