import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import useLocations from '../locations/useLocations';
import ListItemsState from '../../actions/admin/lists';

import { ListItem } from '../../types/list-item';

type UseListItemResponse = {
  loading: boolean;
  data: ListItem[];
}

const useListItems = (fieldKey: string): UseListItemResponse => {
  const dispatch = useDispatch();
  const { homeLocation } = useLocations();
  const { actions, selectors } = ListItemsState;

  const [loading, setLoading] = React.useState(false);
  const [listItems, setListItems] = React.useState<ListItem[]>([]);

  const listItemsFromState = useSelector(selectors.listItems);

  React.useEffect(() => {
    setListItems(listItemsFromState[fieldKey as unknown as number]);
    setLoading(false);
  }, [listItemsFromState, fieldKey]);

  React.useEffect(() => {
    setLoading(true);
    dispatch(actions.getListItems(homeLocation.SiteId, fieldKey));
  }, [
    actions,
    dispatch,
    homeLocation.SiteId,
    fieldKey,
  ]);

  return { loading, data: listItems|| [] };
};

export default useListItems;
