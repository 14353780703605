import { get as _get, find as _find } from 'lodash';

import Api, { initApi } from '../shared/api';
import { pending, resolve, reject } from './shared/status';
import WorkOrdersState from './work-orders';
import DefinitionsState from './definitions';
import AppState from './app';
import PermitsState from './permits';

import s3 from '../shared/s3';

const TYPES = {
  getLocationMetadata: 'LOCATIONS_METADATA_GET',
  setLocationMetaData: 'LOCATIONS_METADATA_SET',
  getPlotPlanImage: 'LOCATIONS_PLOT_PLAN_IMAGE_GET',
  setPlotPlanImage: 'LOCATIONS_PLOT_PLAN_IMAGE_SET',
  clearPlotPlanImage: 'LOCATIONS_PLOT_PLAN_IMAGE_CLEAR',
};

export const getLocationMetadata = (siteId) => {
  return async (dispatch) => {
    try {
      dispatch(pending(TYPES.getLocationMetadata));

      const metadata = await Api.get(`/locationmetadata?LocationId=${siteId}`);

      dispatch(DefinitionsState.actions.setDefinitionIdOfSite(siteId, metadata.PermitDefinitionId));

      // get the latest version ahead of time in case the user goes offline
      dispatch(DefinitionsState.actions.getDefinition(metadata.PermitDefinitionId));

      dispatch(DefinitionsState.actions.setInitialDefinitionForSite({
        PermitDefinitionId: metadata.PermitDefinitionId,
        PermitInstanceMetadataJSON: metadata.InitialPermitInstanceMetadata,
      }));

      dispatch(DefinitionsState.actions.setLists(metadata.Lists));
      dispatch(WorkOrdersState.actions.setStatuses(metadata.WorkOrders));
      dispatch(WorkOrdersState.actions.setWorkOrdersEnabled(metadata.UsesWorkOrders));
      dispatch(AppState.actions.setPrintableStates(metadata.PrintableStates));

      dispatch(PermitsState.actions.setPrintMergeDefault(metadata.MergePdfs));
      dispatch(PermitsState.actions.setPermitCopyEnabled(metadata.CopyEnabled));

      dispatch(resolve(TYPES.getLocationMetadata));
    } catch (error) {
      dispatch(reject(TYPES.getLocationMetadata, error));
    }
  };
};

export const refreshLocationMetadata = () => {
  return async (dispatch, getState) => {
    const { appState, permits } = getState();
    const { selected } = permits;

    if (!appState.homeLocation.SiteId) {
      return;
    }

    // If the selected.instance state is populated but does not have a PermitInstanceId
    // it means that a user is currently in the process of creating a permit. As such, we do not
    // want to refresh the location metadata otherwise it would refresh the form and the user would loose
    // any data that had already entered.
    if (Object.keys(selected.instance).length && !selected.instance.PermitInstanceId) {
      return;
    }

    dispatch(getLocationMetadata(appState.homeLocation.SiteId));
  };
};

const getPlotPlanImage = (locationId) => async (dispatch, getState) => {
  try {
    dispatch(pending(TYPES.getPlotPlanImage));
    const { locations } = getState().appState;
    const location = locations.find((lo) => lo.LocationId === locationId) || {};

    const { PlotPlans = [], CurrentPlotPlan} = location;
    const plotplan = _find(PlotPlans, (data) => data.Version === CurrentPlotPlan);

    const plotPlanUrl = new URL(plotplan?.FileName);
    const s3FileUrl = await s3.getSecuredFileUrl(plotPlanUrl.pathname.substr(1));

    const api = await initApi(true);
    const imageResult = await api.get(s3FileUrl, { responseType: 'blob' });
    const url = window.URL.createObjectURL(imageResult.data);

    dispatch({
      type: TYPES.setPlotPlanImage,
      payload: {
        locationId,
        url,
      },
    });

    dispatch(resolve(TYPES.getPlotPlanImage));
  } catch (error) {
    dispatch(reject(TYPES.getPlotPlanImage, error));
  }
};

export const selector = (path) => {
  return (state) => _get(state.locations, path);
};

export default {
  actions: {
    getPlotPlanImage,
    getLocationMetadata,
    refreshLocationMetadata,
  },
  selectors: {
    plotPlanImage: (locationId) => selector(`plotPlanUrls.${locationId}`),
  },
  types: TYPES,
};
