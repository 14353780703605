import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';

import SideBarList from './SideBarList';

import AppState from '../../actions/app';

const SideBar = () => {
  const dispatch = useDispatch();
  const { actions, selectors } = AppState;

  const leftMenuDrawerOpen = useSelector(selectors.leftMenuDrawerOpen());

  const toggleDrawer = () => (event) => {
    if (event
      && event.type === 'keydown'
      && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    dispatch(actions.toggleLeftMenuDrawer());
  };
  return (
    <SwipeableDrawer
      open={leftMenuDrawerOpen}
      onClose={toggleDrawer('left', false)}
      onOpen={toggleDrawer('left', true)}
    >
      <SideBarList />
    </SwipeableDrawer>
  );
};

export default SideBar;
