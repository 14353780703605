import React, { useState, useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { NutrienContainer } from './ForgotPassword';
import { Auth } from 'aws-amplify';
import { Button, makeStyles, TextField, Typography, Divider } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import toast from '../../shared/toast';

const useStyles = makeStyles((theme) => {
  return {
    input: {
      marginBottom: theme.spacing(2),
    },
    button: {
      marginTop: theme.spacing(2),
    },
    header: {
      marginBottom: theme.spacing(3),
    },
    buttonCancel: {
      marginTop: theme.spacing(2),
    },
    divider: {
      marginBottom: theme.spacing(2),
    },
    error: {
      marginBottom: theme.spacing(3),
    },
  };
});

const EnterCode = () => {

  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const [emailAddress, setEmailAddress] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [code, setCode] = useState('');
  const [error, setError] = useState(false);
  const [registering, setRegistering] = useState(false);

  const handleSubmit = useCallback(async () => {
    try {
      setRegistering(true);
      await Auth.forgotPasswordSubmit(emailAddress, code, newPassword)
      setRegistering(false);
      history.push('/auth/signin');
      toast.success('Password has been changed successfully.')
    } catch (e) {
      setError(e.message);
      setRegistering(false);
      toast.error(e.message)
      throw error;
    }
  }, [dispatch, emailAddress, newPassword, code]);


  useEffect(() => {
    function handleEnterPress(event) {
      if (event.key === 'Enter') {
        handleSubmit();
      }
    }

    window.addEventListener('keyup', handleEnterPress);
    return () => window.removeEventListener('keyup', handleEnterPress);
  }, [handleSubmit]);
  
  const onCancel = () => {
    history.push('/auth/signin');
  }

  const disabled = registering || !code.length || !newPassword.length || !emailAddress.length
  
  return (
    <NutrienContainer>
      <Typography variant="h5" className={classes.header}>Reset your password.</Typography>
      <Divider className={classes.divider} />
      {error && <Alert severity="error" className={classes.error}>{error}</Alert>}
      <TextField
        required
        label="Email Address"
        type="email"
        variant="outlined"
        value={emailAddress}
        onChange={(event) => setEmailAddress(event.target.value)}
        className={classes.input}
      />
      <TextField
        required
        label="Code"
        type="text"
        variant="outlined"
        value={code}
        onChange={(event) => setCode(event.target.value)}
        className={classes.input}
      />
      <TextField
        required
        label="New password"
        type="password"
        variant="outlined"
        value={newPassword}
        onChange={(event) => setNewPassword(event.target.value)}
        className={classes.input}
      />
      <Button
        disabled={disabled}
        variant="contained"
        fullWidth={false}
        onClick={handleSubmit}
        color="primary"
        className={classes.button}
      >
        Submit
      </Button>
      <Button
        variant="contained"
        fullWidth={false}
        onClick={onCancel}
        className={classes.buttonCancel}
      >
        Cancel
      </Button>
    </NutrienContainer>
  )
};
export default EnterCode;