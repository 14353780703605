import { get as _get } from 'lodash';
import { pending, resolve, reject } from './shared/status';
import Api from '../shared/api';
import S3 from '../shared/s3';
import Collections from '../store/indexeddb';
import { recordEvent } from '../shared/analytics';

export const TYPES = {
  getDefinition: 'DEFINITIONS_GET_ONE',
  updateDefinition: 'DEFINITIONS_LIST_UPDATE_ONE',
  setSiteDefinitionId: 'DEFINITIONS_SITE_DEFINITION_ID_SET',
  setDefinitionForSite: 'DEFINITIONS_SITE_DEFINITION_SET',
  setLists: 'DEFINITIONS_DEFINITION_LISTS_SET',
};

export const setDefinitionIdOfSite = (siteId, PermitDefinitionId) => ({
  type: TYPES.setSiteDefinitionId,
  payload: { siteId, PermitDefinitionId },
});

export const getDefinition = (PermitDefinitionId) => {
  return async (dispatch) => {
    try {
      dispatch(pending(TYPES.getDefinition));

      const existingDefinition = await Collections.Definitions.get(PermitDefinitionId);

      if (existingDefinition) {
        dispatch(resolve(TYPES.getDefinition));

        dispatch({
          type: TYPES.updateDefinition,
          payload: existingDefinition,
        });

        return existingDefinition;
      }

      const definition = await Api.get(`/permitdefbyid?PermitDefinitionId=${PermitDefinitionId}`);

      definition.DefinitionMetadataJSON = JSON.parse(definition.DefinitionMetadataJSON);
      const form = await S3.getSecuredFile(definition.DefinitionJSONS3Key);
      const customField = definition.DefinitionMetadataJSON?.CustomFieldPathList || [];
      const filterTerm = customField.reduce((filtered, data) => {
        if (data.PropertyName === 'SearchableAsKey' && data.FormioType === 'textfield') {
           filtered.push(data.PropertyValue);
        }
        return filtered;
      }, []);

      const newDefinition = {
        PermitDefinitionId: definition.PermitDefinitionId,
        definition,
        form,
        filterTerm
      };

      await Collections.Definitions.set(definition.PermitDefinitionId, newDefinition);

      dispatch({
        type: TYPES.updateDefinition,
        payload: newDefinition,
      });
      dispatch(resolve(TYPES.getDefinition));

      return newDefinition;
    } catch (error) {
      dispatch(reject(TYPES.getDefinition, error));
      recordEvent(`getDefinition - failed - PermitDefinitionId - ${PermitDefinitionId} and error: ${error}`);
      return Promise.reject();
    }
  };
};

export const updateCachedDefinition = (PermitDefinitionId) => {
  return async (dispatch) => {
    try {
      dispatch(pending(TYPES.updateCachedDefinition));

      const definition = await Api.get(`/permitdefbyid?PermitDefinitionId=${PermitDefinitionId}`);

      definition.DefinitionMetadataJSON = JSON.parse(definition.DefinitionMetadataJSON);
      const form = await S3.getSecuredFile(definition.DefinitionJSONS3Key);

      const newDefinition = {
        PermitDefinitionId: definition.PermitDefinitionId,
        definition,
        form,
      };

      await Collections.Definitions.set(definition.PermitDefinitionId, newDefinition);

      dispatch({
        type: TYPES.updateDefinition,
        payload: newDefinition,
      });

      dispatch(resolve(TYPES.updateCachedDefinition));

      return newDefinition;
    } catch (error) {
      dispatch(reject(TYPES.updateCachedDefinition, error));
      recordEvent(`pdateCachedDefinition - failed - PermitDefinitionId - ${PermitDefinitionId} and error: ${error}`);
      return Promise.reject();
    }
  };
};

const setInitialDefinitionForSite = (definition) => (dispatch) => dispatch({
  type: TYPES.setDefinitionForSite,
  payload: definition,
});

const setLists = (lists) => (dispatch) => {
  // restructure the lists to be a dictionary. this signifigantly improves the rendering
  // of a form as we don't need to loop through the list of lists searching for a match
  // for every component. Instead the form looks for a matching key in the dictionary
  const listsDictionary = lists.reduce((all, list) => (
    { ...all, [list.FieldKey]: list.ListItems.map((it) => ({ label: it.Label, value: it.Value })) }
  ), {});

  dispatch({
    type: TYPES.setLists,
    payload: listsDictionary,
  });
};

export const selector = (path) => {
  return (state) => _get(state.definitions, path);
};

export default {
  actions: {
    getDefinition,
    setDefinitionIdOfSite,
    setInitialDefinitionForSite,
    setLists,
    updateCachedDefinition,
  },
  selectors: {
    list: () => selector('list'),
    initialPermitForSite: () => selector('initialPermitForSite'),
    definitionIdForSite: (siteId) => selector(`sites.${siteId}`),
  },
  types: TYPES,
};
