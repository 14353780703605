import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { TreeView } from '@material-ui/lab';

import { Button, makeStyles } from '@material-ui/core';
import TreeItem from '../../../../shared/TreeItem';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { Permissions } from '../../Permissions';
import { useUpdatePermissions } from '../../../../../hooks/admin/useFetachGroupData';

const useStyles = makeStyles((theme) => ({
  btnContainer: {
   display: 'flex',
   flexDirection: 'row-reverse',
   paddingBottom: theme.spacing(2),
   '& button': {
    marginLeft: theme.spacing(1),
   }
  },
  treeContainer: {
    border: '1px solid rgba(224, 224, 224, 1)',
  }
}))

const getGroupBy = (data, selectedPermissions = []) => {

  const withPermission = data.map(items => {
    if (selectedPermissions && selectedPermissions.includes(items.PermissionTypeId)) {
      return { ...items, selected: true}
    }
    return items;
  })

  return _.chain(withPermission).groupBy("Module").map((value, key) => {
    const filtered = value.filter(item => item.selected);
    return { 
      Module: key, 
      children: value, 
      isParent: true, 
      selected: !!(value.length && filtered.length === value.length), 
      indeterminate: !!(filtered.length && value.length > filtered.length)}
  }).value();
}

const GropupPermissions = (props) => {
  const { selectedRow, fetchNewGroupData } = props;
  const { Permissions: selectedPermissions} = selectedRow;
  const [PermissionsData, setPermissionData] = useState([]);

  const { updatePermissions, success, isLoading } = useUpdatePermissions();
  const [expanded, setExpanded] = useState([]);
  const classes = useStyles();
  const handleSelect = (checked, data) => {
    if (data.isParent) {
      const i = PermissionsData.findIndex(name => name.Module === data.Module);
      const updatedChildren = PermissionsData[i].children.map((data) => ({ ...data, selected: checked }));
      const result = {
        Module: data.Module, 
        children: updatedChildren, 
        selected: checked, 
        isParent: true, 
        indeterminate: false
      };
      PermissionsData[i] = result;

    } else {
      const i = PermissionsData.findIndex(name => name.Module === data.Module);
      const updatedChildren = PermissionsData[i].children.map((child) => {
        return child.PermissionTypeId === data.PermissionTypeId ? { ...child, selected: checked } : { ...child }
      });
      const filtered = updatedChildren.filter(item => item.selected);
      const selected = filtered.length === PermissionsData[i].children.length;
      const result = {
        Module: data.Module, 
        children: updatedChildren, 
        selected: selected, 
        isParent: true, 
        indeterminate: !!(filtered.length && filtered.length < updatedChildren.length)
      }
      PermissionsData[i] = result;
    }
    setPermissionData([...PermissionsData])
  };

  useEffect(() => {
    setPermissionData()
  }, [selectedPermissions])

  const onSavePermissions = () => {
    const permissions = [];
    PermissionsData.forEach(data => {
      data.children.forEach(child => {
        if(child.selected) {
          permissions.push(child.PermissionTypeId)
        }
      })
    });
    updatePermissions({...selectedRow, Permissions: permissions})
  }

  const handleExpandClick = () => {
    setExpanded(PermissionsData.map(data => data.Module))
  }

  const handleCollapseClick = () => {
    setExpanded([]);
  }

  const handleToggle = (event, nodeIds) => {
    setExpanded(nodeIds);
  };

  useEffect(() => {
    if (success) {
      fetchNewGroupData();
    }
  }, [success])

  useEffect(() => {
    setPermissionData(getGroupBy(Permissions, selectedPermissions));
  }, [Permissions, selectedPermissions])

  if(!PermissionsData?.length) return null

  return (
    <>
    <div className={classes.btnContainer}>
      <Button onClick={handleExpandClick} variant="contained" color="secondary">Expand all</Button>
      <Button onClick={handleCollapseClick} variant="contained" color="primary">Collapse all</Button>
      <Button onClick={onSavePermissions} variant="contained" color="primary">Save Permissions</Button>
    </div>
    <TreeView
      className={classes.treeContainer}
      defaultCollapseIcon={<ExpandMoreIcon />}
      defaultExpandIcon={<ChevronRightIcon />}
      multiSelect
      expanded={expanded}
      onNodeToggle={handleToggle}
    >
      {
        PermissionsData.map(data => (
        <TreeItem 
            handleSelect={handleSelect} 
            indeterminate={data.indeterminate} 
            data={data} 
            key={data.Module} 
            nodeId={data.Module} 
            labelText={data.Module} 
            isParent
            selected={!!data.selected} >
            {
              data.children.map(item => (
                <TreeItem 
                  handleSelect={handleSelect} 
                  data={item} 
                  selected={!!item.selected} 
                  key={item.PermissionTypeId} 
                  nodeId={`${item.PermissionTypeId}`} 
                  labelText={item.Name} />
              ))
            }
          </TreeItem>
        ))
      }
    </TreeView>
    </>
  )
};

export default GropupPermissions;