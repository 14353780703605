import React from 'react';
import { FormControlLabel, Checkbox, Typography, makeStyles } from '@material-ui/core';
import InlineLoader from '../../shared/InlineLoader';

import OpenPermitsTable from './charts/OpenPermitsTable';
import PermitsCountGroupedByStateChart from './charts/PermitsCountGroupedByStateChart';

import VisibleStatesSelect, { PermitFilterTypes } from './VisibleStatesSelect';
import PermitConfig, { PermitConfigMap } from '../../../config/permits';
import { usePermitStatesStats } from './query';
import { PermitState } from '../../../types/permit';

const useStyles = makeStyles((theme) => ({
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: theme.spacing(4),
  },
  reportFiltersWrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  checkboxWrapper: {
    '& *': {
      paddingTop: 0,
      paddingBottom: 0,
    },
    marginBottom: 0,
  },
}));

const PermitsByStateReport = () => {
  const classes = useStyles();

  const [visibleStates, setVisibleStates] = React.useState<PermitFilterTypes>('open');
  const [groupByLocation, setGroupByLocation] = React.useState(true);

  const { data } = usePermitStatesStats(groupByLocation);

  const permitStates = React.useMemo(() => {
    const nextStates = { ...PermitConfig } as PermitConfigMap;

    if (visibleStates === 'all') {
      return nextStates;
    }

    return Object.keys(nextStates)
      .reduce((sts, s) => {
        const state = s as PermitState;
        if (!nextStates[state].openStatus) delete nextStates[state];
        return sts;
      }, nextStates);
  }, [visibleStates]);

  if (!data || !data.graph || !data.table) {
    return (
      <InlineLoader />
    );
  }

  return (
    <>
      <div className={classes.header}>
        <div><Typography variant="h5">Permits by State</Typography></div>
        <div className={classes.reportFiltersWrapper}>
          <FormControlLabel
            className={classes.checkboxWrapper}
            control={(
              <Checkbox
                checked={groupByLocation}
                onChange={() => setGroupByLocation((l) => !l)}
                name="groupByLocation"
                color="primary"
              />
            )}
            label="Group by Location"
          />
          <VisibleStatesSelect onChange={setVisibleStates} selected={visibleStates} />
        </div>
      </div>

      <PermitsCountGroupedByStateChart data={data.graph} states={permitStates} />
      <OpenPermitsTable data={data.table} states={permitStates} />
    </>
  );
};

export default PermitsByStateReport;
