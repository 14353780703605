import { makeStyles } from '@material-ui/core';
import InlineLoader from '../../shared/InlineLoader';

const useStyles = makeStyles(theme => ({
  overlay: {
    textAlign: 'center',
    position: 'absolute',
    zIndex: 10,
    display: 'flex',
    justifyContent: 'center',
    background: "#efefef",
    width: '100%',
    height: '100%',
    paddingTop: 100,
    opacity: 0.9,
  }
}))

const SavingOverlay = () => {
  const classes = useStyles()
  return (
    <div className={classes.overlay}>
      <div className="content">
        <InlineLoader />
        <h3>Saving</h3>
      </div>
    </div>
  );
};


export default SavingOverlay;
