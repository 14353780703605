/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import Container from '@material-ui/core/Container';
import { Route, Switch } from 'react-router-dom';
import { useLocation } from 'react-router-dom';

import useEnsureUserLogin from './hooks/auth/useEnsureUserLogin';
import useCacheExpiry from './hooks/shared/useCacheExpiry';
import AppState from './actions/app';

import NavigationBar from './components/shared/NavigationBar';
import SideBar from './components/shared/SideBar';
import Loader from './components/shared/Loader';
import Permits from './components/permits';
import Admin from './components/admin';
import Reports from './components/reports';
import WorkOrders from './components/work-orders';
import Preferences from './components/preferences/UserPreferences';
import Home from './components/dashboard/Home';
import Unauthorized from './components/auth/Unauthorized';
import SignOut from './components/auth/SignOut';
import LocationNotEnabled from './components/shared/LocationNotEnabled';
import AuthenticatedRoute from './components/shared/AuthenticatedRoute';
import Register from './components/auth/Register';
import SignIn from './components/auth/SignIn';
import ForgotPassword from './components/auth/ForgotPassword';
import EnterCode from './components/auth/EnterCode';

import Config from './config/app-config';
import Amplify, { Analytics, AWSKinesisProvider } from 'aws-amplify';
import { useFeatureFlags } from './shared/useFeatureFlag';

Amplify.configure(Config.Amplify);
Analytics.addPluggable(new AWSKinesisProvider());

const App: React.FC = () => {
  useCacheExpiry();

  const dispatch = useDispatch();
  const { isAuthenticated } = useEnsureUserLogin();
  const { selectors } = AppState;
  const initilized = useSelector(selectors.initilized());
  const pageName = useSelector(selectors.pageName());
  let [outageWarningBanner, setOutageWarningBanner] = React.useState<{ display: boolean; message: boolean | React.ReactChild | React.ReactFragment | React.ReactPortal | null | undefined;}>();
  let [outageBanner, setOutageBanner] = React.useState<{ display: boolean; message: boolean | React.ReactChild | React.ReactFragment | React.ReactPortal | null | undefined;}>();

  const featureFlag: {
    [name: string]: string;
  } = useFeatureFlags();

  useEffect(() => {
    try {
      if (featureFlag && featureFlag.outagewarningbanner && featureFlag.outagebanner) {
        setOutageWarningBanner(JSON.parse(featureFlag.outagewarningbanner));
        setOutageBanner(JSON.parse(featureFlag.outagebanner));
      }
    } catch (e) {
      console.log(e)
    }
  }, [featureFlag]);
  
  const location = useLocation();
  const showNav = location.pathname !== '/auth/signin'; // Hide nav on sign in page
  
  React.useEffect(() => {
    const { actions } = AppState;
    if (isAuthenticated) {
      dispatch(actions.init());
    }
  }, [isAuthenticated, dispatch]);

  const renderAuthenticatedAppRoutes = React.useCallback(() => {
    if (!initilized) {
      return <Loader enabled />;
    }

    return (
      <>
        <ToastContainer />
        { showNav && <NavigationBar pageName={pageName} outageWarningBanner={outageWarningBanner} outageBanner={outageBanner} /> }
        <SideBar />
        <Container maxWidth="lg">
          <Switch>
            <AuthenticatedRoute exact path="/" component={Home} />
            <AuthenticatedRoute path="/permits" component={Permits} />
            <AuthenticatedRoute path="/admin" component={Admin} />
            <AuthenticatedRoute path="/reports" component={Reports} />
            <AuthenticatedRoute path="/workorders" component={WorkOrders} />
            <AuthenticatedRoute path="/preferences" component={Preferences} />
            <Route path="/location-not-enabled" component={LocationNotEnabled} />
            <Route path="/auth/signin" component={SignIn} />
            <Route path="/unauthorized" component={Unauthorized} />
          </Switch>
        </Container>
      </>
    );
  }, [initilized, pageName, showNav]);

  return (
    <div className="app">
      <ToastContainer />
      {isAuthenticated ? renderAuthenticatedAppRoutes() : (
        <Switch>
          <Route path="/auth/signout" component={SignOut} />
          <Route path="/auth/register" component={Register} />
          <Route path="/auth/signin" component={SignIn} />
          <Route path="/auth/forgotpassword" component={ForgotPassword} />
          <Route path="/auth/entercode" component={EnterCode} />
          <Route path="/unauthorized" component={Unauthorized} />
        </Switch>
      )}
    </div>
  );
};

export default App;
