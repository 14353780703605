import { SelectedDefinition } from '../types/definition';
import { Location } from '../types/location';

export const locationsEqual = (a: Location, b: Location) => {
  return !!a && !!b && Number(a.LocationId) === Number(b.LocationId);
}

export const definitionsInStateEqual = (a: SelectedDefinition, b: SelectedDefinition) => {
  return a.instance.PermitDefinitionId === b.instance.PermitDefinitionId;
}
