import React from 'react';
import { VariableSizeList, ListChildComponentProps } from 'react-window';

import {
  TextField,
  useMediaQuery,
  ListSubheader,
  Grid,
  Typography,
  useTheme,
  makeStyles
} from '@material-ui/core';

import AssessmentIcon from '@material-ui/icons/Assessment';
import Autocomplete from '@material-ui/lab/Autocomplete';

import useGetWorkOrders from '../../hooks/work-orders/useGetWorkOrders';
import { WorkOrder } from '../../types/work-order';

const LISTBOX_PADDING = 8; // px

function renderRow(props: ListChildComponentProps) {
  const { data, index, style } = props;
  return React.cloneElement(data[index], {
    style: {
      ...style,
      top: (style.top as number) + LISTBOX_PADDING,
    },
  });
}

const OuterElementContext = React.createContext({});

const OuterElementType = React.forwardRef<HTMLDivElement>((props, ref) => {
  const outerProps = React.useContext(OuterElementContext);
  return <div ref={ref} {...props} {...outerProps} />;
});

function useResetCache(data: any) {
  const ref = React.useRef<VariableSizeList>(null);

  React.useEffect(() => {
    if (ref.current != null) {
      ref.current.resetAfterIndex(0, true);
    }
  }, [data]);
  return ref;
}

const ListboxComponent = React.forwardRef<HTMLDivElement>(
  function ListboxComponent(props, ref) {
    const { children, ...other } = props;
    const itemData = React.Children.toArray(children);
    const theme = useTheme();
    const smUp = useMediaQuery(theme.breakpoints.up("sm"), { noSsr: true });
    const itemCount = itemData.length;
    const itemSize = smUp ? 36 : 48;

    const getChildSize = (child: React.ReactNode) => {
      if (React.isValidElement(child) && child.type === ListSubheader) {
        return 48;
      }

      return itemSize;
    };

    const getHeight = () => {
      if (itemCount > 8) {
        return 8 * itemSize;
      }
      return itemData.map(getChildSize).reduce((a, b) => a + b, 0);
    };

    const gridRef = useResetCache(itemCount);

    return (
      <div ref={ref}>
        <OuterElementContext.Provider value={other}>
          <VariableSizeList
            itemData={itemData}
            height={getHeight() + 2 * LISTBOX_PADDING}
            width="100%"
            ref={gridRef}
            outerElementType={OuterElementType}
            innerElementType="ul"
            itemSize={(index) => getChildSize(itemData[index])}
            overscanCount={5}
            itemCount={itemCount}
          >
            {renderRow}
          </VariableSizeList>
        </OuterElementContext.Provider>
      </div>
    );
  }
);

const useStyles = makeStyles((theme) => ({
  listbox: {
    boxSizing: "border-box",
    "& ul": {
      padding: 0,
      margin: 0,
    },
  },
  icon: {
    color: theme.palette.text.secondary,
    marginRight: theme.spacing(2),
  },
}));


type Props = {
  onChange: (WorkOrder: WorkOrder | undefined) => any;
};

export default function VirtualizedSelectList(props: Props) {
  const classes = useStyles();

  const { loading, list: workOrders } = useGetWorkOrders();

  const handleOnChange = (event: any, value: WorkOrder | null, reason: any) => {
    if (value) {
      props.onChange(value);
    }
  };

  return (
    <Autocomplete
      id="work-orders-from-state"
      style={{ width: "100%" }}
      disableListWrap
      disabled={loading}
      onChange={handleOnChange}
      classes={classes}
      ListboxComponent={
        ListboxComponent as React.ComponentType<
          React.HTMLAttributes<HTMLElement>
        >
      }
      getOptionLabel={(option) =>
        typeof option === "string"
          ? option
          : `${option.workOrderNumber} - ${option.workOrderDescription}`
      }
      options={workOrders}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          label="Select work order"
          style={{ marginTop: "10px" }}
        />
      )}
      renderOption={(option) => {
        return (
          <Grid container alignItems="center">
            <Grid item>
              <AssessmentIcon className={classes.icon} />
            </Grid>
            <Grid item xs>
              <Typography variant="body2" color="textSecondary">
                <b>{option.workOrderNumber}</b> - {option.workOrderDescription}
              </Typography>
            </Grid>
          </Grid>
        );
      }}
    />
  );
}
