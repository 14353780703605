import React from 'react';
import { Route, Switch } from 'react-router-dom';

import TemplatesList from './TemplatesList';
import CreateEditTemplate from './CreateEditTemplate';
import CreateTemplate from './CreateTemplate';

const AdminRoutes: React.FC = () => {
  return (
    <div className="templates">
      <Switch>
        <Route exact path="/admin/templates" component={TemplatesList} />
        <Route exact path="/admin/templates/create" component={CreateTemplate} />
        <Route exact path="/admin/templates/:id/edit" component={CreateEditTemplate} />
        <Route exact path="/admin/templates/:id" render={() => <CreateEditTemplate readOnly />} />
      </Switch>
    </div>
  );
};

export default AdminRoutes;
