import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import {
  Table,
  Chip,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  Typography,
  FormControlLabel,
  Switch,
} from '@material-ui/core';

import Dialog from '../../shared/Dialog';
import InlineLoader from '../../shared/InlineLoader';
import usePermitApprovals from '../../../hooks/permits/usePermitApprovals';
import { ESPermit } from '../../../types/prop-types';

const ApprovalsDialog = (props) => {
  const { onClose, ReferenceId, visible, permit } = props;
  const { approvals, loading } = usePermitApprovals(permit);

  const [showAll, setShowAll] = React.useState(false);

  const filteredApprovals = React.useMemo(() => {
    if (!approvals) return [];

    return approvals
      .RequiredApprovals
      .filter((approval) => {
        return showAll || (!showAll && !approval.IsApproved) ? approval : undefined;
      });
  }, [showAll, approvals]);

  return (
    <Dialog
      onClose={onClose}
      visible={visible}
      title={`Permit Approvals - #${ReferenceId}`}
      actions={[]}
    >
      {loading ? <InlineLoader /> : (
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell align="right">
                <FormControlLabel
                  labelPlacement="start"
                  control={(
                    <Switch
                      checked={showAll}
                      onChange={() => setShowAll((all) => !all)}
                      name="statusFilter"
                      color={showAll ? 'primary' : 'secondary'}
                    />
                  )}
                  label="Show Completed"
                />
              </TableCell>
            </TableRow>
          </TableHead>
          <TableHead>
            <TableRow>
              <TableCell>Approval</TableCell>
              <TableCell align="right">Status</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredApprovals.map((approval) => (
              <TableRow key={approval.PermitApprovalId}>
                <TableCell component="th" scope="row">
                  <Typography display="block" variant="body2" gutterBottom>{approval.ApprovalLabel}</Typography>
                  <Typography variant="h6" display="block">{approval.ApprovedByName}</Typography>
                  {approval.IsApproved && (
                    <Typography variant="caption" display="block">
                      {moment(approval.ApprovalDate).format('YYYY-MM-DD h:mm:ss a')}
                    </Typography>
                  )}
                </TableCell>
                <TableCell align="right">
                  {
                    approval.IsApproved
                      ? <Chip label="Completed" color="primary" />
                      : <Chip label="Missing" color="secondary" />
                  }
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      )}
    </Dialog>
  );
};

ApprovalsDialog.defaultProps = {
  visible: false,
  onClose: undefined,
};

ApprovalsDialog.propTypes = {
  permit: ESPermit.isRequired,
  onClose: PropTypes.func,
  ReferenceId: PropTypes.string.isRequired,
  visible: PropTypes.bool,
};

export default ApprovalsDialog;
