import React from 'react';
import { Button, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Sync as SyncIcon } from '@material-ui/icons';

import useSetPageName from '../../../hooks/shared/useSetPageName';
import FormCard from './FormCard';
import InlineLoader from '../../shared/InlineLoader';
import useForms from '../../../hooks/forms/useForms';

const useStyles = makeStyles((theme) => ({
  actions: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginBottom: theme.spacing(2),
    marginRight: theme.spacing(1),
  },
  refreshIcon: {
    marginRight: theme.spacing(1),
  },
}));

const FormsList = () => {
  useSetPageName('Forms');

  const classes = useStyles();
  const { loading, forms, reloadList } = useForms();

  return (
    <div>
      {loading
        ? <InlineLoader />
        : (
          <Grid container>
            <Grid item xs={12} className={classes.actions}>
              <Button
                color="primary"
                onClick={reloadList}
                variant="contained"
              >
                <SyncIcon className={classes.refreshIcon} />
                Refresh List
              </Button>
            </Grid>
            <Grid item xs={12}>
              {forms.map((form) => <FormCard key={form.site.SiteId} {...form} />)}
            </Grid>
          </Grid>
        )}
    </div>
  );
}

export default FormsList;
