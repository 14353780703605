import axios from 'axios';
import { Storage } from 'aws-amplify';

import { initApi } from './api';

/**
 * Retrieve file from s3
 * @param {string} filePath - Logical path to the file
 * @return {File} - The file requested at filePath in the s3 bucket
 */
const getFile = async (filePath: string) => {
  const api = await initApi(true);
  const result = await api.get(filePath);
  return result.data;
};

/**
 * Retrieve a signed file url from s3
 * @param {string} filePath - Logical path to the file
 * @return {File} - The file requested at filePath in the s3 bucket
 */
const getSecuredFileUrl = async (filePath: string) => {
  return Storage.get(filePath, { customPrefix: { public: '' } });
};

/**
 * Load  secured file from s3
 * @param {string} filePath - Logical path to the file
 * @return {File} - The file requested at filePath in the s3 bucket
 */
const getSecuredFile = async (filePath: string) => {
  const storageUrl = await Storage.get(
    filePath,
    { customPrefix: { public: '' } },
  ) as string;

  const { data } = await axios.get(storageUrl);
  return data;
};

const s3 = {
  getFile,
  getSecuredFileUrl,
  getSecuredFile,
};

export default s3;
