import React from 'react';
import { compare } from 'fast-json-patch';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { Backdrop, CircularProgress, Paper, makeStyles, Button, Typography } from '@material-ui/core';

import CreateEditForm from '../shared/CreateEditForm';
import Breadcrumbs from '../../Breadcrumbs';

import { FormData, FormioForm } from '../../../../types/form-io';
import { ExternalUser } from '../../../../types/external-user';
import ExternalUsersState from '../../../../actions/admin/external-users';
import useExternalUser from '../../../../hooks/admin/useExternalUser';
import InlineLoader from '../../../shared/InlineLoader';
import { Alert } from '@material-ui/lab';
import LocationPermissionsTree, { LocationPermissions } from '../shared/LocationPermissionsTree';
import { PERMISSIONS } from '../../../../shared/auth';

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  error: {
    marginBottom: theme.spacing(2),
  },
  wrapper: {
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
}));

const EditUser: React.FC = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();

  const { PersonnelId } = useParams<{ PersonnelId: string }>();
  const { data: user, loading } = useExternalUser(Number(PersonnelId));

  const [initialFormValues, setInitialFormValues] = React.useState<Partial<ExternalUser>>()
  const [initialPermissions, setInitialPermissions] = React.useState<LocationPermissions>()

  React.useEffect(() => {
    if (!user) {
      return;
    }

    setInitialFormValues({ ...user });
  }, [user])

  const [saving, setSaving] = React.useState<boolean>(false);
  const [error, setError] = React.useState<string>();

  const formRef = React.useRef<FormioForm>(null);

  const [permissions, setPermissions] = React.useState<LocationPermissions>({
    CanEdit: [],
    CanPrint: []
  });

  React.useEffect(() => {
    if (user) {
      if (user.Permissions) {
        const nextPermissions: Partial<LocationPermissions> = {};

        user.Permissions.forEach(permission => {
          if (permission.PermissionTypeId === PERMISSIONS.Permits.Edit) {
            nextPermissions.CanEdit = permission.Locations;
          }

          if (permission.PermissionTypeId === PERMISSIONS.Permits.Print) {
            nextPermissions.CanPrint = permission.Locations;
          }
        })

        setInitialPermissions(nextPermissions as LocationPermissions);
      }
    }
  }, [user]);

  const handleLocationTreeChange = React.useCallback(
    (permissions: LocationPermissions) => {
      setPermissions(permissions);
    }, []);

  const handleSubmit = async (formData: FormData<ExternalUser>) => {
    try {
      setSaving(true);

      const nextUser = {
        ...formData.data,
        CanEdit: Array.from(permissions.CanEdit),
        CanPrint: Array.from(permissions.CanPrint),
      } as ExternalUser;

      await dispatch(
        ExternalUsersState.actions.updateUser(
          Number(PersonnelId),
          nextUser
        )
      );

      history.push('/admin/external-users');
    } catch (error) {
      setError('Invalid user details');
      setSaving(false);
    };
  }

  React.useEffect(() => {
    if (formRef.current) {
      formRef.current.readOnly = saving;
    }
  }, [saving]);

  return (
    <>
      <Breadcrumbs
        crumbs={[
          { label: 'External Users', path: '/admin/external-users' },
          { label: 'Edit User' },
        ]}
      />

      <Paper className={classes.wrapper}>
        {loading ? <InlineLoader /> : (
          <>
            {error && <Alert severity="error" className={classes.error}>{error}</Alert>}

            <CreateEditForm
              editing
              showEmail
              formRef={formRef}
              initialValues={initialFormValues}
              onSubmit={handleSubmit}
            />
          </>
        )}
      </Paper>

      {user && (
        <>
          <Paper className={classes.wrapper}>
            <Typography variant="h6">Select user permissions</Typography>
            <Typography variant="subtitle1">All external users have access to view permits.</Typography>
            <LocationPermissionsTree
              initialPermissions={initialPermissions}
              onChange={handleLocationTreeChange}
            />
          </Paper>
          <Paper className={classes.wrapper}>
            <Button
              variant="contained"
              color="primary"
              size="small"
              onClick={() => {
                if (formRef.current) {
                  formRef.current.submit();
                }
              }}
            >
              Update User
          </Button>

            <Backdrop className={classes.backdrop} open={saving}>
              <CircularProgress color="inherit" />
            </Backdrop>
          </Paper>
        </>
      )}
    </>
  );
};

export default EditUser;
