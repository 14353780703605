import React, { useState, useEffect } from 'react';
import useLocations from '../../hooks/locations/useLocations';
import { cardsPanelsState } from '../../config/site-config';

const useExpandAll = () => {

  const { homeLocation } = useLocations();
  const [expanded, updateExpandState] = useState(cardsPanelsState.expanded.includes(homeLocation.SiteId));
 
  useEffect(() => {
    updateExpandState(cardsPanelsState.expanded.includes(homeLocation.SiteId))
  }, [homeLocation?.SiteId])

  return {
    expandCard: expanded,
    updateExpandState
  }
}

export default useExpandAll;