import React from 'react';
import moment from 'moment';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';

import {
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Typography,
  IconButton
} from '@material-ui/core';
import { Edit as EditIcon } from '@material-ui/icons';
import useSetPageName from '../../hooks/shared/useSetPageName';
import useDrafts from '../../hooks/permits/useDrafts';
import InlineLoader from '../shared/InlineLoader';

import {
  getWorkDate,
  getWorkDescription,
  getWorkOrderNumber,
} from '../../shared/permits';
import DeleteDraftButton from './shared/DeleteDraftButton';
import { formatAsStartCase } from '../../shared/string';

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 650,
  },
  rowItem: {
    '&:hover': {
      cursor: 'pointer',
    },
  },
  deletionText: {
    marginLeft: theme.spacing(1),
    marginTop: theme.spacing(2),
    color: theme.palette.grey[500],
  },
}));

const PermitDrafts = () => {
  useSetPageName('Draft Permits');

  const classes = useStyles();
  const history = useHistory();

  const { loading, drafts } = useDrafts();

  const draftsList = React.useMemo(() => {
    return drafts.map((draft) => {
      const structuredPermit = {
        ContentJSON: draft.PermitContent,
        SiteId: draft.SiteId,
      };

      return {
        ...draft,
        workDate: getWorkDate(structuredPermit),
        workDescription: getWorkDescription(structuredPermit),
        workOrderNumber: getWorkOrderNumber(structuredPermit),
      };
    }).sort((a, b) => {
      return moment(a.LastUpdatedOn).format('x') < moment(b.LastUpdatedOn).format('x')
        ? 1
        : -1;
    });
  }, [drafts]);

  const handleDraftClick = (draftId) => {
    history.push(`/permits/draft/${draftId}`);
  };

  const renderWorkDescription = (workDescription) => {
    if (!Array.isArray(workDescription)) {
      return (typeof workDescription === 'string' && workDescription.length > 0)
        ? workDescription
        : '-'
    }

    return workDescription.map(wd => (
      <div key={wd.parentForm}>
        <Typography variant="caption">{formatAsStartCase(wd.parentForm)}</Typography>
        <Typography>{wd.workDescription}</Typography>
      </div>
    ));
  }

  return (
    <>
      {
        loading
          ? <InlineLoader />
          : (
            <>
              <TableContainer component={Paper}>
                <Table className={classes.table} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Draft Saved</TableCell>
                      <TableCell>Work Date</TableCell>
                      <TableCell>Work Order #</TableCell>
                      <TableCell>Work Description</TableCell>
                      <TableCell />
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {draftsList && draftsList.length > 0 ? (
                      <>
                        {draftsList.map((draft) => (
                          <TableRow key={draft.PermitInstanceId}>
                            <TableCell
                              component="th"
                              scope="row"
                              className={classes.rowItem}
                              onClick={() => handleDraftClick(draft.PermitInstanceId)}
                            >
                              {moment(draft.LastUpdatedOn).format('MMMM Do YYYY - h:mm:ss a')}
                            </TableCell>
                            <TableCell>
                              {
                                draft.workDate
                                  ? moment(draft.workDate).format('YYYY-MM-DD')
                                  : '-'
                              }
                            </TableCell>
                            <TableCell>
                              {draft.workOrderNumber || '-'}
                            </TableCell>
                            <TableCell>
                              {renderWorkDescription(draft.workDescription)}
                            </TableCell>
                            <TableCell align="right">
                              <IconButton size="medium" aria-label="edit" color="primary" onClick={() => handleDraftClick(draft.PermitInstanceId)}>
                                <EditIcon />
                              </IconButton>
                              <DeleteDraftButton draftId={draft.PermitInstanceId} />
                            </TableCell>
                          </TableRow>
                        ))}
                      </>
                    ) : (
                      <TableRow>
                        <TableCell
                          component="th"
                          scope="row"
                          className={classes.rowItem}
                        >
                          <Typography>You don&apos;t have any drafts</Typography>
                        </TableCell>
                        <TableCell />
                        <TableCell />
                        <TableCell />
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              <Typography variant="subtitle2" className={classes.deletionText}>
                Drafts are deleted automatically after 7 days
              </Typography>
            </>
          )
      }
    </>
  );
};

export default PermitDrafts;