import React from 'react';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Container, Paper, makeStyles, Typography, Divider, TextField, Button } from '@material-ui/core';

import useQuery from '../../hooks/shared/useQuery';
import AuthState from '../../actions/auth';

import Logo from '../../images/nutrien-logo.png';

const useStyles = makeStyles((theme) => {
  return {
    container: {
      display: 'flex',
      flexGrow: 1,
      minHeight: '100%',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column',
      marginTop: theme.spacing(10),
    },
    divider: {
      marginBottom: theme.spacing(2),
      marginTop: theme.spacing(3),
    },
    wrapper: {
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'column',
      padding: theme.spacing(5),
      marginTop: theme.spacing(6),
    },
    input: {
      marginBottom: theme.spacing(2),
      marginTop: theme.spacing(2),
    },
    header: {
      marginBottom: theme.spacing(3),
    },
    logo: {
      // height: 50
    },
    loginLink: {
      fontSize: theme.typography.fontSize,
      marginTop: theme.spacing(2),
    },
  };
});

type UserInQuery = {
  EmailAddress: string;
  FirstName: string;
  LastName: string;
}

const Register: React.FC = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const user = useQuery<UserInQuery>();
  const [userDetailsValid, setUserDetailsValid] = React.useState<boolean>();
  const [password, setPassword] = React.useState<string>('');
  const [passwordValid, setPasswordValid] = React.useState<boolean>(false);
  const [registering, setRegistering] = React.useState<boolean>(false);

  React.useEffect(() => {
    // regex borrowed from Stack Overflow https://stackoverflow.com/a/12090265
    const passwordRegex =/^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
    const exp = new RegExp(passwordRegex);
    setPasswordValid(exp.test(password));
  }, [password]);

  React.useEffect(() => {
    if (!user || !user.EmailAddress || !user.FirstName || !user.LastName) {
      setUserDetailsValid(false);
      return;
    }

    setUserDetailsValid(true);
  }, [user]);

  const handleUserSignup = React.useCallback(async () => {
    setRegistering(true);
    await dispatch(AuthState.actions.signUpUser(user, password));
    setRegistering(false);
  }, [dispatch, user, password]);

  React.useEffect(() => {
    function handleEnterPress(event: KeyboardEvent) {
      if (event.key === 'Enter' && passwordValid) {
        handleUserSignup();
      }
    }

    window.addEventListener('keyup', handleEnterPress);
    return () => window.removeEventListener('keyup', handleEnterPress);
  }, [handleUserSignup, passwordValid]);

  return (
    <Container maxWidth="sm" className={classes.container}>
      <img src={Logo} className={classes.logo} alt="Nutrien Logo" />
      <Paper elevation={3} className={classes.wrapper}>
        <Typography variant="h5" className={classes.header}>Complete Registration</Typography>
        {!userDetailsValid ? (
          <>
            <Typography gutterBottom>
              Hi
              {' '}
              {user.FirstName}
              ,
            </Typography>
            <Typography>
              Please provide a password to complete your Nutrien E-Permitting system registration.
            </Typography>
            <Divider className={classes.divider} />
            <TextField
              label="Full Name"
              variant="outlined"
              disabled
              value={`${user.FirstName} ${user.LastName}`}
              className={classes.input}
            />
            <TextField
              disabled
              label="Email Address"
              variant="outlined"
              value={user.EmailAddress}
              className={classes.input}
            />
            <TextField
              type="password"
              label="Enter a password"
              helperText="Passwords must be at least 8 characters and include letters, uppercase, lowercase, numbers and symbols"
              variant="outlined"
              focused
              onChange={(e) => setPassword(e.target.value)}
              className={classes.input}
            />

            <Button
              onClick={handleUserSignup}
              disabled={!passwordValid || registering}
              fullWidth
              variant="contained"
              color="primary"
            >
              register
            </Button>
            <Typography align="center" className={classes.loginLink}>
              If you already have an account or you are a Nutrien employee,
              {' '}
              <Link to="/auth/signin">click here</Link>
              .
            </Typography>
          </>
        ) : (
          <Typography>
            The link you have clicked is invalid.
            {' '}
            Please close this window and click on the link in the email you received again.
          </Typography>
        )}
      </Paper>

    </Container>
  );
};

export default Register;
