import React from 'react';
import { Route, Switch } from 'react-router-dom';

import ListsList from './ListsList';
import ListItemsList from './ListItemsList';

const Lists: React.FC = () => (
  <Switch>
    <Route path="/admin/lists" exact component={ListsList} />
    <Route path="/admin/lists/:fieldKey/items" component={ListItemsList} />
  </Switch>
);

export default Lists;
