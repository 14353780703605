import * as jsonPatch from 'fast-json-patch';
import { findKey as _findKey } from 'lodash';
import { AppThunk } from '../../thunk';
import { determineExternalUserState, ExternalUser } from '../../../types/external-user';
import Api from '../../../shared/api';
import { TYPES } from './types';
import toast from '../../../shared/toast';
import store from '../../../store';

/**
 * Invite an external user to create an Epermitting account
 * @param {ExternalUser} user - search filters
 * @return {void}
 */
export const inviteUser = (user: ExternalUser): AppThunk => {
  return async (dispatch, getState) => {
    try {
      const { homeLocation } = getState().appState;
      const { items: users } = getState().admin.externalUsers;

      await Api.post('/users',
        {
          ...user,
          EmailAddress: user.EmailAddress.trim(),
          SiteId: homeLocation.SiteId,
          ActiveFlag: true,
        });

      dispatch({
        type: TYPES.setList,
        payload: [
          ...users,
          { ...user, Status: determineExternalUserState(user) },
        ],
      });
      toast.success('User invited successfully. They will receive an email with further instructions.');
    } catch (error) {
      toast.error('There was a problem inviting the user, please try again');

      if ([400, 409].includes(error.response.status)) {
        throw new Error(error.response.data ? error.response.data.message : 'Invalid user details');
      } else {
        throw error;
      }
    }
  };
};

export const getUsers = (): AppThunk => {
  return async (dispatch) => {
    try {
      const users = await Api.get<ExternalUser[]>('/users?Limit=50&Page=0');

      dispatch({
        type: TYPES.setList,
        payload: users.map((user) => ({
          ...user,
          Status: determineExternalUserState(user),
        })),
      });
    } catch (error) {
      toast.error('There was a problem loading users, please refresh and try again');
    }
  };
};

export const getUser = (PersonnelId: number): AppThunk => {
  return async (dispatch, getState) => {
    try {
      const { items: users } = getState().admin.externalUsers;
      const user = await Api.get<ExternalUser>(`/users/${PersonnelId}`);

      dispatch({
        type: TYPES.setList,
        payload: [
          ...users,
          { ...user, Status: determineExternalUserState(user) },
        ],
      });
    } catch (error) {
      toast.error('There was a problem loading the user, please refresh and try again');
    }
  };
};

export const updateUser = (PersonnelId: number, nextUserDoc: Partial<ExternalUser>): AppThunk => {
  return async (dispatch, getState) => {
    try {
      const { items: users } = getState().admin.externalUsers;

      const existingUser = users.find((user) => user.PersonnelId === PersonnelId);

      if (!existingUser) {
        throw new Error('Cannot update user that has not been fetched');
      }

      const updates = jsonPatch.compare(existingUser, { ...existingUser, ...nextUserDoc });

      await Api.patch(`/users/${PersonnelId}`, updates);

      const existingKey = _findKey(users, (u: ExternalUser) => u.PersonnelId === PersonnelId)
      const nextList = [...users];

      if (existingKey) {
        nextList[Number(existingKey)] = jsonPatch.applyPatch(existingUser, updates).newDocument;
        nextList[Number(existingKey)].Status = determineExternalUserState(nextList[Number(existingKey)]);
      }

      dispatch({
        type: TYPES.setList,
        payload: nextList,
      });

      toast.success('User updated successfully');
    } catch (error) {
      toast.error('There was a problem loading the user, please refresh and try again');
    }
  };
};

export const resendInvitation = (PersonnelId: number): AppThunk => {
  return async () => {
    try {
      await Api.post<ExternalUser[]>(`/users/${PersonnelId}/invite`);
      toast.success('Invitation resent successfully');
    } catch {
      toast.error('There was a problem sending the invitation, please try again');
    }
  };
};

export const deleteUser = (PersonnelId: number): AppThunk => {
  return async (dispatch, getState) => {
    const { items: users } = getState().admin.externalUsers;
    try {
      await Api.delete(`/users/${PersonnelId}`);

      dispatch({
        type: TYPES.setList,
        payload: users.filter((user) => user.PersonnelId !== PersonnelId),
      });

      toast.success('User deleted successfully');
    } catch {
      toast.error('There was a problem deleting the user, please try again');
    }
  };
};

export const undeleteUser = (PersonnelId: number): AppThunk => {
  return async (dispatch, getState) => {
    try {
      await Api.post(`/users/${PersonnelId}/undelete`);
      store.history.push('/admin/external-users')
      toast.success('User undeleted successfully');
    } catch {
      toast.error('There was a problem undeleting the user, please try again');
    }
  };
};
