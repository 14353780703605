import React from 'react';
import { Typography, TextField } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';

import Dialog from '../../../components/shared/Dialog';
import { ActionStatus } from '../../../actions/shared/status';
import AppState from '../../../actions/app';
import PermitsState from '../../../actions/permits';
import InlineLoader from '../../../components/shared/InlineLoader';

const RevokePermitDialog = (props) => {
  const {
    ReferenceId,
    PermitInstanceId,
    onClose,
    visible
  } = props;

  const dispatch = useDispatch();

  const revokeStatus = useSelector(AppState.selectors.status('revokePermits'));
  const [reasonText, setReasonText] = React.useState('');

  const handleRevokePermit = () => {
    const { actions } = PermitsState;
    dispatch(actions.revokePermits([PermitInstanceId], reasonText));
  }

  const revokeInProgress = revokeStatus === ActionStatus.pending;

  return (
    <Dialog
      onClose={onClose}
      visible={visible}
      title={`Revoke Permit - #${ReferenceId}`}
      actions={[{
        text: 'Revoke',
        color: 'primary',
        disabled: revokeInProgress || !reasonText,
        onClick: handleRevokePermit
      }]}
    >
      {revokeInProgress ? <InlineLoader /> : (
        <>
          <Typography gutterBottom>Enter the reason for revoking this permit</Typography>
          <TextField
            variant="outlined"
            multiline={true}
            cols={12}
            rows={2}
            style={{ width: '100%' }}
            value={reasonText}
            onChange={e => setReasonText(e.target.value)}
          />
        </>
      )}
    </Dialog>
  );
}

export default RevokePermitDialog;
