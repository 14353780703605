import { partition as _partition } from 'lodash';

export const findLocationInTree = (location, tree) => {
  const stack = [tree];
  const parentHash = {};

  while (stack.length) {
    const node = stack.pop();

    parentHash[node.LocationId] = node.ParentLocationId;

    if (Number(node.LocationId) === Number(location)) {
      let locationId = node.LocationId;
      const path = [locationId];

      while (locationId !== 0) {
        const parent = parentHash[locationId];
        path.push(parent);
        locationId = parent;
      }

      return {
        node,
        path,
      };
    }

    if (node.children) {
      stack.push(...node.children);
    }
  }

  return stack.pop() || {};
};

/**
 * Recursive function to build a tree representation of Nutrien locations
 * @param  locations - Flat array of locations
 * @return {LocationTree} - A structured tree of locations. This can be any location in the tree and it will build a
 * tree with the rootbeing the passed location. If none is passed, it will use the Nutrien root (SiteId: 0) as the root
 */
export const createLocationTree = (locations, location = {}) => {
  if (Object.keys(location).length === 0) {
    const nutrienRoot = locations.find((l) => l.LocationId === 0);
    if (!nutrienRoot) {
      throw new Error('Location tree does not include the Nutrien root location');
    }

    return createLocationTree(locations, nutrienRoot);
  }

  const [children, remainder] = _partition(locations, (l) => l.ParentLocationId === location.LocationId);

  if (Array.isArray(remainder) && remainder.length) {
    location.children = children.map((loc) => createLocationTree(remainder, loc));
  }

  return location;
};

const getChildenLocations = (loc, onlyIds, descendants = []) => {
  if (onlyIds) {
    descendants.push(...loc.children.map((c) => c.LocationId));
  } else {
    descendants.push(...loc.children);
  }

  if (loc.children.length) {
    loc.children.forEach((l) => getChildenLocations(l, onlyIds, descendants));
  }

  return descendants;
};

export const getDescendantsByLocation = (locations, location, onlyIds = false) => {
  const tree = createLocationTree(locations, location);
  return getChildenLocations(tree, onlyIds);
};
