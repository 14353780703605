import React, { useEffect, useState, useRef } from 'react';
import { get as _get } from 'lodash';
import moment from 'moment';
import { makeStyles } from '@material-ui/core/styles';
import useLocations from '../../hooks/locations/useLocations';
import dateIoMoment from '@date-io/moment';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import { Box, Button, FormControl, Grid, InputAdornment, Input } from '@material-ui/core';
import { ArrowDropDown as ArrowDropDownIcon } from '@material-ui/icons';
import LocationFilterDialog from './shared/LocationFilterDialog';
import { TermInput } from './shared/SearchTermsInput';
import usePermitAllSearch from '../../hooks/permits/usePermitAllSearch';
import RevokeAllPermitDialog from '../permits/shared/ReokeAllPermitDialog';
import PermitTable from './PermitTable';

const searchColumns = [
  { id: 'checkboxSelection', disablePadding: false, label: '', dataType: 'selection', dataKey: "" },
  { id: 'PermitInstanceReferenceId', numeric: true, dataType: 'number', disablePadding: false, label: 'Permit #', dataKey: "PermitInstanceReferenceId" },
  { id: 'workOrderNumber', numeric: true, disablePadding: false, dataType: 'number', label: 'Workorder #', dataKey: "workOrderNumber" },
  { id: 'CreatedOn', disablePadding: false, label: 'Date', dataType: 'string', dataKey: "CreatedOn" },
  { id: 'workLocation', disablePadding: false, label: 'Location', dataType: 'string', dataKey: "workLocation" },
  { id: 'permitIssuer', disablePadding: false, label: 'Permit Issuer', dataType: 'string', dataKey: "permitIssuer" },
  { id: 'companyName', disablePadding: false, label: 'Company', dataType: 'string', dataKey: "companyName" },
  { id: 'assetNumber', disablePadding: false, label: 'Asset #', dataType: 'number', dataKey: "assetNumber" },
];

const resultColumns = [
  { id: 'ResultCode', disablePadding: false, label: 'Result', dataType: 'string', dataKey: "ResultCode"},
  { id: 'ResultMessage', disablePadding: false, label: 'Reason', dataType: 'string', dataKey: "ResultMessage" },
  { id: 'PermitInstanceReferenceId', numeric: true, dataType: 'number', disablePadding: false, label: 'Permit #', dataKey: "PermitInstanceReferenceId" },
];


const useStyles = makeStyles((theme) => ({
  filtersWrapper: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    backgroundColor: 'white',
    alignItems: 'center'
  },
  input: {
    marginBottom: theme.spacing(2),
  },
  inputTerm: {
    '& .MuiTextField-root': {
      marginBottom: theme.spacing(0),
    }
  },
  filtersItemWrapper: {
    margin: theme.spacing(1),
  },
  locationInputRoot: {
    color: theme.palette.text.primary,
    opacity: 1,
    marginTop: theme.spacing(2),
  },
  locationInputInput: {
    cursor: 'pointer',
    '&::placeholder': {
      color: '#757575',
      opacity: 1,
    },
  },
  btnContainer: {
    display: 'flex',
    justifyContent: 'end',
    '& button': {
      marginLeft: theme.spacing(2)
    }
  },
  root: {
    display: "flex"
  },
  container: {
    flexGrow: 1,
    height: 500
  },
  paper: {
    height: "100%",
    display: "flex",
    flexDirection: "column"
  },
  toolbar: {
    paddingLeft: theme.spacing(8),
    paddingRight: theme.spacing(4)
  },
  title: {
    flex: "0 0 auto"
  },
  spacer: {
    flex: "1 1 100%"
  }
}));

const RevokePermits = () => {

  const classes = useStyles();
  const tableRef = useRef();
  const [showRevokeResult, setShowRevoketable] = useState(false);
  const { locations, homeLocation } = useLocations();
  const [showRevokeDialog, setShowRevokeDialog] = useState(false);
  const [selected, setSelected] = useState(false);
  const Location = locations.find((l) => l.LocationId === Number(homeLocation.LocationId));
  const [input, updateInput] = useState('');
  const [selectedLocation, updateSelectedLocation] = useState(Location);
  const [toDate, updateToDate] = useState();
  const [fromDate, updateFromDate] = useState(moment().subtract(1, 'years'));
  const [locationDialogOpen, setLocationDialogOpen] = useState();

  const onShowRevokeDialog = (list) => {
    setSelected(list);
    setShowRevokeDialog(true);
  }
  const [searchPermits, { searchData : list, loading: inProgress, loadingRevoke }] = usePermitAllSearch();
  const loading = inProgress || loadingRevoke;
  
  const onInputChage = (event) => {
    updateInput(event.target.value)
  }

  useEffect(() => {
    const Location = locations.find((l) => l.LocationId === Number(homeLocation.LocationId));
    updateSelectedLocation(Location);
  }, [locations, homeLocation]);

  const search = async () => {
    const filters = {
      fromDate,
      toDate,
      State: ['APPROVED'],
      ...(selectedLocation ? { Location: selectedLocation } : {})
    }
    searchPermits(input ? [input] : null, filters, false, 200);
    setShowRevoketable(false);
    tableRef.current.setSelectedRow([]);
  }

  const onCloseDialog = (clearSelected) => {
    if (clearSelected) {
      setSelected([]);
      tableRef.current.setSelectedRow([]);
      setShowRevoketable(true);
    }
    setShowRevokeDialog(false)
  }

  const headCells = !showRevokeResult ? searchColumns : resultColumns;
  
  return (
    <>
      <Grid item className={classes.btnContainer}>
        <Button disabled={loading} variant="contained" color="primary" onClick={search}>Search</Button>
      </Grid>
      <Grid container className={classes.filtersWrapper}>
        <Grid item xs={6} md={3}>
          <Box p={2} pt={5} className={classes.inputTerm}>
            <TermInput
              value={input}
              placeholder="Contains text"
              onChange={onInputChage}
              onKeyDown={onInputChage}
              onDeleteClick={() => updateInput('')}
              showDeleteButton
              endAdornment={true}
              helperText="Eg: UREA/CO2 AREA or SOFT-101C"
            />
          </Box>
        </Grid>
        <Grid item xs={6} md={3}>
          <Box p={2} pt={2}>
            <FormControl variant="outlined" className={classes.input} onClick={() => setLocationDialogOpen(true)}>
              <Input
                classes={{ root: classes.locationInputRoot, input: classes.locationInputInput }}
                endAdornment={(
                  <InputAdornment position="end">
                    <ArrowDropDownIcon classes={{ root: classes.locationInputInput }} />
                  </InputAdornment>
                )}
                spellCheck={false}
                placeholder="Location"
                value={selectedLocation?.Title}
              />
            </FormControl>

            {locationDialogOpen && (
              <LocationFilterDialog
                location={selectedLocation}
                onChange={(data) => updateSelectedLocation(data)}
                onClose={() => setLocationDialogOpen(false)}
              />
            )}
          </Box>
        </Grid>
        <Grid item xs={6} md={3}>
          <Box p={2} pt={2}>
            <MuiPickersUtilsProvider utils={dateIoMoment}>
              <KeyboardDatePicker
                className={classes.input}
                value={fromDate || null}
                placeholder="From"
                label="From"
                onChange={(date) => updateFromDate(date)}
                maxDate={new Date()}
                format="YYYY-MM-DD"
              />
            </MuiPickersUtilsProvider>
          </Box>
        </Grid>
        <Grid item xs={6} md={3}>
          <Box p={2} pt={2}>
            <MuiPickersUtilsProvider utils={dateIoMoment}>
              <KeyboardDatePicker
                className={classes.input}
                value={toDate || null}
                placeholder="To"
                label="To"
                onChange={(date) => updateToDate(date)}
                format="YYYY-MM-DD"
                maxDate={new Date()}
              />
            </MuiPickersUtilsProvider>
          </Box>
        </Grid>
      </Grid>
      <PermitTable showRevokeResult={showRevokeResult} ref={tableRef} loading={loading} rows={list} headCells={headCells} setShowRevokeDialog={onShowRevokeDialog} />
      {
        showRevokeDialog && <RevokeAllPermitDialog visible={showRevokeDialog} PermitInstanceIds={selected} onClose={onCloseDialog}/>
      }
    </>
  )
}

export default RevokePermits;