import { Action } from 'redux';
import { Template } from '../../../types/template';

export const TYPES = {
  setList: 'ADMIN_TEMPLATES_LIST_SET',
  updateTemplate: 'ADMIN_TEMPLATES_UPDATE_ONE',
};

export interface SetListAction extends Action {
  type: typeof TYPES.setList,
  payload: Template[];
}

export interface UpdateTemplateAction extends Action {
  type: typeof TYPES.updateTemplate,
  payload: Partial<Template>;
}

export type TemplatesActionTypes = SetListAction | UpdateTemplateAction;
