import React from 'react';
import { Button, Menu, MenuItem } from '@material-ui/core';
import { useHistory } from 'react-router-dom';

const CreateTemplateMenu: React.FC = () => {
  const history = useHistory();
  const [anchorEl, setAnchorEl] = React.useState<EventTarget & Element | null>(null);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenuClick = (event: React.MouseEvent) => {
    setAnchorEl(event.currentTarget);
  };

  return (
    <>
      <Button
        variant="contained"
        color="primary"
        onClick={handleMenuClick}
      >
        Create Template
      </Button>
      <Menu
        id="create-permit"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={() => history.push('/admin/templates/create?type=work')}>Work Template</MenuItem>
        <MenuItem onClick={() => history.push('/admin/templates/create?type=permit')}>Permit Template</MenuItem>
      </Menu>
    </>
  );
};

export default CreateTemplateMenu;
