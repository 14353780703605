import React from 'react';
import { get as _get } from 'lodash';
import moment from 'moment';

import Collections from '../../store/indexeddb';
import Api from '../../shared/api';
import useGetPermitDefinitionForLocation from './useGetPermitDefinitionForLocation';
import useWithUserPreferences from '../auth/useWithUserPreferences';

const { PermitApprovals } = Collections;

const usePermitApprovals = (permit) => {
  if (!permit) {
    throw new Error('No Permit provided');
  }


  const [loading, setLoading] = React.useState(false);
  const initialPermit = useGetPermitDefinitionForLocation();
  const { profile } = useWithUserPreferences();
  const [approvals, setApprovals] = React.useState();
  const [approvalRequired, setApprovalRequired] = React.useState(false);
  const metadata = _get(initialPermit.definition, 'instance.PermitInstanceMetadataJSON');

  React.useEffect(() => {
    // if there is no metadata or PermitInstanceId there will never be approvals
    // to fetch. As such, exit early
    if (!metadata || !permit.PermitInstanceId) {
      return;
    }

    const getApprovals = async () => {
      setLoading(true);

      let cachedApprovals;

      // load existing cached permit approvals from Indexeddb
      try {
        cachedApprovals = await PermitApprovals.get(permit.PermitInstanceId);
      } catch {
        cachedApprovals = undefined;
      }

      let nextApprovals = cachedApprovals;

      // if there are no cached approvals or the last updated time of the cached approvals
      // is before the date that the permit was changed, reload the approvals from the API
      if (!cachedApprovals || moment(permit.DateChanged).isAfter(moment.utc(cachedApprovals.meta.updated))) {
        nextApprovals = {
          approvals: await Api.get(`/permitinstancestatus?PermitInstanceId=${permit.PermitInstanceId}`),
        };
      }

      setApprovals(nextApprovals.approvals);

      const requiredApprovalGroups = nextApprovals?.approvals?.MissingApprovals?.map((ap) => ap.ApprovalGroup) || [];

      // determine if approvals are required
      requiredApprovalGroups.forEach((match) => {
        const approvalUsers = metadata.UsersOfGroupList.find((group) => group.Group === match);

        if (approvalUsers && approvalUsers.UserFormattedList.find((user) => user.value === String(profile.PersonnelId))
        ) {
          setApprovalRequired(true);
          nextApprovals.approvalRequired = true;
        }
      });

      await PermitApprovals.set(permit.PermitInstanceId, nextApprovals);
      setLoading(false);
    };

    getApprovals();
  }, [
    metadata,
    permit,
    profile.PersonnelId,
  ]);

  return { approvalRequired, approvals, loading };
};
export default usePermitApprovals;
