import { findKey as _findKey } from 'lodash';
import jsonPointer from 'json-pointer';

import { Template, TemplateTypes } from '../types/template';

export const isValidTemplateType = (type: string) => TemplateTypes.includes(type);

export const createFormDataFromTemplate = (form: any, data: any) => {
  const formData = {};

  jsonPointer.set(formData, `${form.ContentsPathPointer}`, data);
  jsonPointer.set(formData, form.SubformEnabledContentsPathPointer, true);

  return formData;
};

export const updateTemplateInList = (list: Template[], updates: Partial<Template>): Template[] => {
  const existing = _findKey(list, (template: Template) => template.PermitTemplateId === updates.PermitTemplateId);

  if (!existing) {
    return list;
  }

  const nextList = [...list];
  const { PermitTemplateId, ...restTemplate } = updates;

  nextList[Number(existing)] = {
    ...nextList[Number(existing)],
    ...restTemplate,
  };

  return nextList;
}
