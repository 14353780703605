import React from 'react';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';

import api from '../../../../shared/api';
import { ExternalUser } from '../../../../types/external-user';
import { CircularProgress } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  input: {
    minWidth: 400,
    '& input': {
      padding: theme.spacing(1)
    }
  },
  wrapper: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    marginTop: theme.spacing(2),
    '& > *': {
      marginRight: theme.spacing(1)
    }
  }
}));

type Props = {
  inputValue: string;
  onChange: (user: ExternalUser | null) => void;
  onInputValueChange: (value: string) => void;
}

const EmailTypeAheadField = (props: Props) => {
  const { onChange, inputValue, onInputValueChange } = props;

  const classes = useStyles();

  const [value, setValue] = React.useState<ExternalUser | null>(null);
  const [isFocused, setIsFocused] = React.useState<boolean>(false);
  const [loading, setLoading] = React.useState<boolean>(false);

  React.useEffect(() => {
    onChange(value);
  }, [onChange, value]);


  React.useEffect(() => {
    if (inputValue === '') {
      setValue(null);
    }
  }, [inputValue])

  React.useEffect(() => {
    if (inputValue === '' || isFocused) {
      return undefined;
    }

    const searchUser = async () => {
      try {
        setLoading(true)
        const user = await api.get<ExternalUser>(`/users/${inputValue}`);
        setValue(user || null);
      } catch {
        setValue(null);
      } finally {
        setLoading(false);
      }
    }

    searchUser();
  }, [isFocused, inputValue]);

  return (
    <div className={classes.wrapper}>
      <TextField
        variant="outlined"
        classes={{
          root: classes.input
        }}
        value={inputValue}
        onChange={(event) => onInputValueChange(event.target.value)}
        onBlur={() => {
          onInputValueChange(inputValue.trim());
          setIsFocused(false)
        }}
        onFocus={() => setIsFocused(true)}
      />
      {loading && <CircularProgress size={22} />}
    </div>
  );
}

export default EmailTypeAheadField;
