import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import ExternalUsersState from '../../actions/admin/external-users';
import { ExternalUser } from '../../types/external-user';

type UseExternalUserResponse = {
  loading: boolean;
  data: ExternalUser | undefined;
}

const { actions, selectors } = ExternalUsersState;

const useExternalUser = (personnelId: number): UseExternalUserResponse => {
  const dispatch = useDispatch();

  const [loading, setLoading] = React.useState(false);
  const [usersList, setUsersList] = React.useState<ExternalUser>();

  const usersFromState = useSelector(selectors.list);

  React.useEffect(() => {
    setLoading(true);
    const existingUser = usersFromState.find((user) => user.PersonnelId === personnelId);
    setUsersList(existingUser);
    setLoading(false);

    // PersonnelId change handle in hook below
    // eslint-disable-next-line  react-hooks/exhaustive-deps
  }, [usersFromState]);

  React.useEffect(() => {
    const getUser = async () => {
      setLoading(true);
      await dispatch(actions.getUser(personnelId));
      setLoading(false);
    };

    const existingUser = usersFromState.find((user) => user.PersonnelId === personnelId);
    if (!existingUser) {
      getUser();
    }
  }, [dispatch, personnelId, usersFromState]);

  return { loading, data: usersList };
};

export default useExternalUser;
