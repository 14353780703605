export const setPageScroll = () => {

    document.addEventListener('DOMContentLoaded', () => {

        document.body.addEventListener('focus', (event) => {

            if (!['INPUT', 'BUTTON', 'A', 'TEXTAREA', 'SPAN', 'DIV'].includes(event.target.nodeName.toUpperCase())) return;

            const footerHeight = 100; // footerHeight = footer height + element height + buffer
            const headerHeight = 100; // //headerHeight = header height + element height + buffer
            const element = event.target.getBoundingClientRect();

            if (document.documentElement.clientHeight - element.top < footerHeight) {
                window.scrollBy(0, 300)
            } else if (element.top < headerHeight) {
                window.scrollBy(0, -headerHeight)
            }
            
        }, true)
    
    })
}