import { bool, shape, arrayOf, number, string, oneOfType, array } from 'prop-types';

// eslint-disable-next-line import/prefer-default-export
export const ESPermit = shape({
  CreatedBy: number.isRequired,
  CreatedOn: string.isRequired,
  DateChanged: string.isRequired,
  Latitude: number,
  LocationId: number.isRequired,
  Longitude: number,
  PermitDefinitionId: string.isRequired,
  PermitInstanceId: string.isRequired,
  PermitInstanceReferenceId: string.isRequired,
  SiteId: number.isRequired,
  State: string.isRequired,
  assetDescription: string,
  assetNumber: string,
  companyName: string,
  permitTypes: arrayOf(shape({
    Name: string.isRequired,
    Label: string.isRequired,
  })),
  workDate: string,
  workDescription: oneOfType([array, string]),
  workLocation: string,
  workOrderNumber: oneOfType([number, string]),
});

export const PermitStatesPropType = shape({
  color: string.isRequired,
  label: string.isRequired,
  openStatus: bool,
});
