import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Apps as AppsIcon } from '@material-ui/icons';
import { Box, Button } from '@material-ui/core';

import Portal from './Portal';
import PermitsConfig from '../../config/permits';

const useStyles = makeStyles(theme => ({
  wrapper: {
    position: 'absolute',
    height: 30,
    width: 30,
    top: 10,
    right: 10,
    zIndex: 400,
  },
  toggleButton: {
    width: 26,
    height: 26,
    minWidth: 'unset',
    background: 'white',
  },
  popover: {
    position: 'absolute',
    padding: theme.spacing(2),
    width: 240,
    background: 'white',
    zIndex: 400,
  },
  swatch: {
    display: 'inline-block',
    marginRight: theme.spacing(2),
    height: '1rem',
    width: '1rem'
  }
}));

const MenuPopover = (props) => {
  const classes = useStyles();
  const openStates = Object.keys(PermitsConfig).filter(st => PermitsConfig[st].openStatus);
  return (
    <Box boxShadow={3} className={classes.popover} style={props.coords}>
      {openStates.map(state => (
        <div key={state}>
          <span className={classes.swatch} style={{ backgroundColor: PermitsConfig[state].color }}></span>
          {state}
      </div>
      ))}
    </Box>
  )
}

const PermitMapLegend = (props) => {
  const [coords, setCoords] = React.useState({});
  const [isOpen, setOpen] = React.useState(false);
  const classes = useStyles();
  return (
    <div className={classes.wrapper}>
      <Button
        variant="contained"
        size="small"
        className={classes.toggleButton}
        onClick={e => {
          const rect = e.target.getBoundingClientRect();
          setCoords({
            left: (rect.x + rect.width / 2) - 260,
            top: rect.y + window.scrollY
          });
          setOpen(!isOpen);
        }}
      ><AppsIcon /></Button>
      {isOpen &&
        <Portal>
          <MenuPopover coords={coords} />
        </Portal>
      }
    </div>
  );
}

export default PermitMapLegend;
