/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import { Redirect, Route } from 'react-router-dom';
import { useSelector } from 'react-redux';

import AppState from '../../actions/app';
import useAbilities from '../../hooks/auth/useAbilities';

const AuthenticatedRoute = ({ component: Component, ...rest }) => {
  const abilities = useAbilities();
  const locationDisabled = useSelector(AppState.selectors.locationDisabled());

  if (locationDisabled) {
    return <Redirect to="/location-not-enabled" />;
  }

  const { location } = rest;

  if (location.pathname.startsWith('/permits') && !abilities.permits.view) {
    return <Redirect to="/unauthorized" />;
  }

  if (location.pathname === '/permits/create' && !abilities.permits.edit) {
    return <Redirect to="/unauthorized" />;
  }

  if (location.pathname === '/forms' && !abilities.forms.view) {
    return <Redirect to="/unauthorized" />;
  }

  return (
    <Route
      {...rest}
      render={(props) => <Component {...props} />}
    />
  );
};

AuthenticatedRoute.propTypes = {
  component: PropTypes.func.isRequired,
};

export default AuthenticatedRoute;
