import React from 'react';
import { useParams } from 'react-router-dom';

import useForm from '../../hooks/forms/useForm';
import useGetPermitDefinitionForLocation from '../../hooks/permits/useGetPermitDefinitionForLocation';
import useWithFormCustomizations from '../../hooks/permits/useWithFormCustomizations';
import useAnalytics from '../../hooks/shared/useAnalytics';

import toast from '../../shared/toast';

import {
  populatedOperationsSelect,
  updateOperationsFields,
  populateWorkOrderFieldsOnFormAttach,
  updateFieldsOnWorkOrderChange,
  updateSelectedWorkOrderInForm,
  updateLinkedPermitFields,
  flagFields,
  disabledFields,
  applyDisableEffectToPermit,
  updateFormFieldToSubForm,
  setInitValueInForm,
  getErrorList,
  hideComponent
} from './effects';

import useDraft from '../../hooks/permits/useDraft';

import { expandErrorPanels } from '../../shared/form-io';
import { makeStyles } from '@material-ui/core';
import { Location } from '../../types/location';
import { WorkOrder } from '../../types/work-order';
import { FormioChangeEvent, FormioForm } from '../../types/form-io';
import ErrorStickyBar from './ErrorStickyBar';

const useStyles = makeStyles(() => ({
  wrapper: {
    minWidth: '100%',
    maxWidth: '100%'
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%'
  },
}));

type Props = {
  location: Location;
  handleSubmit: () => any;
  onChange: (formData: any) => void;
  workOrder?: WorkOrder;
  ref?: any;
}

const CreatePermitForm: React.FC<Props> = React.forwardRef((props, ref) => {
  const { location, onChange, workOrder, handleSubmit } = props;
  const [errors, setErrors] = React.useState<any>([]);
  const [total, setTotalError] = React.useState<number>(0);
  const [showErrors, updateShowErrors] = React.useState<boolean>(false);
  const [showSidebarBtn, updateSidebarBtn] = React.useState<boolean>(false);
  const { draftId } = useParams<{ draftId: string }>();
  const { content } = useDraft(draftId);
  const classes = useStyles();
  const recordEvent = useAnalytics();

  const {
    loading: definitionLoading,
    definition,
  } = useGetPermitDefinitionForLocation();

  const customizations = React.useMemo(() => [
    flagFields('DRAFT'),
    // default to has ability as if a user can get to this screen
    // they already have the edit permit ability. As such, set to true
    
    disabledFields('DRAFT', true,location, false),
  ], [])

  const components = useWithFormCustomizations(
    (definition.permitFormDefinition || {}).components,
    customizations
  );

  const handleFormChange = React.useMemo(() => async (event: FormioChangeEvent, form: FormioForm) => {
    hideComponent(form,event);
    updateFieldsOnWorkOrderChange(form, event, definition.erpFields);
    populateWorkOrderFieldsOnFormAttach(form, event, definition.erpFields, workOrder);
    populatedOperationsSelect(form, event, definition.erpFields, workOrder);
    updateOperationsFields(form, event, definition.erpFields, workOrder);
    await updateLinkedPermitFields(form, event, location.SiteId);
    await updateFormFieldToSubForm(form, event)
    onChange(form.submission);
    if (showErrors) {
      const { total,  result } = getErrorList(form);
      setErrors(result);
      setTotalError(total);
    }
  }, [definition.erpFields, location.SiteId, workOrder, onChange, showErrors]);

  React.useEffect(() => {
    setErrors([]);
    setTotalError(0);
  }, [location.SiteId])

  const { form, ready } = useForm(
    'form',
    components,
    content || {},
    handleFormChange,
    handleSubmit,
    'DRAFT',
    draftId,
  );

  const handleFormSubmit = async () => {
    var list:any = Array.from(document.querySelectorAll("input[type=checkbox]"));
    if(list.filter((x: { checked: any; }) => x.checked).length > 0)
    {
      try {
        setErrors([]);
        setTotalError(0);
        updateShowErrors(false);
        const promiseObj = await form.submit();
        return { success: true, data: promiseObj }
      } catch (error: any) {
        if (Array.isArray(error)) {
          expandErrorPanels(form)
          const errors = getErrorList(form) as any;
          setErrors(errors.result);
          setTotalError(errors.total);
          updateShowErrors(true);
          toast.error(`Please check the form and correct all (${errors.total}) errors before submitting.`)
        }
        recordEvent(`Create Permit form submit error ${JSON.stringify(error)}`)
        return { success: false, data: error }
      }
    }
    else
    {
      toast.error('No permits have been selected to be created. Please select one or more permits to create');
      return { success: false}
    }

    
  }

  React.useImperativeHandle(ref, () => {
    return {
      submitForm: handleFormSubmit
    }
  }, [ready, form]);
  
  // Form onLoad disbale attached permit and set default
  React.useEffect(() => {
    if (form) {
      setInitValueInForm(form)
    }
    if(form && content?.data){
      applyDisableEffectToPermit(form, content.data)
    }
  }, [ready, content, form])

  // when the selected work order changes, update the work order related fields
  // in the form. By doing it in an effect, we can use the form ref from useForm() and
  // update the fields rather than having to completely rebuild the component tree and
  // rerender the form
  React.useEffect(() => {
    if (form) {
      updateSelectedWorkOrderInForm(form, workOrder);
    }
  }, [form, workOrder]);

  const isLoading = definitionLoading || !ready;

  return (
    <>
      <div className={classes.container}>
        <div
          className={classes.wrapper}
          id="form"
          style={{ display: isLoading ? 'none' : 'block' }}
        />
      </div>
      <ErrorStickyBar
        showErrors={showErrors}
        form={form}
        total={total}
        errors={errors}
        showSidebarBtn={showSidebarBtn}
        updateSidebarBtn={updateSidebarBtn}
      />
    </>
  );
});

export default CreatePermitForm;