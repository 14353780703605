import { Button, makeStyles, TextField } from '@material-ui/core';
import React from 'react';
import { Link } from 'react-router-dom';

type Props = {
  onSubmit: (emailAddress: string, password: string) => void;
}

const useStyles = makeStyles((theme) => {
  return {
    wrapper: {
      display: 'flex',
      flexDirection: 'column',
    },
    input: {
      marginBottom: theme.spacing(2),
    },
    forgotpassword: {
      paddingBottom: theme.spacing(2),
      alignItems: 'left'
    }
  };
});

const LoginForm: React.FC<Props> = (props) => {
  const { onSubmit } = props;
  const classes = useStyles();
  const [emailAddress, setEmailAddress] = React.useState<string>('');
  const [password, setPassword] = React.useState<string>('');
  const [submitting, setSubmitting] = React.useState<boolean>(false);

  const formDisabled = submitting || !emailAddress || !password;

  const handleSubmit = React.useCallback(async () => {
    try {
      setSubmitting(true);
      await onSubmit(emailAddress, password);
    } catch {
      setSubmitting(false);
    }
  }, [emailAddress, password, onSubmit]);

  React.useEffect(() => {
    function handleEnterPress(event: KeyboardEvent) {
      if (event.key === 'Enter' && !formDisabled) {
        handleSubmit();
      }
    }

    window.addEventListener('keyup', handleEnterPress);
    return () => window.removeEventListener('keyup', handleEnterPress);
  }, [formDisabled, handleSubmit]);

  return (
    <div className={classes.wrapper}>
      <TextField
        disabled={submitting}
        required
        label="Email Address"
        type="email"
        variant="outlined"
        value={emailAddress}
        onChange={(event) => setEmailAddress(event.target.value)}
        className={classes.input}
      />
      <TextField
        disabled={submitting}
        required
        type="password"
        value={password}
        label="Password"
        variant="outlined"
        onChange={(event) => setPassword(event.target.value)}
        className={classes.input}
      />
      <div className={classes.forgotpassword}>
        <Link to="/auth/forgotpassword">Forgot password?</Link>
      </div>
      <Button
        variant="contained"
        fullWidth={false}
        disabled={formDisabled}
        onClick={handleSubmit}
      >
        Sign in
      </Button>
    </div>
  );
};

export default LoginForm;
