import { shallowEqual, useSelector } from 'react-redux';

import useThrottledDataLoad from '../shared/useThrottledDataLoad';
import { filterWorkOrdersByStatus } from '../../shared/work-orders';

import AppState from '../../actions/app';
import WorkOrdersState from '../../actions/work-orders';
import { ActionStatus } from '../../actions/shared/status';

const useGetWorkOrders = (useActiveFilter = false) => {
  const { selectors, types } = WorkOrdersState;

  const activeFilters = useSelector(WorkOrdersState.selectors.activeStatuses());
  const homeLocation = useSelector(AppState.selectors.homeLocation(), shallowEqual);

  const { loading, data } = useThrottledDataLoad(
    types.get,
    () => WorkOrdersState.actions.get(homeLocation.SiteId),
    selectors.list,
  );

  return {
    loading: loading === ActionStatus.pending,
    list: useActiveFilter
      ? filterWorkOrdersByStatus(data, activeFilters)
      : data,
  };
};

export default useGetWorkOrders;
