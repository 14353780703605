import { number, shape, string } from 'prop-types';

export const FormIoForm = shape({
  _id: string.isRequired,
  title: string.isRequired,
  _vid: number.isRequired,
  modified: string.isRequired,
});
export const FormIoResource = shape({
  _id: string.isRequired,
  title: string.isRequired,
  _vid: number.isRequired,
  modified: string.isRequired,
});
