import React from 'react';
import useAnalytics from '../shared/useAnalytics';

/**
 * Loop through all branches in the component tree and execute an array
 * of effects on the end leaf components
 *
 * @param {Component[]} originalComponents - The component definition from the PermitDeifnition
 * @param {Component => Component[]} effects - Set of effect functions to execute against each component
 * @return {Component[]} - Effected component
 */
// eslint-disable-next-line arrow-body-style
const useWithFormCustomizations = (originalComponents, effects) => {
  const recordEvent = useAnalytics();

  return React.useMemo(() => {
    if (!originalComponents || !effects) return;

    recordEvent(`Mutating the form components with customizations [${effects?.length}`);

    const applyEffectsOnComponents = (components) => {
      if (!Array.isArray(components)) return;
      const effectComponents = components;
      // eslint-disable-next-line array-callback-return
      effectComponents.map((component) => {
        effects.reduce((previous, effect) => effect(previous), component);
        if (Array.isArray(component.components)) {
          applyEffectsOnComponents(component.components);
        } else if (Array.isArray(component.columns)) {
          applyEffectsOnComponents(component.columns);
        } else if (Array.isArray(component.rows)) {
          Object.keys(component.rows).forEach((k) => {
            applyEffectsOnComponents(component.rows[k]);
          });
        }
      });
      // eslint-disable-next-line consistent-return
      return effectComponents;
    };

    // Array.from() used here to ensure that the returned components are
    // a new array and not a reference to the original components
    return applyEffectsOnComponents(Array.from(originalComponents));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [originalComponents, effects]);
};

export default useWithFormCustomizations;
