import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import dateIoMoment from '@date-io/moment';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import { Button, Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import WorkOrdersState from '../../../actions/work-orders';
import FilterDropDownMultiSelect from '../../permits/shared/MultiSelectInput';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    padding: theme.spacing(1),
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  input: {
    marginBottom: theme.spacing(2),
  },
}));

const SearchPermitsFilters = (props) => {
  const classes = useStyles();
  const statuses = useSelector(WorkOrdersState.selectors.statuses());

  const {
    disabled,
    filters,
    handleUpdate,
    onChange,
    onClearClick,
  } = props;

  const handleFilterUpdate = (filter, value) => {
    onChange({ ...filters, [filter]: value });
  };

  return (
    <Paper elevation={0} className={classes.wrapper}>
      <MuiPickersUtilsProvider utils={dateIoMoment}>
        <KeyboardDatePicker
          className={classes.input}
          value={filters.fromDate || null}
          placeholder="From"
          label="From"
          onChange={(date) => handleFilterUpdate('fromDate', date)}
          maxDate={new Date()}
          format="YYYY-MM-DD"
        />
        <KeyboardDatePicker
          className={classes.input}
          value={filters.toDate || null}
          placeholder="To"
          label="To"
          onChange={(date) => handleFilterUpdate('toDate', date)}
          format="YYYY-MM-DD"
        />
        <FilterDropDownMultiSelect
          disabled={disabled}
          className={classes.input}
          label="Status"
          defaultValue={filters.status || []}
          onSelect={(status) => handleFilterUpdate('status', status)}
          options={statuses.map((status) => ({ value: status }))}
        />
        <Button
          variant="contained"
          color="primary"
          className={classes.updateButton}
          onClick={handleUpdate}
          disabled={disabled}
        >
          Search
        </Button>

        <Button
          color="primary"
          onClick={onClearClick}
          disabled={disabled}
        >
          Clear Filters
        </Button>
      </MuiPickersUtilsProvider>
    </Paper>
  );
};

SearchPermitsFilters.defaultProps = {
  disabled: false,
  filters: {},
};

SearchPermitsFilters.propTypes = {
  disabled: PropTypes.bool,
  handleUpdate: PropTypes.func.isRequired,
  filters: PropTypes.shape({
    // expects a moment.Moment or Date
    fromDate: PropTypes.object,
    // expects a moment.Moment or Date
    toDate: PropTypes.object,
    status: PropTypes.arrayOf(PropTypes.string),
  }),
  onChange: PropTypes.func.isRequired,
  onClearClick: PropTypes.func.isRequired,
};

export default SearchPermitsFilters;
