import React from 'react';
import Grid from '@material-ui/core/Grid';
import LandingTile from './LandingTile';
import ActiveWorkLocationsTile from './ActiveWorkLocationsTile';

import useSetPageName from '../../hooks/shared/useSetPageName';
import useGetWorkOrders from '../../hooks/work-orders/useGetWorkOrders';
import useGetPermits from '../../hooks/permits/useGetPermits';

import useLocations from '../../hooks/locations/useLocations';

export default function Home() {
  useSetPageName('E-Permitting - Dashboard');
  const locations = useLocations();

  const { loading: woLoading, list: workOrders } = useGetWorkOrders(true);
  const { loading: permitLoading, list: permits } = useGetPermits(true);

  const permitCounts = React.useMemo(
    () => {
      if (!permits) return {};

      return permits.reduce((counts, permit) => {
        const nextCounts = counts;
        if (permit.State === 'PENDING') {
          nextCounts.pending += 1;
        }
        if (!['COMPLETED', 'REVOKED', 'REJECTED'].includes(permit.State)) {
          nextCounts.open += 1;
        }
        return nextCounts;
      }, { open: 0, pending: 0 });
    },
    [permits],
  );

  return (
    <Grid container spacing={4}>
      <Grid item sm={4} xs={12}>
        <LandingTile
          loading={permitLoading}
          title="Open Permits"
          count={permitCounts.open}
          path="/permits/list?state=PRE-APPROVAL,PENDING,APPROVED"
        />
      </Grid>
      <Grid item sm={4} xs={12}>
        <LandingTile
          loading={permitLoading}
          title="Permits Needing Approval"
          count={permitCounts.pending}
          path="/permits/list?state=PENDING"
        />
      </Grid>
      <Grid item sm={4} xs={12}>
        <LandingTile
          loading={woLoading}
          title="Open Workorders"
          count={workOrders && workOrders.length}
          path="/workorders/list"
        />
      </Grid>
      {locations.plotPlanEnabled && (
        <Grid item xs={12}>
          <ActiveWorkLocationsTile
            loading={permitLoading}
            permits={permits}
          />
        </Grid>
      )}
    </Grid>
  );
}
