import { Action } from 'redux';
import { ExternalUser } from '../../../types/external-user';

export const TYPES = {
  setList: 'ADMIN_EXTERNAL_USERS_LIST_SET',
};

export interface SetListAction extends Action {
  type: typeof TYPES.setList,
  payload: ExternalUser[];
}

export type ExternalUsersActions = SetListAction;
