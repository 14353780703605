import { push } from 'connected-react-router';
import { compare } from 'fast-json-patch';
import { ElasticSearchResponse } from '../../../types/elasticsearch';
import Api from '../../../shared/api';
import toast from '../../../shared/toast';
import { RootState } from '../../../store/RootState';

import {
  TemplateType,
  Template,
  TemplateStateAction,
  TemplateComment,
} from '../../../types/template';

import { AppThunk } from '../../thunk';
import { TYPES } from './types';

/**
 * Get template by Id
 * @param {string} PermitTemplateId
 * @return {Template} - template
 */
export const getTemplate = (PermitTemplateId: string): AppThunk => {
  return async (dispatch, getState) => {
    try {
      const query = {
        size: 1,
        query: {
          term: { 'PermitTemplateId.keyword': { value: PermitTemplateId } },
        },
      };

      const result: ElasticSearchResponse<Template> = await Api.post('/templates/search', query);

      if (!result || !result.hits) {
        throw new Error('No template found');
      }

      const templates = result.hits.hits.map((template) => ({
        // eslint-disable-next-line no-underscore-dangle
        ...template._source,
        // eslint-disable-next-line no-underscore-dangle
        ContentJSON: JSON.parse(template._source.ContentJSON),
      }));

      if (templates[0]) {
        const existingList = getState().admin.templates.list;

        dispatch({
          type: TYPES.setList,
          payload: [...existingList, templates[0]],
        });
      }
    } catch {
      toast.error('There was a problem fetching templates, please try again.');
    }
  };
};

/**
 * Fetch templates
 * @param {number} LocationId
 * @return {Template} - array of ES permit results
 */
export const getTemplates = (
  LocationId: number,
  Type: TemplateType,
): AppThunk => {
  return async (dispatch) => {
    try {
      const query = {
        size: 1000,
        query: {
          bool: {
            must: [
              { term: { LocationId } },
              { term: { Type } },
            ],
          },
        },
        sort: {
          'Name.keyword': 'asc',
        },
      };

      const result: ElasticSearchResponse<Template> = await Api.post('/templates/search', query);

      const templates = result.hits.hits.map((template) => ({
        // eslint-disable-next-line no-underscore-dangle
        ...template._source,
        // eslint-disable-next-line no-underscore-dangle
        ContentJSON: JSON.parse(template._source.ContentJSON),
      }));

      dispatch({
        type: TYPES.setList,
        payload: templates,
      });
    } catch {
      toast.error('There was a problem fetching templates, please try again.');
    }
  };
};
/**
 * Create a permit template
 * @param {number} LocationId
 * @return {Template} - array of ES permit results
 */
export const createPermitTemplate = (template: Partial<Template>): AppThunk => {
  return async (dispatch, getState) => {
    try {
      const { homeLocation } = getState().appState;

      const nextTemplate = {
        ...template,
        ContentJSON: JSON.stringify(template.ContentJSON),
        Type: 'permit',
        SiteId: homeLocation.SiteId,
        LocationId: homeLocation.LocationId,
      };

      await Api.post('/templates', nextTemplate);
      toast.success('Template created successfully');
    } catch {
      toast.error('There was a problem creating the template, please try again.');
    }
  };
};

/**
 * update a  template
 * @param {string} PermitTemplateId
 * @return {Partial<Template>} - updates
 */
export const updateTemplate = (PermitTemplateId: string, updates: Partial<Template>): AppThunk => {
  return async (dispatch, getState) => {
    try {
      const { list } = getState().admin.templates;

      const previous = list.find((template) => template.PermitTemplateId === PermitTemplateId);

      if (!previous) {
        throw new Error('cannot update template that has not already been fetched');
      }

      const patchUpdates = compare(
        {
          ContentJSON: previous.ContentJSON,
          FormKey: previous.FormKey,
          Name: previous.Name,
        },
        updates,
      );

      await Api.patch(`/templates/${PermitTemplateId}`, patchUpdates);
      toast.success('Template updated successfully');
    } catch {
      toast.error('There was a problem updating the template, please try again.');
    }
  };
};

/**
 * Delete a template
 * @param {string} PermitTemplateId
 * @return void
 */
export const deleteTemplate = (PermitTemplateId: string): AppThunk => {
  return async (dispatch, getState) => {
    try {
      const { list } = getState().admin.templates;

      await Api.delete(`/templates/${PermitTemplateId}`);

      dispatch({
        type: TYPES.setList,
        payload: list.filter((template) => template.PermitTemplateId !== PermitTemplateId),
      });

      toast.success('Template deleted successfully');
    } catch {
      toast.error('There was a problem deleting the template, please try again.');
    }
  };
};

/**
 * Copy a template
 * @param {string} PermitTemplateId - Id of template to copy
 * @return void
 */
export const copyTemplate = (PermitTemplateId: string): AppThunk => {
  return async (dispatch, getState) => {
    try {
      const copiedTemplate = await Api.post<void, Template>(`/templates/${PermitTemplateId}/copy`);
      const { list } = getState().admin.templates;

      copiedTemplate.ContentJSON = JSON.parse(copiedTemplate.ContentJSON);

      dispatch({
        type: TYPES.setList,
        payload: [...list, copiedTemplate],
      });

      dispatch(push(`/admin/templates/${copiedTemplate.PermitTemplateId}/edit`));

      toast.success('Template copied successfully');
    } catch {
      toast.error('There was a problem copying the template, please try again.');
    }
  };
};

/**
 * Add a comment to a template
 * @param {string} PermitTemplateId - Id of template to add comment to
 * @param {string} Comment - The comment to add to the template
 * @return void
 */
export const addComment = (PermitTemplateId: string, Comment: string): AppThunk => {
  return async (dispatch, getState) => {
    try {
      const { list } = getState().admin.templates;
      const { profile } = getState().auth;
      const template = list.find((t) => t.PermitTemplateId === PermitTemplateId);

      if (!template) {
        throw new Error('Cannot add comment to template that isn\'t fetched');
      }

      await Api.post(`/templates/${PermitTemplateId}/comment`, { Comment });

      const templateComment: TemplateComment = {
        Comment,
        CommentType: 'General',
        CreatedBy: {
          PersonnelId: profile.PersonnelId,
          FirstName: profile.givenName,
          LastName: profile.surname,
          EmailAddress: profile.email,
        },
        CreatedOn: new Date().toISOString(),
      };

      dispatch({
        type: TYPES.updateTemplate,
        payload: {
          PermitTemplateId: template?.PermitTemplateId,
          Comments: [templateComment, ...(template.Comments || [])],
        },
      });

      toast.success('Comment added successfully');
    } catch {
      toast.error('There was a problem adding the comment, please try again.');
    }
  };
};

/**
 * Change Permit State
 * @param {number} PermitTemplateId - id template to perform action on
 * @param {TemplateStateAction} - the action being performed (submit/reject etc)
 * @param {string} - comment to add
 * @return
 */
export const changeState = (PermitTemplateId: string, action: TemplateStateAction, Comment?: string): AppThunk => {
  return async (dispatch, getState) => {
    try {
      const { profile } = getState().auth;
      const { list } = getState().admin.templates;

      const body: { Comment?: string } = {};

      if (Comment) {
        body.Comment = Comment;
      }

      const endpoints = {
        [TemplateStateAction.Approve]: 'approve',
        [TemplateStateAction.Discontinue]: 'discontinue',
        [TemplateStateAction.Reject]: 'reject',
        [TemplateStateAction.Review]: 'review',
      };

      await Api.post(
        `/templates/${PermitTemplateId}/${endpoints[action]}`,
        body,
      );

      const payload: Partial<Template> = {};

      switch (action) {
        case TemplateStateAction.Reject:
          payload.State = 'DRAFT';
          break;
        case TemplateStateAction.Review:
          payload.State = 'REVIEW';
          break;
        case TemplateStateAction.Approve:
          payload.State = 'APPROVED';
          payload.ApprovedOn = new Date().toISOString();

          payload.ApprovedBy = {
            PersonnelId: profile.PersonnelId,
            FirstName: profile.givenName,
            LastName: profile.surname,
            EmailAddress: profile.email,
          };

          break;
        case TemplateStateAction.Discontinue:
          payload.State = 'DISCONTINUED';
          break;
        default:
          throw new Error('Invalid template state action');
      }

      if (Comment) {
        const templateComment: TemplateComment = {
          Comment,
          CommentType: action,
          CreatedBy: {
            PersonnelId: profile.PersonnelId,
            FirstName: profile.givenName,
            LastName: profile.surname,
            EmailAddress: profile.email,
          },
          CreatedOn: new Date().toISOString(),
        };

        const template = list.find((t) => t.PermitTemplateId === PermitTemplateId);

        if (!template) {
          throw new Error('Cannot add comment to template that isn\'t fetched');
        }

        payload.Comments = [templateComment, ...template.Comments || []];
      }

      dispatch({
        type: TYPES.updateTemplate,
        payload: {
          ...payload,
          PermitTemplateId,
        },
      });

      toast.success('Template state updated successfully');
    } catch {
      toast.error('There was a problem updating the template state, please try again.');
    }
  };
};

export default {
  types: TYPES,
  actions: {
    addComment,
    copyTemplate,
    createPermitTemplate,
    deleteTemplate,
    getTemplates,
    getTemplate,
    changeState,
    updateTemplate,
  },
  selectors: {
    list: (state: RootState): Template[] => state.admin.templates.list,
  },
};
