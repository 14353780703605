import { get as _get, findKey as _findKey } from 'lodash';
import moment from 'moment';

import toast from '../shared/toast';
import Api from '../shared/api';
import { pending, resolve, reject } from './shared/status';

const TYPES = {
  filter: 'WORKORDER_FILTER',
  select: 'WORKORDER_SELECT',
  setList: 'WORKORDERS_LIST_SET',
  setStatuses: 'WORKORDER_STATUSES_SET',
  clearList: 'WORKORDERS_LIST_CLEAR',
  setEnabled: 'WORKORDERS_ENABLED_SET'
};

export const getWorkOrders = (LocationId, replaceExisting = false) => async (dispatch, getState) => {
  try {
    dispatch(pending(TYPES.get));

    let locationToSearch = LocationId;

    if (!locationToSearch) {
      const { homeLocation } = getState().appState;

      if (!homeLocation || !homeLocation.SiteId) {
        throw new Error('Invalid home location');
      }

      locationToSearch = homeLocation.SiteId;
    }


    const fetchWorkOrders = async (siteId, skip, limit) => Api.post('/workorders/search', {
      from: skip,
      size: limit,
      query: {
        bool: {
          filter: [
            { term: { siteId } },
            {
              range: {
                scheduledStart: {
                  gte: moment().subtract(20, 'days').format('YYYY-MM-DD'),
                  lte: moment().add(7, 'days').format('YYYY-MM-DD'),
                },
              },
            },
          ],
        },
      },
    });

    let hasMore = true;
    let skip = 0;

    const workOrders = [];

    while (hasMore) {
      // eslint-disable-next-line no-await-in-loop
      const result = await fetchWorkOrders(locationToSearch, skip, 1000);

      if (result.hits) {
        // eslint-disable-next-line no-underscore-dangle
        result.hits.hits.map((wo) => workOrders.push(wo._source));
        skip = workOrders.length;
      }

      if (Number(result.hits.total.value) === workOrders.length) {
        hasMore = false;
      }
    }

    dispatch({
      type: TYPES.setList,
      payload: workOrders,
      replace: replaceExisting,
    });

    dispatch(resolve(TYPES.get));
  } catch (error) {
    dispatch(reject(TYPES.get, error));
  }
};

const updateList = (workOrders) => (dispatch, getState) => {
  const { list } = getState().workOrders;
  const newList = list;

  workOrders.forEach((workOrder) => {
    const existing = _findKey(newList, workOrder.workOrderNumber);
    if (existing) {
      newList[existing] = workOrder;
    } else {
      newList.push(workOrder);
    }
  });

  dispatch({
    type: TYPES.setList,
    payload: newList,
  });
};

/**
 * Get a work order by workOrderId
 * @param {string} workOrderNumber - The workOrderNumber
 * @return {WorkOrder}
 */
export const getWorkOrderById = (workOrderNumber) => {

  return async (dispatch) => {
    try {
      // we need to ensure that workOrderNumber is a string here as
      // there is some edge cases created by form.io that sets workOrderNumber
      // in some random permit's ContentJSON to be an empty object. This object
      // is then getting passed to Elastic search which is an invalid query.
      // as such, we want to be extra sure that the type of workOrder is a string or number
      if (
        !workOrderNumber
        || (typeof workOrderNumber !== 'string' || typeof workOrderNumber !== 'number')
      ) {
        return;
      }

      const result = await Api.post('/workorders/search', {
        query: {
          term: { 'workOrderNumber.keyword': { value: workOrderNumber } },
        },
      });

      const workOrder = _get(result, 'hits.hits.0._source');

      if (workOrder) {
        // eslint-disable-next-line no-underscore-dangle
        dispatch(updateList([workOrder]));
      }

      return workOrder;
    } catch {
      toast.error('There was a problem fetching the work order. Please refresh to try again')
      return undefined;
    }
  };
};

export const setWorkOrderFilter = (values, filterBy) => (dispatch) => {
  dispatch({
    type: TYPES.filter,
    payload: {
      filterBy,
      values,
    },
  });
};

export const setStatuses = (statuses) => (dispatch) => dispatch({
  type: TYPES.setStatuses,
  payload: {
    list: statuses.AvailableStatuses,
    active: statuses.ActiveStatuses,
  },
});

const clearList = () => (dispatch) => dispatch({
  type: TYPES.clearList,
});

const setWorkOrdersEnabled = (enabled) => (dispatch) => dispatch({
  type: TYPES.setEnabled,
  payload: enabled
});

export const selector = (path) => (state) => _get(state.workOrders, path);

export default {
  actions: {
    clearList,
    get: getWorkOrders,
    getWorkOrderById,
    filter: setWorkOrderFilter,
    setStatuses,
    updateList,
    setWorkOrdersEnabled,
  },
  selectors: {
    enabled: () => selector('enabled'),
    filters: () => selector('workOrderFilter'),
    list: () => selector('list'),
    selected: () => selector('selected'),
    statuses: () => selector('statuses.list'),
    activeStatuses: () => selector('statuses.active'),
  },
  types: TYPES,
};
