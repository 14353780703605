import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import Alert from '@material-ui/lab/Alert';

import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Divider,
  ListItemSecondaryAction,
  IconButton,
  Typography,
} from '@material-ui/core';

import {
  Delete as DeleteIcon,
  Description as DescriptionIcon,
  Image as ImageIcon,
  PictureAsPdf as PictureAsPdfIcon,
} from '@material-ui/icons';

import s3 from '../../../shared/s3';
import AppState from '../../../actions/app';
import PermitsState from '../../../actions/permits';
import Actions from '../../../actions/permits';
import useAbilities from '../../../hooks/auth/useAbilities';
import useOffline from "../../../hooks/shared/useOffline";

import InlineLoader from '../../shared/InlineLoader';
import Dialog from '../../shared/Dialog';

const useGetAttachments = (permitInstanceId) => {
  const dispatch = useDispatch();
  const attachments = useSelector(PermitsState.selectors.attachments());
  const loading = useSelector(AppState.selectors.status('getAttachments'));

  React.useEffect(() => {
    if (permitInstanceId) {
      dispatch(PermitsState.actions.getAttachments(permitInstanceId));
    }
  }, [permitInstanceId, dispatch]);

  return { attachments, loading: loading === 'resolved' };
};

const AttachmentsDialog = (props) => {
  const { onClose, PermitInstanceId, visible } = props;

  const inputRef = React.useRef();
  const dispatch = useDispatch();
  const abilities = useAbilities();
  const { online, outbox = [] } = useOffline();

  const { attachments, loading: attachmentsLoading } = useGetAttachments(PermitInstanceId);
  const uploadInProgress = useSelector(AppState.selectors.status('uploadAttachment'));
  const [selectedForDelete, setSelectedForDelete] = React.useState(false);

  const handleFileUpload = (event) => {
    const { target } = event;
    if (target.files && target.files.length) {
      const file = target.files[0];
      dispatch(PermitsState.actions.uploadAttachment(PermitInstanceId, file));
    }
  };

  const handleAttachmentDelete = () => {
    dispatch(PermitsState.actions.deleteAttachment(PermitInstanceId, selectedForDelete));
    setSelectedForDelete(undefined);
  };

  const handleListItemClick = (attachment) => {
    s3.getSecuredFileUrl(attachment.AttachmentKey)
      .then((url) => window.open(url, '_blank'));
  };

  const determineIcon = (mimeType) => {
    if (mimeType === 'application/pdf') {
      return <PictureAsPdfIcon />;
    }
    if (['image/jpeg', 'image/png', 'image/svg', 'image/tiff'].includes(mimeType)) {
      return <ImageIcon />;
    }

    return <DescriptionIcon />;
  };

  useEffect(() => {
    const files = outbox.filter(data => (data.type === Actions.types.fileUploadStart) && (data.PermitInstanceId === PermitInstanceId));
    if (!files.length) {
      dispatch(PermitsState.actions.getAttachments(PermitInstanceId));
    }
  }, [outbox.length]);

  const renderNoAttachmentsAlert = () => (
    <Alert severity="info">
      <Typography>
        This permit has no attachments
      </Typography>
    </Alert>
  );

  const renderAttachments = () => (
    <List component="nav" aria-label="attachments">
      {attachments.map((attachment, i) => (
        <React.Fragment key={i}>
          <ListItem
            disabled={attachment.isOfflineSaved || !online}
            button
            onClick={() => handleListItemClick(attachment)}
          >
            <ListItemIcon>
              {determineIcon(attachment.MimeType)}
            </ListItemIcon>
            <ListItemText primary={attachment.FileName} />
            {!attachment.isOfflineSaved && online &&
              <ListItemSecondaryAction>
                <IconButton
                  edge="end"
                  aria-label="delete"
                  onClick={() => setSelectedForDelete(attachment.PermitAttachmentId)}
                >
                  <DeleteIcon />
                </IconButton>
              </ListItemSecondaryAction>
            }
          </ListItem>
          {i !== attachments.length - 1 && <Divider />}
        </React.Fragment>
      ))}
    </List>
  );

  const loadingInProgress = uploadInProgress === 'pending' || attachmentsLoading;
  return (
    <Dialog
      onClose={onClose}
      visible={visible}
      title="Permit Attachments"
      actions={[{
        text: 'Add Attachment',
        color: 'primary',
        disabled: !abilities.permits.edit || loadingInProgress,
        onClick: () => inputRef && inputRef.current.click(),
      }]}
    >
      {loadingInProgress ? <InlineLoader /> : (
        <>
          {!online &&
            <Alert severity="warning" style={{ marginBottom: '1rem' }}>
              <Typography>
                There is no connection to the E-Permitting system and you are currently working offline. Any Attachments
                {' '}
                you made to the permit will be synchronized automatically when the device comes back online.
              </Typography>
            </Alert>
          }
          {attachments && attachments.length
            ? renderAttachments()
            : renderNoAttachmentsAlert()
          }

          <input
            type="file"
            ref={inputRef}
            style={{ visibility: 'hidden' }}
            onChange={handleFileUpload}
          />

          {selectedForDelete && (
            <Dialog
              visible
              onClose={() => setSelectedForDelete(false)}
              title="Confirm Delete"
              actions={[
                {
                  text: 'Delete Attachment',
                  color: 'red',
                  onClick: () => handleAttachmentDelete(false),
                },
                {
                  text: 'Cancel',
                  color: 'primary',
                  onClick: () => setSelectedForDelete(false),
                },
              ]}
            >
              Are you sure you want to delete this attachment?
            </Dialog>
          )}
        </>
      )}
    </Dialog>
  );
};

AttachmentsDialog.defaultProps = {
  visible: false,
};

AttachmentsDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  PermitInstanceId: PropTypes.string.isRequired,
  visible: PropTypes.bool,
};

export default AttachmentsDialog;
