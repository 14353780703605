import React from 'react';
import { Typography } from '@material-ui/core';
import { useDispatch } from 'react-redux';

import Dialog from '../../../shared/Dialog';

import ExternalUsersState from '../../../../actions/admin/external-users';
import InlineLoader from '../../../shared/InlineLoader';

type Props = {
  onClose: () => void;
  ActiveFlag: boolean;
  PersonnelId: number;
}

const EnableDisableDialog: React.FC<Props> = (props) => {
  const { ActiveFlag, onClose, PersonnelId } = props;
  const dispatch = useDispatch();

  const [loading, setLoading] = React.useState<boolean>(false);

  const handleDeleteClick = async () => {
    setLoading(true);
    await dispatch(ExternalUsersState.actions.updateUser(PersonnelId, { ActiveFlag: !ActiveFlag}));
    onClose();
    setLoading(false);
  };

  const actionText = ActiveFlag ? 'Disable' : 'Enable';

  return (
    <Dialog
      visible
      onCancel={onClose}
      onClose={onClose}
      title={`${actionText} User`}
      actions={[{
        text: actionText,
        color: 'primary',
        disabled: loading,
        onClick: handleDeleteClick,
      }]}
    >
      {loading ? <InlineLoader /> : (
        <Typography gutterBottom>
          Are you sure you want to
          {' '}
          {actionText.toLowerCase()}
          {' '}
          this user?
        </Typography>
      )}
    </Dialog>
  );
};

export default EnableDisableDialog;
