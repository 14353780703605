import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import useLocations from '../locations/useLocations';
import ListItemsState from '../../actions/admin/lists';
import { ListItemField } from '../../types/list-item';

type UseListsResponse = {
  loading: boolean;
  data: any;
}

const useLists = (): UseListsResponse => {
  const dispatch = useDispatch();
  const { homeLocation } = useLocations();
  const { actions, selectors } = ListItemsState;

  const [loading, setLoading] = React.useState<boolean>(false);
  const [fieldsList, setFieldsList] = React.useState<ListItemField[]>([]);

  const fieldsFromState = useSelector(selectors.fields);

  React.useEffect(() => {
    setFieldsList(fieldsFromState);
    setLoading(false);
  }, [fieldsFromState]);

  React.useEffect(() => {
    setLoading(true);
    dispatch(actions.getLists(homeLocation.SiteId));
  }, [
    actions,
    dispatch,
    homeLocation.SiteId,
  ]);

  return { loading, data: fieldsList };
};

export default useLists;
