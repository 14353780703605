import React from 'react';
import TreeItem, { TreeItemProps } from '@material-ui/lab/TreeItem';
import {
  makeStyles,
  Typography,
  createStyles,
  Theme,
  FormControlLabel,
  Checkbox
} from '@material-ui/core';

import { Location } from '../../../../types/location';
import { LocationTreeLocation } from '../../../../hooks/locations/useLocationTree';

type StyledTreeItemProps = TreeItemProps & {
  canEdit: boolean;
  canPrint: boolean;
  labelText: string;
  location: Location;
  onPermissionClick: (value: boolean, type: 'print' | 'edit', location: LocationTreeLocation) => void;
};

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    color: theme.palette.text.secondary,
    marginLeft: theme.spacing(2),
    backgroundColor: 'transparent',
    margin: theme.spacing(1, 0),
    '&.Mui-selected > $content > $label': {
      backgroundColor: theme.palette.grey[100]
    }
  },
  content: {
    color: theme.palette.text.secondary,
    fontWeight: theme.typography.fontWeightRegular,
  },
  group: {
    marginLeft: theme.spacing(1),
  },
  label: {
    fontWeight: 'inherit',
    color: 'inherit',
    paddingLeft: 0,
  },
  labelRoot: {
    display: 'flex',
    padding: theme.spacing(1),
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    boxShadow: theme.shadows[1],
  },
  labelText: {
    fontWeight: 'inherit',
    flexGrow: 1,
  },
  checkboxWrapper: {
    padding: 0,
    marginLeft: 0,
    marginRight: theme.spacing(1),
    marginBottom: 0,
  },
}));

const LocationTreeItem: React.FC<StyledTreeItemProps> = (props) => {
  const classes = useStyles();

  const {
    canPrint,
    canEdit,
    labelText,
    location,
    onPermissionClick,
    ...other
  } = props;

  const handleClick = (event: React.ChangeEvent<any>, type: 'print' | 'edit', value: boolean) => {
    onPermissionClick(value, type, location);
  };

  const disabled = !location.PermittingEnabled;

  return (
    <TreeItem
      label={
        <div className={classes.labelRoot}>
          <div>
            <Typography variant="body2" className={classes.labelText}>
              {labelText}
            </Typography>
          </div>
          <div>
            <FormControlLabel
              onClick={event => event.stopPropagation()}
              classes={{
                root: classes.checkboxWrapper
              }}
              control={
                <Checkbox
                  checked={canPrint}
                  disabled={disabled}
                  onClick={event => event.stopPropagation()}
                  onChange={(event, checked) => handleClick(event, 'print', checked)}
                  name="checkedB"
                  color="primary"
                  classes={{
                    root: classes.checkboxWrapper
                  }}
                  size="small"
                />
              }
              label="Print"
            />
            <FormControlLabel
              onClick={event => event.stopPropagation()}
              classes={{
                root: classes.checkboxWrapper
              }}
              control={
                <Checkbox
                  checked={canEdit}
                  disabled={disabled}
                  onClick={event => event.stopPropagation()}
                  onChange={(event, checked) => handleClick(event, 'edit', checked)}
                  name="checkedB"
                  color="primary"
                  classes={{
                    root: classes.checkboxWrapper
                  }}
                  size="small"
                />
              }
              label="Edit"
            />
          </div>
        </div>
      }
      classes={{
        root: classes.root,
        content: classes.content,
        group: classes.group,
        label: classes.label,
      }}
      {...other}
    />
  );
}

export default LocationTreeItem;
