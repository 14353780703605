import React from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';

import ApprovalsDialog from './ApprovalsDialog';
import PermitsState from '../../../actions/permits';
import usePermitApprovals from '../../../hooks/permits/usePermitApprovals';

const useStyles = makeStyles((theme) => {
  return {
    button: {
      margin: theme.spacing(1),
    },
    completedButton: {
      backgroundColor: theme.palette.success.main,
      margin: theme.spacing(1),
      color: 'white',
    },
    notCompletedButton: {
      backgroundColor: theme.palette.secondary.main,
      margin: theme.spacing(1),
      color: 'white',
    },
  };
});

const ApprovalsCountButton = () => {
  const classes = useStyles();
  const { instance } = useSelector(PermitsState.selectors.selected());
  const { approvals } = usePermitApprovals(instance);
  const [showApprovalsDialog, setShowApprovalsDialog] = React.useState(false);

  const approvalCounts = React.useMemo(() => {
    const counts = { total: 0, missing: 0, completed: 0 };
    if (!approvals || Object.keys(approvals).length === 0) return counts;

    counts.total = approvals.RequiredApprovals.length;
    counts.missing = approvals.MissingApprovals.length;
    counts.completed = counts.total - counts.missing;
    return counts;
  }, [approvals]);

  if (!approvalCounts.total) {
    return <></>;
  }

  return (
    <>
      <Button
        variant="contained"
        size="small"
        className={
          approvalCounts.total === approvalCounts.completed
            ? classes.completedButton
            : classes.notCompletedButton
        }
        onClick={() => setShowApprovalsDialog((visible) => !visible)}
      >
        {/* eslint-disable-next-line react/jsx-one-expression-per-line */}
        Approvals ({approvalCounts.completed}/{approvalCounts.total})
      </Button>

      {showApprovalsDialog && (
        <ApprovalsDialog
          visible
          permit={instance}
          ReferenceId={instance.PermitInstanceReferenceId}
          PermitInstanceId={instance.PermitInstanceId}
          onClose={() => setShowApprovalsDialog(false)}
        />
      )}
    </>
  );
};

export default ApprovalsCountButton;
