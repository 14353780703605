import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';

import {
  ListItem,
  ListItemText,
  Divider,
} from '@material-ui/core';

import AppState from '../../actions/app';
import AuthState from '../../actions/auth';
import WorkOrdersState from '../../actions/work-orders';
import useAbilities from '../../hooks/auth/useAbilities';

import SideMenuItems from '../../config/menuConfig';
import useAnalytics from '../../hooks/shared/useAnalytics';

const useStyles = makeStyles((theme) => ({
  listItemText: {
    [theme.breakpoints.up('sm')]: {
      fontSize: '0.8rem',
    },
  },
  listItem: {
    '& active': {
      backgroundColor: '#ededed',
    },
  },
  version: {
    position: 'absolute',
    bottom: theme.spacing(1),
    fontSize: 12,
    width: '100%',
    textAlign: 'center',
  },
}));

const SideList = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const abilities = useAbilities();
  const recordEvent = useAnalytics();

  const workOrdersEnabled = useSelector(WorkOrdersState.selectors.enabled())

  const handleSignOut = React.useMemo(() => () => {
    recordEvent('Sidebar link clicked [SignOut]');
    dispatch(AuthState.actions.signOut());
    dispatch(AppState.actions.toggleLeftMenuDrawer());
  }, [dispatch, recordEvent]);

  const displayList = () => SideMenuItems.map((menuItem) => {
    if (menuItem.pathName.startsWith('/permits') && !abilities.permits.view) {
      return <React.Fragment key={menuItem.id} />;
    }

    if (menuItem.pathName === '/permits/create' && !abilities.permits.edit) {
      return <React.Fragment key={menuItem.id} />;
    }

    if (menuItem.pathName === '/permits/revoke' && !abilities.permits.edit) {
      return <React.Fragment key={menuItem.id} />;
    }

    if (menuItem.pathName.startsWith('/workorders') && !workOrdersEnabled) {
      return <React.Fragment key={menuItem.id} />;
    }

    if (menuItem.pathName === '/admin' && !abilities.forms.view) {
      return <React.Fragment key={menuItem.id} />;
    }

    return (
      <React.Fragment key={menuItem.id}>
        <ListItem
          button
          component={NavLink}
          to={menuItem.pathName}
          onClick={() => {
            recordEvent(`Sidebar link clicked [${menuItem.label}]. Browsing to [${menuItem.pathName}]`);
            dispatch(AppState.actions.toggleLeftMenuDrawer())
          }}
          className={classes.listItem}
          disabled={menuItem.disabled}
        >
          <ListItemText
            classes={{ primary: classes.listItemText }}
            primary={menuItem.label}
          />
        </ListItem>
        {menuItem.divider && <Divider key={menuItem.label + menuItem.id} />}
      </React.Fragment>
    );
  });

  return (
    <>
      {displayList()}
      <ListItem
        button
        onClick={handleSignOut}
        className={classes.listItem}
      >
        <ListItemText
          classes={{ primary: classes.listItemText }}
          primary="Signout"
        />
      </ListItem>
      <div className={classes.version}>
        v
        {process.env.REACT_APP_VERSION}
        -
        {process.env.REACT_APP_GIT_SHA}
      </div>
    </>
  );
};

export default SideList;
