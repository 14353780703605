export const ExternalUserStates = ['PENDING', 'ACTIVE', 'DISABLED', 'DELETED'];
export type ExternalUserState = typeof ExternalUserStates[number];

export const ExternalUsersStateColors = {
  PENDING: '#6f42c1',
  ACTIVE: '#28a745',
  DISABLED: '#dc3545',
  DELETED: '#17a2b8',
};


export type UserPermissions = {
  PermissionTypeId: number;
  Name: string;
  Locations: number[];
}

export interface ExternalUser {
  AccessExpiresDate?: string;
  PersonnelId: number;
  EmailAddress: string;
  FirstName: string;
  LastName: string;
  SiteId: string;
  State: string;
  Status: ExternalUserState;
  ActiveFlag: boolean;
  InactiveDate?: string;
  DeleteFlag?: string;
  CreatedDate: string;
  CanEdit?: number[];
  CanPrint?: number[];
  LastLoginDate: string;
  L: string;
  Company: string;
  StreetAddress: string;
  Country: string;
  PostalCode: string;
  SignupInitiated: boolean;
  SignupComplete: boolean;
  Permissions?: UserPermissions[];
}

export const determineExternalUserState = (user: ExternalUser): ExternalUserState => {
  if (user.DeleteFlag) {
    return 'DELETED';
  }

  if (!user.ActiveFlag) {
    return 'DISABLED';
  }

  if (user.SignupInitiated && !user.SignupComplete) {
    return 'PENDING';
  }

  if (user.SignupComplete) {
    return 'ACTIVE';
  }

  return 'DISABLED';
};
