import * as configcat from 'configcat-js';
import { singletonHook } from 'react-singleton-hook';
import { useEffect, useState } from 'react';
import Config from '../config/app-config';

const init = {};

const useFeatureFlagsImpl = () => {
  const [featureFlags, setFeatureFlags] = useState(init);

  let configCatClient: configcat.IConfigCatClient;

  const updateFeatureFlags = async () => {
    configCatClient.getAllValues((keyValues) => {
          let values: any = {};

          for (let keyValue of keyValues) {
              values[keyValue.settingKey] = keyValue.settingValue;
          }

          setFeatureFlags(values);
      });
  };

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    // eslint-disable-next-line no-unused-vars
    console.log("environment value", Config);
    let configCatKey;
    if(Config.API.baseUrl && (Config.API.baseUrl).includes("dev")){ 
      configCatKey = "3ivbCIDH_0iMtDT8XvZZUA/ZFN3SryKrEuHC7yfPKxgtA";
    } else if (Config.API.baseUrl && (Config.API.baseUrl).includes("uat")){
      configCatKey = "3ivbCIDH_0iMtDT8XvZZUA/bjaYB1B3qEGqGHW_4PRa0A";
    } else{
      configCatKey = "3ivbCIDH_0iMtDT8XvZZUA/_eOiop4jAUm8SHClwxOcbQ";
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    configCatClient = configcat.createClientWithAutoPoll(configCatKey, {
      configChanged: updateFeatureFlags,
    });

    updateFeatureFlags();
  }, []);

  return featureFlags;
};

export const useFeatureFlags = useFeatureFlagsImpl;
