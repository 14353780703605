import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Link } from 'react-router-dom';

const useStyles = makeStyles(() => ({
  center: {
    textAlign: 'center',
    justifyContent: 'center',
  },
}));

const LandingTile = ({ loading, title, count, path }) => {
  const classes = useStyles();

  const renderCardContent = () => {
    if (loading) return <CircularProgress className={classes.center} />;
    return <Typography className={classes.center} variant="h1">{count}</Typography>;
  };

  return (
    <Card>
      <CardHeader className={classes.center} title={title} />
      <CardContent className={classes.center}>
        {renderCardContent()}
      </CardContent>
      <CardActions className={classes.center}>
        <Button variant="text" component={Link} to={path}>View Details</Button>
      </CardActions>
    </Card>
  );
};

LandingTile.defaultProps = {
  count: 0,
  loading: false,
  path: '#',
};

LandingTile.propTypes = {
  loading: PropTypes.bool,
  title: PropTypes.string.isRequired,
  count: PropTypes.number,
  path: PropTypes.string,
};

export default LandingTile;
