import * as actions from '../../actions/admin/templates/types';
import { updateTemplateInList } from '../../shared/templates';
import { Template } from '../../types/template';

export interface TemplatesState {
  readonly list: Template[];
}

const initialState: TemplatesState = {
  list: [],
};

const templatesReducer = (
  state = initialState,
  action: actions.TemplatesActionTypes,
): TemplatesState => {
  const { TYPES } = actions;
  let typedAction;

  switch (action.type) {
    case TYPES.setList:
      typedAction = action as actions.SetListAction;
      return {
        ...state,
        list: typedAction.payload,
      };
    case TYPES.updateTemplate:
      typedAction = action as actions.UpdateTemplateAction;
      return {
        ...state,
        list: updateTemplateInList(state.list, typedAction.payload),
      };
    default:
      return state;
  }
};

export default templatesReducer;
