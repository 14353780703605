import React from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { Paper, Typography, FormControlLabel, Switch } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import Pagination from '@material-ui/lab/Pagination';
import { makeStyles } from '@material-ui/core/styles';

import PermitListCard from './shared/PermitListCard';
import PermitListFilters from './shared/PermitListFilters';

import PermitsState from '../../actions/permits';
import InlineLoader from '../shared/InlineLoader';
import useSetPageName from '../../hooks/shared/useSetPageName';
import useGetPermits from '../../hooks/permits/useGetPermits';
import useExpandAll from '../../hooks/permits/useExpandCard';

const useStyles = makeStyles((theme) => ({
  paginationWrapper: {
    display: 'flex',
    justifyContent: 'center',
    padding: theme.spacing(2),
  },
  btnGroup: {
    display: 'flex',
    justifyContent: 'flex-end',
    '& label': {
      margin: 0
    },
  },
}));

const PermitList = () => {
  useSetPageName('Permits');

  const classes = useStyles();
  const dispatch = useDispatch();

  const { error, loading, list: permits } = useGetPermits();
  const { expandCard, updateExpandState } = useExpandAll();
  const { actions } = PermitsState;

  const [page, setPage] = React.useState(1);

  React.useEffect(() => {
    setPage(1);
  }, [permits]);

  React.useEffect(() => {
    return () => {
      dispatch(actions.clearFilters());
    };
  }, [dispatch, actions]);

  const permitList = React.useMemo(() => {
    if (!permits.length && !error) {
      return (
        <Alert severity="warning">
          <Typography>
            No permits matched the selected filters. Please update your selection or
            {' '}
            <Link to="/permits/list">
              {' '}
              click here
              {' '}
            </Link>
            {' '}
            to reset the filters.
          </Typography>
        </Alert>
      );
    }

    return permits
      .slice((page - 1) * 10, page * 10)
      .map((permit) => (
        <PermitListCard
          permit={permit}
          key={permit.PermitInstanceId}
          expandCard={expandCard}
        />
      ));
  }, [permits, page, error, expandCard]);

  const renderLoadFailed = () => (
    <Alert severity="error">
      There was an problem loading permits. Please refresh the page to try again.
    </Alert>
  );

  return (
    <div className="permit-list">
      <PermitListFilters disabled={loading} />
      {
        permitList.length && (
          <div className={classes.btnGroup}>
            <FormControlLabel
              control={<Switch color="secondary"  checked={!!expandCard} onChange={() => updateExpandState(!expandCard)}/>}
              label="Expand All Cards"
              labelPlacement="end"
            />
          </div> 
        )
      }
      {loading ? <InlineLoader /> : permitList}
      {!loading && permits.length > 0 && (
        <Paper className={classes.paginationWrapper}>
          <Pagination
            color="primary"
            count={Math.ceil(permits.length / 10)}
            page={page}
            onChange={(e, p) => setPage(p)}
          />
        </Paper>
      )}
      {error && renderLoadFailed()}
    </div>
  );
};

export default PermitList;
