import React from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import {
  Button,
  makeStyles,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,

} from '@material-ui/core';

import InlineLoader from '../../../shared/InlineLoader';
import useListItems from '../../../../hooks/admin/useListItems';
import ListItemsListRow from './ListItemsListRow';
import Breadcrumbs from '../../Breadcrumbs';

import ListItemsState from '../../../../actions/admin/lists';
import { ListItem } from '../../../../types/list-item';

const useStyles = makeStyles((theme) => ({
  wrapper: {},
  heading: {
    marginBottom: theme.spacing(2),
  },
}));

const FieldItemsList: React.FC = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { fieldKey } = useParams<{ fieldKey: string }>();
  const { loading, data } = useListItems(fieldKey);
  const [showAddItemForm, setShowAddItemForm] = React.useState(false);

  const handleItemChange = async (item: ListItem, initialItemValue?: string) => {
    await dispatch(ListItemsState.actions.upsertItem(fieldKey, item, initialItemValue));
  };

  const handleItemDelete = async (item: ListItem) => {
    await dispatch(ListItemsState.actions.deleteItem(fieldKey, item));
  };

  const handleItemAdd = async (item: ListItem) => {
    await dispatch(ListItemsState.actions.upsertItem(fieldKey, item));
    setShowAddItemForm(false);
  };

  const existingValues = data.map((item) => item.Value);

  return (
    <div className={classes.wrapper}>

      <Breadcrumbs
        crumbs={[
          { label: 'Lists', path: '/admin/lists' },
          { label: fieldKey },
        ]}
      />

      <TableContainer component={Paper}>
        {loading ? <InlineLoader /> : (
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Value</TableCell>
                <TableCell>Label</TableCell>
                <TableCell align="right" />
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((item) => (
                <ListItemsListRow
                  key={item.Value}
                  existingValues={existingValues}
                  item={item}
                  onChange={handleItemChange}
                  onDelete={handleItemDelete}
                />
              ))}

              {showAddItemForm ? (
                <ListItemsListRow
                  forceEdit
                  existingValues={existingValues}
                  item={{ Label: '', Value: '' }}
                  onChange={handleItemAdd}
                  onCancel={() => setShowAddItemForm(false)}
                />
              ) : (
                  <TableRow>
                    <TableCell colSpan={3}>
                      <Button
                        size="small"
                        variant="outlined"
                        color="primary"
                        onClick={() => setShowAddItemForm(true)}
                      >
                        Add Item
                    </Button>
                    </TableCell>
                  </TableRow>
                )}

            </TableBody>
          </Table>
        )}
      </TableContainer>
    </div>
  );
};

export default FieldItemsList;
