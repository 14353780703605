
import toast from '../../../shared/toast';
import { AppThunk } from '../../thunk';
import { ESPermitPayload } from '../../../types/elasticsearch';
import Api from '../../../shared/api';

/**
 * Invite user to clear permit cache state
 * @param {ESPermitPayload} esPermit - permit cache state
 * @return {void}
 */
export const clearESPermitCache = (esPermit: ESPermitPayload): AppThunk => {
  return async (dispatch, getState) => {
    try {
      const { homeLocation } = getState().appState;
      const payload = { ...esPermit, SiteId: homeLocation.SiteId }
      const result = await Api.patch('/permits/search/all', payload);
      if(result){
        toast.success('Permit cache cleared');
      }
    } catch (error) {
      toast.error('There was a problem while clearing permit cache, please try again');
    }
  };
};
