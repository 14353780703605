import React from "react";
import PropTypes from "prop-types";
import { ArrowDropDown, ArrowDropUp } from "@material-ui/icons";
import { Button, Menu, MenuItem } from "@material-ui/core";
import FilterInputWrapper from "./FilterInputWrapper";

const FilterDropDown = (props) => {
  const { defaultValue, label, options, disabled, permitpad } = props;

  const [isOpen, setIsOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [selected, setSelected] = React.useState({});

  React.useEffect(() => {
    if (defaultValue) {
      setSelected(defaultValue);
    }
  }, [defaultValue]);

  const renderArrow = () => {
    return isOpen ? <ArrowDropUp /> : <ArrowDropDown />;
  };

  const handleOnSelect = (option) => {
    option.onSelect(option.value);
    setSelected(option);
    setIsOpen(!isOpen);
  };

  return (
    <FilterInputWrapper label={label} permitpad={permitpad}>
      <Button
        variant="outlined"
        disabled={disabled}
        ref={(ref) => setAnchorEl(ref)}
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={() => setIsOpen(!isOpen)}
      >
        {selected.label} {renderArrow()}
      </Button>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        defaultValue={defaultValue}
        open={isOpen}
        onClose={() => setIsOpen(!isOpen)}
      >
        {options.map((option) => (
          <MenuItem key={option.value} onClick={() => handleOnSelect(option)}>
            {option.label}
          </MenuItem>
        ))}
      </Menu>
    </FilterInputWrapper>
  );
};

FilterDropDown.defaultProps = {
  disabled: false,
};

FilterDropDown.propTypes = {
  label: PropTypes.string.isRequired,
  defaultValue: PropTypes.shape({
    value: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    onSelect: PropTypes.func.isRequired,
  }).isRequired,
  disabled: PropTypes.bool,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      onSelect: PropTypes.func.isRequired,
    })
  ).isRequired,
  permitpad: PropTypes.number,
};

export default FilterDropDown;
