import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Card, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import LeafletMap from '../shared/LeafletMap';
import InlineLoader from '../shared/InlineLoader';
import PermitMapLegend from '../shared/PermitMapLegend';

const useStyles = makeStyles((theme) => ({
  card: { position: 'relative' },
  noLocationCount: {
    position: 'absolute',
    backgroundColor: '#fff',
    padding: theme.spacing(2),
    boxShadow: '0px 3px 3px -2px rgba(0,0,0,0.2), 0px 3px 4px 0px rgba(0,0,0,0.14), 0px 1px 8px 0px rgba(0,0,0,0.12)',
    left: 0,
    bottom: 0,
    zIndex: 999,
  },

  noPermitsText: {
    textAlign: 'center',
    margin: theme.spacing(2),
  },
}));

const ActiveWorkLocationsTile = (props) => {
  const classes = useStyles();
  const { loading, permits } = props;

  // We only want to show permits where the work is happening today.
  const permitsToday = React.useMemo(() => {
    const startofYesterday = moment().subtract(1, 'day').startOf('day').subtract(1, 'second');
    return permits.filter((permit) => {
      return permit.workDate && moment(permit.workDate).isAfter(startofYesterday.subtract(1, 'second'));
    });
  }, [permits]);

  // Count the number of permits that don't have a location set.
  const noLocationSetCount = React.useMemo(() => {
    if (loading || !permitsToday || !permitsToday.length) return 0;

    return permitsToday.reduce((c, p) => {
      let nextCount = c;
      if (p.Latitude > 0 && p.Longitude > 0) {
        return nextCount;
      }

      nextCount += 1;
      return nextCount;
    }, 0);
  }, [permitsToday, loading]);

  const renderMap = () => {
    if (permitsToday.length) {
      return (
        <>
          <LeafletMap
            markers={
              permitsToday.map((p) => ({
                lat: Number(p.Latitude),
                lng: Number(p.Longitude),
                permit: {
                  PermitInstanceId: p.PermitInstanceId,
                  PermitInstanceReferenceId: p.PermitInstanceReferenceId,
                  State: p.State,
                  workDescription: p.workDescription,
                },
              }))
            }
          />
          <PermitMapLegend />
        </>
      );
    }

    return (
      <Typography className={classes.noPermitsText}>
        No permits to display
      </Typography>
    );
  };

  return (
    <Card className={classes.card}>
      {loading
        ? <InlineLoader />
        : renderMap()}
      {noLocationSetCount > 0 && (
        <div className={classes.noLocationCount}>
          Hiding
          {' '}
          {noLocationSetCount}
          {' '}
          permit
          {noLocationSetCount > 1 && 's'}
          {' '}
          with no location set
        </div>
      )}
    </Card>
  );
};

ActiveWorkLocationsTile.defaultProps = {
  loading: false,
  permits: [],
};

ActiveWorkLocationsTile.propTypes = {
  loading: PropTypes.bool,
  permits: PropTypes.arrayOf(
    PropTypes.shape({
      PermitInstanceId: PropTypes.string,
    }),
  ),
};

export default ActiveWorkLocationsTile;
