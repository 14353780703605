import React from 'react';
import PropTypes from 'prop-types';
import Alert from '@material-ui/lab/Alert';
import { useDispatch, useSelector } from 'react-redux';

import {
  Typography,
  RadioGroup,
  FormControlLabel,
  Radio,
  Checkbox,
  makeStyles,
} from '@material-ui/core';

import useWithPrinters from '../../../hooks/shared/useWithPrinters';
import AppState from '../../../actions/app';
import AuthState from '../../../actions/auth';
import PermitsState from '../../../actions/permits';
import { ActionStatus } from '../../../actions/shared/status';

import Dialog from '../../shared/Dialog';
import InlineLoader from '../../shared/InlineLoader';
import PrinterListItem from './PrinterListItem';
import useWithUserPreferences from '../../../hooks/auth/useWithUserPreferences';
import WarningOutlinedIcon from '@material-ui/icons/WarningOutlined';

const useStyles = makeStyles((theme) => ({
  footerCheckBox: {
    marginBottom: 0,
  },
  settingsWrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: theme.spacing(2),
  },
  printerLabel: {
    paddingBottom: theme.spacing(1),
    paddingTop: theme.spacing(1),
    width: '100%'
  },
  note: {
    fontSize: 12,
  },
  icon: {
    width: 15,
  }
}));

const PrintPermitDialog = (props) => {
  const { ReferenceId, PermitInstanceId, onClose, visible } = props;

  const classes = useStyles();
  const { merge: mergeDefault, printers, printingResponse } = useWithPrinters();
  const dispatch = useDispatch();
  const { preferences } = useWithUserPreferences();

  const [merge, setMerge] = React.useState(mergeDefault);
  const [favouritePrinters, setFavouritePrinters] = React.useState(preferences.FavouritePrinters || []);
  const [selectedPrinter, setSelectedPrinter] = React.useState(preferences.DefaultPrinterId);
  const [makeDefault, setMakeDefault] = React.useState(false);
  const [printerData, setSelectedPrinterData] = React.useState({});
  const [colorPrint, setColorPrint] = React.useState(false);

  const printStatus = useSelector(AppState.selectors.status('printPermit'));

  React.useEffect(() => {
    setMerge(mergeDefault);
  }, [mergeDefault]);

  React.useEffect(() => {
    dispatch(AppState.actions.setPrintingResponse(null));
    return () => {
      dispatch(AppState.actions.resetStatus('printPermit'));
      dispatch(AppState.actions.setPrintingResponse(null));
    };
  }, [dispatch]);

  const printerList = React.useMemo(() => {
    return printers
      .sort(pr => favouritePrinters.includes(pr.PermitPrinterId) ? -1 : 1);
  }, [printers, favouritePrinters]);

  const handleSelection = (event) => {
    const printer = printers.find(data => data.PermitPrinterId === Number(event.target.value));
    setSelectedPrinterData(printer)
    setColorPrint(printer.DefaultColor);
    setSelectedPrinter(Number(event.target.value));
  };

  const handlePrintPermit = () => {
    const { actions } = PermitsState;
    dispatch(actions.printPermit(PermitInstanceId, selectedPrinter, merge, makeDefault, colorPrint));
  };

  const handleTogglFavouritePrinter = (PermitPrinterId) => {
    let nextPrinters;
    if (favouritePrinters.includes(PermitPrinterId)) {
      nextPrinters = Array.from(favouritePrinters).filter(printer => printer !== PermitPrinterId);
    } else {
      nextPrinters = [...favouritePrinters, PermitPrinterId];
    }

    setFavouritePrinters(nextPrinters);

    const { actions } = AuthState;
    dispatch(actions.updateUserPreference('FavouritePrinters', nextPrinters));
  }

  const printingInProgress = printStatus === ActionStatus.pending;

  return (
    <Dialog
      onClose={onClose}
      visible={visible}
      title={`Print Permit - #${ReferenceId}`}
      footerLeft={
        <>
          {
            printerData.SupportsColor && (
              <FormControlLabel
                control={
                  <Checkbox
                    color="primary"
                    name="colorPrint"
                    checked={colorPrint}
                    onChange={(event) => setColorPrint(event.target.checked)}
                  />
                }
                classes={{ root: classes.footerCheckBox }}
                label="Color Print"
              />
            )
          }
          {
            selectedPrinter !== preferences.DefaultPrinterId && (
              <FormControlLabel
                control={
                  <Checkbox
                    color="primary"
                    name="makeDefault"
                    checked={makeDefault}
                    onChange={(event) => setMakeDefault(event.target.checked)}
                  />
                }
                classes={{ root: classes.footerCheckBox }}
                label="Use as default printer"
              />
            )}
        </>
      }
      actions={
        [{
          text: 'Print Permit',
          color: 'primary',
          disabled: printingInProgress || !selectedPrinter,
          onClick: handlePrintPermit,
        }]}
    >
      {printingInProgress ? <InlineLoader /> : (
        <>
          {!printers.length ? (
            <Alert severity="warning">
              <Typography>There are no printers available at this locaton.</Typography>
            </Alert>
          ) : (
            <>
              {
                printingResponse && (
                  <Alert severity={printingResponse.severity}>
                    {printingResponse.severity !== 'success' && <Typography><b>Printer State: </b>{printingResponse.PrinterState}</Typography> }
                    <Typography><b>Result: </b>{printingResponse.Result}</Typography>
                    {printingResponse.severity !== 'success' && <Typography><b>Reasons: </b>{printingResponse.PrinterStateReasons}</Typography> }
                  </Alert>
                )
              }
              <Typography gutterBottom><b>Print Setup</b></Typography>
              <div className={classes.settingsWrapper}>
                <Checkbox
                  onChange={(e) => setMerge(e.target.checked)}
                  checked={merge}
                  color="primary"
                />
                <Typography>Merge permits into a single print job</Typography>
              </div>
              <Typography gutterBottom><b>Select Printer</b></Typography>
              <RadioGroup
                aria-label="printer"
                name="printer"
                value={String(selectedPrinter)}
                onChange={handleSelection}
                className={classes.radioList}
              >
                {printerList.map((printer) => (
                  <FormControlLabel
                    control={<Radio color="primary" />}
                    key={printer.PermitPrinterId}
                    classes={{ label: classes.printerLabel }}
                    label={
                      <PrinterListItem
                        favourite={favouritePrinters.includes(printer.PermitPrinterId)}
                        onToggleFavourite={handleTogglFavouritePrinter}
                        printer={printer}
                        default={false}
                      />
                    }
                    value={String(printer.PermitPrinterId)}
                  >
                  </FormControlLabel>
                ))}
              </RadioGroup>
              <div className={classes.note}>
                <b>Note:</b> 
                <WarningOutlinedIcon
                  classes={{ root: classes.icon }}
                  fontSize="small" color="error" /> Indicate Printer was offline so there is no guarantee for print
              </div>
            </>
          )}
        </>
      )}
    </Dialog >
  );
};

PrintPermitDialog.propTypes = {
  ReferenceId: PropTypes.string.isRequired,
  PermitInstanceId: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  visible: PropTypes.bool.isRequired,
};

export default PrintPermitDialog;