import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import UpdateLocationDialog from './UpdateLocationDialog';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';

import {
  Button,
  Container,
  Paper,
} from '@material-ui/core';

import AppState from '../../actions/app';

const useStyles = makeStyles(theme => {
  return {
    container: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      maxWidth: 500
    },
    box: {
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'column',
      padding: theme.spacing(5),
      marginTop: theme.spacing(20)
    },
    heading: {
      textAlign: 'center',
      marginBottom: theme.spacing(4)
    },
    button: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      marginBottom: theme.spacing(1)
    },
    helpText: {
      textAlign: 'center',
      marginTop: theme.spacing(3)
    }
  }
});

const LocationNotEnabled = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [dialogOpen, setDialogOpen] = React.useState(false);

  const handleLocationUpdate = (location) => {
    dispatch(AppState.actions.setHomeLocation(location))
      .then(dispatch(AppState.actions.setLocationDisabled(false)))
      .then(dispatch(push('/')))
  }

  return (
    <>
      <Container className={classes.container}>
        <Paper className={classes.box}>
          <h4 className={classes.heading}>Location Not Enabled</h4>
          <Button variant="contained" color="primary" className={classes.button} onClick={() => setDialogOpen(true)}>Select Preferred Home Location</Button>
          <div className={classes.helpText}>
            Your current home location is not enabled for E-Permitting. Please choose another location to access the application.
          </div>
        </Paper>
      </Container>
      {dialogOpen && (
        <UpdateLocationDialog
          reduxActionType={AppState.types.setHomeLocation}
          onClose={() => setDialogOpen(false)}
          onUpdate={handleLocationUpdate}
        />
      )}
    </>
  );
}

export default LocationNotEnabled;
