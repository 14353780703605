import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

import {
  Button,
  TableRow,
  TableCell,
  CircularProgress,
} from '@material-ui/core';

type Props = {
  fieldKey: string;
  onDelete: (fieldKey: string) => void;
}

const ListsListItem: React.FC<Props> = (props) => {
  const { fieldKey, onDelete } = props;
  const history = useHistory();

  const [deleting, setDeleting] = React.useState(false);
  const [confirmDeleteActive, setConfirmDeleteOpen] = React.useState(false);

  const handleDeleteClick = async () => {
    setDeleting(true);
    await onDelete(fieldKey);
    setDeleting(false);
  };

  const renderDeleteButtons = () => (
    <>
      <Button size="small" color="secondary" onClick={handleDeleteClick}>
        Delete
      </Button>
      <Button size="small" onClick={() => setConfirmDeleteOpen(false)}>
        Cancel
      </Button>
    </>
  );

  const renderEditDeleteButtons = () => (
    <>
      <Button
        size="small"
        color="primary"
        onClick={() => history.push(`/admin/lists/${fieldKey}/items`)}
      >
        Edit
      </Button>
      <Button size="small" color="secondary" onClick={() => setConfirmDeleteOpen(true)}>
        Delete
      </Button>
    </>
  );

  return (
    <TableRow>
      <TableCell component="th" scope="row">
        {deleting ? <CircularProgress /> : fieldKey}
      </TableCell>
      <TableCell align="right">
        {confirmDeleteActive ? renderDeleteButtons() : renderEditDeleteButtons()}
      </TableCell>
    </TableRow>
  );
};

ListsListItem.propTypes = {
  onDelete: PropTypes.func.isRequired,
  fieldKey: PropTypes.string.isRequired,
};

export default ListsListItem;
