import React from 'react';
import PropTypes from 'prop-types';
import { MenuItem, Select } from '@material-ui/core';

export type PermitFilterTypes = 'all' | 'open';

type Props = {
  onChange: (value: PermitFilterTypes) => void;
  selected?: PermitFilterTypes;
}

const VisibleStatesSelect: React.FC<Props> = (props) => {
  const { onChange, selected } = props;

  return (
    <div>
      <Select
        value={selected}
        onChange={(e) => onChange(e.target.value as PermitFilterTypes)}
      >
        <MenuItem value="open">Open Permits</MenuItem>
        <MenuItem value="all">All Permits</MenuItem>
      </Select>
    </div>
  );
};

VisibleStatesSelect.propTypes = {
  onChange: PropTypes.func.isRequired,
};

export default VisibleStatesSelect;
