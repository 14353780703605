import AuthState from '../actions/auth';

export const initialState = {
  abilities: {},
  isAuthenticated: false,
  isAdmin: false,
  identity: {},
  profile: {},
  preferences: {},
  token: {},
};

export const authReducer = (state = initialState, action) => {
  const { types } = AuthState;

  switch (action.type) {
    case types.setAbilities:
      return {
        ...state,
        abilities: action.payload.abilities,
      };
    case types.setLoggedInUser:
      return {
        ...state,
        isAuthenticated: true,
        identity: action.payload.identity,
        profile: action.payload.profile,
        token: action.payload.token,
      };
    case types.updateUserPreferenceValue:
      return {
        ...state,
        preferences: {
          ...state.preferences,
          ...action.payload,
        },
      };
    case types.updateUserSearchPreferences: 
      return {
        ...state,
        preferences: {
          ...state.preferences, 
          [action.screenType]: action.payload
        },
      };
    case types.updateUserPermissions:
      return {
        ...state,
        ...action.payload,
      };
    case types.updateUserProfileValue:
      return {
        ...state,
        profile: {
          ...state.profile,
          ...action.payload,
        },
      };
    case types.unAuthenticatedMessage:
      return {
        ...state,
        unAuthenticatedMessage: action.payload
      };
    case types.signOut:
      return initialState;
    default:
      return state;
  }
};
