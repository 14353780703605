import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import LocationSelectTree from './LocationSelectTree';
import Dialog from './Dialog';
import InlineLoader from './InlineLoader';

import AppState from '../../actions/app';
import { ActionStatus } from '../../actions/shared/status';

const UpdateLocationDialog = (props) => {
  const { reduxActionType, onClose, onUpdate, selectedLocation } = props;
  const dispatch = useDispatch();
  const loading = useSelector((s) => s.appState.status[reduxActionType]);
  const [selected, setSelected] = React.useState(selectedLocation);

  React.useEffect(() => {
    if (loading === ActionStatus.resolved) {
      dispatch(AppState.actions.resetStatus(reduxActionType));
      onClose();
    }

    return () => {
      dispatch(AppState.actions.resetStatus(reduxActionType));
    };
  }, [loading, dispatch, onClose, reduxActionType]);

  const handleOnChange = () => {
    if (onUpdate) {
      onUpdate(selected);
    }
  };

  return (
    <Dialog
      visible
      title="Update Location"
      onClose={onClose}
      actions={[{
        disabled: !selected || selected.LocationId === 0,
        text: 'Update Location',
        onClick: handleOnChange,
      }]}
    >
      {loading === ActionStatus.pending
        ? <InlineLoader />
        : <LocationSelectTree onChange={(location) => setSelected(location)} />}
    </Dialog>
  );
};

UpdateLocationDialog.defaultProps = {
  selectedLocation: null,
};

UpdateLocationDialog.propTypes = {
  selectedLocation: PropTypes.number,
  onUpdate: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  reduxActionType: PropTypes.string.isRequired,
};

export default UpdateLocationDialog;
