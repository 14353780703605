import React from 'react';
import { useDispatch } from 'react-redux';

import {
  Button,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
} from '@material-ui/core';

import ListsState from '../../../../actions/admin/lists';

import Breadcrumbs from '../../Breadcrumbs';
import useLists from '../../../../hooks/admin/useLists';
import InlineLoader from '../../../shared/InlineLoader';
import NewListForm from './NewListForm';
import ListsListItem from './ListsListItem';

const ListItemsList: React.FC = () => {
  const dispatch = useDispatch();

  const { loading, data } = useLists();
  const [showNewListForm, setShowNewListForm] = React.useState(false);

  return (
    <div>
      <Breadcrumbs crumbs={[{ label: 'Lists' }]} />

      <TableContainer component={Paper}>
        {loading ? <InlineLoader /> : (
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>List</TableCell>
                <TableCell align="right">
                  <Button
                    size="small"
                    variant="outlined"
                    color="primary"
                    onClick={() => setShowNewListForm(true)}
                  >
                    Add List
                  </Button>

                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {showNewListForm && (
                <NewListForm
                  existingFieldKeys={data}
                  onCancel={() => setShowNewListForm(false)}
                  onSubmit={() => setShowNewListForm(false)}
                />
              )}

              {data.map((fieldKey: string) => (
                <ListsListItem
                  key={fieldKey}
                  fieldKey={fieldKey}
                  onDelete={(fk) => dispatch(ListsState.actions.deleteList(fk))}
                />
              ))}
            </TableBody>
          </Table>
        )}
      </TableContainer>
    </div>
  );
};

ListItemsList.defaultProps = {};

ListItemsList.propTypes = {};

export default ListItemsList;
