import React from 'react';
import { get as _get } from 'lodash';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';

import {
  Button,
  Card,
  CardContent,
  Chip,
  Grid,
  Typography,
} from '@material-ui/core';

import { ESWorkOrder } from '../../../types/prop-types';

import { workOrderHasStatus } from '../../../shared/work-orders';
import WorkOrdersState from '../../../actions/work-orders';
import useAbilities from '../../../hooks/auth/useAbilities';

const useStyles = makeStyles((theme) => ({
  card: {
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  createButton: {
    marginTop: theme.spacing(2),
    marginBottom: 0,
  },
  cardContent: {
    paddingBottom: 0,
  },
  statusWrapper: {
    textAlign: 'right',
  },
  statusChip: {
    backgroundColor: theme.palette.warning.light,
    marginRight: theme.spacing(1),
  },
  assetNumber: {
    fontWeight: 'bold',
    marginTop: theme.spacing(1),
  },
  workOrderDescription: {
    marginTop: theme.spacing(2),
  },
  activeStatus: {
    backgroundColor: theme.palette.grey[100],
    opacity: 1,
  },
}));

const WorkOrderListCard = (props) => {
  const { workOrder } = props;

  const history = useHistory();
  const classes = useStyles();
  const abilities = useAbilities();
  const activeStatuses = useSelector(WorkOrdersState.selectors.activeStatuses());

  const statusIsActive = React.useMemo(
    () => workOrderHasStatus(workOrder, activeStatuses),
    [workOrder, activeStatuses],
  );

  const renderStatusChips = (statuses) => {
    if (!Array.isArray(statuses)) {
      return <></>;
    }

    return statuses.map((status) => (
      <Chip
        key={status}
        label={status}
        className={classes.statusChip}
        size="small"
      />
    ));
  };

  return (
    <Card className={`${classes.card} ${statusIsActive ? classes.activeStatus : ''}`}>
      <CardContent className={classes.cardContent}>
        <Grid container>
          <Grid item xs={6}>
            <Typography variant="h4">{workOrder.workOrderNumber}</Typography>
            <Typography
              gutterBottom
              className={classes.assetNumber}
              color="textSecondary"
              variant="subtitle2"
            >
              {workOrder.assetNumber}
            </Typography>
            <Typography gutterBottom variant="subtitle2" color="textSecondary">{workOrder.assetDescription}</Typography>
          </Grid>
          <Grid item xs={6} className={classes.statusWrapper}>
            {renderStatusChips(workOrder.workOrderStatus)}
          </Grid>

          <Grid item xs={12}>
            <Typography gutterBottom variant="body1" className={classes.workOrderDescription}>
              {workOrder.workOrderDescription}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            {statusIsActive && _get(abilities, 'permits.edit') && (
              <Button
                size="small"
                variant="contained"
                className={classes.createButton}
                onClick={() => history.push('/permits/create', { workOrder })}
              >
                Create Permit
              </Button>
            )}
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

WorkOrderListCard.propTypes = {
  workOrder: ESWorkOrder.isRequired,
};

export default WorkOrderListCard;
