import { v4 as uuid } from 'uuid';

export const SessionId = uuid();

const AppConfig = {
  App: {
    /* Enable same sign on by automatically redirecting the application to the SAML SSO provider */
    automaticFederatedSigninRedirectEnabled: false,
    /* log level */
    logLevel: process.env.REACT_APP_LOG_LEVEL || window?.LOG_LEVEL || 'INFO',
    /* kinesis stream name to stream logs to */
    kinesisStreamName: process.env.REACT_APP_KINESIS_STREAMNAME || 'epermitting-development-ui-logstream',
  },
  API: {
    baseUrl: process.env.REACT_APP_BASEURL_AWS,
    throttleLength: process.env.REACT_APP_ThrottleLength || 900, // 15 minutes
  },
  Cache: {
    /* Amount of time between when the app checks for permit updates */
    expirePermitsAfter: 60000 * 15, // 15 minutes
    /* Amount of time between when the app refreshes the location metadata */
    expireLocationMetadataAfter: 60000 * 5, // 5 minutes
  },
  Permits: {
    /* Amount of time between saving a permit draft */
    saveDraftInterval: 20000, // 20 seconds
  },
  FormIO: {
    serverUrl: process.env.REACT_APP_FORM_IO_SERVER_URL || 'http://form-io.nutrien.com',
    baseUrl: process.env.REACT_APP_PERMITS_BASEURL,
    projectUrl: process.env.REACT_APP_PERMITS_BASEURL,
  },
  Amplify: {
    Auth: {
      adfsFederatedIdentityProviderId: process.env.REACT_APP_ADFSFederatedIdentityProvider || 'nutrien-e-permitting-ad',
      region: process.env.REACT_APP_AmplifyAuthRegion || 'us-east-2',
      userPoolWebClientId: process.env.REACT_APP_AmplifyAuthUserPoolWebClientId || '1brehhom2juekumfnikq74nsr5',
      userPoolId: process.env.REACT_APP_AmplifyAuthUserPoolId || 'us-east-2_0lVhYTSgR',
      identityPoolId: process.env.REACT_APP_AmplifyAuthIdenitityPoolId || 'us-east-2:db449005-3f86-467f-9c13-5e7f5881aa53',
      oauth: {
        domain: process.env.REACT_APP_AmplifyOauthDomain || 'work-permit-dev.auth.us-east-2.amazoncognito.com',
        scope: ['email', 'profile', 'openid'],
        redirectSignIn: process.env.REACT_APP_AmplifyOauthRedirectSignInUrl || 'http://localhost:5000/',
        redirectSignOut: process.env.REACT_APP_AmplifyOauthRedirectSignOutUrl || 'http://localhost:5000/auth/signout',
        responseType: 'code',
      },
    },
    Storage: {
      AWSS3: {
        bucket: process.env.REACT_APP_AmplifyStorageBucket || 'eaoperations-epermitting-app-data-dev',
        identityPoolId: process.env.REACT_APP_AmplifyStorageIdentityPoolId || 'us-east-2:db449005-3f86-467f-9c13-5e7f5881aa53',
        region: process.env.REACT_APP_AmplifyStorageRegion || 'us-east-2',
      },
    },
    Analytics: {
      AWSKinesis: {
        region: 'us-east-2',
        bufferSize: 10,
        flushSize: 100,
        flushInterval: 5000,
        resendLimit: 5
      }
    }
  },
};

export default AppConfig;
