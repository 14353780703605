import PermitSearchActions from '../../actions/permits/search';

const initialState = {
  error: null,
  list: [],
  loading: false,
  page: 0,
};

// eslint-disable-next-line import/prefer-default-export
export const searchReducer = (state = initialState, action) => {
  const { types } = PermitSearchActions;

  switch (action.type) {
    case types.setSearchResults:
      return {
        ...state,
        list: action.payload.list,
        total: action.payload.total,
        page: action.payload.page,
        loading: false,
      };
    case types.setLoading:
      return {
        ...state,
        loading: action.payload,
      };
    case types.clearSearchResults:
      return {
        ...state,
        list: [],
      };
    default:
      return state;
  }
};
