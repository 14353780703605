import { useSelector } from 'react-redux';

import AppState from '../../actions/app';
import AuthState from '../../actions/auth';

const useWithUserPreferences = () => {
  const homeLocation = useSelector(AppState.selectors.homeLocation());
  const preferences = useSelector(AuthState.selectors.preferences());
  const permissions = useSelector(AuthState.selectors.permissions());
  const profile = useSelector(AuthState.selectors.profile());

  return { homeLocation, preferences, permissions, profile };
};

export default useWithUserPreferences;
