import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import {
  Card,
  CardContent,
  Typography,
  IconButton,
  Tooltip,
} from '@material-ui/core';

import { Assessment as AssessmentIcon } from '@material-ui/icons';

import useWithUserPreferences from '../../hooks/auth/useWithUserPreferences';
import useAbilities from '../../hooks/auth/useAbilities';
import useAnalytics from '../../hooks/shared/useAnalytics';
import { SessionId } from '../../config/app-config';

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: 220,
    padding: theme.spacing(1),
  },
  title: {
    fontSize: 14,
    marginBottom: 12,
  },
  pos: {
    fontSize: 14,
  },
  content: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  details: {

  },
  actions: {

  }
}));

const UserDetails = () => {
  const recordEvent = useAnalytics();
  const { profile } = useWithUserPreferences();
  const abilities = useAbilities();
  const classes = useStyles();

  const diagnosticInformation = React.useMemo(() => {
    return JSON.stringify({
      Profile: profile,
      CalculatedUserAbilities: abilities,
      SessionId: SessionId,
    });
  }, [abilities, profile])

  const [tooltipText, setTooltipText] = React.useState('Copy User Details');

  const handleCopy = () => {
    if (navigator) {
      navigator?.clipboard.writeText(diagnosticInformation);
      recordEvent({ message: 'User copied user diagnostics details', diagnosticInformation });
      setTooltipText('Copied!')
    } else {
      recordEvent({ message: 'User attempted to copy diagnostics details but failed as navigator is not defined', diagnosticInformation });
      setTooltipText('Failed to copy details');
    }
  };

  const handleTooltipReset = () => {
    // wait half a second as the tiptip resets while the user's mouse is over
    // the material ui ripple border
    setTimeout(() => {
      setTooltipText('Copy User Details');
    }, 500);
  };

  return (
    <Card className={classes.root}>
      <CardContent className={classes.content}>
        <div className={classes.details}>
          <Typography className={classes.title} color="primary" gutterBottom>
            Current User
          </Typography>
          <Typography variant="h5" component="h2">
            {profile.givenName} {profile.surname}
          </Typography>
          <Typography className={classes.pos} color="textSecondary">
            {profile.email}
          </Typography>
        </div>
        <div className={classes.actions}>
          <IconButton
            disableRipple
            onClick={handleCopy}
            onMouseOut={handleTooltipReset}
          >
            <Tooltip title={tooltipText} aria-label="can-view-permits">
              <AssessmentIcon />
            </Tooltip>
          </IconButton>
        </div>
      </CardContent>
    </Card>
  );
};

export default UserDetails;

