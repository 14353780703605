import { useDispatch, useSelector } from 'react-redux';
import PermitsSearchState from '../../actions/permits/search';

const usePermitSearch = () => {
  const dispatch = useDispatch();
  const { actions, selectors } = PermitsSearchState;

  const search = useSelector(selectors.search);
  return [
    (...props) => dispatch(actions.searchPermits(...props)),
    search,
    () => dispatch(actions.clearResults()),
  ];
};

export default usePermitSearch;
