import React from 'react';
import { Typography, TextField } from '@material-ui/core';
import { useDispatch } from 'react-redux';

import Dialog from '../../../components/shared/Dialog';
import PermitsState from '../../../actions/permits';
import InlineLoader from '../../../components/shared/InlineLoader';

const RevokeAllPermitDialog = (props) => {
  const {
    PermitInstanceIds,
    onClose,
    visible
  } = props;

  const dispatch = useDispatch();

  const [reasonText, setReasonText] = React.useState('');

  const handleRevokePermit = async () => {
    const { actions } = PermitsState;
    dispatch(actions.revokeAllPermits(PermitInstanceIds, reasonText))
    onClose(true);
  }

  return (
    <Dialog
      onClose={onClose}
      visible={visible}
      title={`Revoke All Selected Permits`}
      actions={[{
        text: 'Revoke',
        color: 'primary',
        disabled: false,
        onClick: handleRevokePermit
      }]}
    >
        <>
          <Typography gutterBottom>Enter the reason for revoking all these permits</Typography>
          <TextField
            variant="outlined"
            multiline={true}
            cols={12}
            rows={2}
            style={{ width: '100%' }}
            value={reasonText}
            onChange={e => setReasonText(e.target.value)}
          />
        </>
    </Dialog>
  );
}

export default RevokeAllPermitDialog;
