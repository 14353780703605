import React from 'react';
import { Button } from '@material-ui/core';

import BaseAccordian from './BaseAccordian';
import LeafletMap from '../../shared/LeafletMap';

export type Coordinates = {
  lat: number;
  lng: number;
}

type Props = {
  toggleAccordian: (name?: string) => any;
  expanded: boolean;
  name: string;
  onChange: (location: Coordinates) => void;
  workLocation: Coordinates;
}

const WorkLocationAccordian: React.FC<Props> = (props) => {
  const { expanded, name, onChange, toggleAccordian, workLocation } = props;

  const workLocationSet = workLocation.lat > 0 && workLocation.lng > 0;
  const [currentLocation, setCurrentLocation] = React.useState<Coordinates>(workLocation);

  const actions = (
    <>
      <Button
        size="small"
        color="primary"
        onClick={() => onChange(currentLocation)}
        disabled={currentLocation.lat === 0 && currentLocation.lng === 0}
        >
        {workLocationSet ? 'Update ' : 'Set '}  Work Location
      </Button>
    </>
  );

  return (
    <BaseAccordian
      actions={actions}
      expanded={expanded}
      name={name}
      heading="Work Location"
      onChange={toggleAccordian}
      secondaryHeading={workLocationSet ? 'Location set' : 'None selected'}
    >
      <LeafletMap
        markers={workLocationSet ? [{
          lat: workLocation.lat,
          lng: workLocation.lng,
          onMarkerMove: setCurrentLocation,
        }] : []}
        onMarkerMove={!workLocationSet ? setCurrentLocation : undefined}
        useInitialMarker={!workLocationSet}
        readOnly={false}
      />
    </BaseAccordian>
  );
}

export default WorkLocationAccordian;
