// eslint-disable-next-line import/prefer-default-export
export const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

// https://stackoverflow.com/a/8084248
// This is not an overly trustyworthy random string, but works well for random strings for object keys in components
export const generateRandomString = () => Math.random().toString(36).slice(2);

/**
 * Checks if a string ends in an number
 * From: https://stackoverflow.com/a/14294295
 * @param {string} string - String to test
 * @return {boolean}
 */
export const endsWithNumber = (string) => {
  if (!string) {
    throw new Error('string param is missing');
  }

  if (typeof string !== 'string') {
    throw new Error('string param must be of type string');
  }

  const regex = /^.*?(\d+(?:[.,]\d+)?)\s*$/;
  return string.match(regex) !== null;
};

/**
 * Returns a string in start case with the first letter in uppercase
 * @param {string} str - A string with camelcase formatting
 * @return {string} - A string formatted in start case
 */
export const formatAsStartCase = (str) => {
  const result = str.replace(/([A-Z])/g, " $1");
  return result.charAt(0).toUpperCase() + result.slice(1);
}
