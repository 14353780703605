import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

import adminReducer from './admin';
import { appReducer as appConfigReducer } from './app';
import { authReducer } from './auth';
import { workOrdersReducer } from './work-orders';
import { permitsReducer } from './permits';
import locationsReducer from './locations';
import { definitionsReducer } from './definitions';

import authActions from '../actions/auth';

/**
 * Combines all reducers and returns a single reducer to handle app state
 * @param {History} history - The browser history object created from createBrowserHistory
 * @return {Reducer} the combined app reducer
 */

const reducers = (history) => {
  const appReducer = combineReducers({
    admin: adminReducer,
    auth: authReducer,
    appState: appConfigReducer,
    definitions: definitionsReducer,
    locations: locationsReducer,
    permits: permitsReducer,
    router: connectRouter(history),
    workOrders: workOrdersReducer,
  });

  return (state, action) => {
    const { types } = authActions;
    if (action.type === types.signOut) {
      state = undefined;
    }

    return appReducer(state, action);
  };
};

export default reducers;
