import React, { useEffect, forwardRef, useImperativeHandle} from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import AssessmentIcon from '@material-ui/icons/Assessment';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { throttle as _throttle } from 'lodash';

import { queryElasticSearchWorkOrders } from '../../shared/work-orders';
import useLocations from '../../hooks/locations/useLocations';


const useStyles = makeStyles((theme) => ({
  icon: {
    color: theme.palette.text.secondary,
    marginRight: theme.spacing(2),
  },
}));

type WorkOrderTypeAheadInputType = {
  onChange: (w: any) => any;
  value?: any
}

const WorkOrderTypeAheadInput = forwardRef((props:WorkOrderTypeAheadInputType, ref) => {
  const { onChange, value } = props;

  const classes = useStyles();
  // const [selectedValue, setSelectedValue] = React.useState(defaultValue || null);
  const [inputValue, setInputValue] = React.useState('');
  const [options, setOptions] = React.useState([]);
  const { homeLocation } = useLocations();

  const fetch = React.useMemo(
    () => _throttle((request, callback) => {
      const promiseObj = queryElasticSearchWorkOrders(request, {}, homeLocation.SiteId) as any;
      promiseObj.then(callback);
    }, 500),
    [homeLocation.SiteId],
  );

  useEffect(() => {
    if (!value) {
      setInputValue('');
    }
  }, [value]);

  const clearState = () => {
    setInputValue('')
    setOptions([])
  }

  useImperativeHandle(ref, () => ({
    clearState
  }))


  useEffect(() => {
    let active = true;

    if (inputValue === '') {
      setOptions(value ? [value] : [] as any);
      return undefined;
    }

    fetch(inputValue, (response: any) => {
      // eslint-disable-next-line no-underscore-dangle
      const results = response.hits.hits.map((h:any) => h._source);

      if (active) {
        let newOptions:any = [];

        if (value) {
          newOptions = [value];
        }

        if (results) {
          newOptions = [...newOptions, ...results];
        }

        setOptions(newOptions);
      }
    });

    return () => {
      active = false;
    };
  }, [value, inputValue, fetch]);

  return (
    <Autocomplete
      id="workorder-select"
      style={{ width: '100%', marginBottom: '2rem' }}
      getOptionLabel={(option) => (
        typeof option === 'string'
          ? option
          : `${option.workOrderNumber} - ${option.workOrderDescription}`
      )}
      options={options}
      noOptionsText="Enter a number or detail"
      autoComplete
      includeInputInList
      filterSelectedOptions
      value={value}
      onChange={(event, newValue) => {
        setOptions(newValue ? [newValue, ...options] : options as any);
        onChange(newValue);
      }}
      onInputChange={(event, newInputValue) => setInputValue(newInputValue)}
      inputValue={inputValue}
      renderInput={(params) => (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <TextField {...params} variant="outlined" label="Select work order" fullWidth style={{ marginTop: 20 }} />
      )}
      renderOption={(option) => {
        return (
          <Grid container alignItems="center">
            <Grid item>
              <AssessmentIcon className={classes.icon} />
            </Grid>
            <Grid item xs>
              <Typography variant="body2" color="textSecondary">
                <b>{option.workOrderNumber}</b>
                {' '}
                -
                {' '}
                {option.workOrderDescription}
              </Typography>
            </Grid>
          </Grid>
        );
      }}
    />
  );
});


export default WorkOrderTypeAheadInput;
