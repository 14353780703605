import React from 'react';
import { get as _get } from 'lodash';

import { PermitDefinition, DefinitionForm } from '../../types/definition';
import useDefinition from '../definitions/useDefinition';

type FormsSelectOptions = { id: string, label: string }[];

type HookResponse = {
  definition: PermitDefinition | null;
  forms: DefinitionForm[];
  selectOptions: FormsSelectOptions;
}

const useDefinitionForTemplates = (): HookResponse => {
  const { data: definitionData } = useDefinition();

  const definition = React.useMemo(() => {
    if (!definitionData) return null;
    return definitionData.definition;
  }, [definitionData]);

  const forms = React.useMemo(() => {
    return _get(definition, 'DefinitionMetadataJSON.Forms', []) as DefinitionForm[];
  }, [definition]);

  const selectOptions = React.useMemo(() => {
    if (!forms) return [];

    return forms.reduce((all, form) => [
      ...all,
      { id: form.Name, label: form.PermitType },
    ], [] as FormsSelectOptions);
  }, [forms]);

  return { definition, forms, selectOptions };
};

export default useDefinitionForTemplates;
