import { uniqWith as _uniqWith } from 'lodash';

import WorkOrdersState from '../actions/work-orders';

export const initialState = {
  /* Work orders are enabled at this site */
  enabled: false,
  /* Selected Work order*/
  selected: null,
  /* List of work orders */
  list: [],
  /* Work orders list filters */
  workOrderFilter: {
    status: [],
    text: '',
  },
  /* work order statuses. These change depending on whether the site uses SAP or Oracle */
  statuses: {
    list: [],
    active: [],
  },
};

export const workOrdersReducer = (state = initialState, action) => {
  const { types } = WorkOrdersState;
  switch (action.type) {
    case types.clearList:
      return {
        ...state,
        list: initialState.list,
      };
    case types.setList:
      return {
        ...state,
        list: action.replace
          ? action.payload
          : _uniqWith(
            [...state.list, ...action.payload],
            (a, b) => a.workOrderNumber === b.workOrderNumber,
          ),
      };
    case types.filter:
      return {
        ...state,
        workOrderFilter: {
          ...state.workOrderFilter,
          [action.payload.filterBy]: action.payload.values,
        },
      };
    case types.setStatuses:
      return {
        ...state,
        statuses: action.payload,
        workOrderFilter: {
          ...state.workOrderFilter,
          status: action.payload.active,
        },
      };
    case types.select:
      return {
        ...state,
        selected: action.payload,
      };
    case types.setEnabled:
      return {
        ...state,
        enabled: action.payload,
      };
    default:
      return state;
  }
};
