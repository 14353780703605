import React, { useEffect, useRef, useState } from 'react';
import moment from 'moment';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Grid, Paper, Typography, Select, MenuItem, FormControl, TextField, FormLabel, InputLabel, FormControlLabel, Switch } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import { useDispatch } from 'react-redux';
import useSetPageName from '../../hooks/shared/useSetPageName';


import SearchPermitsFilters from './shared/SearchPermitsFilters';
import PermitListCard from './shared/PermitListCard';
import InlineLoader from '../shared/InlineLoader';
import SearchTermsInput from './shared/SearchTermsInput';
import Dialog from '../shared/Dialog';
import { useGetScreenFilters } from '../../hooks/shared/useSaveFilters';

import usePermitSearch from '../../hooks/permits/usePermitSearch';
import useLocations from '../../hooks/locations/useLocations';

import AuthState from '../../actions/auth';

import {generateInitialInputsValue} from './shared/SearchTermsInput';
import useExpandAll from '../../hooks/permits/useExpandCard';

const getDates = (type) => {
  if(type === 'today') {
    return { fromDate: moment().format('YYYY-MM-DD'), toDate: moment().format('YYYY-MM-DD') }
  } else if (type === 'week') {
    return { fromDate: moment().startOf('week').format('YYYY-MM-DD'), toDate: moment().endOf('week').format('YYYY-MM-DD') }
  } else if (type === 'month') {
    return { fromDate:moment().startOf('month').format('YYYY-MM-DD'), toDate: moment().endOf('month').format('YYYY-MM-DD') }
  }
  return {}
}

const formatePayload = (activeFilter, filter) => {
  const { Name, Id, Active } = activeFilter;
  const { searchTerms, PermitType, State, fromDate, toDate, dateType, Location, permitIssuer, permitReceiver } = filter;
  return {
    Name,
    Id,
    Active,
    Filter: {
      searchTerms,
      PermitType,
      State,
      fromDate,
      toDate,
      dateType,
      locationId: Location?.LocationId || null,
      permitIssuer,
      permitReceiver
    }
  }
};

const initFilter = { 
  State: [], 
  PermitType: [], 
  fromDate: '', 
  toDate: '', 
  permitReceiver: '', 
  permitIssuer: ''
}

const useStyles = makeStyles((theme) => ({
  searchBoxWrapper: {
    backgroundColor: 'white',
    paddingTop: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingBottom: 0,
  },
  searchInput: {
    width: '100%',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  searchButton: {
    textAlign: 'right',
  },
  resultsText: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: theme.spacing(2)

  },
  linkDiv: {
    '&:hover': {
      cursor: 'pointer',
      textDecoration: 'underline',
    },
  },
  controlpanel: {
    marginBottom: theme.spacing(3)
  },
  dropDownContainer: {
    padding: theme.spacing(1),
  },
  dropDown: {
    width: '100%'
  },
  btnGroup: {
    display: 'flex',
    '& button': {
      marginRight: theme.spacing(2),
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2)
    },
    '& button:last-child': {
      marginRight: theme.spacing(0),
    },
    '& label': {
      margin: 0
    }
  },
  menuItem: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  searchInput: {
    width: '100%',
    paddingLeft: theme.spacing(1),
    marginBottom: 0
  },
}));

const PermitList = () => {
  useSetPageName('Search Permits');
  const searchTermsInputRef = useRef();
  const workOrderTypeAheadInputRef = useRef();
  const dispatch = useDispatch();
  const styles = useStyles();
  const { locations } = useLocations();
  const { activeFilter = {}, filterList, query, fromReporting } = useGetScreenFilters({screen: 'SearchPermits'});

  const filterLoaded = Object.keys(activeFilter).length
  const { Filter: filterData, Id } = activeFilter;
  let Filter = filterData;

  if (Array.isArray(filterData)) {
    Filter = filterData && filterData[0];
  }

  useEffect(() => {
    if (activeFilter?.Name) {
      updateFilterName(activeFilter.Name)
    }
  }, [activeFilter?.Name])

  const [searchPerformed, setSearchPerformed] = useState(false);
  const [searchPermits, { list, loading, total }, resetSearchState] = usePermitSearch();
 const { expandCard, updateExpandState } = useExpandAll();

  const [showModal, toggleModal] = useState(false);
  const [showDeleteModal, toggleDeleteModal] = useState(false);
  const [showCreateModal, toggleCreateModal] = useState(false);

  const [filterName, updateFilterName] = useState(activeFilter?.Name);
  const [createFilterName, updateCreateFilterName] = useState('');

  const [searchTerms, setSearchTerms] = useState([]);
  
  const [filters, setFilters] = useState(initFilter);

  const fetchSearchResults = (loadMore = false) => {
    searchPermits(searchTerms, { ...filters, ...getDates(filters.dateType) }, loadMore);
    setSearchPerformed(true);
  };

  useEffect(() => {
    if (fromReporting) {
      setFilters({
        State: query.State ? query.State.split(',') : [],
        PermitType: query.PermitType ? query.PermitType.split(',') : [],
        workOrderNumber: query.workOrderNumber,
        fromDate: query.fromDate,
        toDate: query.toDate,
        ...(query.locationId ? { Location: locations.find((l) => l.LocationId === Number(query.locationId)) } : {}),
      });
      searchPermits([], query);
      setSearchPerformed(true);
    } else if (Filter && Object.keys(Filter).length) {
      const data = { ...Filter, Id, ...getDates(Filter.dateType) }
      searchPermits(Filter.searchTerms, data, false);
      setSearchPerformed(true);
      if (Filter.searchTerms?.length) {
        searchTermsInputRef.current.clearState(generateInitialInputsValue(Filter.searchTerms));
      }
      const Location = (Filter.locationId || Filter.locationId === 0) && locations.find((l) => l.LocationId === Number(Filter.locationId));
      setFilters({...Filter, Id, ...getDates(Filter.dateType), Location })
      setSearchTerms(Filter.searchTerms);
    }
    return () => {
      resetSearchState();
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeFilter.Id || fromReporting]);

  const updateFilters = (nextFilters) => {
    setFilters((f) => ({ ...f, ...nextFilters }));
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      fetchSearchResults();
    }
  };

  const renderPermitsCount = () => {
    return list.length > 0
      ? (
        <div className={styles.resultsText}>
          <div><b>{total}</b>{' '}matches found</div>
          <div className={styles.btnGroup}>
            <FormControlLabel
                control={<Switch color="secondary"  checked={!!expandCard} onChange={() => updateExpandState(!expandCard)}/>}
                label="Expand All Cards"
                labelPlacement="end"
              />
          </div> 
        </div>
      ) : (
        <Alert severity="warning">
          <Typography>No permits matched the search query. Please adjust the filters and try again</Typography>
        </Alert>
      );
  };

  const renderNoSearchPerformed = () => (
    <Alert severity="info">
      <Typography>Perform a search using the filters in the sidebar and then press search.</Typography>
    </Alert>
  );

  const renderedPermits = React.useMemo(
    () => list.map((permit) => (
      <PermitListCard
        key={permit.PermitInstanceId}
        permit={permit}
        expandCard={expandCard}
      />
    )),
    [list, expandCard],
  );

  const onSaveAndSearch = () => {
    const updatedFilter = formatePayload(activeFilter, {...filters, searchTerms });
    dispatch(AuthState.actions.updateFilterByScreen(updatedFilter));
    fetchSearchResults();
  }

  const onCloseDialog = () => {
    toggleModal(false);
  }

  const onCloseDeleteModal = () => {
    toggleDeleteModal(false)
  }

  const onFilterSelect = (event) => {
    dispatch(AuthState.actions.setActiveFilterByScreen(event.target.value))
  }
  
  const onSaveFilter = () => {
    const updatedFilter = formatePayload({...activeFilter, Name: filterName }, {...filters });
    dispatch(AuthState.actions.updateFilterByScreen(updatedFilter))
    toggleModal(false);
  };

  const onCreateAndSearch = () => {
    toggleCreateModal(true);
  }

  const onCloseCreateDialog = () => {
    updateCreateFilterName('');
    toggleCreateModal(false);
  }

  const onCreateFilter = () => {
    const updatedFilter = formatePayload({ Name: createFilterName, Active: true}, {...filters });
    dispatch(AuthState.actions.createFilterByScreen(updatedFilter));
    updateCreateFilterName('');
    toggleCreateModal(false);
    fetchSearchResults();
  }

  const onEditFilter = () => {
    toggleModal(true);
  }

  const onDeleteFilter = () => {
    dispatch(AuthState.actions.deleteFilterByScreen(activeFilter.Id));
    toggleDeleteModal(false);
    setFilters({...initFilter});
    setSearchTerms([])
  }

  const onPermitIssuerChange = (e) => {
    setFilters({...filters, permitIssuer: e.target.value})
  }

  const onPermitReceiverChange = (e) => {
    setFilters({...filters, permitReceiver: e.target.value})
  }

  return (
    <div className="permit-search">
      <Grid container spacing={2}>
        <Grid item xs={12} md={3}>
          {
            !!filterLoaded && !fromReporting && (
              <div className={styles.controlpanel}>
                <Paper elevation={0} className={styles.searchBoxWrapper}>
                  <div className={styles.dropDownContainer}>
                  <FormControl className={styles.formControl} fullWidth>
                    <InputLabel>Select Filter</InputLabel >
                    <Select
                      className={styles.dropDown}
                      labelId="demo-simple-select-label"
                      id="filter-select"
                      value={activeFilter.Id || ''}
                      label={activeFilter.Name}
                      onChange={onFilterSelect}
                    >
                      { filterList.map(data => <MenuItem key={data.Id} value={data.Id} className={styles.menuItem}>{data.Name}</MenuItem>) }
                    </Select>
                    </FormControl>
                  </div>
                  <div className={styles.btnGroup}>
                    <Button color="primary" size="small" variant="contained" onClick={onEditFilter}>Edit</Button>
                    <Button color="secondary" size="small" variant="contained" onClick={() => toggleDeleteModal(true)}>Delete</Button>
                  </div>
                </Paper>
              </div>
            )
          }

          <Paper elevation={0} className={styles.searchBoxWrapper}>
            <SearchTermsInput
              ref={searchTermsInputRef}
              searchTerms={searchTerms}
              onChange={setSearchTerms}
              onKeyDown={handleKeyDown}
            />
          </Paper>
          <Paper elevation={0} className={styles.searchBoxWrapper}>
            <TextField
              className={styles.searchInput}
              value={filters.permitIssuer}
              placeholder="Permit Issuer"
              onChange={onPermitIssuerChange}
              onKeyDown={onPermitIssuerChange}
              helperText="Eg: John Doe"
            />
          </Paper>
          <Paper elevation={0} className={styles.searchBoxWrapper}>
            <TextField
              className={styles.searchInput}
              value={filters.permitReceiver}
              placeholder="Permit Receiver"
              onChange={onPermitReceiverChange}
              onKeyDown={onPermitReceiverChange}
              helperText="Eg: John Doe"
            />
          </Paper>
        
          <Paper elevation={0} className={styles.searchBoxWrapper}>
            <SearchPermitsFilters
              fromReporting={fromReporting}
              ref={workOrderTypeAheadInputRef}
              filters={filters}
              handleUpdate={() => fetchSearchResults(false)}
              onChange={updateFilters}
              onSaveAndSearch={onSaveAndSearch}
              onCreateAndSearch={onCreateAndSearch}
              filterLoaded={!!filterLoaded}
              totalFilter={filterList?.length}
              onClearClick={() => {
                setFilters({ ...initFilter });
                searchTermsInputRef.current.clearState()
                workOrderTypeAheadInputRef.current.clearState()
                setSearchTerms(undefined)
                setSearchPerformed(false);
                window.history.pushState('', '', `${window.location.pathname}`);
              }}
              disabled={loading}
            />
          </Paper>
        </Grid>
        <Grid item xs={12} md={9}>
          {searchPerformed ? (
            <>
              {!loading && renderPermitsCount()}
              {list.length > 0 && renderedPermits}
              {loading && <InlineLoader />}
              {!loading && total && list.length < total ? (
                <Button
                  className={styles.searchButton}
                  variant="contained"
                  onClick={() => fetchSearchResults(true)}
                  size="medium"
                  color="primary"
                >
                  Load more
                </Button>
              ) : <></>}
            </>
          ) : renderNoSearchPerformed()}
        </Grid>
      </Grid>
      {
        showModal && 
        <Dialog
          visible
          onCancel={onCloseDialog}
          onClose={onCloseDialog}
          title="Edit Filter"
          actions={[{
            text: 'Save',
            color: 'primary',
            disabled: !filterName?.length,
            onClick: onSaveFilter
          }]}
        >
          <FormControl component="fieldset">
            <FormLabel component="legend">Please enter filter name: </FormLabel>
            <TextField value={filterName} onChange={(e) => updateFilterName(e.target.value)} />
          </FormControl>
        </Dialog>
      }
      {
        showCreateModal && 
        <Dialog
          visible
          onCancel={onCloseCreateDialog}
          onClose={onCloseCreateDialog}
          title="Create Filter"
          actions={[{
            text: 'Save',
            color: 'primary',
            disabled: !createFilterName?.length,
            onClick: onCreateFilter
          }]}
        >
          <FormControl component="fieldset">
            <FormLabel component="legend">Please enter filter name: </FormLabel>
            <TextField value={createFilterName} onChange={(e) => updateCreateFilterName(e.target.value)} />
          </FormControl>
        </Dialog>
      }
      {
        showDeleteModal && 
        <Dialog
          visible
          onCancel={onCloseDeleteModal}
          onClose={onCloseDeleteModal}
          title="Delete Filter"
          actions={[{
            text: 'Delete',
            color: 'primary',
            disabled: loading,
            onClick: onDeleteFilter
          }]}
        >
        <Typography>Are you sure, you want to delete filter ?</Typography>   
        </Dialog>
      }
    </div>
  );
};

export default PermitList;
