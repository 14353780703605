import { Button, Paper, makeStyles } from '@material-ui/core';

import React from 'react';
import { useHistory } from 'react-router-dom';
import ExternalUsersTable from './ExternalUsersTable';
import Breadcrumbs from '../../Breadcrumbs';

type Props = {}

const useStyles = makeStyles((theme) => ({
  wrapper: {
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
}));

const UsersList: React.FC<Props> = () => {
  const classes = useStyles();
  const history = useHistory();

  return (
    <>
      <Breadcrumbs crumbs={[{ label: 'External Users' }]} />
      <Paper elevation={1} className={classes.wrapper}>
        <Button
          size="small"
          color="primary"
          variant="outlined"
          onClick={() => history.push('/admin/external-users/create')}
        >
          Invite User
        </Button>
      </Paper>

      <ExternalUsersTable />
    </>
  );
};

export default UsersList;
