import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'connected-react-router';
import { makeStyles } from '@material-ui/core/styles';

import {
  Card,
  CardActions,
  CardContent,
  Chip,
  Collapse,
  Divider,
  Fade,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Tooltip,
  Typography,
} from '@material-ui/core';

import {
  Description as DescriptionIcon,
  ExpandMore as ExpandMoreIcon,
  FileCopy as FileCopyIcon,
  MoreVert as MoreVertIcon,
  OfflinePin as OfflinePinIcon,
  PlaylistAddCheck as ApprovalIcon,
  Print as PrintIcon
} from '@material-ui/icons';

import Collections from '../../../store/indexeddb';
import InlineLoader from '../../shared/InlineLoader';
import PermitStateChip from './PermitStateChip';
import PermitsState from '../../../actions/permits';
import { ESPermit } from '../../../types/prop-types';
import useAbilities from '../../../hooks/auth/useAbilities';
import usePermitIssuer from '../../../hooks/permits/usePermitIssuer';
import ApprovalsDialog from './ApprovalsDialog';
import useLocationPermitSettings from '../../../hooks/shared/useLocationPermitSettings';
import PrintDialog from './PrintPermitDialog';

const useStyles = makeStyles((theme) => ({
  cardFooter: {
    justifyContent: 'center',
    padding: 0,
  },
  expand: {
    transform: 'rotate(0deg)',
    transition: theme.transitions.create('tranform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  card: {
    margin: '10px 0',
    '& h6': {
      fontSize: '1rem'
    }
  },
  approvalRequired: {
    backgroundColor: '#ffe6e6',
  },
  approvalRequiredChip: {
    paddingLeft: '4px',
    color: 'white',
    background: '#ff7373',
    marginBottom: '0.8rem',
  },
  permitType: {
    backgroundColor: theme.palette.warning.light,
    marginRight: theme.spacing(1),
  },
  menu: {
    alignItems: 'flex-end',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  status: {
    alignItems: 'center',
    display: 'flex',
  },
  menuIcon: {
    marginRight: theme.spacing(1),
    fontSize: 20,
  },
  menuDivider: {
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(1),
  },
  offlineEnabled: {
    color: theme.palette.secondary.main,
  },
  secondRow: {
    marginTop: theme.spacing(1.5),
  },
  inline: {
    [theme.breakpoints.only('xs')]: {
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'row',
      '& h6': {
        width: 100,
        fontSize: '1rem'
      },
    },
    [theme.breakpoints.only('sm')]: {
      fontSize: '1rem',
    },
    [theme.breakpoints.up('md')]: {
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'row',
      '& h6': {
        fontSize: '1.25rem',
        marginRight: theme.spacing(2),
      },
    },
  },
  inlineFooter: {
    [theme.breakpoints.only('xs')]: {
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'row',
      '& h6': {
        width: 125,
        fontSize: '1rem'
      },
    },
  },
  content: {
    paddingBottom: 0,
  },
  permitTypes: {
    paddingLeft: theme.spacing(0),
  },
  danger: {
    color: '#dc3545'
  }
}));

const PermitCard = ({ permit, expandCard }) => {
  const classes = useStyles();
  const abilities = useAbilities();
  const permitIssuerName = usePermitIssuer(permit);
  const locationPermitSettings = useLocationPermitSettings();

  const [expanded, setExpanded] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleMoreClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMoreClose = () => {
    setAnchorEl(null);
  };

  const dispatch = useDispatch();
  const { online } = useSelector((s) => s.offline);
  const [isLoading, setIsLoading] = React.useState(false);

  const [availableOffline, setAvailableOffline] = React.useState(false);
  const [approvalsDialogOpen, setApprovalsDialogOpen] = React.useState(false);
  const [showPrintDialog, setShowPrintDialog] = React.useState(false);

  React.useEffect(() => {
    Collections.Permits.get(permit.PermitInstanceId)
      .then((p) => p && setAvailableOffline(true));
  }, [permit.PermitInstanceId]);

  React.useEffect(() => {
    setExpanded(expandCard)
  }, [expandCard])

  const handleClick = (event, clickedPermit, action) => {
    event.stopPropagation();
    setIsLoading(true);

    switch (action) {
      case 'offline':
        dispatch(PermitsState.actions.toggleAvailableOffline(clickedPermit.PermitInstanceId))
          .then((state) => {
            setAvailableOffline(state);
            setIsLoading(false);
            handleMoreClose();
          });
        break;
      case 'edit':
        dispatch(push(`/permits/${clickedPermit.PermitInstanceId}/edit`));
        break;
      case 'copy':
        dispatch(PermitsState.actions.copyPermit(clickedPermit.PermitInstanceId));
        break;
      case 'approvals':
        setApprovalsDialogOpen(true);
        handleMoreClose();
        setIsLoading(false);
        break;
      default:
    }
  };

  const renderPermitTypeChips = (permitTypes) => (permitTypes || [])
    .map((type) => (
      <Chip
        key={type.Name || type.name}
        label={type.Label || type.label}
        className={classes.permitType}
        size="small"
      />
    ));

  return (
    <Fade in>
      <Card className={`${classes.card} ${permit.approvalRequired ? classes.approvalRequired : ''}`}>
        <CardContent className={classes.content}>
          {permit.approvalRequired && (
            <Chip
              className={classes.approvalRequiredChip}
              avatar={<ApprovalIcon style={{ color: 'white' }} />}
              label="Your Approval Required"
            />
          )}
          <Grid container style={{ alignItems: 'center' }}>
            <Grid item xs={12} sm={12} md={9}>
              <Grid container>
                <Grid item xs={6} sm={4} md={3} className={classes.inline}>
                  <Typography variant="h6">Permit #</Typography>
                  <Typography variant="body2">
                    {permit.PermitInstanceReferenceId}
                  </Typography>
                </Grid>

                <Grid item xs={6} sm={4} md={5} className={classes.inline}>
                  <Typography variant="h6">Workorder #</Typography>
                  <Typography variant="body2">
                    {permit.workOrderNumber}
                  </Typography>
                </Grid>

                <Grid item xs={6} sm={4} md={4} className={classes.inline}>
                  <Typography variant="h6">Company</Typography>
                  <Typography variant="body2">
                    {permit.companyName}
                  </Typography>
                </Grid>
              </Grid>

              <Grid container className={classes.secondRow}>
                <Grid item xs={6} sm={4} md={3} className={classes.inline}>
                  <Typography variant="h6">Date</Typography>
                  <Typography variant="body2">
                    {permit.workDate ? moment(permit.workDate).format('YYYY-MM-DD') : ''}
                  </Typography>
                </Grid>

                <Grid item xs={6} sm={4} md={5} className={classes.inline}>
                  <Typography variant="h6">Location</Typography>
                  <Typography variant="body2">{permit.workLocation}</Typography>
                </Grid>

                <Grid item xs={6} sm={4} md={4} className={classes.inline}>
                  <Typography variant="h6">Status</Typography>
                  <PermitStateChip state={permit.State} />
                </Grid>
              </Grid>
              {
                (permit.State === 'REJECTED' || permit.State === 'REVOKED') && 
                (<Grid container className={classes.secondRow}>
                  <Grid item xs={12} className={classes.inline}>
                    <Typography variant="h6">Reason</Typography>
                    <Typography variant="body2" className={classes.danger}>{permit.EndStateDescription}</Typography>
                  </Grid>
                </Grid>)
              }
            </Grid>
            <Grid item xs={12} sm={12} md={2}  className={classes.permitTypes}>
              <Typography variant="h6">Type</Typography>
              {renderPermitTypeChips(permit?.permitTypes?.sort((a,b) => (a.Label > b.Label) ? 1 : ((b.Label > a.Label) ? -1 : 0)))}
            </Grid>
            <Grid item xs={12} md={1} className={classes.menu}>
              {availableOffline && (
                <Tooltip title="Permit Available Offline">
                  <OfflinePinIcon className={classes.menuIcon} style={{ margin: 12 }} color="secondary" />
                </Tooltip>
              )}

              <IconButton
                aria-label="options"
                aria-controls="options-menu"
                aria-haspopup="true"
                onClick={handleMoreClick}
              >
                <MoreVertIcon />
              </IconButton>
              <Menu
                id="options-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleMoreClose}
              >
                {isLoading ? <InlineLoader /> : (
                  <span>
                    <MenuItem onClick={(e) => handleClick(e, permit, 'edit')} disabled={!online && !availableOffline}>
                      <DescriptionIcon className={classes.menuIcon} />
                      {' '}
                      View
                      {abilities.permits.edit ? '/Edit' : ''}
                      {' '}
                      Permit
                    </MenuItem>
                    {abilities.permits.edit && locationPermitSettings.copyEnabled && (
                      <MenuItem onClick={(e) => handleClick(e, permit, 'copy')} disabled={!online}>
                        <FileCopyIcon className={classes.menuIcon} />
                        {' '}
                        Copy Permit
                      </MenuItem>
                    )}
                    <MenuItem onClick={(e) => handleClick(e, permit, 'approvals')}>
                      <ApprovalIcon className={classes.menuIcon} />
                      Permit Approvals
                    </MenuItem>
                    { permit.SiteId === 65 && <MenuItem onClick={() => setShowPrintDialog((visible) => !visible)}>
                      <PrintIcon className={classes.menuIcon} />
                      Print
                    </MenuItem>
                    }
                    <Divider className={classes.menuDivider} />
                    {online ? (
                      <MenuItem onClick={(e) => handleClick(e, permit, 'offline')}>
                        {availableOffline
                          ? (
                            <>
                              <OfflinePinIcon className={`${classes.menuIcon} ${classes.offlineEnabled}`} />
                              Available Offline
                            </>
                          ) : (
                            <>
                              <OfflinePinIcon className={classes.menuIcon} />
                              Make Available Offline
                            </>
                          )}
                      </MenuItem>
                    ) : (
                        <MenuItem>
                          {availableOffline
                            ? (
                              <>
                                <OfflinePinIcon className={`${classes.menuIcon} ${classes.offlineEnabled}`} />
                              Available Offline
                              </>
                            ) : (
                              <>
                                <OfflinePinIcon className={classes.menuIcon} />
                              Not Available Offline
                              </>
                            )}
                        </MenuItem>
                      )}
                  </span>
                )}
              </Menu>
            </Grid>
          </Grid>
        </CardContent>
        <CardActions className={classes.cardFooter}>
          <IconButton
            onClick={() => setExpanded((e) => !e)}
            aria-expanded={expanded}
            aria-label="show more"
          >
            <ExpandMoreIcon />
          </IconButton>
        </CardActions>
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <CardContent>
            <Grid container spacing={5}>
              <Grid item xs={6} md={3}>
                <Typography variant="h6">Description</Typography>
                <Typography variant="body2" color="textSecondary" component="p">
                  {(permit.workDescription || []).map((description) => (
                    <span key={description.parentForm}>
                      <Typography variant="displayBlock"><b>{description.parentForm}</b></Typography>
                      <Typography variant="inherit">{description.workDescription}</Typography>
                    </span>
                  ))}
                </Typography>
              </Grid>
              <Grid item xs={6} md={3}>
                <Typography variant="h6">Permit Issuer</Typography>
                <Typography variant="body2" color="textSecondary" component="p">
                  {permitIssuerName}
                </Typography>
              </Grid>
              <Grid item xs={6} md={3}>
                <Typography variant="h6">Asset #</Typography>
                <Typography variant="body2" color="textSecondary" component="p">
                  {permit.assetNumber}
                </Typography>
              </Grid>
              <Grid item xs={6} md={3}>
                <Typography variant="h6">Asset Description</Typography>
                <Typography variant="body2" color="textSecondary" component="p">
                  {permit.assetDescription}
                </Typography>
              </Grid>
            </Grid>
          </CardContent>
        </Collapse>

        {approvalsDialogOpen && (
          <ApprovalsDialog
            permit={permit}
            onClose={() => setApprovalsDialogOpen(false)}
            ReferenceId={permit.PermitInstanceReferenceId}
            visible={approvalsDialogOpen}
          />
        )}
        {showPrintDialog && (
          <PrintDialog
            visible
            ReferenceId={permit.PermitInstanceReferenceId}
            PermitInstanceId={permit.PermitInstanceId}
            onClose={() => setShowPrintDialog(false)}
          />
        )}
      </Card>
    </Fade>
  );
};

PermitCard.defaultProps = {
  approvalRequired: false,
  expandCard: false,
};

PermitCard.propTypes = {
  approvalRequired: PropTypes.bool,
  permit: ESPermit.isRequired,
  expandCard: PropTypes.bool
};

export default PermitCard;
