import { findKey as _findKey } from 'lodash';

import Api from '../../../shared/api';
import toast from '../../../shared/toast';

import { ListItem } from '../../../types/list-item';
import { RootState } from '../../../store/RootState';
import { AppThunk } from '../../thunk';

import { TYPES } from './types';

/**
 * performs a search of Elasticsearch for permits
 * @param {{[key: string]}} filters - search filters
 * @return {ESPermit[]} - array of ES permit results
 */
export const getLists = (LocationId: number): AppThunk => {
  return async (dispatch) => {
    const lists = await Api.get(`/lists/${LocationId}`);
    dispatch({
      type: TYPES.setListsList,
      payload: lists,
    });
  };
};

/**
 * performs a search of Elasticsearch for permits
 * @param {{[key: string]}} filters - search filters
 * @return {ESPermit[]} - array of ES permit results
 */
export const getListItems = (LocationId: number, fieldKey: string): AppThunk => {
  return async (dispatch) => {
    const items = await Api.get<ListItem[]>(`/lists/${LocationId}/${fieldKey}`);

    dispatch({
      type: TYPES.setListItems,
      payload: {
        fieldKey,
        items: items.map((item) => ({ Label: item.Label, Value: item.Value })),
      },
    });
  };
};

export const addList = (fieldKey: string, item: ListItem): AppThunk => {
  return async (dispatch, getState) => {
    try {
      const { homeLocation } = getState().appState;
      const { lists } = getState().admin;

      if (lists.list.includes(fieldKey)) {
        throw new Error('Key already in use');
      }

      await Api.post(
        `/lists/${homeLocation.SiteId}/${fieldKey}`,
        [item],
      );

      dispatch({
        type: TYPES.setListsList,
        payload: [...lists.list, fieldKey],
      });

      dispatch({
        type: TYPES.setListItems,
        payload: {
          fieldKey,
          items: [item],
        },
      });

      toast.success('List added successfully');
    } catch (error) {
      toast.error('There was a problem adding the list, please try again.');
    }
  };
};

/**
 * performs a search of Elasticsearch for permits
 * @param {{[key: string]}} filters - search filters
 * @return {ESPermit[]} - array of ES permit results
 */
export const deleteList = (fieldKey: string): AppThunk => {
  return async (dispatch, getState) => {
    try {
      const { homeLocation } = getState().appState;
      const { list } = getState().admin.lists;

      await Api.delete(`/lists/${homeLocation.SiteId}/${fieldKey}`);

      dispatch({
        type: TYPES.setListsList,
        payload: list.filter((li) => li !== fieldKey),
      });

      dispatch({
        type: TYPES.setListItems,
        payload: {
          fieldKey,
          items: undefined,
        },
      });

      toast.success('List deleted successfully');
    } catch {
      toast.error('There was a problem deleting the list, please try again.');
    }
  };
};

/**
 * performs a search of Elasticsearch for permits
 * @param {{[key: string]}} filters - search filters
 * @return {ESPermit[]} - array of ES permit results
 */
export const upsertItem = (
  fieldKey: string,
  item: ListItem,
  initialItemValue?: string,
): AppThunk => {
  return async (dispatch, getState) => {
    try {
      const { homeLocation } = getState().appState;
      const { lists } = getState().admin;

      await Api.post(
        `/lists/${homeLocation.SiteId}/${fieldKey}`,
        [{ Label: item.Label, Value: item.Value }],
      );

      let nextItemsList = [...lists.items[fieldKey]];

      if (initialItemValue && item.Value !== initialItemValue) {
        nextItemsList = nextItemsList.filter((it) => it.Value !== initialItemValue);
      }

      const existingItem = _findKey(
        lists.items[fieldKey],
        (existing: ListItem) => existing.Value === item.Value,
      );

      if (existingItem) {
        nextItemsList[existingItem as unknown as number] = item;
      } else {
        nextItemsList.push(item);
      }

      dispatch({
        type: TYPES.setListItems,
        payload: {
          fieldKey,
          items: nextItemsList,
        },
      });

      toast.success('Item added successfully');
    } catch (error) {
      toast.error('There was a problem adding the item, please try again.');
    }
  };
};

/**
 * Delete list item from field
 * @param {string} fieldKey - the field to delete the item from
 * @param {ListItem} item = item to delete
 * @return {void}
 */
export const deleteItem = (fieldKey: string, item: ListItem): AppThunk => {
  return async (dispatch, getState) => {
    try {
      const { homeLocation } = getState().appState;
      const { items } = getState().admin.lists;

      await Api.delete(`/lists/${homeLocation.SiteId}/${fieldKey}/${item.Value}`);

      dispatch({
        type: TYPES.setListItems,
        payload: {
          fieldKey,
          items: items[fieldKey].filter((it) => it.Value !== item.Value),
        },
      });

      toast.success('Item deleted successfully');
    } catch {
      toast.error('There was a problem deleting the item, please try again.');
    }
  };
};

export default {
  types: TYPES,
  actions: {
    addList,
    deleteList,
    getLists,
    deleteItem,
    upsertItem,
    getListItems,
  },
  selectors: {
    listItems: (state: RootState) => state.admin.lists.items,
    fields: (state: RootState) => state.admin.lists.list,
  },
};
