import React from 'react';
import { useSelector } from 'react-redux';

import AppState from '../../actions/app';
import { RootState } from '../../store/RootState';
import { Definition } from '../../types/definition';

/**
 * gets a definition by id
 *
 * @param {string} definitionId - Id of definition to select. If not provided, it will get the
 *  definition of the home location
 * @return {Definition} - the requested definition
 */
const useDefinition = (definitionId?: string) => {
  const [loading, setLoading] = React.useState(false);
  const [data, setData] = React.useState<Definition>();

  const homeLocation = useSelector(AppState.selectors.homeLocation());
  const { list, sites } = useSelector((state: RootState) => state.definitions);

  React.useEffect(
    () => {
      setLoading(true);
      const definitionToSelect = definitionId || sites[homeLocation.SiteId];
      setData(list[definitionToSelect as string]);
      setLoading(false);
    },
    [definitionId, homeLocation.SiteId, list, sites],
  );

  return { data, loading };
};

export default useDefinition;
