import { set as _set } from 'lodash';
import FormioUtils from 'formiojs/utils';
import jsonPointer from 'json-pointer';

/**
 * Find approvers list by group name
 * @param {ApprovalGroup[]} approvalGroups - List of approver definitions (Group, LocationId, UserForMattedList)
 * @param {string} groupName - The name to search for
 * @return {void}
 */
export const findApproversByGroup = (approvalGroups, groupName) => {
  return approvalGroups.find((a) => a.Group === groupName);
};

/**
 * Iterates through the approval groups and set the select value/text pairs in the FormIO form definition
 * @param {ApprovalGroup[]} approvalGroups - List of approval group definitions (Group, LocationId, UserForMattedList)
 * @param {Approver[]} approvers - List of approvers
 * @param {FormDefinition} definition - Form Definition
 * @param {FieldDefinition[]} fields - array of fields definitions from the form
 * @return {void} - Matching approver definition or undefined
 */
export const setApproversSelectValues = (approvalGroups, approvers, definition, fields) => {
  if (!approvalGroups || typeof approvalGroups !== 'object' || !Array.isArray(approvalGroups)) return;

  approvalGroups.forEach((approvalGroup) => {
    const { Group, Name } = approvalGroup;
    const groupApprovers = findApproversByGroup(approvers, Group);

    if (!groupApprovers) return;
    const field = fields.find((f) => f.KeyName === Name);

    if (!field) return;
    const valuesPointer = `${field.DefinitionPathPointer}/data/values`;
    jsonPointer.set(definition, valuesPointer, groupApprovers.UserFormattedList);
  });
};

export const setApproversInDefinition = (definition, approvalFields, approvers, fieldsData) => {
  if (!approvalFields || typeof approvalFields !== 'object') return;

  Object.keys(approvalFields).forEach((key) => {
    const fields = approvalFields[key];

    fields.forEach((child) => {
      setApproversSelectValues(
        child.Approvals,
        approvers,
        definition,
        fieldsData,
      );
    });
  });
};

export const setAvailablePermitsInDefinition = (definition, availablePermits) => {
  availablePermits.forEach((type) => {
    const { PermitType } = type;
    const [component] = FormioUtils.searchComponents(definition.components, {
      'properties.SelectFromPermitType': PermitType,
    });

    if (component) {

      if (!component.data) {
        component.data = {};
      }
      
      component.data.values = [
        ...type.PermitFormattedList
      ];
      
    }
  });
};

/**
 * Set list item label/value pairs in form definition list components.
 * @param {Form} form - The form io form to effect
 * @param {ListDictionary} list - the list dictonary from the definitions state
 * @return {void}
 */
export const setListItemsInDefinition = (form, lists) => {  
  Object.keys(lists).forEach(key => {
    const comp = FormioUtils.searchComponents(form.components, { key });
    if(comp?.length) {
      comp.forEach(component => {
        if (component) {
          if (!component.data) {
            component.data = {};
          }
          component.data.values = [...lists[key]]
        }
      })
    }
  })
};

/**
 * Iterates through all panels in a form and executes the passed update function.
 * @param {Form} form - The form io form to effect
 * @param {Function} updateFn - update function to call against each panel
 * @return {void}
 */

const updateAllPanels = (form, updateFn) => {
  const panels = FormioUtils.searchComponents(form.components, { templateName: 'panel' });
  panels.forEach(updateFn);
};

/**
 * Collapse all panels in a form
 *
 * @param {Form} form - The form io form to effect
 * @return {void}
 */
export const collapseAllPanels = (form) => updateAllPanels(
  form,
  (component) => { component.collapsed = true; },
);

/**
 * Expand all panels in a form
 *
 * @param {Form} form - The form io form to effect
 * @return {void}
 */
export const expandAllPanels = (form) => {
  updateAllPanels(
    form,
    (component) => {
      component.collapsed = false
    },
  );
}

export const expandErrorPanels = (form) => {
  updateAllPanels(
    form,
    (component) => {
      if(component.collapsed && component.errors.length) {
        component.collapsed = false
      }
    },
  );
}
