import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch } from 'react-redux';
import { Button, Typography } from '@material-ui/core';

import useLocations from '../../../hooks/locations/useLocations';
import useOffline from '../../../hooks/shared/useOffline';
import PermitsState from '../../../actions/permits';

import UpdateLocationDialog from '../../shared/UpdateLocationDialog';

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: 275,
    marginBotton: theme.spacing(0),
  },
  button: {
    marginLeft: -4,
    paddingLeft: 4,
    paddingRight: 4,
    paddingTop: 2,
    paddingBottom: 2,
    display: 'block',
    fontSize: 10,
    color: 'grey',
    marginBotton: theme.spacing(0),
  },
  title: {
    marginLeft: theme.spacing(0.5),
  },
  pos: {
    fontSize: 14,
  },
}));

const PermitLocationSelection = (props) => {
  const { disabled, LocationId, onUpdate } = props;

  const classes = useStyles();
  const dispatch = useDispatch();
  const { selected } = useLocations();
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const { online } = useOffline();

  React.useEffect(() => {
    dispatch(PermitsState.actions.setLocation(LocationId));
  }, [dispatch, LocationId]);

  const handleLocationUpdate = (location) => {
    if (onUpdate) {
      onUpdate(location);
    }
    dispatch(PermitsState.actions.setLocation(location.LocationId));
  };

  if (!selected || !online) { return <></>; }

  return (
    <div className={classes.root}>
      <Typography variant="h6" component="h6" onClick={() => setDialogOpen(true)}>
        Location:
        {' '}
        <span className={classes.title}>{selected.Title}</span>
        {' '}
        {!disabled && (
          <Button
            size="small"
            className={classes.button}
          >
            CHANGE LOCATION
          </Button>
        )}
      </Typography>

      {dialogOpen && (
        <UpdateLocationDialog
          reduxActionType={PermitsState.types.setLocation}
          onClose={() => setDialogOpen(false)}
          onUpdate={handleLocationUpdate}
          selectedLocation={LocationId}
        />
      )}
    </div>
  );
};

PermitLocationSelection.defaultProps = {
  disabled: false,
  onUpdate: undefined,
};

PermitLocationSelection.propTypes = {
  disabled: PropTypes.bool,
  LocationId: PropTypes.number.isRequired,
  onUpdate: PropTypes.func,
};

export default PermitLocationSelection;
