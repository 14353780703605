/* eslint-disable camelcase */
import React from 'react';
import { Auth } from 'aws-amplify';
import { Hub } from 'aws-amplify';
import { useDispatch } from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom';

import Config from '../../config/app-config';
import AuthState from '../../actions/auth';
import useQuery from '../shared/useQuery';
import { performFederatedSignin } from '../../shared/auth';
import { logEvent } from '../../shared/analytics';

type EnsureUserLoginResponse = {
  isAuthenticated: boolean;
  loading: boolean;
}

const useEnsureUserLogin = (): EnsureUserLoginResponse => {
  const { code, state, error_description, error } = useQuery();

  const [loading, setLoading] = React.useState(true);
  const [isAuthenticated, setIsAuthenticated] = React.useState(false);
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();

  const signOutUser = React.useCallback(
    async (redirect?: string) => {
      await Auth.signOut();
      setIsAuthenticated(false);
      setLoading(false);

      if (redirect) {
        history.push(redirect);
      }
    },
    [history],
  );

  const ensureCognitoUser = React.useCallback(async () => {
    try {
      const session = await Auth.currentSession();
      dispatch(AuthState.actions.set(session));
      setLoading(false);
      setIsAuthenticated(true);
    } catch {
      if (Config.App.automaticFederatedSigninRedirectEnabled) {
        performFederatedSignin();
      } else {
        history.push('/auth/signin?error=unauthenticated');
      }
    } finally {
      setLoading(false);
    }
  }, [dispatch, history]);

  const handleSignInFailure = React.useCallback(() => {
    signOutUser();

    if (error || error_description) {
      history.replace(`/auth/signin?error=${error}&errorDescription=${error_description}`);
    }
  }, [error, error_description, history, signOutUser]);

  const handleAuthEvents = React.useCallback((data) => {
    logEvent('AUTH_EVENT', { event: data.payload });

    switch (data.payload.event) {
      case 'signUp':
        ensureCognitoUser();
        break;
      case 'signIn':
        ensureCognitoUser();
        break;
      case 'signIn_failure':
        handleSignInFailure();
        break;
      case 'singOut':
        history.push('/auth/signout');
        break;
      default:
    }
  }, [ensureCognitoUser, handleSignInFailure, history]);

  React.useEffect(() => {
    Hub.listen('auth', handleAuthEvents);

    return () => {
      Hub.remove('auth', handleAuthEvents);
    };
  }, [handleAuthEvents]);

  React.useEffect(() => {
    if (!code || !state) {
      if (location.pathname.startsWith('/auth')) {
        return;
      }

      ensureCognitoUser();
    }
    // linting overriden here as we want to ensure this only ever runs once
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return { isAuthenticated, loading };
};

export default useEnsureUserLogin;
