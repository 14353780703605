import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Template } from '../../types/template';
import TemplatesState from '../../actions/admin/templates';

type TemplateResponse = {
  data: Template | undefined;
  loading: boolean;
}

const useTemplate = (PermitTemplateId: string): TemplateResponse => {
  const dispatch = useDispatch();

  const [loading, setLoading] = React.useState<boolean>(false);
  const [template, setTemplate] = React.useState<Template>();

  const { actions, selectors } = TemplatesState;
  const templatesFromState = useSelector(selectors.list);

  React.useEffect(() => {
    if (!PermitTemplateId) {
      return;
    }

    setLoading(true);
    const existing = templatesFromState.find((t) => t.PermitTemplateId === PermitTemplateId);

    if (existing) {
      setTemplate(existing);
      setLoading(false);
    } else {
      dispatch(actions.getTemplate(PermitTemplateId));
      setLoading(false);
    }
  }, [actions, dispatch, PermitTemplateId, templatesFromState]);

  return { data: template, loading };
};

export default useTemplate;
