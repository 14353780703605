import React from 'react';

import { Button, makeStyles, Paper } from '@material-ui/core';
import { DefinitionForm } from '../../../../types/definition';
import { Template } from '../../../../types/template';
import WorkTemplatePermitsTable from './WorkTemplatePermitsTable';

type Props = {
  forms: DefinitionForm[];
}

export type FormTemplatesDictionary = {
  form: string;
  template: Template;
};

const useStyles = makeStyles((theme) => ({
  wrapper: {
    flexDirection: 'column',
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  updateButton: {
    margin: theme.spacing(2),
  },
}));

const WorkTemplateForm: React.FC<Props> = (props) => {
  const classes = useStyles();

  return (
    <Paper className={classes.wrapper}>
      <WorkTemplatePermitsTable />
      <Button
        color="primary"
        size="small"
        variant="contained"
        className={classes.updateButton}
        // onClick={() => removePermitTemplate(template)}
      >
        Update
      </Button>
    </Paper>
  );
};

export default WorkTemplateForm;
