import React from 'react';
import { IconButton, Menu, MenuItem } from '@material-ui/core';
import { MoreVert as MoreVertIcon } from '@material-ui/icons';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import ExternalUsersState from '../../../../actions/admin/external-users';
import { ExternalUser } from '../../../../types/external-user';
import DeleteDialog from './DeleteDialog';
import EnableDisableDialog from './EnableDisableDialog';

type Props = {
  user: ExternalUser;
}

type DialogType = 'delete' | 'enableDisable';

const ExternalUsersTableRowMenu: React.FC<Props> = (props) => {
  const { user } = props;

  const history = useHistory();
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = React.useState<Element>();
  const [openDialog, setOpenDialog] = React.useState<DialogType>();

  const handleClick: React.MouseEventHandler = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(undefined);
  };

  const handleResendInvitation = () => {
    dispatch(ExternalUsersState.actions.resendInvitation(user.PersonnelId));
    handleClose();
  };

  const handleItemClick = (dialogType: DialogType) => {
    setOpenDialog(dialogType);
    handleClose();
  };

  return (
    <div>
      <IconButton onClick={handleClick}>
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {user.State === 'PENDING' && <MenuItem onClick={handleResendInvitation}>Resend Invitiation</MenuItem>}
        <MenuItem onClick={() => history.push(`/admin/external-users/${user.PersonnelId}/edit`)}>Edit User</MenuItem>
        <MenuItem onClick={() => handleItemClick('enableDisable')}>{user.ActiveFlag ? 'Disable User' : 'Enable User'}</MenuItem>
        <MenuItem onClick={() => handleItemClick('delete')}>Delete User</MenuItem>
      </Menu>

      {openDialog === 'enableDisable' && (
        <EnableDisableDialog
          onClose={() => setOpenDialog(undefined)}
          PersonnelId={user.PersonnelId}
          ActiveFlag={user.ActiveFlag}
        />
      )}

      {openDialog === 'delete' && (
        <DeleteDialog
          onClose={() => setOpenDialog(undefined)}
          PersonnelId={user.PersonnelId}
        />
      )}
    </div>
  );
};

export default ExternalUsersTableRowMenu;
