import * as actions from '../../actions/admin/lists/types';
import { ListItem } from '../../types/list-item';

export interface ListsState {
  readonly items: { [key: string]: ListItem[] };
  readonly list: string[];
}

const initialState: ListsState = {
  items: {},
  list: [],
};

const listsReducer = (
  state = initialState,
  action: actions.ListsActionTypes,
): ListsState => {
  const { TYPES } = actions;
  let typedAction;

  switch (action.type) {
    case TYPES.setListsList:
      typedAction = action as actions.SetListsListAction;
      return {
        ...state,
        list: typedAction.payload,
      };
    case TYPES.setListItems:
      typedAction = action as actions.SetListItemsAction;
      return {
        ...state,
        items: {
          [typedAction.payload.fieldKey]: typedAction.payload.items,
        },
      };
    default:
      return state;
  }
};

export default listsReducer;
