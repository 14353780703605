import { filter, get as _get } from 'lodash';
import { Auth } from 'aws-amplify';
import { pending, resolve, reject } from './shared/status';
import Api from '../shared/api';
import toast from '../shared/toast';
import { determineUserAbilities } from '../shared/auth';
import Collections, { deleteDatabase } from '../store/indexeddb';

import AppState from './app';
import { push } from 'connected-react-router';

const TYPES = {
  get: 'AUTH_GET',
  update: 'AUTH_UPDATE',
  setAbilities: 'AUTH_ABILITIES_SET',
  setLoggedInUser: 'AUTH_SET_LOGGED_IN',
  signOut: 'AUTH_SIGNOUT',
  updateUserPreferenceValue: 'AUTH_PROFILE_PREFERENCES_VALUE_SET',
  updateUserProfileValue: 'AUTH_PROFILE_VALUE_SET',
  updateUserPermissions: 'AUTH_PERMISSIONS_SET',
  unAuthenticatedMessage: 'UNAUTHORIZED_MESSAGE',
  updateUserSearchPreferences: 'UPDATE_SEARCH_FILTER'
};

const unsetAuthenticatedUser = ({ type: TYPES.signOut });

const updateSearchFilter = (payload, screenType="SearchPermits") => ({
  type: TYPES.updateUserSearchPreferences,
  payload,
  screenType
});

const MSGERROR = {
  CREATE: 'There was a problem while creating filter, please try again.',
  UPDATE: 'There was a problem while updating filter, please try again.',
  DELETE: 'There was a problem while deleting filter, please try again.',
  GET: 'There was a problem while loading filters'
};

const MSGSUCCESS = {
  CREATE: 'Filter has been created successfully.',
  UPDATE: 'Filter has been updated successfully.',
  DELETE: 'Filter has been deleted successfully.',
};

export const getFiltersByScreen = (Type="SearchPermits") => {
  return async (dispatch, getState) => {
    try {
      const { profile } = getState().auth;
      const userProfile = await Api.get(`/user?Email=${encodeURIComponent(profile.email)}`);
      const { Preferences } = userProfile;
      if (Preferences[Type]) {
        await dispatch(updateSearchFilter(Preferences[Type]));
      }
    } catch(error) {
      toast.error(MSGERROR.GET)
    }
  }
};

export const setActiveFilterByScreen = (Id, Type = "SearchPermits") => {
  return async (dispatch, getState) => {
    try {
      const preferences = getState().auth?.preferences;
      const filters = preferences[Type];
      const filter = filters.map(data => ({...data, Active: data.Id === Id}));
      await dispatch(updateSearchFilter(filter));
      await Api.post('/userpref', { [Type]: filter });
    } catch(error) {

    }
  }
};

export const createFilterByScreen = (filter, Type = "SearchPermits") => {
  return async (dispatch, getState) => {
    try {
      const filters = getState().auth.preferences[Type] || [];
      const updatedFilter = { ...filter };
      const storeFilter = filters.map(data => ({...data, Active: false}))
      updatedFilter.Active = true;
      delete updatedFilter.Id;
      const profile = await Api.post('/userpref', { [Type]: [updatedFilter, ...storeFilter] });
      const { Preferences } = profile;
      await dispatch(updateSearchFilter(Preferences[Type]));
      toast.success(MSGSUCCESS.CREATE)
    } catch(error) {
      toast.error(MSGERROR.CREATE)
    }
  }
};


export const deleteFilterByScreen = (Id, Type = "SearchPermits") => {
  return async (dispatch, getState) => {
    try {
      const filterData = getState().auth.preferences[Type] || [];
      const filters = filterData.filter(data => data.Id !== Id);
      if (filters.length) {
        filters[0].Active = true;
      }
      const profile = await Api.post('/userpref', { [Type]: filters });
      const { Preferences } = profile;
      await dispatch(updateSearchFilter(Preferences[Type]));
      toast.success(MSGSUCCESS.DELETE)
    } catch(error) {
      toast.error(MSGERROR.DELETE)
    }
  }
}

export const updateFilterByScreen = (filter, Type = "SearchPermits") => {
  return async (dispatch, getState) => {
    try {
      const filterData = getState().auth.preferences[Type] || [];
      const updatedFilter = filterData.map(data => {
        return data.Id === filter.Id ? filter : data
      })
      const profile = await Api.post('/userpref', { [Type]: updatedFilter });
      const { Preferences } = profile;
      await dispatch(updateSearchFilter(Preferences[Type]));
      toast.success(MSGSUCCESS.UPDATE)
    } catch(error) {
      toast.error(MSGERROR.UPDATE)
    }
  }
};


export const signOut = (redirectTo) => {
  return async (dispatch) => {
    try {
      await Auth.signOut();

      // clear the indexeddb collections
      await Collections.Permits.clear();
      await Collections.Definitions.clear();

      // then delete the indexeddb database
      await deleteDatabase();

      dispatch(unsetAuthenticatedUser);
    } finally {
      if (redirectTo) {
        dispatch(push(`${redirectTo}`));
      }
    }
  };
};

const setAuthenticatedUser = (userSession) => async (dispatch) => {
  const { payload, jwtToken } = userSession.idToken;
  dispatch({
    type: TYPES.setLoggedInUser,
    payload: {
      identity: {
        username: payload['cognito:username'],
        adUsername: payload['cognito:username'].replace('nutrien-e-permitting-ad_', ''),
        sub: payload.sub,
      },
      profile: {
        email: payload.email,
        givenName: payload.given_name,
        surname: payload.family_name,
      },
      token: {
        jwt: jwtToken,
        refreshToken: userSession.refreshToken.token,
      },
    },
  });
};


export const signUpUser = (user, password) => {
  return async (dispatch) => {
    try {
      await Auth.signUp({
        username: user.EmailAddress,
        password,
        attributes: {
          email: user.EmailAddress,
        },
      });

      await Auth.signIn(user.EmailAddress, password);

      const session = await Auth.currentSession({ bypassCache: true });

      await dispatch(setAuthenticatedUser(session));
      await dispatch(AppState.actions.init());
      dispatch(push('/'));

      toast.success('User registration successful. Welcome to Nutrien E-Permitting')
    } catch {
      // eslint-disable-next-line max-len
      toast.error('The details you provided do not match our system. Please contact your Nutrien representative for assistance.')
    }
  }
}

const setUserAbilities = (locationId) => async (dispatch, getState) => {
  const { permissions } = getState().auth;
  const abilities = determineUserAbilities(permissions, locationId);

  dispatch({
    type: TYPES.setAbilities,
    payload: { abilities },
  });
};

const setUserPermissions = (permissions, isAdmin) => ({
  type: TYPES.updateUserPermissions,
  payload: { permissions, isAdmin },
});

const setUserPreferences = (preferences) => ({
  type: TYPES.updateUserPreferenceValue,
  payload: preferences,
});

const updateUserProfileValue = (profile) => ({
  type: TYPES.updateUserProfileValue,
  payload: profile,
});

const updateUserPreference = (preference, value) => async (dispatch, getState) => {
  try {
    dispatch(pending('updateUserPreferences'));
    const { PersonnelId } = getState().auth.preferences;
    await Api.post('/userpref', { PersonnelId, [preference]: value });
    dispatch(setUserPreferences({ [preference]: value }));
    dispatch(resolve('updateUserPreferences'));
  } catch (error) {
    dispatch(reject('updateUserPreferences', error));
  }
};

const setUnAuthenticatedMessage = (message) => ({ type: TYPES.unAuthenticatedMessage, payload: message})

export const selector = (path) => (state) => _get(state.auth, path);

export default {
  actions: {
    set: setAuthenticatedUser,
    unset: unsetAuthenticatedUser,
    signOut,
    signUpUser,
    setUserAbilities,
    setUserPermissions,
    setUserPreferences,
    updateUserPreference,
    updateUserProfileValue,
    setUnAuthenticatedMessage,
    updateSearchFilter,
    getFiltersByScreen,
    createFilterByScreen,
    deleteFilterByScreen,
    updateFilterByScreen,
    setActiveFilterByScreen
  },
  selectors: {
    abilities: () => selector('abilities'),
    identity: () => selector('identity'),
    isAdmin: () => selector('isAdmin'),
    isAuthenticated: () => selector('isAuthenticated'),
    profile: () => selector('profile'),
    preferences: () => selector('preferences'),
    permissions: () => selector('permissions'),
    tokens: () => selector('token'),
    getUnAuthenticatedMessage: () => selector('unAuthenticatedMessage'),
    tokens: () => selector('token')
  },
  types: TYPES,
};
