import { bool, shape, arrayOf, number, string } from 'prop-types';

export const Approval = shape({
  ApprovalDate: string,
  ApprovalDateField: string,
  ApprovalGroup: string,
  ApprovalLabel: string,
  ApprovalName: string,
  ApprovalOrder: number,
  ApprovalPath: string,
  ApprovedByName: string,
  ApprovedByPersonnelId: number,
  CheckedField: string,
  CreatedOn: string,
  Expression: string,
  IsApprovalRequired: bool,
  IsApproved: bool,
  LoggedInPersonnelId: number,
  NameField: string,
  Nature: string,
  NotificationRecipient: string,
  NotificationSentAt: string,
  ParentForm: string,
  PermitApprovalId: string,
  PermitInstanceId: string,
  PermitSubformId: string,
  SignatureField: string,
});

// eslint-disable-next-line import/prefer-default-export
export const PermitApprovalResponse = shape({
  PermitInstanceId: string,
  ApprovalNature: string,
  MissingApprovals: arrayOf(Approval),
  RequiredApprovals: arrayOf(Approval),
});
