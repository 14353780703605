import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import { get as _get } from 'lodash';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import PermitsState from '../../../actions/permits';
import Dialog from '../../shared/Dialog';
import InlineLoader from '../../shared/InlineLoader';
import PrintDialog from './PrintPermitDialog';
import useAnalytics from '../../../hooks/shared/useAnalytics';

const PermitSubmittedDialog = (props) => {
  const history = useHistory();
  const params = useParams();
  const recordEvent = useAnalytics();
  const { online } = useSelector((state) => state.offline);

  const previousState = params.PermitState;

  const { onClose, updateType, visible } = props;
  const [showPrintDialog, setShowPrintDialog] = React.useState(false);
  const { instance } = useSelector(PermitsState.selectors.selected());
  const togglePrintDialog = () => setShowPrintDialog((v) => !v);

  // There is a case where Formio sets the work order number to an empty
  // object instead of undefined when no value is set. In this case we
  // need to assume it is undefined and handle it accordingly.
  const renderWorkOrderNumber = () => {
    const workOrderNumber = _get(instance.ContentJSON, 'data.workOrder');
    let value = workOrderNumber;

    if (workOrderNumber && typeof workOrderNumber === 'object') {
      value = undefined;
    }

    return value || 'no work order selected';
  };


  const performAction = (action, message) => {
    recordEvent(`Permit Submitted Dialog Action: ${message}`);
    action();
  }

  // when a permit is created offline, then indicate to the user that the permit reference id
  // isn't available until we get back online will be issued once back online
  const permitNumber = (!instance || instance.PermitInstanceReferenceId === 'TBD')
    ? 'Pending...'
    : instance.PermitInstanceReferenceId;

  return (
    <>
      <Dialog
        disableClose
        visible={visible}
        title={`Permit has been ${updateType === 'create' ? 'created' : 'updated'}`}
        actions={[
          { text: 'Continue Editing', disabled: !permitNumber, color: 'primary', onClick: () => performAction(onClose, 'Continue Editing') },
          { text: 'Print Permit', disabled: !permitNumber, color: 'primary', onClick: () => performAction(togglePrintDialog, 'Print Permit') },
          {
            text: 'Exit Permit',
            disabled: !permitNumber,
            color: 'secondary',
            onClick: () => performAction(
              () => { onClose(); history.push('/permits/list') },
              'Exit Permit'
            ),
          },
        ]}
      >
        {!permitNumber ? <InlineLoader /> : (
          <>
            {!online && (
              <Alert severity="warning" style={{ marginBottom: '1rem' }}>
                <Typography>
                  There is no connection to the E-Permitting system and you are currently working offline. Any changes
                  {' '}
                  you made to the permit will be synchronized automatically when the device comes back online.
                </Typography>
              </Alert>
            )}
            <Typography gutterBottom>
              Permit # -
              {' '}
              <b>{permitNumber}</b>
            </Typography>
            <Typography gutterBottom>
              Work Order # -
              {' '}
              <b>{renderWorkOrderNumber()}</b>
            </Typography>
            {previousState !== instance.State && (
              <Typography gutterBottom>
                New Permit State -
                {' '}
                <b>{instance.State}</b>
              </Typography>
            )}
          </>
        )}
      </Dialog>
      {
        showPrintDialog && (
          <PrintDialog
            visible
            ReferenceId={instance.PermitInstanceReferenceId}
            PermitInstanceId={instance.PermitInstanceId}
            onClose={() => setShowPrintDialog(false)}
          />
        )
      }
    </>
  );
};

PermitSubmittedDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  visible: PropTypes.bool.isRequired,
  updateType: PropTypes.oneOf(['create', 'edit']).isRequired,
};

export default PermitSubmittedDialog;
