import React from 'react';
import PropTypes from 'prop-types';
import { Chip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import PermitsConfig, { states } from '../../../config/permits';

const useStyles = makeStyles((theme) => ({
  chip: {
    backgroundColor: (props) => props.state && PermitsConfig[props.state].color,
    color: theme.palette.common.white,
  },
}));

const PermitStateChip = (props) => {
  const classes = useStyles(props);
  const { state } = props;

  if (!state) {
    return <></>;
  }

  return (
    <Chip
      className={classes.chip}
      label={state}
      size="small"
    />
  );
};

PermitStateChip.propTypes = {
  state: PropTypes.oneOf(states).isRequired,
};

export default PermitStateChip;
