import React from 'react';
import { Route, Switch } from 'react-router-dom';

import UsersList from './UsersList';
import CreateUser from './CreateUser';
import EditUser from './EditUser';

const Lists: React.FC = () => (
  <Switch>
    <Route path="/admin/external-users" exact component={UsersList} />
    <Route path="/admin/external-users/:PersonnelId/edit" exact component={EditUser} />
    <Route path="/admin/external-users/create" exact component={CreateUser} />
  </Switch>
);

export default Lists;
