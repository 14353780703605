import React from 'react';
import { useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { IconButton, Tooltip } from '@material-ui/core';

import useLocations from '../../hooks/locations/useLocations';
import { Room as RoomIcon } from '@material-ui/icons';

import AppState from '../../actions/app';
import UpdateLocationDialog from './UpdateLocationDialog';
import useAnalytics from '../../hooks/shared/useAnalytics';

const useStyles = makeStyles((theme) => ({
  locationWrapper: {
    color: theme.palette.common.white,
    padding: 0
  },
  mobile: {
    display: 'none',
    paddingLeft: theme.spacing(1),
    fontSize: '1.1rem',
    color: "inherit",
    [theme.breakpoints.down('sm')]: {
      display: 'inline-block',
    },
  },
  noHover: {
    "&:hover": {
      backgroundColor: 'transparent'
    },
  },
}));

const NavBarLocationIndicator = ({onClose}) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const recordEvent = useAnalytics();

  const { homeLocation } = useLocations();
  const [locationDialogVisible, setLocationDialogVisible] = React.useState(false);

  const handleLocationUpdate = (location) => {
    dispatch(AppState.actions.setHomeLocation(location));
    setLocationDialogVisible(false);
    onClose && onClose();
  };

  return (
    <>
      <Tooltip title={homeLocation.Title} aria-label="collapse-permits">
        <IconButton
          className={classes.noHover}
          onClick={() => {
            setLocationDialogVisible(true);
            recordEvent({
              action: 'NAVIGATION_BAR_CLICK',
              item: 'Change Home Location',
            });
          }}
          edge="end"
          color="inherit"
        >
          <RoomIcon size="xsmall" className={classes.locationIcon} />
          <span className={classes.mobile}>Change Location</span>
        </IconButton>
      </Tooltip>

      {locationDialogVisible && (
        <UpdateLocationDialog
          selectedLocation={homeLocation.LocationId}
          reduxActionType={AppState.types.setHomeLocation}
          onClose={() => setLocationDialogVisible(false)}
          onUpdate={handleLocationUpdate}
        />
      )}
    </>
  );
}

export default NavBarLocationIndicator;
