import React from 'react';
import { Typography, TextField } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';

import Dialog from '../../shared/Dialog';

import { ActionStatus } from '../../../actions/shared/status';
import AppState from '../../../actions/app';
import PermitsState from '../../../actions/permits';
import InlineLoader from '../../shared/InlineLoader';

const RejectPermitDialog = (props) => {
  const { ReferenceId, PermitInstanceId, onClose, visible } = props;
  const dispatch = useDispatch();

  const printStatus = useSelector(AppState.selectors.status('rejectPermit'));

  const [reasonText, setReasonText] = React.useState('');

  const handleRejectPermit = () => {
    const { actions } = PermitsState;
    dispatch(actions.rejectPermit(PermitInstanceId, reasonText));
  }

  const rejectionInProgress = printStatus === ActionStatus.pending;

  return (
    <Dialog
      onClose={onClose}
      visible={visible}
      title={`Reject Permit - #${ReferenceId}`}
      actions={[{
        text: 'Reject',
        color: 'primary',
        disabled: rejectionInProgress || !reasonText,
        onClick: handleRejectPermit
      }]}
    >
      {rejectionInProgress ? <InlineLoader /> : (
        <>
          <Typography gutterBottom>Enter the reason for rejecting this permit</Typography>
          <TextField
            variant="outlined"
            multiline={true}
            cols={12}
            rows={2}
            style={{ width: '100%' }}
            value={reasonText}
            onChange={e => setReasonText(e.target.value)}
          />
        </>
      )}
    </Dialog>
  );
}

export default RejectPermitDialog;
