import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import ExternalUsersState from '../../actions/admin/external-users';
import { ExternalUser } from '../../types/external-user';

type UseExternalUsersResponse = {
  loading: boolean;
  data: ExternalUser[];
}

const useExternalUser = (): UseExternalUsersResponse => {
  const dispatch = useDispatch();
  const { actions, selectors } = ExternalUsersState;

  const [loading, setLoading] = React.useState(false);
  const [usersList, setUsersList] = React.useState<ExternalUser[]>([]);

  const usersFromState = useSelector(selectors.list);

  React.useEffect(() => {
    setUsersList(usersFromState);
    setLoading(false);
  }, [usersFromState]);

  React.useEffect(() => {
    const getUsers = async () => {
      setLoading(true);
      try {
        await dispatch(actions.getUsers());
        setLoading(false);
      } catch {
      }
    };

    getUsers();
  }, [
    actions,
    dispatch,
  ]);

  return { loading, data: usersList };
};

export default useExternalUser;
