import { Route, Switch } from 'react-router-dom';

import permitCache from './permit-cache';

const PermitCachesearch = () => (
  <Switch>
    <Route path="/admin/permit-cache" exact component={permitCache} />
  </Switch>
);

export default PermitCachesearch;
