import React from 'react';
import { Paper, makeStyles, Typography, Checkbox, Button, FormControlLabel, FormGroup, FormHelperText, FormControl } from '@material-ui/core';
import { Moment } from 'moment';
import { useDispatch } from 'react-redux';
import dateIoMoment from '@date-io/moment';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';

import Breadcrumbs from '../Breadcrumbs';
import { filteredStates } from '../../../config/permits';
import { PermitState } from '../../../types/permit';
import Dialog from '../../shared/Dialog';
import ClearRermitCache from '../../../actions/admin/permit-cache';

interface ErrorType {
  stateValidationMessage: string,
  dateValidationMessage: string
}

interface SelectedDate {
  fromDate: Moment | null,
  toDate: Moment | null
}

const useStyles = makeStyles((theme) => ({
  wrapper: {
    padding: theme.spacing(3),
    marginBottom: theme.spacing(2),
  },
  formWapper: {
    margin: theme.spacing(2),
  },
  formControl: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap'
  },
  dateSection: {
    margin: theme.spacing(2),
  },
  datePicker: {
    marginRight: theme.spacing(4),
  },
  statusTitle: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(0)
  },
  submitBtn: {
    marginTop: theme.spacing(4)
  }
}));

// Get Filtered State
const filteredStateConfig = filteredStates(['DRAFT']);

const ClearPermitCache: React.FC = () => {

  const dispatch = useDispatch();
  const classes = useStyles();

  const [selectedStates, setSelectedStates] = React.useState<PermitState[]>(filteredStateConfig);
  const [selectedDates, setselectedDates] = React.useState<SelectedDate>({ fromDate: null, toDate: null });
  const [showConfirmDialog, setShowConfirmDialog] = React.useState<boolean>(false);
  const [loading, setLoading] = React.useState<boolean>(false)
  const [errorMessages, setErrorMessages] = React.useState<ErrorType>({
    stateValidationMessage: '',
    dateValidationMessage: ''
  })

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>, state: PermitState) => {
    if (event.target.checked) {
      setSelectedStates([...selectedStates, state]);
    } else {
      const filteredData = selectedStates.filter((data: string) => data !== state);
      setSelectedStates(filteredData);
    }
  }

  const handleSubmit = () => {
    const errors = { stateValidationMessage: '', dateValidationMessage: '' };
    if (!selectedStates.length) {
      errors.stateValidationMessage = 'Please select at least one state';
    }
    const { fromDate, toDate } = selectedDates;
    if (!fromDate && toDate) {
      errors.dateValidationMessage = 'Please select From Date';
    } else if (fromDate && toDate && (fromDate >= toDate)) {
      errors.dateValidationMessage = 'Please select valid From and To Date';
    }

    if (errors.dateValidationMessage || errors.stateValidationMessage) {
      setErrorMessages({ ...errors })
    } else {
      setErrorMessages({ stateValidationMessage: '', dateValidationMessage: '' });
      setShowConfirmDialog(true);
    }
  }

  const onDateChange = (name: string, value: Moment) => {
    setselectedDates({ ...selectedDates, [name]: value })
  }

  const handleOkClick = async () => {
    setLoading(true);
    setShowConfirmDialog(false);
    const { fromDate, toDate } = selectedDates;
    await dispatch(ClearRermitCache.actions.clearESPermitCache({
      States: selectedStates,
      StartDate: fromDate ? fromDate.format('YYYY-MM-DD') : null,
      EndDate: toDate ? toDate.format('YYYY-MM-DD') : null,
    }));
    setLoading(false);
  }

  const onClose = () => {
    setShowConfirmDialog(false)
  }

  const { stateValidationMessage, dateValidationMessage } = errorMessages;

  return <>
    <Breadcrumbs crumbs={[{ label: 'Permit Cache' }]} />
    <Paper className={classes.wrapper}>
      <Typography variant="h6">Clear Permit Cache</Typography>
      <FormControl component="fieldset" error={!!stateValidationMessage || !!dateValidationMessage} >
        <Typography className={classes.statusTitle} variant="body2">Select Permit States </Typography>
        <div className={classes.formWapper}>
          {stateValidationMessage && <FormHelperText>{stateValidationMessage}</FormHelperText>}
          <FormGroup className={classes.formControl}>
            {filteredStateConfig.map(state => (
                <FormControlLabel
                  key={state}
                  label={state}
                  control={
                    <Checkbox
                      color="primary"
                      checked={selectedStates.includes(state)}
                      onChange={(e) => handleChange(e, state)}
                      name={state}
                    />
                  }
                />
              )
            )}
            </FormGroup>
          </div>
      </FormControl>
      <FormControl component="fieldset" error={!!stateValidationMessage || !!dateValidationMessage}>
        <Typography className={classes.statusTitle} variant="body2">Select From and To Date</Typography>
        <div className={classes.dateSection}>
          {dateValidationMessage && <FormHelperText>{dateValidationMessage}</FormHelperText>}
          <MuiPickersUtilsProvider utils={dateIoMoment}>
            <KeyboardDatePicker
              className={classes.datePicker}
              value={selectedDates.fromDate}
              placeholder="From"
              label="From"
              onChange={(date) => onDateChange('fromDate', date as Moment)}
              maxDate={new Date()}
              format="YYYY-MM-DD"
            />
            <KeyboardDatePicker
              value={selectedDates.toDate}
              placeholder="To"
              label="To"
              onChange={(date) => onDateChange('toDate', date as Moment)}
              maxDate={new Date()}
              format="YYYY-MM-DD"
            />
          </MuiPickersUtilsProvider>
        </div>
      </FormControl>
      <div className={classes.submitBtn}>
        <Button disabled={loading} type="submit" variant="contained" color="primary" onClick={handleSubmit}>Submit</Button>
      </div>
      {showConfirmDialog &&
        <Dialog
          visible={true}
          onCancel={onClose}
          onClose={onClose}
          title="Clear Permit Cache"
          actions={[{
            text: 'Ok',
            color: 'primary',
            onClick: handleOkClick
          }]}
        >
        <Typography gutterBottom>
          Are you sure you want to clear the permit cache?
        </Typography>
      </Dialog>
      }
    </Paper>
  </>
}

export default ClearPermitCache;
