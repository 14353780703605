import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import LeafletMap from '../../shared/LeafletMap';
import Dialog from '../../shared/Dialog';

import { ActionStatus } from '../../../actions/shared/status';
import AppState from '../../../actions/app';
import PermitsState from '../../../actions/permits';
import useAbilities from '../../../hooks/auth/useAbilities';

import InlineLoader from '../../shared/InlineLoader';
import toast from '../../../shared/toast';

import 'leaflet/dist/leaflet.css';

const WorkLocationDialog = (props) => {
  const dispatch = useDispatch();
  const abilities = useAbilities();

  const {
    ReferenceId,
    PermitInstanceId,
    onClose,
    visible,
    workLocation: initialWorkLocation,
  } = props;

  const [workLocation, setWorkLocation] = React.useState(initialWorkLocation);
  const loadingState = useSelector(AppState.selectors.status(PermitsState.types.updateWorkLocation));

  const handleLocationUpdate = () => {
    if (!workLocation) {
      toast.error('No work location selected. Please move the marker to the work location');
      return;
    }

    if (PermitInstanceId) {
      dispatch(
        PermitsState
          .actions
          .updateWorkLocation(PermitInstanceId, workLocation.lat, workLocation.lng),
      );
    }

    if (props.onUpdate) {
      props.onUpdate(workLocation);
    }
  };

  const updateInProgress = loadingState === ActionStatus.pending;
  const workLocationSet = workLocation && (workLocation.lat > 0 && workLocation.lng > 0);
  return (
    <Dialog
      onClose={onClose}
      maxWidth="xl"
      visible={visible}
      title={`Work Location${ReferenceId ? ` - #${ReferenceId}` : ''}`}
      actions={[{
        text: `${PermitInstanceId ? 'Update' : 'Set'} Location`,
        color: 'primary',
        disabled: !abilities.permits.edit || updateInProgress,
        onClick: handleLocationUpdate,
      }]}
    >
      {updateInProgress ? (
        <div style={{ minHeight: '30vh' }}>
          <InlineLoader />
        </div>
      ) : (
        <LeafletMap
          markers={workLocationSet ? [{
            lat: workLocation.lat,
            lng: workLocation.lng,
            onMarkerMove: setWorkLocation,
          }] : []}
          onMarkerMove={!workLocationSet ? setWorkLocation : undefined}
          useInitialMarker={!workLocationSet}
          readOnly={false}
          style={{ maxWidth: '100%' }}
        />
      )}
    </Dialog>
  );
};

WorkLocationDialog.defaultProps = {
  PermitInstanceId: '',
  ReferenceId: '',
  workLocation: {},
};

WorkLocationDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  onUpdate: PropTypes.func,
  PermitInstanceId: PropTypes.string,
  ReferenceId: PropTypes.string,
  visible: PropTypes.bool.isRequired,
  workLocation: PropTypes.exact({
    lat: PropTypes.number,
    lng: PropTypes.number,
  }),
};

export default WorkLocationDialog;
