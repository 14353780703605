import React, { forwardRef, useImperativeHandle } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Button, IconButton, InputAdornment, TextField } from '@material-ui/core';
import { Cancel as CancelIcon } from '@material-ui/icons';
import { generateRandomString } from '../../../shared/string';

const useStyles = makeStyles((theme) => ({
  searchInput: {
    width: '100%',
    paddingLeft: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  addTermButton: {
    marginBottom: 0,
  },
}));

export const TermInput = (props) => {
  const { onChange, onKeyDown, value, placeholder, showDeleteButton, onDeleteClick, helperText } = props;
  const classes = useStyles();
  return (
    <TextField
      className={classes.searchInput}
      value={value}
      placeholder={placeholder}
      onChange={onChange}
      onKeyDown={onKeyDown}
      helperText={helperText}
      endAdornment={showDeleteButton ? (
        <InputAdornment position="end">
          <IconButton aria-label="add an alarm" onClick={onDeleteClick}>
            <CancelIcon size="small" />
          </IconButton>
        </InputAdornment>
      ): null}
    />
  );
};

TermInput.defaultProps = {
  helperText: null,
  placeholder: null,
  value: '',
};

TermInput.propTypes = {
  helperText: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onDeleteClick: PropTypes.func.isRequired,
  onKeyDown: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  showDeleteButton: PropTypes.bool.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export const generateInitialInputsValue = (searchTerms) => {
  if (searchTerms && !Array.isArray(searchTerms)) {
    throw new Error('searchTerms prop must be an Array');
  }

  if (searchTerms.length) {
    return searchTerms.reduce((value, term) => {
      return {
        ...value,
        [generateRandomString()]: term,
      };
    }, {});
  }

  return { [generateRandomString()]: '' };
};

const SearchTermsInput = forwardRef((props, ref) => {
  const { onChange, onKeyDown, searchTerms } = props;
  const classes = useStyles();
  const [inputs, setInputs] = React.useState(generateInitialInputsValue(searchTerms));

  const clearState = (state) => {
    if(state){
      setInputs(state)
    } else {
      const id = generateRandomString();
      setInputs({[id]: '' })
    }
  }

  useImperativeHandle(ref, () => ({
    clearState
  }))

  React.useEffect(() => {
    onChange(
      Object
        .values(inputs)
        .filter((ins) => ins && ins.length),
    );
  }, [inputs, onChange]);

  const handleAddTerm = () => {
    const id = generateRandomString();
    setInputs((existing) => ({ ...existing, [id]: '' }));
  };
  
  const handleDeleteClick = (id) => {
    const nextInputs = inputs;
    delete nextInputs[id];

    setInputs({ ...nextInputs });
  };

  return (
    <>
      {Object.keys(inputs).map((input, i) => (
        <TermInput
          key={input}
          value={inputs[input]}
          onKeyDown={onKeyDown}
          showDeleteButton={i > 0}
          onDeleteClick={() => handleDeleteClick(input)}
          onChange={(v) => {
            v.persist();
            setInputs((existing) => ({ ...existing, [input]: v.target.value }));
          }}
          placeholder="Contains text"
          helperText="Eg: UREA/CO2 AREA or SOFT-101C"
        />
      ))}
      {Object.keys(inputs).length < 3 && (
        <Button
          size="small"
          color="primary"
          className={classes.addTermButton}
          onClick={handleAddTerm}
        >
          + add search term
        </Button>
      )}
    </>
  );
});

SearchTermsInput.defaultProps = {
  searchTerms: [],
};

SearchTermsInput.propTypes = {
  onChange: PropTypes.func.isRequired,
  onKeyDown: PropTypes.func.isRequired,
  searchTerms: PropTypes.arrayOf(PropTypes.string),
};

export default SearchTermsInput;
