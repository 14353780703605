import { Auth } from 'aws-amplify';
import axios, { AxiosInstance, AxiosRequestConfig, AxiosPromise, AxiosResponse } from 'axios';

import Config from '../config/app-config';

export const initApi = (withoutAuth = false): Promise<AxiosInstance> => {
  return Auth.currentSession().then((session) => {
    const idToken = session.getIdToken();
    return axios.create({
      baseURL: `${Config.API.baseUrl}`,
      headers: {
        'Content-Type': 'application/json',
        // 'x-app-session-id': SessionId,
        ...(withoutAuth ? {} : { authorization: `${idToken.getJwtToken()}` }),
      },
    });
  });
};

interface AxiosRequestConfigWithBody<T> extends AxiosRequestConfig {
  body?: T;
}

export const apiWithAuth = <T>(
  config: AxiosRequestConfigWithBody<T> = {},
): AxiosPromise<T> => {
  // typed as any as the older version of amplify has the typings wrong for the
  // response of currentSession
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return Auth.currentSession().then(({ idToken }: any) => {
    return axios({
      ...config,
      ...(config.body ? { data: config.body } : {}),
      baseURL: `${Config.API.baseUrl}`,
      headers: {
        'Content-Type': 'application/json',
        // 'x-app-session-id': SessionId,
        ...config.headers,
        authorization: `${idToken.jwtToken}`,
      },
    });
  });
};

const get = async <R = Record<string, unknown>>(
  endpoint: string,
  config: AxiosRequestConfig = {},
): Promise<R> => {
  const AuthedApi = await initApi();
  const result = await AuthedApi.get<R>(endpoint, config);
  return result.data;
};

const post = async <B = Record<string, unknown>, R = Record<string, unknown>>(
  endpoint: string,
  body: B = {} as B,
  config: AxiosRequestConfig = {},
): Promise<R> => {
  if (!body) {
    throw new Error('body must not be empty');
  }
  const AuthedApi = await initApi();
  const result = await AuthedApi.post<B, AxiosResponse<R>>(endpoint, body, config);
  return result.data;
};

const put = async <B = Record<string, unknown>, R = Record<string, unknown>>(
  endpoint: string,
  body: B,
  config: AxiosRequestConfig = {},
): Promise<R> => {
  const AuthedApi = await initApi();
  const result = await AuthedApi.put<B, AxiosResponse<R>>(endpoint, body, config);
  return result.data;
};

const patch = async <B = Record<string, unknown>, R = Record<string, unknown>>(
  endpoint: string,
  body: B,
  config: AxiosRequestConfig = {},
): Promise<R> => {
  const AuthedApi = await initApi();
  const result = await AuthedApi.patch<B, AxiosResponse<R>>(endpoint, body, config);
  return result.data;
};

const deleteCall = async <R = Record<string, unknown>>(
  endpoint: string,
  config: AxiosRequestConfig = {},
): Promise<R> => {
  const AuthedApi = await initApi();
  const result = await AuthedApi.delete<R>(endpoint, config);
  return result.data;
};

export default {
  get,
  delete: deleteCall,
  post,
  put,
  patch,
};
