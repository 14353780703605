import { openDB, deleteDB } from 'idb';
import { v4 as uuid } from 'uuid';

// Databases have unique names to ensure that multiple browser windows do not
// create database blocks for the other windows open.
export const INDEXED_DB_NAME = `nutrien-epermitting-${uuid()}`;
// export const INDEXED_DB_NAME = `nutrien-epermitting`;

const dbPromise = openDB(INDEXED_DB_NAME, 1, {
  upgrade(db) {
    db.createObjectStore('definitions');
    db.createObjectStore('permits');
    db.createObjectStore('permit-templates');
    db.createObjectStore('permit-approvals');
    db.createObjectStore('work-orders');
  },
});

export const deleteDatabase = () => {
  return deleteDB(INDEXED_DB_NAME);
};

const IndexedDb = (dbName) => {
  // Delete the database when the user closes their browser window
  window.onunload = async () => {
    await deleteDatabase();
  };

  return {
    get: async (key) => {
      return (await dbPromise).get(dbName, key);
    },
    getAll: async () => {
      return (await dbPromise).getAll(dbName);
    },
    set: async (key, val) => {
      const db = await dbPromise;
      const existing = await db.get(dbName, key);

      const update = {
        ...val,
        meta: {
          ...(!existing ? { created: new Date() } : existing.meta),
          ...val.meta,
          updated: new Date(),
        },
      };

      return db.put(dbName, update, key);
    },
    delete: async (key) => {
      return (await dbPromise).delete(dbName, key);
    },
    clear: async () => {
      return (await dbPromise).clear(dbName);
    },
    keys: async () => {
      return (await dbPromise).getAllKeys(dbName);
    },
  };
};

const Collections = {
  Definitions: IndexedDb('definitions'),
  PermitApprovals: IndexedDb('permit-approvals'),
  Permits: IndexedDb('permits'),
  PermitTemplates: IndexedDb('permit-templates'),
  WorkOrders: IndexedDb('work-orders'),
};

export default Collections;
