import { get as _get } from 'lodash';
import { push } from 'connected-react-router';

import Api from '../shared/api';
import toast from '../shared/toast';
import {
  pending,
  resolve,
  reject,
  reset,
  ActionStatusTypes,
} from './shared/status';

import { recordEvent } from '../shared/analytics';

import AuthState from './auth';
import LocationsState from './locations';
import PermitsState from './permits';
import WorkOrdersState from './work-orders';

import Collections from '../store/indexeddb';
import { RootState } from '../store/RootState';
import { AppThunk } from './thunk';
import { UserProfile } from '../types/user';
import { Location } from '../types/location';
import { Printer, PrinterResponse } from '../types/printer';

const TYPES = {
  setLastRun: 'APP_ACTION_LAST_RUN_SET',
  resetLastRun: 'APP_ACTION_LAST_RUN_RESET',
  setInitilized: 'APP_INITILIZED_SET',
  setStatus: 'APP_STATUS_SET',
  setPageName: 'APP_PAGE_NAME_SET',
  setPrinters: 'APP_PRINTERS_SET',
  setPrintingResponse: 'APP_PRINTING_RESPONSE_SET',
  setIsLoading: 'APP_LOADING_STATE_SET',
  getLocations: 'APP_LOCATIONS_GET',
  toggleLeftMenuDrawer: 'APP_LEFT_MENU_DRAWER_TOGGLE',
  refreshData: 'APP_REFRESH_DATA',
  setHomeLocation: 'APP_HOME_LOCATION_SET',
  setLocations: 'APP_LOCATIONS_SET',
  setLocationDisabled: 'APP_LOCATION_DISABLED_SET',
  setPrintableStates: 'APP_PRINTABLE_STATES_SET',
};

export const toggleLeftMenuDrawer = (): AppThunk => (dispatch, getState) => {
  const { left } = getState().appState;

  recordEvent(getState(), `Sidebar ${left ? 'closed' : 'opened'}`);

  dispatch({
    type: TYPES.toggleLeftMenuDrawer,
    payload: !left,
  });
};

type LocationsResponse = {
  LocationList: Location[];
}

export const getLocations = (): AppThunk<Promise<void>> => async (dispatch) => {
  try {
    dispatch(pending('getLocations'));

    const response = await Api.get<LocationsResponse>('/location?IncludeSublocations=true');

    // The PlotPlanFileName can be either a url, null or undefined. If it's not
    // defined, simply omit it so we don't have to do a null/undefined check every
    // where that the PlotPlanFileName is used.
    const locations = (response.LocationList).map((location) => {
      const locationClone = { ...location };

      if (!location.PlotPlans) {
        locationClone.PlotPlans = null;
      }

      return locationClone;
    });

    dispatch({
      type: TYPES.setLocations,
      payload: locations,
    });

    dispatch(resolve('getLocations'));
  } catch (error) {
    dispatch(reject('getLocations', error));
  }
};

export const getPrinters = (LocationId: number): AppThunk => async (dispatch, getState) => {
  try {

    if(LocationId === undefined || LocationId === null) {
      recordEvent(getState(), 'LocationId Not Found to get printer list');
      return;
    }
    dispatch(pending('getPrinters'));
    const printers = await Api.get(`/printer?LocationId=${LocationId}`);

    dispatch({
      type: TYPES.setPrinters,
      payload: printers.PrinterList,
    });
    dispatch(resolve('getPrinters'));
  } catch (error) {
    dispatch(reject('getPrinters', error));
    recordEvent(getState(), {error, message: 'getPrinters: Found error', LocationId})
  }
};

export const setHomeLocation = (location: Location, skipToast = false): AppThunk => async (dispatch) => {
  try {
    dispatch(pending(TYPES.setHomeLocation));

    dispatch(
      AuthState
        .actions
        .updateUserPreference('HomeLocationId', location.LocationId),
    ).then(async () => {
      dispatch({
        type: TYPES.setHomeLocation,
        payload: location,
      });

      dispatch(AuthState.actions.setUserAbilities(location.LocationId));

      dispatch(PermitsState.actions.clearList());
      dispatch(PermitsState.actions.get());

      dispatch(WorkOrdersState.actions.clearList());
      dispatch(WorkOrdersState.actions.get(location.SiteId, true));

      // clear all permits from the cache
      await Collections.Permits.clear();

      dispatch(LocationsState.actions.getLocationMetadata(location.LocationId));
      dispatch(LocationsState.actions.getPlotPlanImage(location.LocationId));
      dispatch(PermitsState.actions.setLocation(location.LocationId));
      dispatch(getPrinters(location.LocationId));

      if (!skipToast) {
        toast.success('Home location updated successfully');
      }
      dispatch(resolve(TYPES.setHomeLocation));
    });
  } catch (error) {
    dispatch(reject(TYPES.setHomeLocation, error));
  }
};

export const isLoading = (): AppThunk => (dispatch) => {
  dispatch({ type: TYPES.setIsLoading });
};

export const setPageName = (name: string) => ({
  type: TYPES.setPageName,
  payload: name,
});

export const setLocationDisabled = (disabled: boolean) => ({
  type: TYPES.setLocationDisabled,
  payload: disabled,
});

export const initApp = (): AppThunk => async (dispatch, getState) => {
  dispatch(getLocations())
    .then(async () => {
      const { profile } = getState().auth;
      let userProfile: UserProfile;

      recordEvent(getState(), 'Application initialization started');

      try {
        userProfile = await Api.get(`/user?Email=${encodeURIComponent(profile.email)}`);

        // if the userProfile returns null, it means the user has been disabled.
        // in this case, we want to set the app as initilized and sign the user out
        if (userProfile === null) {
          await dispatch(AuthState.actions.signOut('/auth/signin'));
          await dispatch({ type: TYPES.setInitilized, payload: true });

          recordEvent(getState(), 'User profile response returned null. User is disabled in the backend. Signing user out.');
          return;
        }
      } catch (error: any) {
        await dispatch(AuthState.actions.setUnAuthenticatedMessage(error?.response?.data?.message))
        recordEvent(getState(), 'User does not exist or other issuer, signing user out.');
        return dispatch(push('/unauthorized'));
      }

      const {
        SiteId,
        PersonnelId,
        Permissions,
        Preferences,
        SysAdminFlag,
      } = userProfile;

      const { appState } = getState();
      recordEvent(getState(), { message: 'dispatching actions to set user preferences, profiles and permissions', userProfile });
      dispatch(AuthState.actions.updateUserProfileValue({ PersonnelId, SiteId }));
      dispatch(AuthState.actions.setUserPreferences(Preferences));
      dispatch(AuthState.actions.setUserPermissions(Permissions, SysAdminFlag));

      // If a user hasn't signed into OPIS before their preferences will be set
      // to null. I which case, default back to their siteId
      const homeLocationId = Preferences && Preferences.HomeLocationId
      ? Preferences.HomeLocationId
      : userProfile.SiteId;

      const homeLocation = appState
      .locations
      .find((l) => l.LocationId === homeLocationId);

      recordEvent(getState(), { message: 'Setting user home location', homeLocation });

      if (!homeLocation) {
        dispatch(setLocationDisabled(true));

        recordEvent(getState(), { message: 'Home location for user is not ensabled. Redirecting to /location-not-enabled', homeLocation });
        return dispatch(push('/location-not-enabled'));
      }

      dispatch(AuthState.actions.setUserAbilities(homeLocation.LocationId));

      // default the selected location to the home location
      dispatch(PermitsState.actions.setLocation(homeLocation.LocationId));
      await dispatch(LocationsState.actions.getLocationMetadata(homeLocation.LocationId));
      dispatch(LocationsState.actions.getPlotPlanImage(homeLocation.LocationId));
      dispatch(getPrinters(homeLocation.LocationId));
      return dispatch({
        type: TYPES.setHomeLocation,
        payload: homeLocation,
      });
    }).then(() => {
      recordEvent(getState(), 'App initialization completed');
      dispatch({
        type: TYPES.setInitilized,
        payload: true,
      });
    });
};

const setPrintableStates = (states: string[]) => ({
  type: TYPES.setPrintableStates,
  payload: states,
});

export const resetStatus = (status: string) => reset(status);

export const resetLastRun = (action: any) => ({
  type: TYPES.resetLastRun,
  payload: action,
});

export const setPrintingResponse = (error: PrinterResponse) => ({
  type: TYPES.setPrintingResponse,
  payload: error,
});

export const selector = <T>(path: string) => (state: RootState) => _get(state.appState, path) as T;

const AppState = {
  actions: {
    init: initApp,
    resetStatus,
    resetLastRun,
    setPageName,
    setHomeLocation,
    setLocationDisabled,
    setPrintableStates,
    toggleLeftMenuDrawer,
    setPrintingResponse
  },
  selectors: {
    initilized: () => selector<boolean>('initilized'),
    leftMenuDrawerOpen: () => selector<boolean>('left'),
    locations: () => selector<Location[]>('locations'),
    locationDisabled: () => selector<boolean>('locationDisabled'),
    printers: () => selector<Printer[]>('printers'),
    printingResponse: () => selector<PrinterResponse>('printingResponse'),
    printableStates: () => selector<string[]>('printableStates'),
    pageName: () => selector<string>('pageName'),
    homeLocation: () => selector<Location>('homeLocation'),
    lastRun: (type: string) => selector<string>(`lastRun.${type}`),
    status: (scope: string) => selector<ActionStatusTypes>(`status.${scope}`),
  },
  types: TYPES,
};

export default AppState
