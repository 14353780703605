import { Auth } from 'aws-amplify';
// import { FederatedSignInOptions } from 'aws-amplify/auth/lib-esm/types';

import { PermissionsList, UserAbilities } from '../types/auth';
import Config from '../config/app-config';

// These numbers are the Ids from the OPIS permission structure.
export const PERMISSIONS = {
  Forms: {
    View: 84, // View module administration
    Edit: 83, // Modify module administration,
    Create: 83, // Modify module administration,
  },
  Permits: {
    View: 82, // View Permits,
    Create: 85, // Create and edit
    Edit: 85, // Create and edit
    Print: 87, // Create and edit
  },
  PermitTemplates: {
    // View: ,
    // Create: ,
    // Edit: ,
    // Approve: /
  },
  GroupAdministration: {
    View: 11, // show Group Administration moudle or not
    Edit: 12, // can edit group
    Delete: 13 // can delete group
  }
};

export const performFederatedSignin = (): void => {
  Auth.federatedSignIn({
    provider: Config.Amplify.Auth.adfsFederatedIdentityProviderId,
    // replace dashes with __ because for some reason AWS
    // trims everything after the first dash of a custom state.
    customState: String(window.location.pathname + window.location.search).replace(/-/g, '__'),
  // } as FederatedSignInOptions);
  } as any);
};

const hasPermission = (permissions: PermissionsList, locationId: number, permissionTypeId: number) => {
  if (!Array.isArray(permissions)) {
    return false;
  }
  const permissionType = permissions.find((p) => p.PermissionTypeId === permissionTypeId);

  if (
    !permissionType
    || !Array.isArray(permissionType.Locations)
    || !permissionType.Locations.length
  ) return false;

  return permissionType.Locations.includes(Number(locationId));
};

export const determineUserAbilities = (permissions: PermissionsList, locationId: number): UserAbilities => {
  const { Forms, Permits, GroupAdministration } = PERMISSIONS;

  return {
    permits: {
      edit: hasPermission(permissions, locationId, Permits.Edit),
      print: hasPermission(permissions, locationId, Permits.Print),
      view: hasPermission(permissions, locationId, Permits.View),
    },
    forms: {
      view: hasPermission(permissions, locationId, Forms.View),
      edit: hasPermission(permissions, locationId, Forms.Edit),
    },
    groupAdministration: {
      edit: hasPermission(permissions, locationId, GroupAdministration.Edit),
      view: hasPermission(permissions, locationId, GroupAdministration.View),
      delete: hasPermission(permissions, locationId, GroupAdministration.Delete),
    }
  };
};


export const isFormAdmin = (permissions: PermissionsList): boolean => {
  const { Forms } = PERMISSIONS;
  const userPermissions = permissions && permissions.map((p) => p.PermissionTypeId);

  if (!userPermissions) return false;
  return userPermissions.some((pId: number) => [Forms.View, Forms.Edit].includes(pId));
};

export const isEpermittingUser = (permissions: PermissionsList): boolean => {
  const { Forms, Permits } = PERMISSIONS;
  const keys = [...Object.values(Forms), ...Object.values(Permits)];
  return permissions && permissions.some((p) => keys.indexOf(p.PermissionTypeId) > -1);
};

