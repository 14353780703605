import React from 'react';
import { useSelector } from 'react-redux';
import Pagination from 'material-ui-flat-pagination';
import { Link } from 'react-router-dom';
import Alert from '@material-ui/lab/Alert';
import { Typography } from '@material-ui/core';

import WorkOrderFilters from './shared/WorkOrdersFilters';
import ListItemCard from './shared/ListItemCard';
import InlineLoader from '../shared/InlineLoader';
import WorkOrdersState from '../../actions/work-orders';

import useGetWorkOrders from '../../hooks/work-orders/useGetWorkOrders';
import useSetPageName from '../../hooks/shared/useSetPageName';
import { filterWorkOrdersByStatus } from '../../shared/work-orders';

const WorkOrdersList = () => {
  useSetPageName('Work Orders');

  const { loading, list: workOrderList, error: loadingError } = useGetWorkOrders();
  const { selectors } = WorkOrdersState;
  const { status: statusFilter, text: textFilter } = useSelector(selectors.filters());

  const [pagination, setPagination] = React.useState({
    offset: 0,
    limit: 10,
    page: 1,
  });

  const handlePagination = (event, offset, page) => {
    setPagination({
      ...pagination,
      offset,
      page,
    });
    window.scrollTo(0, 0);
  };

  const displayWorkOrderList = () => {
    let workorders = workOrderList.slice();

    if (statusFilter && statusFilter.length > 0) {
      workorders = filterWorkOrdersByStatus(workorders, statusFilter);
    }
    if (textFilter) {
      workorders = workorders.filter((workorder) => (
        workorder.workOrderDescription.toLowerCase().includes(textFilter.toLowerCase())
        || workorder.workOrderNumber.toString().includes(textFilter)
        || (workorder.assetNumber && workorder.assetNumber.toLowerCase().includes(textFilter.toLowerCase()))
        || (workorder.assetDescription && workorder.assetDescription.toLowerCase().includes(textFilter.toLowerCase()))
      ));
    }

    const { offset, limit, page } = pagination;
    const paginatedResults = workorders.slice(offset, (page + 1) * limit);

    if (!paginatedResults.length) {
      return (
        <Alert severity="warning">
          <Typography>
            No work orders matched the selected filters. Please update your selection or
            {' '}
            <Link to="/workorders/list">
              click here
            </Link>
            {' '}
            to reset the filters.
          </Typography>
        </Alert>
      );
    }

    return (
      <>
        {paginatedResults.map((workOrder) => (
          <ListItemCard
            key={workOrder.workOrderNumber}
            workOrder={workOrder}
          />
        ))}

        <Pagination
          limit={pagination.limit}
          total={workorders.length}
          offset={pagination.offset}
          onClick={handlePagination}
          size="medium"
        />
      </>
    );
  };

  const renderLoadFailed = () => (
    <Alert severity="error">
      There was an problem loading workorders. Please refresh the page to try again.
    </Alert>
  );

  return (
    <div className="workOrder-list">
      <WorkOrderFilters disabled={loading} />
      {loading ? <InlineLoader /> : displayWorkOrderList()}
      {!loading && loadingError && renderLoadFailed()}
    </div>
  );
};

export default WorkOrdersList;
