export const Permissions = [
	{
		"PermissionTypeId": 1,
		"Module": "Abnormal Equipment and Process",
		"Name": "View and acknowledge"
	},
	{
		"PermissionTypeId": 2,
		"Module": "Abnormal Equipment and Process",
		"Name": "Create and edit"
	},
	{
		"PermissionTypeId": 3,
		"Module": "Abnormal Equipment and Process",
		"Name": "Delete / Return to service"
	},
	{
		"PermissionTypeId": 4,
		"Module": "Abnormal Equipment and Process",
		"Name": "Receive email on AEP creation"
	},
	{
		"PermissionTypeId": 5,
		"Module": "Abnormal Equipment and Process",
		"Name": "Receive email on AEP change"
	},
	{
		"PermissionTypeId": 6,
		"Module": "Abnormal Equipment and Process",
		"Name": "Receive email on AEP return to service"
	},
	{
		"PermissionTypeId": 7,
		"Module": "Abnormal Equipment and Process",
		"Name": "Receive email when AEP exceeds estimated return to service date"
	},
	{
		"PermissionTypeId": 8,
		"Module": "Abnormal Equipment and Process",
		"Name": "Receive email when AEP open more than x days"
	},
	{
		"PermissionTypeId": 9,
		"Module": "Abnormal Equipment and Process",
		"Name": "View module administration"
	},
	{
		"PermissionTypeId": 10,
		"Module": "Abnormal Equipment and Process",
		"Name": "Modify module administration"
	},
	{
		"PermissionTypeId": 11,
		"Module": "Group Administration",
		"Name": "View Group configuration"
	},
	{
		"PermissionTypeId": 12,
		"Module": "Group Administration",
		"Name": "Create and edit groups"
	},
	{
		"PermissionTypeId": 13,
		"Module": "Group Administration",
		"Name": "Delete groups"
	},
	{
		"PermissionTypeId": 14,
		"Module": "Limit Reporting",
		"Name": "View deviation records"
	},
	{
		"PermissionTypeId": 15,
		"Module": "Limit Reporting",
		"Name": "Create and edit deviation records and actual production"
	},
	{
		"PermissionTypeId": 16,
		"Module": "Limit Reporting",
		"Name": "Delete deviation records"
	},
	{
		"PermissionTypeId": 17,
		"Module": "Limit Reporting",
		"Name": "View Limit Target configuration"
	},
	{
		"PermissionTypeId": 18,
		"Module": "Limit Reporting",
		"Name": "Create and edit limit targets"
	},
	{
		"PermissionTypeId": 19,
		"Module": "Limit Reporting",
		"Name": "View module administration"
	},
	{
		"PermissionTypeId": 20,
		"Module": "Limit Reporting",
		"Name": "Modify module administration"
	},
	{
		"PermissionTypeId": 21,
		"Module": "Operating Targets",
		"Name": "View and acknowledge"
	},
	{
		"PermissionTypeId": 22,
		"Module": "Operating Targets",
		"Name": "Create and edit"
	},
	{
		"PermissionTypeId": 23,
		"Module": "Operating Targets",
		"Name": "View module administration"
	},
	{
		"PermissionTypeId": 24,
		"Module": "Operating Targets",
		"Name": "Modify module administration"
	},
	{
		"PermissionTypeId": 31,
		"Module": "Safety Critical Defeats",
		"Name": "View and Acknowledge"
	},
	{
		"PermissionTypeId": 32,
		"Module": "Safety Critical Defeats",
		"Name": "Create, edit, and delete unapproved defeats"
	},
	{
		"PermissionTypeId": 33,
		"Module": "Safety Critical Defeats",
		"Name": "Receive email when defeat reaches defeated status"
	},
	{
		"PermissionTypeId": 34,
		"Module": "Safety Critical Defeats",
		"Name": "Receive email when defeat returns to service"
	},
	{
		"PermissionTypeId": 35,
		"Module": "Safety Critical Defeats",
		"Name": "View module administration"
	},
	{
		"PermissionTypeId": 36,
		"Module": "Safety Critical Defeats",
		"Name": "Modify module administration"
	},
	{
		"PermissionTypeId": 37,
		"Module": "Safety Critical Defeats",
		"Name": "View Defeats Equipment administration screen"
	},
	{
		"PermissionTypeId": 38,
		"Module": "Safety Critical Defeats",
		"Name": "Modify Defeats Equipment"
	},
	{
		"PermissionTypeId": 39,
		"Module": "Safety Critical Defeats",
		"Name": "Create Defeats Equipment from Add/Edit Defeats screen"
	},
	{
		"PermissionTypeId": 40,
		"Module": "SAP Link",
		"Name": "View"
	},
	{
		"PermissionTypeId": 41,
		"Module": "SAP Link",
		"Name": "View module administration"
	},
	{
		"PermissionTypeId": 42,
		"Module": "SAP Link",
		"Name": "Modify module administration"
	},
	{
		"PermissionTypeId": 43,
		"Module": "Shift Handover",
		"Name": "View module administration"
	},
	{
		"PermissionTypeId": 44,
		"Module": "Shift Handover",
		"Name": "Modify module administration"
	},
	{
		"PermissionTypeId": 45,
		"Module": "Shift Logs",
		"Name": "View"
	},
	{
		"PermissionTypeId": 46,
		"Module": "Shift Logs",
		"Name": "Create and edit"
	},
	{
		"PermissionTypeId": 47,
		"Module": "Shift Logs",
		"Name": "Chief Steam Engineer approve"
	},
	{
		"PermissionTypeId": 48,
		"Module": "Shift Logs",
		"Name": "Shift Engineer signoff"
	},
	{
		"PermissionTypeId": 49,
		"Module": "Shift Logs",
		"Name": "View Shift Log Templates"
	},
	{
		"PermissionTypeId": 50,
		"Module": "Shift Logs",
		"Name": "Modify Shift Log Templates"
	},
	{
		"PermissionTypeId": 51,
		"Module": "Shift Logs",
		"Name": "View module administration"
	},
	{
		"PermissionTypeId": 52,
		"Module": "Shift Logs",
		"Name": "Modify module administration"
	},
	{
		"PermissionTypeId": 53,
		"Module": "Shift Handover",
		"Name": "Catch up shift handovers"
	},
	{
		"PermissionTypeId": 54,
		"Module": "Shift Orders",
		"Name": "View and acknowledge"
	},
	{
		"PermissionTypeId": 55,
		"Module": "Shift Orders",
		"Name": "Create and edit"
	},
	{
		"PermissionTypeId": 56,
		"Module": "Shift Orders",
		"Name": "Delete / Withdraw"
	},
	{
		"PermissionTypeId": 57,
		"Module": "Shift Orders",
		"Name": "Engineer Approve"
	},
	{
		"PermissionTypeId": 58,
		"Module": "Shift Orders",
		"Name": "Supervisor Approve"
	},
	{
		"PermissionTypeId": 59,
		"Module": "Shift Orders",
		"Name": "Receive email on Engineer Approval"
	},
	{
		"PermissionTypeId": 60,
		"Module": "Shift Orders",
		"Name": "Receive email on Supervisor Approval"
	},
	{
		"PermissionTypeId": 61,
		"Module": "Shift Orders",
		"Name": "Receive email when my shift order is withdrawn"
	},
	{
		"PermissionTypeId": 62,
		"Module": "Shift Orders",
		"Name": "View module administration"
	},
	{
		"PermissionTypeId": 63,
		"Module": "Shift Orders",
		"Name": "Modify module administration"
	},
	{
		"PermissionTypeId": 64,
		"Module": "Limit Reporting",
		"Name": "View Non-SAP Equipment"
	},
	{
		"PermissionTypeId": 65,
		"Module": "Limit Reporting",
		"Name": "Modify Non-SAP Equipment"
	},
	{
		"PermissionTypeId": 66,
		"Module": "Limit Reporting",
		"Name": "Modify Master Reason List"
	},
	{
		"PermissionTypeId": 67,
		"Module": "Safe Operating Control Limits",
		"Name": "View and acknowledge"
	},
	{
		"PermissionTypeId": 68,
		"Module": "Safe Operating Control Limits",
		"Name": "Create and edit"
	},
	{
		"PermissionTypeId": 69,
		"Module": "Safe Operating Control Limits",
		"Name": "Delete"
	},
	{
		"PermissionTypeId": 70,
		"Module": "Safe Operating Control Limits",
		"Name": "Receive email on SOCL creation"
	},
	{
		"PermissionTypeId": 71,
		"Module": "Safe Operating Control Limits",
		"Name": "Receive email on SOCL change"
	},
	{
		"PermissionTypeId": 72,
		"Module": "Safe Operating Control Limits",
		"Name": "View module administration"
	},
	{
		"PermissionTypeId": 73,
		"Module": "Safe Operating Control Limits",
		"Name": "Modify module administration"
	},
	{
		"PermissionTypeId": 74,
		"Module": "Limit Reporting",
		"Name": "Modify Reason Collections"
	},
	{
		"PermissionTypeId": 75,
		"Module": "Safety Critical Defeats",
		"Name": "Receive email when SIS defeat reaches defeated status"
	},
	{
		"PermissionTypeId": 76,
		"Module": "Safety Critical Defeats",
		"Name": "Receive email when SIS defeat returns to service"
	},
	{
		"PermissionTypeId": 77,
		"Module": "Safety Critical Defeats",
		"Name": "Approve by email"
	},
	{
		"PermissionTypeId": 78,
		"Module": "Limit Reporting",
		"Name": "Allow modification of validated data"
	},
	{
		"PermissionTypeId": 79,
		"Module": "HyperLinks",
		"Name": "View HyperLinks"
	},
	{
		"PermissionTypeId": 80,
		"Module": "HyperLinks",
		"Name": "Modify module administration"
	},
	{
		"PermissionTypeId": 81,
		"Module": "HyperLinks",
		"Name": "View module administration"
	},
	{
		"PermissionTypeId": 82,
		"Module": "Permits",
		"Name": "View Permits"
	},
	{
		"PermissionTypeId": 83,
		"Module": "Permits",
		"Name": "Modify module administration"
	},
	{
		"PermissionTypeId": 84,
		"Module": "Permits",
		"Name": "View module administration"
	},
	{
		"PermissionTypeId": 85,
		"Module": "Permits",
		"Name": "Create and edit"
	},
	{
		"PermissionTypeId": 86,
		"Module": "Shift Logs",
		"Name": "View Ownership History"
	},
	{
		"PermissionTypeId": 87,
		"Module": "Permits",
		"Name": "Print"
	},
	{
		"PermissionTypeId": 88,
		"Module": "Permits",
		"Name": "Assign emailed attachments"
	},
	{
		"PermissionTypeId": 89,
		"Module": "Permits",
		"Name": "Modify permit templates"
	},
	{
		"PermissionTypeId": 90,
		"Module": "Permits",
		"Name": "Approve permit templates"
	},
	{
		"PermissionTypeId": 91,
		"Module": "Permits",
		"Name": "Administer external users"
	}
]