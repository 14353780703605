import { get as _get } from 'lodash';
import moment from 'moment';

import { queryElasticSearchPermits } from '../../shared/permits';
import { formatObjectAsSearchQuery } from '../../shared/url';

export const TYPES = {
  setLoading: 'PERMITS_SEARCH_RESULTS_LOADING',
  setSearchResults: 'PERMITS_SEARCH_RESULTS_SET',
  clearSearchResults: 'PERMITS_SEARCH_RESULTS_CLEAR',
};

/**
 * performs a search of Elasticsearch for permits
 * @param {{[key: string]}} filters - search filters
 * @return {ESPermit[]} - array of ES permit results
 */
export const searchPermits = (searchTerms, filters, loadMore) => {
  return async (dispatch, getState) => {
    try {
      const { appState, auth, permits: permitsState, definitions } = getState();
      const { initialDefinitionForSite, list } = definitions;
      const filterTerm = list[initialDefinitionForSite.PermitDefinitionId]?.filterTerm || [];
      if (!loadMore) {
        dispatch({ type: TYPES.clearSearchResults });
      }

      dispatch({
        type: TYPES.setLoading,
        payload: true,
      });

      const startPage = loadMore ? permitsState.search.page : 0;

      const searchResults = await queryElasticSearchPermits(
        searchTerms,
        filters,
        appState.locations,
        appState.homeLocation,
        auth.permissions,
        startPage,
        filterTerm
      );

      // eslint-disable-next-line no-underscore-dangle
      const permits = _get(searchResults, 'hits.hits', []).map((p) => p._source);

      const nextPage = loadMore ? permitsState.search.page + 20 : 20;

      dispatch({
        type: TYPES.setSearchResults,
        payload: {
          list: loadMore ? [...permitsState.search.list, ...permits] : permits,
          total: _get(searchResults, 'hits.total.value', 0),
          page: nextPage,
        },
      });

      dispatch({
        type: TYPES.setLoading,
        payload: false,
      });

      // delete filters.Location;

      // if (filters.fromDate) filters.fromDate = moment(filters.fromDate).format('YYYY-MM-DD');
      // if (filters.toDate) filters.toDate = moment(filters.toDate).format('YYYY-MM-DD');

      // const filterString = formatObjectAsSearchQuery({
      //   ...filters,
      //   ...(filters.Location ? { locationId: filters.Location.LocationId } : {}),
      //   searchTerms,
      // });

      // window.history.pushState('', '', `${window.location.pathname}?page=${nextPage}`);
    } catch (error) {
      console.log('error:', error)
    }
  };
};

const clearResults = () => (dispatch) => dispatch({ type: TYPES.clearSearchResults });

export default {
  types: TYPES,
  actions: {
    searchPermits,
    clearResults,
  },
  selectors: {
    search: (state) => state.permits.search,
  },
};
