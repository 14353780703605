import React from 'react';
import { } from '@material-ui/core/styles';
import TreeView from '@material-ui/lab/TreeView';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { makeStyles } from '@material-ui/core';
import useLocationTree, { LocationTreeLocation } from '../../../../hooks/locations/useLocationTree';
import LocationTreeItem from './LocationTreeItem';

export type LocationPermissions = {
  CanEdit: number[]
  CanPrint: number[]
}

type Props = {
  initialPermissions?: LocationPermissions;
  onChange: (permissions: LocationPermissions) => void;
}

const useStyles = makeStyles({
  root: {
    maxWidth: 700,
  },
});

const LocationPermissionsTree: React.FC<Props> = (props) => {
  const { initialPermissions, onChange } = props;

  const classes = useStyles();
  const { tree, utils } = useLocationTree();

  const [expanded, setExpanded] = React.useState<string[]>([]);
  const [canEdit, setCanEdit] = React.useState<Set<number>>(new Set());
  const [canPrint, setCanPrint] = React.useState<Set<number>>(new Set());

  React.useEffect(() => {
    if (!initialPermissions) {
      return;
    }

    const { CanEdit, CanPrint } = initialPermissions;

    if (CanEdit || CanPrint) {
      setCanPrint(new Set(CanPrint));
      setCanEdit(new Set(CanEdit));
    }
  }, [initialPermissions]);

  React.useEffect(() => {
    onChange({
      CanEdit: Array.from(canEdit),
      CanPrint: Array.from(canPrint),
    });
  }, [canEdit, canPrint, onChange]);

  const handleToggle = (event: React.ChangeEvent<{}>, nodeIds: string[]) => {
    setExpanded(nodeIds);
  };

  const handlePermissionClick = (value: boolean, type: 'print' | 'edit', location: LocationTreeLocation) => {
    const children = utils.getChildrenOfLocation(location);
    const childIds = children.map(ch => ch.LocationId);

    if (type === 'print') {
      const nextValue = new Set(canPrint);

      value
        ? childIds.map(id => nextValue.add(id))
        : childIds.map(id => nextValue.delete(id))

      setCanPrint(nextValue);
    }
    if (type === 'edit') {
      const nextValue = new Set(canEdit);

      value
        ? childIds.map(id => nextValue.add(id))
        : childIds.map(id => nextValue.delete(id))

      setCanEdit(nextValue);
    }
  }

  const renderTree = (location: LocationTreeLocation) => {
    const disabled = location.LocationId !== 0
      && (!location.EnabledFlag || !location.userHasAccess);

    const childSitesEnabled = utils.hasEnabledChildSites(location);

    if (disabled && !childSitesEnabled) return null;

    return (
      <LocationTreeItem
        key={String(location.LocationId)}
        nodeId={String(location.LocationId)}
        canPrint={canPrint.has(location.LocationId)}
        canEdit={canEdit.has(location.LocationId)}
        location={location}
        labelText={location.Title}
        onPermissionClick={handlePermissionClick}
      >
        {Array.isArray(location.children)
          && location.children.length
          ? location.children.map((node: any) => renderTree(node))
          : null}
      </LocationTreeItem>
    );
  };

  return (
    <TreeView
      className={classes.root}
      defaultCollapseIcon={<ExpandMoreIcon />}
      defaultExpandIcon={<ChevronRightIcon />}
      expanded={expanded}
      onNodeToggle={handleToggle}
    >
      {tree && renderTree(tree)}
    </TreeView>
  );
}


export default LocationPermissionsTree;
