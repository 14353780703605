import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import {
  Card,
  CardContent,
  Typography,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from '@material-ui/core';

import useWithAbilities from '../../hooks/auth/useAbilities';
import { capitalizeFirstLetter } from '../../shared/string';

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: 275,
    padding: theme.spacing(1),
  },
  title: {
    fontSize: 14,
    marginBottom: 12,
  },
  pos: {
    fontSize: 14,
  },
}));

const LocationAbilities = () => {
  const classes = useStyles();
  const abilities = useWithAbilities();

  const structuredAbilities = React.useMemo(() => {
    const someForms = abilities.forms
      && Object.values(abilities.forms).some((p) => p === true);

    const somePermits = abilities.permits
      && Object.values(abilities.permits).some((p) => p === true);

    const response = {};

    if (someForms) {
      response.forms = abilities.forms;
    }

    if (somePermits) {
      response.permits = abilities.permits;
    }

    return response;
  }, [abilities]);

  return (
    <Card className={classes.root}>
      <CardContent>
        <Typography className={classes.title} color="primary" gutterBottom>
          Location Abilities
        </Typography>
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Area</TableCell>
              <TableCell align="right">View</TableCell>
              <TableCell align="right">Edit</TableCell>
              <TableCell align="right">Print</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Object.keys(structuredAbilities).map((area) => {
              const areaAbilities = structuredAbilities[area];
              return (
                <TableRow key={area}>
                  <TableCell component="th" scope="row">
                    {capitalizeFirstLetter(area)}
                  </TableCell>
                  <TableCell align="right">{areaAbilities.view ? 'Yes' : 'No'}</TableCell>
                  <TableCell align="right">{areaAbilities.edit ? 'Yes' : 'No'}</TableCell>
                  {area === 'permits' && <TableCell align="right">{(areaAbilities.print ? 'Yes' : 'No')}</TableCell>}
                  {area === 'forms' && <TableCell align="right">n/a</TableCell>}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </CardContent>
    </Card>
  );
};

export default LocationAbilities;
