import React from 'react';
import { IconButton, makeStyles, Typography, Tooltip } from '@material-ui/core';
import WarningOutlinedIcon from '@material-ui/icons/WarningOutlined';
import { Star as StarIcon } from '@material-ui/icons';

import { Printer } from '../../../types/printer';

type Props = {
  favourite: boolean;
  default: boolean;
  printer: Printer;
  onToggleFavourite: (printerId: number) => void;
};

const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'row',
    minWidth: '100%',
    alignItems: 'center',
    height: theme.spacing(2)
  },
  favourite: {
    fill: theme.palette.warning.main
  },
  starIcon: {
    marginLeft: theme.spacing(1),
    color: '#ff9800'
  },
  details: {
    fontSize: 12,
    color: '#9e9e9e',
    marginTop: theme.spacing(0.5)
  }
}))

const PrinterListItem: React.FC<Props> = (props) => {
  const {
    favourite,
    printer,
    onToggleFavourite
  } = props;

  const classes = useStyles();
  const [hoverActive, setHoverActive] = React.useState<boolean>(false);

  const handleOnMouseEnter = () => {
    setHoverActive(true)
  }
  const handleOnMouseLeave = () => {
    setHoverActive(false)
  }

  const favouriteIconVisible = hoverActive || favourite;
  return (
    <div className={classes.wrapper} onMouseOver={handleOnMouseEnter} onMouseLeave={handleOnMouseLeave}>
      <Typography>{printer.Name}</Typography>
      {
        !printer?.Online 
        &&
        (<Tooltip title="May be Offline">
          <WarningOutlinedIcon classes={{ root: classes.starIcon }} fontSize="small" color="error" />
        </Tooltip>
        )}
      {favouriteIconVisible && (
        <Tooltip title={`${favourite ? 'Clear ' : ''}Favourite`}>
          <IconButton
            size="small"
            className={classes.starIcon}
            disableRipple
            onClick={() => onToggleFavourite(printer.PermitPrinterId)}
          >
            <StarIcon
              classes={{ root: favourite ? classes.favourite : undefined }}
            />
          </IconButton>
        </Tooltip>
      )}
    </div>
  );
}

export default PrinterListItem;
