import { shape, arrayOf, number, string, oneOf } from 'prop-types';

import { WorkOrderStatuses } from '../work-order';

export const WorkOrderOperation = shape({
  operationNumber: string.isRequired,
  operationDescription: string.isRequired,
  operationStatus: string.isRequired,
  resources: string,
  operationSystemStatus: string.isRequired,
  operationSystemStatusDescription: string.isRequired,
  operationUserStatus: string.isRequired,
  operationUserStatusDescription: string.isRequired,
});

export const ESWorkOrder = shape({
  workOrderNumber: string.isRequired,
  workOrderDescription: string.isRequired,
  workOrderStatus: arrayOf(
    oneOf(WorkOrderStatuses),
  ).isRequired,
  assetNumber: string,
  assetDescription: string,
  orderType: string,
  plantCode: string,
  revisionNumber: string,
  equipmentNumber: string,
  scheduledStart: string.isRequired,
  siteId: number.isRequired,
  sourceSystem: oneOf(['SAP', 'Oracle']),
  workCenterName: string,
  operations: arrayOf(WorkOrderOperation),
});
