/* eslint-disable no-shadow */
/* eslint-disable no-param-reassign */
import React from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import { TreeView, TreeItem } from '@material-ui/lab';
import Alert from '@material-ui/lab/Alert';
import { Typography } from '@material-ui/core';
import {
  ExpandMore as ExpandMoreIcon,
  ChevronRight as ChevronRightIcon,
} from '@material-ui/icons';

import useWithUserPreferences from '../../hooks/auth/useWithUserPreferences';
import useWithUserPermissions from '../../hooks/auth/useWithUserPermissions';
import useLocations from '../../hooks/locations/useLocations';

import InlineLoader from './InlineLoader';

const useStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.text.secondary,
    '&:focus > $content, &$selected > $content': {
      backgroundColor: `var(--tree-view-bg-color, ${theme.palette.grey[400]})`,
      color: 'var(--tree-view-color)',
    },
    '&:focus > $content $label, &:hover > $content $label, &$selected > $content $label': {
      backgroundColor: 'transparent',
    },
  },
  content: {},
  group: {
    marginLeft: theme.spacing(2),
  },
  expanded: {
  },
  selected: {},
  label: {
    fontWeight: 'inherit',
    color: 'inherit',
  },
  labelRoot: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0.5, 0),
  },
  labelIcon: {
    marginRight: theme.spacing(1),
  },
  labelText: {
    fontWeight: 'inherit',
    flexGrow: 1,
  },
}));

/**
 * Traverses up the location tree from the specified leaf and finds the path to the root node
 * @param {Location[]} tree - Array of locations
 * @param {string} locationId - The id of the leaf node to start at
 * @return {string[]} - Array of LocationIds cast as strings starting at the left, ending at the root
 */
const getPathToRoot = (tree, locationId) => {
  // cast as a string as that's what the MaterialUI component expects
  const locationPath = [String(locationId)];

  let currLeaf = locationId;
  while (currLeaf !== 0) {
    const currSetLeaf = currLeaf;
    const leaf = tree.find((l) => l.LocationId === Number(currSetLeaf));
    if (leaf) currLeaf = leaf.ParentLocationId;
    locationPath.push(String(currLeaf));
  }
  return locationPath;
};

export default function LocationSelectTree(props) {
  const { defaultSelected } = props;
  const classes = useStyles();
  const { permissions } = useWithUserPermissions();
  const { profile: { SiteId } } = useWithUserPreferences();
  const { locations, selected: selectedLocation } = useLocations();

  const locationsUserCanView = React.useMemo(() => {
    const viewPermissions = permissions.find((p) => p.PermissionTypeId === 82);
    return viewPermissions ? viewPermissions.Locations : [];
  }, [permissions]);

  const locationIdFromState = defaultSelected || selectedLocation
  ? selectedLocation.LocationId
  : SiteId;

  // This component is initialized differently depending on the location it's being used.
  // this allows for a defaultSelected LocationId to be provided as a prop, else use the
  // selected or home location set in state.
  const initialLocation = defaultSelected || locationIdFromState || 0;

  const [selected, setSelected] = React.useState(String(initialLocation));
  const [expanded, setExpanded] = React.useState(getPathToRoot(locations, initialLocation));

  const tree = React.useMemo(() => {

    const recurse = (location, locations, tree) => {
      if (location.LocationId === 0) {
        tree = location;
        tree.Title = 'Nutrien';
      }

      if (locationsUserCanView.includes(location.LocationId)) {
        location.userHasAccess = true;
      } else {
        location.userHasAccess = false;
      }

      location.children = locations
        .filter((loc) => loc.LocationId !== 0 && loc.ParentLocationId === location.LocationId);

      if (location.children) {
        location.children.forEach((child) => recurse(child, locations, tree));
      }
      return tree;
    };

    let builtTree = {};

    if (locations.length) {
      builtTree = recurse(
        locations.find((l) => l.LocationId === 0),
        locations,
        {},
      );
    }

    return builtTree;
  }, [locations, locationsUserCanView]);

  const handleItemClick = (location) => {
    setSelected(String(location.LocationId));

    // If permitting is disabled or the does not have view permits permissions
    // for a location, set the locationId to 0 to ensure that no updates
    // can be made (this is checked for elsewhere in the app)
    const nextLocation = location.PermittingEnabled && location.userHasAccess
      ? location
      : { LocationId: 0 };

    props.onChange(nextLocation);
  };

  const handleChange = (event, nodes) => {
    setExpanded(nodes);
  };

  const hasEnabledChildSites = (node) => {
    let hasEnabledLeaf = false;

    const traverseBranch = (leaf) => {
      if (Array.isArray(leaf.children) && leaf.children.length) {
        leaf.children.forEach((ch) => traverseBranch(ch));
      } else if (leaf.EnabledFlag && leaf.userHasAccess) {
        hasEnabledLeaf = true;
      }
    };

    traverseBranch(node);

    return hasEnabledLeaf;
  };

  const renderTree = (nodes) => {
    const disabled = nodes.Location !== 0
      && (!nodes.EnabledFlag || !nodes.userHasAccess);

    const childSitesEnabled = hasEnabledChildSites(nodes);

    if (disabled && !childSitesEnabled) return null;

    return (
      <TreeItem
        key={String(nodes.LocationId)}
        onClick={() => handleItemClick(nodes)}
        nodeId={String(nodes.LocationId)}
        classes={{
          root: classes.root,
          content: classes.content,
          expanded: classes.expanded,
          selected: classes.selected,
          group: classes.group,
          label: classes.label,
        }}
        style={{
          '--tree-view-color': 'white',
          '--tree-view-bg-color': '#007bff',
          ...(disabled ? { color: 'rgba(0, 0, 0, 0.30)' } : {}),
        }}
        label={nodes.Title}
      >
        {Array.isArray(nodes.children)
          && nodes.children.length
          ? nodes.children.map((node) => renderTree(node))
          : null}
      </TreeItem>
    );
  };

  return !Array.isArray(expanded)
    ? <InlineLoader />
    : (
      <TreeView
        className={classes.root}
        defaultCollapseIcon={<ExpandMoreIcon />}
        defaultExpandIcon={<ChevronRightIcon />}
        expanded={expanded}
        onNodeToggle={handleChange}
        selected={selected}
      >
        {Object.keys(tree).length
          ? renderTree(tree)
          : (
            <Alert severity="warning">
              <Typography>There are no locations enabled for E-Permitting at your site.</Typography>
            </Alert>
          )}
      </TreeView>
    );
}

LocationSelectTree.defaultProps = {
  defaultSelected: undefined,
};

LocationSelectTree.propTypes = {
  defaultSelected: PropTypes.number,
  onChange: PropTypes.func.isRequired,
};
