import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';

import { logErrors } from './shared/analytics';
import reduxStore from './store';
import App from './App';
import { setPageScroll } from './shared/utils';
import './index.scss';
import 'react-toastify/dist/ReactToastify.css';

// eslint-disable-next-line @typescript-eslint/no-var-requires
require('dotenv').config();

const { store, history } = reduxStore;

logErrors();
setPageScroll();

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <App />
      </ConnectedRouter>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root'),
);
