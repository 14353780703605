import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { CSVLink } from 'react-csv';
import { GetApp as DownloadIcon } from '@material-ui/icons';

import {
  Button,
  Link,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  makeStyles,
} from '@material-ui/core';

import { PermitConfigMap } from '../../../../config/permits';
import { TableStats } from '../query';
import { PermitState } from '../../../../types/permit';

const useStyles = makeStyles((theme) => ({
  locationCard: {
    marginBottom: theme.spacing(2),
    padding: theme.spacing(2),
  },
  exportButton: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: theme.spacing(2),
  },
}));

type Props = {
  data: TableStats;
  states: Partial<PermitConfigMap>;
}

const OpenPermitsTable: React.FC<Props> = ({ data, states }) => {
  const classes = useStyles();

  const csvData = React.useMemo(() => {
    const sts = Object.keys(states) as PermitState[];
    const headings = ['', ...sts, 'Total'];

    const rows = data.map((loc) => {
      const total = Object.keys(loc.counts).reduce((sum, st) => {
        const state = st as PermitState;

        return states[state] && loc.counts[state]
          ? sum + loc.counts[state]
          : sum;
      }, 0);

      return [loc.location.Title, ...sts.map((state) => loc.counts[state] || 0), total];
    });

    return [headings, ...rows];
  }, [data, states]);

  return (
    <>
      <Paper className={classes.locationCard}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell />
              {Object.keys(states).map((key) => <TableCell key={key} align="center">{key}</TableCell>)}
              <TableCell align="right">Total</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {(data).map((location) => (
              <TableRow hover key={location.location.LocationId}>
                <TableCell>{location.location.Title}</TableCell>
                {Object.keys(states).map((key) => (
                  <TableCell key={key} align="center">
                    <Link
                      color="inherit"
                      underline="hover"
                      component={RouterLink}
                      to={`/permits/search?State=${key}&locationId=${location.location.LocationId}&from=reporting`}
                    >
                      {location.counts[key as PermitState] || 0}
                    </Link>
                  </TableCell>
                ))}
                <TableCell align="right">
                  {Object.keys(location.counts).reduce((sum, st) => {
                    const state = st as PermitState;
                    return states[state] && location.counts[state]
                      ? sum + location.counts[state]
                      : sum;
                  }, 0)}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <CSVLink className={classes.exportButton} filename="permits-by-state-report.csv" data={csvData}>
          <Button color="primary" startIcon={<DownloadIcon />}>
            Export to Excel
          </Button>
        </CSVLink>
      </Paper>
    </>
  );
};

export default OpenPermitsTable;
