import React, { useCallback, useEffect, useState } from 'react';
import Api from '../../shared/api';
import toast from '../../shared/toast';
import useLocations from '../locations/useLocations';

export const ACTIONS = {
  CREATE: 'CREATE',
  UPDATE: 'UPDATE',
  DELETE: 'DELETE'
}

const MSG = {
  CREATE: 'Group has been created successfully.',
  UPDATE: 'Group has been updated successfully.',
  DELETE: 'Group has been deleted successfully.'
}

const MSGERROR = {
  CREATE: 'There was a problem while loading creating group, please try again.',
  UPDATE: 'There was a problem while loading updating group, please try again.',
  DELETE: 'There was a problem while loading deleting group, please try again.'
};

export const useFetchGroupData = () => {
  const [groupData, setGroupData] = useState([]);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const {homeLocation: { LocationId }} = useLocations();

  const fetchData = useCallback(async () => {
    setIsLoading(true);
    setError(false);
    try {
      const result = await Api.get(`/groups/location/${LocationId}`);
      const data = result.map((row, i) => {
        if (!row.Members) {
          row.Members = [];
        }
        if (!row.Permissions) {
          row.Permissions = [];
        }
        return { ...row, rowId: i }
      })
      setGroupData(data);
      setIsLoading(false);
    } catch (error) {
      setError(true);
      setGroupData([]);
      setIsLoading(false);
      toast.error('There was a problem while loading groups, please try again.');
    }
  }, [LocationId]);

  return { rowData: groupData, error, isLoading, fetchData };
};

export const useUpdateGroupData = () => {
  const [success, updateSuccess] = useState(null);
  const [loading, setIsLoading] = useState(false);
  const {homeLocation: { LocationId }} = useLocations();
  const updateGroup = useCallback(async (payload = {}, actionType) => {
    setIsLoading(true);
    updateSuccess(false);
    const { GroupId, Members } = payload;
    try {
      if(actionType === ACTIONS.CREATE) {
        const data = {
          ...payload,
          GroupId: null,
          LocationId,
          EnabledFlag: true,
          CreatedOn: null
        }
        await Api.post('/groups', data);

      } else if(actionType === ACTIONS.UPDATE) {
        const data = { ...payload, Members: Members.map(id => id.PersonnelId) }
        await Api.put(`/groups/${GroupId}`, data);

      } else if(actionType === ACTIONS.DELETE) {
        await Api.delete(`/groups/${GroupId}`);
      }
      updateSuccess(true);
      setIsLoading(false);
      toast.success(MSG[actionType]);

    } catch (error) {
      updateSuccess(false);
      setIsLoading(false);
      toast.error(MSGERROR[actionType]);
    }
  }, [LocationId]);

  return { success, loading, updateGroup };
};

export const useSearchUser = () => {
  const [users, updateUsers] = useState([]);
  const [error, setError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const {homeLocation: { SiteId, LocationId }} = useLocations();

  const fetchUsers = useCallback(async (data) => {
    const { FirstName, LastName, isOnlySiteSearch } = data;
    if (!FirstName && !LastName) {
      updateUsers([]);
      setError(false);
      return
    }
    setIsLoading(true);
    setError(false);
    const firsname = FirstName ? `FirstName=${FirstName}` : '';
    const lastname = LastName ? `&LastName=${LastName}` : '';
    const site =  isOnlySiteSearch ? `&SiteId=${SiteId}`: '';

    const url = 'users/search?'+ firsname + lastname + site;
    try {
      const res = await Api.get(url);
      updateUsers(res)
      setIsLoading(false);
      setError(false);
    } catch (error) {
      setError(true);
      setIsLoading(false);
      updateUsers([])
      toast.error('There was a problem while searching users, please try again.')
    }
  }, [SiteId, LocationId]);

  return { users, error, isLoading, fetchUsers };
};

export const useCopyLocation = () => {

  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const {homeLocation: { LocationId }} = useLocations();

  const copyLocations = useCallback(async (payload = {}) => {
    setIsLoading(true);
    setError(false);
    try {
      const { GroupId } = payload;
      await Api.post(`/groups/${GroupId}/copy`, {LocationIds: payload.LocationIds});
      toast.success('Group has been copied successfully.');
      setIsLoading(false);
    } catch (error) {
      setError(true);
      setIsLoading(false);
      toast.error('There was a problem while copying group or selected locations may not have enough permissions.');
    }
  }, [LocationId]);

  return { copyLocations, error, isLoading };
};

export const useUpdateMember = () => {

  const [success, updateSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const {homeLocation: { LocationId }} = useLocations();

  const updateMember = useCallback(async (payload = {}) => {
    setIsLoading(true);
    updateSuccess(false);
    try {
      const { GroupId } = payload;
      await Api.put(`/groups/${GroupId}`, payload);
      setIsLoading(false);
      updateSuccess(true);
      toast.success('User has been added successfully');
    } catch (error) {
      updateSuccess(false);
      setIsLoading(false);
      toast.error('There was a problem while updating members, please try again.');
    }
  }, [LocationId]);

  return { updateMember, success, isLoading };
};

export const useUpdatePermissions = () => {

  const [success, updateSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const {homeLocation: { LocationId }} = useLocations();

  const updatePermissions = useCallback(async (payload = {}) => {
    setIsLoading(true);
    updateSuccess(false);
    try {
      const { GroupId } = payload;
      const data = { ...payload };
      data.Members = payload.Members.map(data => data.PersonnelId)
      await Api.put(`/groups/${GroupId}`, data);
      toast.success('Group permissions has been updated successfully.');
      setIsLoading(false);
      updateSuccess(true)
    } catch (error) {
      updateSuccess(false);
      setIsLoading(false);
      toast.error('There was a problem while updating group permissions.');
    }
  }, [LocationId]);

  return { updatePermissions, success, isLoading };
};
