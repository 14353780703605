import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import api from '../../shared/api';
import Collections from '../../store/indexeddb';

import LocationsState from '../../actions/locations';
import PermitsState from '../../actions/permits';

import Config from '../../config/app-config';

const useCacheExpiry = (ttl, online) => {
  const dispatch = useDispatch();
  const interval = React.useRef();

  React.useEffect(() => {
    interval.current = setInterval(() => {
      Collections.Permits.keys().then((PermitInstanceIds) => {
        if (!online || !PermitInstanceIds || PermitInstanceIds.length === 0) {
          return Promise.resolve();
        }

        return api
          .post('/lastchanged/instance', { PermitInstanceIds })
          // eslint-disable-next-line arrow-body-style
          .then(({ PermitInstanceList }) => {
            return Promise.all(PermitInstanceList.map(async (time) => {
              const permit = await Collections.Permits.get(time.PermitInstanceId);
              if (new Date(time.DateChanged) > new Date(permit.instance.DateChanged)) {
                dispatch(PermitsState.actions.updateCachedPermit(time.PermitInstanceId));
              }
            }));
          });
      });
    }, ttl);
  }, [ttl, online, dispatch]);

  return () => {
    if (interval.current(interval.current)) {
      interval.current();
    }
  };
};

const useLocationMetadataRefresh = (ttl, online) => {
  const dispatch = useDispatch();
  const interval = React.useRef();

  React.useEffect(() => {
    interval.current = setInterval(() => {
      if (!online) {
        return Promise.resolve();
      }
      dispatch(LocationsState.actions.refreshLocationMetadata());
    }, ttl);
  }, [ttl, online, dispatch]);

  return () => {
    if (interval.current(interval.current)) {
      interval.current();
    }
  };
};

export default function useIndexedDbExpiry() {
  const { online } = useSelector(st => st.offline);
  useCacheExpiry(Config.Cache.expirePermitsAfter, online);
  useLocationMetadataRefresh(Config.Cache.expireLocationMetadataAfter, online);
}
