import React from 'react';
import { useSelector } from 'react-redux';

import AppState from '../../actions/app';
import PermitsState from '../../actions/permits';

const useWithPrinters = () => {
  const [printers, setPrinters] = React.useState([]);
  const [merge, setMerge] = React.useState(false);

  const list = useSelector(AppState.selectors.printers());
  const printingResponse = useSelector(AppState.selectors.printingResponse());
  const mergeDefault = useSelector(PermitsState.selectors.printMergeDefault());

  React.useEffect(() => {
    setPrinters(list);
  }, [list]);

  React.useEffect(() => {
    setMerge(mergeDefault);
  }, [mergeDefault]);

  return { merge, printers, printingResponse };
};

export default useWithPrinters;