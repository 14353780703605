import _findKey from 'lodash/findKey';
import { get as _get } from 'lodash';

import PermitsState from '../../actions/permits';

import { creatPermitListItem, updatePermitInList } from '../../shared/permits';

import { searchReducer } from './search';

export const initialState = {
  approvals: null,
  attachments: [],
  draft: {},
  draftsList: [],
  filter: {
    filter: 'allPermits',
    state: ['DRAFT', 'PRE-APPROVAL', 'PENDING', 'APPROVED'],
    text: '',
  },
  list: [],
  location: {},
  /* Default value for if a print job should merge permits into one print job */
  printMergeDefault: false,
  /* Copy Permit functionality enabled. Provided from location metadat */
  copyEnabled: false,
  selected: {
    permitFormDefinition: {},
    instance: {},
  },
  permitsByState: [],
  permitsByStateLoading: false,
  permitsByStateError: null
};

export const permitsReducer = (state = initialState, action) => {
  const { types } = PermitsState;
  let updatedList;

  // This is the start of cleaning up the reducers into
  // smaller separate reducers. Because we have a mix of styles
  // we cant utilize the combineReducers() functionality. Once
  // they are all separated we can switch this style to use
  // combineReducers
  state.search = searchReducer(state.search, action);

  switch (action.type) {
    case types.get:
      return {
        ...state,
        list: action.payload.list,
      };
    case types.setDraft:
      return {
        ...state,
        draft: action.payload,
      };
    case types.clearDraft:
      return {
        ...state,
        draft: initialState.draft,
      };
    case types.setDraftsList:
      return {
        ...state,
        draftsList: action.payload,
      };
    case types.clearDraftsList:
      return {
        ...state,
        draftsList: initialState.draftsList,
      };

    case types.deleteDraft:
      return {
        ...state,
        draftsList: [...state.draftsList.filter((d) => d.PermitInstanceId !== action.payload)],
      };
    case types.setSelected:
      return {
        ...state,
        selected: {
          ...state.selected,
          ...action.payload.selected,
        },
      };
    case types.createPermitOptimistically:
      // eslint-disable-next-line no-case-declarations
      const { PermitInstanceMetadataJSON, ...restInstance } = action.payload.instance;

      updatedList = [creatPermitListItem(restInstance), ...state.list];

      return {
        ...state,
        list: [...updatedList],
        selected: {
          ...state.selected,
          instance: action.payload.instance,
        },
      };

    case types.createPermitSucceeded:
      // eslint-disable-next-line no-case-declarations
      let { selected } = state;

      if (_get(state.selected, 'instance.PermitInstanceId') === action.payload.data.PermitInstanceId) {
        const {
          PermitInstanceId,
          // eslint-disable-next-line no-shadow
          PermitInstanceMetadataJSON,
          ContentJSON,
          ...restPermit
        } = action.payload.data;

        selected = {
          ...selected,
          instance: {
            ...selected.instance,
            ...restPermit,
          },
        };
      }

      // eslint-disable-next-line no-case-declarations
      const createdPermit = {
        ...action.payload.data,
        ContentJSON: JSON.parse(action.payload.data.ContentJSON),
        PermitInstanceMetadataJSON: JSON.parse(action.payload.data.PermitInstanceMetadataJSON),
      };

      updatedList = updatePermitInList(state.list, createdPermit);

      return {
        ...state,
        list: [...updatedList],
        selected,
      };
    case types.updatePermitLocation:
      updatedList = updatePermitInList(state.list, action.payload.instance);
      return {
        ...state,
        list: [...updatedList],
        selected: {
          ...state.selected,
          instance: {
            ...state.selected.instance,
            LocationId: action.payload.instance.LocationId,
          },
        },
      };

    case types.updateOne:
      updatedList = updatePermitInList(state.list, action.payload.instance);

      return {
        ...state,
        list: [...updatedList],
        approvals: {
          ...state.approvals,
          ...(action.payload.approvals ? action.payload.approvals : {}),
        },
        selected: {
          ...state.selected,
          instance: {
            ...state.selected.instance,
            State: action.payload.instance.State,
            ...(action.payload.backgroundUpdate ? {} : { ContentJSON: action.payload.instance.ContentJSON }),
          },
        },
      };
    case types.setApprovals: {
      return {
        ...state,
        approvals: action.payload,
      };
    }
    case types.clearSelected:
      return {
        ...state,
        selected: initialState.selected,
        approvals: initialState.approvals,
        attachments: initialState.attachments,
      };

    case types.clearList:
      return {
        ...state,
        list: initialState.list,
      };
    case types.setList:
      return {
        ...state,
        list: action.payload,
      };

    case types.updatePermitState: {
      updatedList = updatePermitInList(state.list, {
        PermitInstanceId: action.payload.PermitInstanceId,
        State: action.payload.state,
      });

      return {
        ...state,
        list: [...updatedList],
      };
    }
    case types.updateWorkLocation: {
      let newState = state;
      const { PermitInstanceId, Latitude, Longitude } = action.payload;

      updatedList = updatePermitInList(state.list, { PermitInstanceId, Latitude, Longitude });

      newState = {
        ...newState,
        list: [...updatedList],
      };

      if (action.payload.PermitInstanceId === _get(state.selected, 'instance.PermitInstanceId')) {
        newState = {
          ...newState,
          selected: {
            ...newState.selected,
            instance: {
              ...newState.selected.instance,
              Latitude,
              Longitude,
            },
          },
        };
      }

      return newState;
    }
    case types.updatePermitTypesInListPermit:
      // eslint-disable-next-line no-case-declarations
      const permitKey = _findKey(
        state.list,
        (p) => p.PermitInstanceId.toLowerCase() === action.payload.PermitInstanceId,
      );

      if (!permitKey) return state;

      // eslint-disable-next-line no-case-declarations
      const newList = [...state.list];
      newList[permitKey].permitTypes = action.payload.permitTypes;

      return {
        ...state,
        list: [...newList],
      };
    case types.setAttachmentsList:
      return {
        ...state,
        attachments: action.payload,
      };
    case types.setLocation:
      return {
        ...state,
        location: action.payload,
      };
    case types.filter:
      return {
        ...state,
        filter: {
          ...state.filter,
          ...action.payload,
        },
      };
    case types.clearFilters:
      return {
        ...state,
        filter: initialState.filter,
      };
    case types.setPrintMergeDefault:
      return {
        ...state,
        printMergeDefault: action.payload,
      };
    case types.setPermitCopyEnabled:
      return {
        ...state,
        copyEnabled: action.payload,
      };
    case types.getAllPermitsListByState:
      return {
        ...state,
        permitsByState: action.payload,
        permitsByStateError: null
      };
    case types.setAllPermitsListByState:
      return {
        ...state,
        permitsByState: action.payload,
        permitsByStateError: null
      };
    case types.setAllPermitsListByStateLoading:
      return {
        ...state,
        permitsByStateLoading: action.payload,
      };
      case types.setAllPermitsListByStateError:
        return {
          ...state,
          permitsByStateError: action.payload,
        };
    default:
      return state;
  }
};
