import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Paper, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import AuthState from '../../actions/auth';

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
  },
  heading: {
    marginBottom: theme.spacing(2),
  },
  error: {
    marginTop: theme.spacing(2),
  },
}));

const Unauthorized: React.FC = () => {
  const classes = useStyles();
  const message = useSelector(AuthState.selectors.getUnAuthenticatedMessage());

  return (
    <Paper className={classes.paper}>
      <Typography variant="h4" className={classes.heading}>Unauthorized</Typography>
      <Typography>
        If you require access to the E-Permitting application, please contact the service desk at < a href="mailto:servicedesk@nutrien.com" > servicedesk@nutrien.com</a>
      </Typography>
      <Typography className={classes.error}>
        {message}
      </Typography>
      <Typography className={classes.error}>
        If you already have an account or you are a Nutrien employee,
        {' '}
        <Link to="/auth/signin">click here</Link>
        .
      </Typography>
    </Paper>
  );
};

export default Unauthorized;
