import React from 'react';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';

import {
  Link,
  makeStyles,
  Typography,
  Breadcrumbs as MuiBreadcrumbs,
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    marginBottom: theme.spacing(2),
    marginLeft: theme.spacing(1),
  },
}));

interface Props {
  crumbs: {
    label: string;
    path?: string | null;
  }[];
}

const Breadcrumbs: React.FC<Props> = (props) => {
  const { crumbs } = props;
  const classes = useStyles();

  return (
    <MuiBreadcrumbs aria-label="breadcrumb" className={classes.wrapper}>
      <Link color="primary" to="/admin" variant="h6" component={RouterLink}>
        Admin
      </Link>

      {crumbs.map((crumb) => (
        <span key={crumb.label}>
          {crumb.path ? (
            <Link color="primary" to={crumb.path} component={RouterLink} variant="h6">
              {crumb.label}
            </Link>
          ) : (
            <Typography variant="h6" color="textPrimary">
              {crumb.label}
            </Typography>
          )}
        </span>
      ))}
    </MuiBreadcrumbs>
  );
};

Breadcrumbs.propTypes = {
  crumbs: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      path: PropTypes.oneOfType([PropTypes.string]),
    }).isRequired,
  ).isRequired,
};

export default Breadcrumbs;
