import React from 'react';
import PropTypes from 'prop-types';
import { Formio } from 'formiojs';

type Props = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  components: Record<string, any>;
  disabled?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  initialFormData: Record<string, any>;
  permitType?: string;
  // eslint-disable-next-line no-unused-vars,@typescript-eslint/no-explicit-any
  onChange: (data: any) => void;
};

type FormOnChangeResponse = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  changed: Record<string, any>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: Record<string, any>;
  isValid: boolean
}

const SubForm: React.FC<Props> = (props) => {
  const {
    disabled,
    components,
    initialFormData,
    permitType,
    onChange,
  } = props;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const formRef = React.useRef<any>();

  React.useEffect(() => {
    const createForm = async () => {
      const form = await Formio.createForm(
        document.getElementById('form'),
        { components },
        { readOnly: disabled, noSubmit: true, noAlerts: true },
      );

      form.on('change', ({ changed, data }: FormOnChangeResponse) => {
        if (changed) {
          onChange({ data });
        }
      });

      form.setSubmission(initialFormData);
      formRef.current = form;
    };

    createForm();
  // We only ever want the form to rebuild if the components change or it needs to be disabled.
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [components, disabled]);

  React.useEffect(() => {
    if (formRef.current) {
      formRef.current.setSubmission(initialFormData);
    }
  }, [formRef, initialFormData]);

  /*
    This component uses Boostrap components as this is what FormIO is built
    with. I replicated their Card component here so the rendered form looks
    the same as it it does on the create/edit template page.
    see: https://getbootstrap.com/docs/5.0/components/card/
  */
  return (
    <div className="card border mb-2" hidden={formRef === undefined}>
      <div className="card-header bg-primary">
        <span className="mb-0 card-title text-light">
          {permitType}
        </span>
      </div>
      <div className="card-body">
        <div id="form" />
      </div>
    </div>
  );
};

SubForm.defaultProps = {
  disabled: false,
  initialFormData: {},
};

SubForm.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  components: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  initialFormData: PropTypes.any,
  permitType: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default SubForm;
