import moment from 'moment';
import { get as _get } from 'lodash';
import _findKey from 'lodash/findKey';

import Api from './api';
import { PERMISSIONS } from './auth';
import { getDescendantsByLocation } from './location';
import { ESPermit, Permit } from '../types/permit';
import { Location } from '../types/location';
import { Permission } from '../types/auth';
import { ElasticSearchAsyncResponse } from '../types/elasticsearch';

export enum PermitTypes {
  WorkPermit = 'workPermit',
  HotWork = 'hotWork',
};

interface TotalFilters {
  [key: string]: string[];
}

type PermitKeyDictionaryMap = {
  [key: number]: {
    [key in PermitTypes]: {
      [key: string]: string;
    }
  }
}

const PermitKeyDictionary: PermitKeyDictionaryMap = {
  /** Terminals */
  1: {
    [PermitTypes.WorkPermit]: {
      checkbox: 'safeWork',
      form: 'safeWorkPermit',
      companyName: 'companyName',
      workDate: 'workDate',
    },
    [PermitTypes.HotWork]: {}
  },
  /** Redwater */
  17: {
    [PermitTypes.WorkPermit]: {
      checkbox: 'safeWorkRedwater',
      form: 'safeWorkPermit',
      assetNumber: 'functionalLocation',
      assetDescription: 'functionalLocationDescription',
      companyName: 'companyName',
      workDate: 'workDate',
      permitIssuer: 'swPermitIssuerApprovalName',
    },
    [PermitTypes.HotWork]: {}
  },
  /** Trinidad */
  65: {
    [PermitTypes.WorkPermit]: {
      checkbox: 'generalWorkTrinidad',
      form: 'generalWork',
      assetNumber: 'assetNumber',
      assetDescription: 'equipmentDescription',
      companyName: 'issuedTo',
      workDate: 'permitDate',
    },
    [PermitTypes.HotWork]: {}
  },
  /** Geismer */
  66: {
    [PermitTypes.WorkPermit]: {
      checkbox: 'safeWork',
      form: 'safeWorkPermit',
      companyName: 'companyPerformingWork',
      workDate: 'dateOfWork',
    },
    [PermitTypes.HotWork]: {}
  },
};

/**
 * Returns the keys of a permit type for a specified location
 * @param {number} siteId - The SiteId attached to the permit
 * @param {PermitTypes} permitType - The permit type of the subform
 * @return {PermitKeyDictionaryEntry} - the keys for the permit
 */
export const getFormKeysForSiteByType = (siteId: number, type: PermitTypes) => {
  return PermitKeyDictionary[siteId] ? PermitKeyDictionary[siteId][type] : {};
};

/**
 * Return the content from a subform field based on a location specific permit name definied in PermitKeyDictionary
 * @param {number} siteId - The SiteId attached to the permit
 * @param {PermitTypes} permitType - The permit type of the subform
 * @param {string} permitFieldName - The key of the field to fetch (eg workDate)
 * @param {FormContentJSON} contentJSON - The contentJSON from a permit
 * @return {string} - The content of the requested field
 */
const getLocationSpecificSubFormValue = (
  siteId: number,
  permitType: PermitTypes,
  permitFieldName: string,
  contentJSON: any,
  defaultValue: string | null = '-'
) => {
  if (PermitKeyDictionary[siteId]) {
    return _get(contentJSON, `data.${PermitKeyDictionary[siteId][permitType].form}.data.${permitFieldName}`, defaultValue);
  }

  return null;
};

/**
 * Return the work location text from the permit form content based on the location of the permit
 * @param {Permit} permit - The permit passed to this component
 * @return {string} - The content of the work location field
 */
export const getWorkLocation = (permit: Permit) => {
  return getLocationSpecificSubFormValue(
    permit.SiteId,
    PermitTypes.WorkPermit,
    'workLocation',
    permit.ContentJSON,
  );
};
/**
 * Return the permit issuer  text from the permit form content based on the location of the permit
 * @param {Permit} permit - The permit passed to this component
 * @return {string} - The content of the work location field
 */
export const getPermitIssuer = (permit: Permit) => {
  const keys = getFormKeysForSiteByType(permit.SiteId, PermitTypes.WorkPermit);
  return getLocationSpecificSubFormValue(
    permit.SiteId,
    PermitTypes.WorkPermit,
    keys.permitIssuer,
    permit.ContentJSON,
  );
};

/**
 * Return the work description text from the permit form content based on the location of the permit
 * @param {Permit} permit - The permit passed to this component
 * @return {string} - The content of the work description field
 */
export const getWorkDescription = (permit: Permit) => {
  const keys = getFormKeysForSiteByType(permit.SiteId, PermitTypes.WorkPermit);

  const formValue = getLocationSpecificSubFormValue(
    permit.SiteId,
    PermitTypes.WorkPermit,
    'workDescription',
    permit.ContentJSON,
    null,
  );

  const workOrderValue = [];

  if (formValue) {
    workOrderValue.push({
      parentForm: keys.form,
      workDescription: formValue,
    });
  }

  return workOrderValue;
};

/**
 * Return the work order number from the permit form content based on the location of the permit
 * @param {Permit} permit - The permit passed to this component
 * @return {string} - The content of the work description field
 */
export const getWorkOrderNumber = (permit: Permit) => {
  const workOrder = _get(permit.ContentJSON, 'data.workOrder');

  // for some reason some permits have empty objects set for the workOrder,
  // others are numbers but most are strings so ignore empty objects and
  // return numbers/strings coalesced into a string
  if (workOrder && typeof workOrder === 'object') {
    return undefined;
  }

  return workOrder;
};

/**
 * Return the company name text from the permit form content based on the location of the permit
 * @param {Permit} permit - The permit passed to this component
 * @return {string} - The content of the work description field
 */
export const getCompanyName = (permit: Permit) => {
  const keys = getFormKeysForSiteByType(permit.SiteId, PermitTypes.WorkPermit);
  return getLocationSpecificSubFormValue(
    permit.SiteId,
    PermitTypes.WorkPermit,
    keys.companyName,
    permit.ContentJSON,
  );
};

/**
 * Return the work date text from the permit form content based on the location of the permit
 * @param {Permit} permit - The permit passed to this component
 * @return {string} - The content of the work description field
 */
export const getWorkDate = (permit: Permit, format = false) => {
  const keys = getFormKeysForSiteByType(permit.SiteId, PermitTypes.WorkPermit);

  const value = getLocationSpecificSubFormValue(
    permit.SiteId,
    PermitTypes.WorkPermit,
    keys.workDate,
    permit.ContentJSON,
  );

  if (!value || value === '-') {
    return undefined;
  }

  const date = new Date(value);

  return format
    ? `${date.getFullYear()}-${date.getMonth() > 8
      ? (date.getMonth() + 1)
      : `0${date.getMonth() + 1}`}-${date.getDate() > 9 ? date.getDate() : `0${date.getDate()}`}`
    : date;
};

/**
 * Return the asset number text from the permit form content based on the location of the permit
 * @param {Permit} permit - The permit passed to this component
 * @return {string} - The content of the work description field
 */
export const getAssetNumber = (permit: Permit) => {
  const keys = getFormKeysForSiteByType(permit.SiteId, PermitTypes.WorkPermit);
  return getLocationSpecificSubFormValue(
    permit.SiteId,
    PermitTypes.WorkPermit,
    keys.assetNumber,
    permit.ContentJSON,
  );
};

/**
 * Return the asset number description from the permit form content based on the location of the permit
 * @param {Permit} permit - The permit passed to this component
 * @return {string} - The content of the work description field
 */
export const getAssetDescription = (permit: Permit) => {
  const keys = getFormKeysForSiteByType(permit.SiteId, PermitTypes.WorkPermit);
  return getLocationSpecificSubFormValue(
    permit.SiteId,
    PermitTypes.WorkPermit,
    keys.assetDescription,
    permit.ContentJSON,
  );
};

export const creatPermitListItem = (permit: ESPermit) => {
  const permitDoc: any = {
    State: permit.State,
    PermitInstanceId: permit.PermitInstanceId,
    PermitDefinitionId: permit.PermitDefinitionId,
    PermitInstanceReferenceId: permit.PermitInstanceReferenceId,
    CreatedBy: permit.CreatedBy,
    CreatedOn: permit.CreatedOn,
    DateChanged: permit.DateChanged,
    SiteId: permit.SiteId,
    LocationId: permit.LocationId,
    Latitude: permit.Latitude,
    Longitude: permit.Longitude,
    assetNumber: getAssetNumber(permit),
    assetDescription: getAssetDescription(permit),
    workOrderNumber: getWorkOrderNumber(permit),
    workDescription: getWorkDescription(permit),
    companyName: getCompanyName(permit),
    workDate: getWorkDate(permit),
    workLocation: getWorkLocation(permit),
    permitIssuer: getPermitIssuer(permit),
    permitTypes: permit.permitTypes,
  };

  // delete any keys with undefined values;
  Object.keys(permitDoc).forEach((key) => {
    if ((permitDoc)[key] === undefined) {
      delete (permitDoc)[key];
    }
  });

  return permitDoc as ESPermit;
};

/**
 * Update a single permit in a list of unique permits.
 * @param {ESPermit[]} list - List of permits to search
 * @param {ESPermit[]} permit - The permit passed to this component
 * @param {boolean} replace - Should the permit be replaced entirely
 * @return {ESPermit[]} - List of permits
 */
export const updatePermitInList = (list: ESPermit[], permit: ESPermit, replace = false) => {
  const key = _findKey(list, (p: Permit) => p.PermitInstanceId === permit.PermitInstanceId) as number | undefined;
  const permitItem = creatPermitListItem(permit);

  if (!key) {
    return list;
  }

  const newList = list;

  if (replace) {
    newList[key] = permitItem;
  } else {
    newList[key] = {
      ...newList[key],
      ...permitItem,
    };
  }

  return newList;
};

/**
 * Builds an Elasticsearch query and returns a Promise to call the search endpoint
 * @param {string} searchTerms - term to search
 * @param {[key: string]: any} filters - key/value par if filters to use in the query
 * @param {number} location - location to search
 * @param {page} location - the page to start at - aka where to start from in the result. Used for pagination
 * @return {ESPermitDocument[]} - List of permits
 */
export const queryElasticSearchPermits = async (
  searchTerms: string[],
  filters: Record<any, any> = {},
  locations: Location[],
  homeLocation: Location,
  permissions: Permission[],
  page = 0,
  filterTerm: string[]
) => {
  const { CreatedBy, fromDate, workOrderNumber, PermitType, toDate, State, permitIssuer, permitReceiver } = filters;

  // get the locations where user has view access
  const locationsWithPermissions = permissions.find((p) => p.PermissionTypeId === PERMISSIONS.Permits.View);

  if (!locationsWithPermissions) {
    return Promise.resolve();
  }

  // get all the descendant locationss from the user's home location
  const descendantLocations = getDescendantsByLocation(locations, filters.Location || homeLocation, true);

  // Filter out any descendant locations the user does not have permissions to.
  const allowedLocations = descendantLocations.filter((l) => locationsWithPermissions.Locations.includes(l));

  // get the base location to include in the search
  const baseLocationId = filters.Location ? filters.Location.LocationId : homeLocation.LocationId;

  // this is a good example of a fully built out bool query
  // https://stackoverflow.com/a/57626279
  const filter = [];
  const must: any = [];

  const terms = {
    LocationId: [baseLocationId, ...allowedLocations],
    ...(State && State.length > 0 ? { 'State.keyword': State } : {}),
    ...(CreatedBy ? { 'CreatedBy.keyword': CreatedBy } : {}),
    ...(PermitType && PermitType.length > 0 ? { 'permitTypes.Name.keyword' : PermitType.map((type: string) =>  type ) } : {} ),
    ...(workOrderNumber ? { workOrderNumber: Number(workOrderNumber) } : {}),
  };

  filter.push({
    bool: {
      must: Object.keys(terms)
        .map((term: string) => {
          const termValue = (terms as any)[term];
          const key = Array.isArray(termValue) ? 'terms' : 'term';
          return { [key]: { [term]: termValue } };
        }),
    },
  });

  if (fromDate || toDate) {
    const range = {
      ...(fromDate ? { gte: moment(fromDate).startOf('day').format() } : {}),
      ...(toDate ? { lte: moment(toDate).endOf('day').format() } : { lte: moment.utc().endOf('day') }),
    };

    filter.push({
      range: {
        workDate: range,
      },
    });
  }
  
  const totalFilters = {
    'PermitInstanceReferenceId': ['PermitInstanceReferenceId^6'],
    'workLocation': ['workLocation^5'],
    'permitIssuer': ['permitIssuer^4'],
    'assetNumber': ['assetNumber^3'],
    'workDescription': ['workDescription.workDescription^2'],
    'assetDescription': ['assetDescription^1'],
    'companyName': ['companyName'],
    'permitReceiver': ['permitReceiver^7'],
  } as TotalFilters

  const fields: string[] = [ ...Object.values(totalFilters).flat(), 'fullText'];
  
  filterTerm.forEach((item: string) => {
    if(!totalFilters[item]){
      fields.push(item)
    }
  })

  if (Array.isArray(searchTerms)) {
    searchTerms.forEach((term) => {
      must.push({
        multi_match: {
          query: `${term}`,
          operator: 'and',
          type: 'phrase_prefix',
          fields       
        },
      });
    });
  }

  if (permitIssuer) {
      must.push({
        multi_match: {
          query: `${permitIssuer}`,
          operator: 'and',
          type: 'phrase_prefix',
          fields: ['permitIssuer']      
        },
      });
  }

  if (permitReceiver) {
      must.push({
        multi_match: {
          query: `${permitReceiver}`,
          operator: 'and',
          type: 'phrase_prefix',
          fields: ['permitReceiver']      
        },
      });
  }

  const query: any = {
    from: page,
    size: 20,
    query: {
      bool: { ...(must.length ? { must } : {}) },
    },
    sort: {
      _score: {
        order: 'desc',
      },
    },
  };

  if (filter.length > 0) {
    query.query.bool.filter = filter;
  }

  return Api.post('/permits/search', query);
};


/**
 * Search permits using async search. This is useful when executing long running queries
 * or when querying data that will return > 10k records
 * @param {Record<any, any>} query - Elasticsearch query
 * @param {'30s' | '1m' | '5m'} keepAlive - how long to keep the query alive to be re-queried
 * @return {ESPermitDocument[]} - List of permits
 */
export const queryElasticSearchPermitsAsync = async (
  query: Record<any, any>,
  keepAlive: '30s' | '1m' | '5m' = '1m'
) => {
  const result = await Api.post(`/permits/search?keepAlive=${keepAlive}`, JSON.stringify(query)) as ElasticSearchAsyncResponse<ESPermit>;

  let { id, state, response } = result;

  while (state === 'RUNNING') {
    const retryResponse = await Api.get(`/search/${id}`) as ElasticSearchAsyncResponse<ESPermit>;

    id = retryResponse.id;
    response = retryResponse.response;
    state = retryResponse.state;
  }

  return response;
}
