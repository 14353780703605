import React from 'react';
import { makeStyles, IconButton } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';
import CloseIcon from '@material-ui/icons/Close';
import LaunchIcon from '@material-ui/icons/Launch';

const useStyles = makeStyles((theme) => ({
  errorMessages: {
    '& ul': {
      paddingLeft: theme.spacing(2),
    },
    '& li': {
      paddingBottom: theme.spacing(1),
    },
    '& .MuiAlert-message': {
      maxWidth: '300px',
      minWidth: '150px',
    }
  },
  bg: {
    backgroundColor: '#f8d7da',
  },
  hover: {
    '&:hover': {
      cursor: 'pointer',
      textDecoration: 'underline',
      color: '#007bff'
    }
  },
  errorBox: {
    position: 'fixed',
    right: 0,
    bottom: 0,
    zIndex: 10000,
    minWidth: '200px',
    maxWidth: '300px',
  },
  noPadding: {
    padding: 0,
    position: 'relative',
    bottom: '5px',
  },
  sidebar: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  listContainer: {
    maxHeight: '500px',
    padding: 0,
    marginTop: theme.spacing(2),
    overflow: 'auto'
  }
}));

const findAndScroll = (key, id) => {
  const elm = document.querySelectorAll(`[name='data[${key}]'`);
  if (elm?.length) {
    if (elm.length === 1) {
      elm[0].scrollIntoView({ behavior: "smooth", block: "center", inline: "center" });
      return true;
    } else {
      elm.forEach((node, i) => {
        if (node.id.includes(id)) {
          elm[i].scrollIntoView({ behavior: "smooth", block: "center", inline: "center" });
          return true;
        }
      })
    }
  }
  return false;
}

const ErrorSideBar = (props) => {

  const { form, updateSidebarBtn, showErrors, errors, total, showSidebarBtn } = props;
  const classes = useStyles();

  const scrollToError = (data) => {
    try {
      const components = form.flattenComponents();
      if (components[data.key]?.element) {
        components[data.key].element?.scrollIntoView({ behavior: "smooth", block: "center", inline: "center" })
      } else if (components[data.name]?.element) {
        components[data.name].element?.scrollIntoView({ behavior: "smooth", block: "center", inline: "center" })
      } else {
        if (findAndScroll(data.key, data.id)) return;
        if (findAndScroll(data.name, data.id));
      }
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <div className={classes.errorBox}>
      {
        showErrors ? (
          errors.length ? (
            <div className={classes.errorMessages}>
              <Alert severity="error" className={classes.bg}>
                <div className={classes.sidebar}>
                  <AlertTitle>{`Error - (${total})`}</AlertTitle>
                  <IconButton className={classes.noPadding} onClick={() => updateSidebarBtn(!showSidebarBtn)}>
                    {
                      !showSidebarBtn ? <CloseIcon /> : <LaunchIcon />
                    }
                  </IconButton>
                </div>
                {
                  !showSidebarBtn && (
                    <ul className={classes.listContainer}>
                      {
                        errors.map((error, i) => {
                          return (
                            <li key={i}><AlertTitle>{`${error.label} - (${error.count})`}</AlertTitle>
                              <ul>
                                {
                                  error.errorMessage.map((e, index) => <li onClick={() => scrollToError(e)} key={index} className={classes.hover}>{`${e.label}`}</li>)
                                }
                              </ul>
                            </li>
                          )
                        })
                      }
                    </ul>
                  )
                }
              </Alert>
            </div>
          ) : null

        ) : null
      }
    </div>
  )
}

export default ErrorSideBar;