import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import { useDispatch } from 'react-redux';

import Dialog from '../../../shared/Dialog';

import ExternalUsersState from '../../../../actions/admin/external-users';
import InlineLoader from '../../../shared/InlineLoader';

type Props = {
  onClose: () => void;
  PersonnelId: number;
}

const DeleteDialog: React.FC<Props> = (props) => {
  const { onClose, PersonnelId } = props;
  const dispatch = useDispatch();

  const [loading, setLoading] = React.useState<boolean>(false);

  const handleDeleteClick = async () => {
    setLoading(true);
    await dispatch(ExternalUsersState.actions.deleteUser(PersonnelId));
    onClose();
    setLoading(false);
  };

  return (
    <Dialog
      visible
      onCancel={onClose}
      onClose={onClose}
      title="Delete User"
      actions={[{
        text: 'Delete',
        color: 'primary',
        disabled: loading,
        onClick: handleDeleteClick,
      }]}
    >
      {loading ? <InlineLoader /> : (
        <Typography gutterBottom>
          Are you sure you want to delete this user?
        </Typography>
      )}
    </Dialog>
  );
};

DeleteDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  PersonnelId: PropTypes.number.isRequired,
};

export default DeleteDialog;
