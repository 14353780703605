export const ActionStatus = {
  pending: 'pending',
  resolved: 'resolved',
  rejected: 'rejected',
};

export enum ActionStatusTypes {
  Pending = 'pending',
  Resolved = 'resolved',
  Rejected = 'rejected',
}


type Payload = {
  action: string;
  status?: ActionStatusTypes;
  error?: any;
}

const generateActionCreator = (payload: Payload) => ({
  type: 'APP_STATUS_SET',
  payload,
});

export const pending = (action: string) => generateActionCreator({
  action,
  status: ActionStatusTypes.Pending,
});

export const resolve = (action: string) => generateActionCreator({
  action,
  status: ActionStatusTypes.Resolved,
});

export const reject = (action: string, error: any) => generateActionCreator({
  action,
  error,
  status: ActionStatusTypes.Rejected,
});

export const reset = (action: string) => generateActionCreator({
  action,
  status: undefined,
});
