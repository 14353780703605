import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { Box, TextField, Grid } from "@material-ui/core";
import { useLocation } from "react-router-dom";
import dateIoMoment from "@date-io/moment";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";

import PermitsState from "../../../actions/permits";
import useQuery from "../../../hooks/shared/useQuery";
import { states } from "../../../config/permits";

import FilterDropDown from "./FilterDropDown";
import FilterDropDownMultiSelect from "./FilterDropDownMultiSelect";
import FilterInputWrapper from "./FilterInputWrapper";

const useStyles = makeStyles((theme) => ({
  filtersWrapper: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    backgroundColor: "white",
  },
  filtersItemWrapper: {
    margin: theme.spacing(1),
  },
}));

const PermitListFilters = (props) => {
  const { disabled } = props;
  const { selectors, actions } = PermitsState;

  const classes = useStyles();
  const [textValue, setTextValue] = React.useState("");
  const dispatch = useDispatch();
  const query = useQuery();
  const location = useLocation();

  React.useEffect(() => {
    const { text, filter, workDate } = query;
    let { state } = query;
    if (text || state || filter || workDate) {
      if (state) state = state.split(",");
      dispatch(actions.filter({ text, state, filter, workDate }, true));
    } else {
      dispatch(actions.clearFilters());
    }
    // disabling lint as we want the url params to trigger the update, not the state.
    // This way the filters are set by sharing a url and we don't get stuck in an infinte update loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.key]);

  const { text, state, filter, workDate } = useSelector(selectors.filters());
  React.useEffect(() => {
    if (text && text !== textValue) {
      setTextValue(text);
    }
  }, [text, textValue]);

  const handleSearch = (value, filterBy) => {
    if (filterBy === "text") setTextValue(value);
    dispatch(actions.filter({ [filterBy]: value }));
  };

  const filterOptions = [
    {
      value: "myPermits",
      label: "My Permits",
      onSelect: () => handleSearch("myPermits", "filter"),
    },
    {
      value: "allPermits",
      label: "All Permits",
      onSelect: () => handleSearch("allPermits", "filter"),
    },
    {
      value: "myApprovalRequired",
      label: "Requires My Approval",
      onSelect: () => handleSearch("myApprovalRequired", "filter"),
    },
  ];

  return (
    <Grid container className={classes.filtersWrapper}>
      <Grid item xs={12} sm={6} md={3}>
        <Box p={2} pt={5}>
          <TextField
            disabled={disabled}
            label="Contains Text"
            value={textValue}
            id="text-search"
            helperText="Eg: 03 Plant"
            onChange={(event) => handleSearch(event.target.value, "text")}
          />
        </Box>
      </Grid>
      <Grid item xs={12} sm={6} md={3}>
        <FilterDropDown
          disabled={disabled}
          label="Permit Filters"
          permitpad={1}
          defaultValue={
            filterOptions.find((o) => o.value === filter) || filterOptions[1]
          }
          options={filterOptions}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={3}>
        <FilterDropDownMultiSelect
          disabled={disabled}
          label="State"
          statepad={1}
          defaultValue={state}
          onSelect={(status) => handleSearch(status, "state")}
          options={states.map((st) => ({ value: st }))}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={3}>
        <FilterInputWrapper label="Work Date" permitpad={1}>
          <MuiPickersUtilsProvider utils={dateIoMoment}>
            <KeyboardDatePicker
              clearable
              cancelLabel="Close"
              okLabel="Update"
              disabled={disabled}
              value={workDate ? moment(Number(workDate)) : null}
              placeholder="Select Date"
              onClear={() => {
                handleSearch(null, "workDate");
              }}
              onChange={(date) => {
                handleSearch(new Date(date).getTime(), "workDate");
              }}
              format="YYYY-MM-DD"
            />
          </MuiPickersUtilsProvider>
        </FilterInputWrapper>
      </Grid>
    </Grid>
  );
};

PermitListFilters.defaultProps = {
  disabled: false,
};

PermitListFilters.propTypes = {
  disabled: PropTypes.bool,
};

export default PermitListFilters;
