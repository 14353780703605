import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import {
  Button,
  CircularProgress,
  makeStyles,
  TableCell,
  TableRow,
  TextField,
  Typography,
} from '@material-ui/core';

import ListsState from '../../../../actions/admin/lists';

const useStyles = makeStyles((theme) => ({
  itemWrapper: {
    backgroundColor: theme.palette.grey[100],
    borderRadius: 5,
    width: '50%',
    marginTop: theme.spacing(2),
    padding: theme.spacing(2),
  },
  itemFieldsWrapper: {
    display: 'flex',
    flexDirection: 'row',
    '& > *': {
      marginRight: theme.spacing(2),
    },
  },
  buttonWrapper: {
    marginTop: theme.spacing(2),
  },
  heading: {
    marginBottom: theme.spacing(2),
  },
}));

type Props = {
  existingFieldKeys: string[];
  onCancel: () => void;
  onSubmit: () => void;
};

type FormData = {
  fieldKey: string;
  Label: string;
  Value: string;
}

const NewListForm: React.FC<Props> = (props) => {
  const { existingFieldKeys, onCancel, onSubmit } = props;

  const classes = useStyles();
  const dispatch = useDispatch();

  const [formValues, setFormValues] = React.useState<Partial<FormData>>({});
  const [errors, setErrors] = React.useState<{ [key: string]: string }>({});
  const [submitting, setSubmitting] = React.useState(false);

  React.useEffect(() => {
    // eslint-disable-next-line no-unused-vars
    const nextErrors: { [key: string]: string } = {};
    const validKeyRegex = /^(\w|\w[\w-.]*\w)$/;
    if (formValues.fieldKey && !RegExp(validKeyRegex).test(formValues.fieldKey)) {
      nextErrors.fieldKey = 'Key can only include letters, numbers and underscores';
    }

    if (formValues.fieldKey && existingFieldKeys.includes(formValues.fieldKey)) {
      nextErrors.fieldKey = 'A list already exists with this key';
    }

    setErrors(nextErrors);
  }, [existingFieldKeys, formValues]);

  const updateFieldValue = (field: string, value: string) => {
    setFormValues((values) => ({ ...values, [field]: value }));
  };

  const handleItemSave = async () => {
    let hasErrors = false;
    if (!formValues.Label) {
      setErrors((e) => ({ ...e, label: 'Label is required' }));
      hasErrors = true;
    }

    if (!formValues.Value) {
      setErrors((e) => ({ ...e, value: 'Value is required' }));
      hasErrors = true;
    }

    if (!formValues.fieldKey) {
      setErrors((e) => ({ ...e, fieldKey: 'Key is required' }));
      hasErrors = true;
    }

    if (!hasErrors) {
      setSubmitting(true);

      const { fieldKey, Label, Value } = formValues;
      if (fieldKey && Label && Value) {
        await dispatch(ListsState.actions.addList(
          fieldKey,
          { Label, Value },
        ));
      }
      setSubmitting(false);

      onSubmit();
    }
  };

  const handleCancelClick = () => {
    onCancel();
  };

  return (
    <TableRow>
      <TableCell colSpan={3}>
        <Typography variant="h6" className={classes.heading}>Add List</Typography>
        <TextField
          required
          id="outlined-helperText"
          label="Key"
          helperText={errors.fieldKey || 'This cannot be edited after being created.'}
          variant="outlined"
          error={errors.fieldKey !== undefined}
          size="small"
          onChange={(event) => updateFieldValue('fieldKey', event.target.value)}
        />

        <div className={classes.itemWrapper}>
          <Typography variant="h6" gutterBottom>Item</Typography>

          <Typography gutterBottom>
            A list cannot exist without at least one item. Provide the first item.
          </Typography>

          <div className={classes.itemFieldsWrapper}>
            <TextField
              label="Value"
              variant="outlined"
              required
              error={errors.value !== undefined}
              helperText={errors.value}
              size="small"
              onChange={(event) => updateFieldValue('Value', event.target.value)}
            />
            <TextField
              label="Label"
              size="small"
              error={errors.label !== undefined}
              helperText={errors.label}
              variant="outlined"
              required
              onChange={(event) => updateFieldValue('Label', event.target.value)}
            />
          </div>
        </div>
        <div className={classes.buttonWrapper}>
          <Button
            size="small"
            color="primary"
            onClick={handleItemSave}
            disabled={submitting || Object.keys(errors).length !== 0}
          >
            Save
          </Button>
          <Button size="small" onClick={handleCancelClick}>
            Cancel
          </Button>

          {submitting && (
            <div>
              <CircularProgress size="small" />
            </div>
          )}
        </div>
      </TableCell>
    </TableRow>
  );
};

NewListForm.defaultProps = {};

NewListForm.propTypes = {
  existingFieldKeys: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default NewListForm;
