import AppState from '../actions/app';

export const initialState = {
  pageName: 'E-Permitting',
  initilized: false,
  left: false,
  open: false,
  expanded: '',
  printers: [],
  /* States a permit must be in to be able to print */
  printableStates: [],
  locations: [],
  homeLocation: {},
  status: {},
  printingError: null
};

export const appReducer = (state = initialState, action) => {
  const TYPES = AppState.types;

  switch (action.type) {
    case TYPES.setInitilized:
      return {
        ...state,
        initilized: action.payload,
      };
    case TYPES.setIsLoading:
      return {
        ...state,
        isLoading: !state.isLoading,
      };
    case TYPES.toggleLeftMenuDrawer:
      return {
        ...state,
        left: action.payload,
      };
    case TYPES.setHomeLocation:
      return {
        ...state,
        homeLocation: action.payload,
      };
    case TYPES.setLocations:
      return {
        ...state,
        locations: action.payload,
      };
    case TYPES.setPrintableStates:
      return {
        ...state,
        printableStates: action.payload,
      };
    case TYPES.setLocationDisabled:
      return {
        ...state,
        locationDisabled: action.payload,
      };
    case TYPES.setPrinters:
      return {
        ...state,
        printers: action.payload,
      };
    case TYPES.setPrintingResponse:
      return {
        ...state,
        printingResponse: action.payload,
      };
    case TYPES.setNetworkStatus:
      return {
        ...state,
        offlineState: action.payload,
      };
    case TYPES.setStatus:
      return {
        ...state,
        status: {
          ...state.status,
          [action.payload.action]: action.payload.status,
        },
      };
    case TYPES.setPageName:
      return {
        ...state,
        pageName: action.payload,
      };
    case TYPES.setLastRun:
      return {
        ...state,
        lastRun: {
          ...state.lastRun,
          [action.payload.type]: action.payload.date,
        },
      };
    case TYPES.resetLastRun:
      return {
        ...state,
        lastRun: {
          ...state.lastRun,
          [action.payload]: undefined,
        },
      };
    default:
      return state;
  }
};
