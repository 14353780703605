import React, { useState } from 'react';
import { Tabs, Tab, Box, makeStyles, Typography, Paper, Divider } from '@material-ui/core';
import AddUsers from './AddUsers';
import RemoveUsers from './RemoveUsers';
import Permissions from './Permissions';
import CopyGroup from './CopyGroup';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      <Box p={3}>{children}</Box>
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  displayNone: {
    display: 'none'
  },
  noRowSelected: {
    minHeight: 300,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  tabs: {
    borderBottom: '1px solid rgba(224, 224, 224, 1)',
    borderColor: 'divider'
  },
  title: {
    padding: theme.spacing(2)
  }
}));

const GroupPanel = (props) => {
  const { selectedRow, fetchNewGroupData, title } = props
  const [value, setValue] = useState('one');
  const classes = useStyles()
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Paper>
      <Box sx={{ width: '100%', bgcolor: 'background.paper', marginTop: '30px' }}>
      <Typography variant="h6" className={classes.title}>{`Manage Group : ${selectedRow.Name || ''}`}</Typography>
      <Divider />
        <Box sx={{ borderBottom: '1px solid rgba(224, 224, 224, 1)', borderColor: 'divider' }}>
          <Tabs
            className={classes.tabs}
            value={value}
            onChange={handleChange}
            textColor="primary"
            indicatorColor="primary"
            variant="scrollable"
            scrollButtons={'on'}
          >
            <Tab value="one" label="Add Users" />
            <Tab value="two" label="Remove Users" />
            <Tab value="three" label="Permissions" />
            <Tab value="four" label="Copy Group" />
          </Tabs>
          {
            Object.keys(selectedRow).length ? (
              <>
                <TabPanel value={value} index={'one'}>
                  <AddUsers title={title} fetchNewGroupData={fetchNewGroupData} selectedRow={selectedRow} />
                </TabPanel>
                <TabPanel value={value} index={'two'}>
                  <RemoveUsers fetchNewGroupData={fetchNewGroupData} selectedRow={selectedRow} />
                </TabPanel>
                <TabPanel value={value} index={'three'}>
                  <Permissions fetchNewGroupData={fetchNewGroupData} selectedRow={selectedRow} />
                </TabPanel>
                <TabPanel value={value} index={'four'}>
                  <CopyGroup GroupId={selectedRow.GroupId}/>
                </TabPanel>
              </>
            ) : <div className={classes.noRowSelected}>The group members and permissions are shown when you have selected a group from above.</div>
          }
        </Box>
      </Box>
    </Paper>

  );
};

export default GroupPanel;
