import { useSelector } from 'react-redux';
import { LogLevel, recordEvent as recordAnalyticsEvent } from '../../shared/analytics';
import { RootState } from '../../store/RootState';

const useAnalytics = () => {
  const state = useSelector((state: RootState) => state);

  return async (data: Record<any, any> | string, logLevel?: LogLevel) => recordAnalyticsEvent(state, data, undefined, logLevel);
}

export default useAnalytics;
