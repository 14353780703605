import React from 'react';
import { useDispatch } from 'react-redux';

import AppState from '../../actions/app';

const useSetPageName = (name: string): void => {
  const dispatch = useDispatch();
  const { actions } = AppState;

  React.useEffect(() => {
    dispatch(actions.setPageName(name));
  }, [name, dispatch, actions]);
};

export default useSetPageName;
