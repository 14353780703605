import React from 'react';
import { Formio } from 'formiojs';

import { ExternalUser } from '../../../../types/external-user';
import { FormioChangeEvent, FormData, FormioForm } from '../../../../types/form-io';

const components = (editing: boolean, showEmail = false) => ({
  components: [
    {
      type: 'textfield',
      key: 'FirstName',
      label: 'First Name',
      input: true,
      validate: { required: true },
    },
    {
      type: 'textfield',
      key: 'LastName',
      label: 'Last Name',
      input: true,
      validate: { required: true },
    },
    {
      type: 'email',
      persistent: true,
      defaultValue: '',
      key: 'EmailAddress',
      label: 'Email',
      inputType: 'email',
      tableView: true,
      disabled: editing,
      hidden: !showEmail,
      validate: { required: true },
      input: true,
    },
    {
      input: false,
      customClass: 'my-3',
      type: 'well',
      key: 'Well',
      components: [
        {
          input: false,
          tableView: true,
          legend: 'Company Information',
          type: 'fieldset',
          components: [
            {
              type: 'textfield',
              key: 'Company',
              label: 'Company Name',
              input: true,
              validate: { required: true },
            },
            {
              type: 'textfield',
              key: 'StreetAddress',
              label: 'Street Address',
              input: true,
            },
            {
              type: 'textfield',
              key: 'L',
              label: 'City',
              input: true,
            },
            {
              type: 'textfield',
              key: 'State',
              label: 'State',
              input: true,
            },
            {
              type: 'textfield',
              key: 'PostalCode',
              label: 'Postal/ZIP Code',
              input: true,
            },
            {
              type: 'select',
              key: 'Country',
              label: 'Country',
              input: true,
              data: {
                values: [
                  { label: 'Canada', value: 'CAN' },
                  { label: 'Trinidad and Tobago', value: 'TTO' },
                  { label: 'United States', value: 'USA' },
                ],
              },
            },
          ],
        },
      ],
    },
    {
      input: false,
      type: 'well',
      customClass: 'my-3',
      key: 'Well-2',
      components: [
        {
          input: false,
          tableView: true,
          legend: 'User Expiry Date',
          type: 'fieldset',
          components: [
            {
              input: false,
              html: '<p>The user will no longer be able to access E-Permitting once this date has passed</p>',
              type: 'content',
              key: 'mycontent2',
            },
            {
              input: true,
              tableView: true,
              label: '',
              key: 'AccessExpiresDate',
              placeholder: 'Select expiry date',
              format: 'yyyy-MM-dd',
              enableDate: true,
              enableTime: false,
              datepickerMode: 'day',
              datePicker: {
                showWeeks: true,
                startingDay: '0',
                initDate: '',
                minMode: 'day',
                maxMode: 'year',
                datepickerMode: 'day'
              },
              validate: { required: false },
              type: 'datetime',
              minDate: new Date().toISOString(),
            }
          ],
        },
      ],
    },
  ],
});

type Props = {
  editing?: boolean;
  showEmail?: boolean;
  initialValues?: Partial<ExternalUser>;
  onSubmit: (formData: FormData<ExternalUser>) => void
  formRef: React.MutableRefObject<FormioForm | null>;
  onChange?: (changeEvent: FormioChangeEvent<ExternalUser>) => void
}

const CreateEditForm: React.FC<Props> = (props) => {
  const {
    editing,
    initialValues,
    onChange,
    onSubmit,
    formRef,
    showEmail
  } = props;

  React.useEffect(() => {
    if (!formRef) {
      return;
    }

    if (formRef.current) {
      formRef.current = null;
    }

    Formio.createForm(
      document.getElementById('form'),
      components(editing || false, showEmail),
      { noAlerts: true },
    ).then((form) => {
      form.submission = {
        data: initialValues || {},
      };

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      formRef.current = form;

      if (onSubmit) {
        form.on('submit', (submission: any) => onSubmit(submission));
      }
      
    });
  }, [editing, showEmail, initialValues, formRef]);


  React.useEffect(() => {
    if (!formRef || !formRef.current) {
      return;
    }

    formRef.current.off('submit');
    formRef.current.on('submit', (submission: FormData<ExternalUser>) => {
      onSubmit(submission);
    });
  }, [onSubmit, formRef]);

  React.useEffect(() => {
    if (!formRef || !formRef.current) {
      return;
    }

    formRef.current.off('change');
    formRef.current.on('change', onChange);
  }, [onChange, formRef]);

  return (
    <div id="form" />
  );
};

export default CreateEditForm;
