import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import {
  Button,
  Container,
  Paper,
} from '@material-ui/core';

import Config from '../../config/app-config';
import { Auth } from 'aws-amplify';

const useStyles = makeStyles((theme) => {
  return {
    container: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      maxWidth: 500,
    },
    box: {
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'column',
      padding: theme.spacing(5),
      marginTop: theme.spacing(20),
    },
    heading: {
      textAlign: 'center',
      marginBottom: theme.spacing(4),
    },
    button: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    helpText: {
      textAlign: 'center',
      marginTop: theme.spacing(3),
    },
  };
});

const SignOut: React.FC = () => {
  const classes = useStyles();

  React.useEffect(() => {
    const ensureUserSignedOut = async () => {
      Auth.signOut();
    };

    ensureUserSignedOut();
  }, []);

  const handleAppReload = () => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (window as any).location = Config.Amplify.Auth.oauth.redirectSignIn;
  };

  const handleOfficeSignout = () => {
    window.open('https://login.microsoftonline.com/common/oauth2/logout', 'logout', 'height=600,width=500');
  };

  return (
    <>
      <Container className={classes.container}>
        <Paper className={classes.box}>
          <h4 className={classes.heading}>You are now signed out</h4>
          <Button variant="contained" color="primary" className={classes.button} onClick={handleAppReload}>
            Sign In again
          </Button>
          <Button variant="contained" color="primary" className={classes.button} onClick={handleOfficeSignout}>
            Sign out - current user
          </Button>
          <div className={classes.helpText}>
            You need to select
            {' '}
            &apos;Sign out - Current User&apos;
            {' '}
            if you are done using the application for the day and another user needs to sign in.
          </div>
        </Paper>
      </Container>
    </>
  );
};

export default SignOut;
