/* eslint-disable array-callback-return */
/* eslint-disable no-param-reassign */
import FormioUtils from 'formiojs/utils';
import React from 'react';
import { get as _get, set as _set } from 'lodash';
import api from '../../shared/api';
import { getFormKeysForSiteByType, PermitTypes } from '../../shared/permits';

const setIsCloseOut = (component) => ({ ...component, isCloseOut: true });
const setIsOperationData = (component) => ({ ...component, isOperationalData: true });

/**
 * Flag fields and their children in order to be able to identify and perform effects
 * further down the tree
 *  * @param {FormState} formState - State of form APPROVED, TIMED-OUT etc
 * @return {(Component) => Component)} - A function the takes a component ref and returns the ref modified or not
 */
export const flagFields = (formState) => (component) => {
  if (component.key === 'closeOut' || component.isCloseOut || _get(component, 'properties.CloseOut', '') === 'true') {
    component.isCloseOut = true;
    if (component.components) component.components = component.components.map(setIsCloseOut);
    if (component.columns) component.columns = component.columns.map(setIsCloseOut);
    if (component.rows && Array.isArray(component.rows)) component.rows = component.rows.map(setIsCloseOut);
  }

  if (
    component.key === 'OperationalData'
    || component.isOperationalData
    || _get(component, 'properties.OperationalData', '') === 'true'
  ) {
    component.isOperationalData = true;
    // eslint-disable-next-line no-unused-expressions
    if (component.components) component.components = component.components.map(setIsOperationData); component.label === "Upload - Signed Paper Copy" && formState === 'APPROVED' ? component.validate= {required: true} : component.validate= {required: false}
    if (component.columns) component.columns = component.columns.map(setIsOperationData);
    if (component.rows && Array.isArray(component.rows)) component.rows = component.rows.map(setIsOperationData);
  }

  return component;
};

/**
 * Disables components in the form based on the state the permit is in
 * @param {FormState} formState - State of form APPROVED, TIMED-OUT etc
 * @return {(Component) => Component)} - A function the takes a component ref and returns the ref modified or not
 */
export const disabledFields = (formState, hasEditPermissions,Location,data) => (component) => {
  let locationId = Location?.LocationId;
  let siteId = Location?.SiteId;
  console.log(siteId)
  if(data){
    let restrictedHotWorkChecklist = data?.ContentJSON?.data?.restrictedHotWorkChecklistGeismar;
    let prePlanningApprovalChecklist = data?.ContentJSON?.data?.prePlanningApprovalChecklist;
    console.log("restrictedHotWorkChecklist"+restrictedHotWorkChecklist)
    console.log("prePlanningApprovalChecklist"+prePlanningApprovalChecklist)
    if(prePlanningApprovalChecklist && restrictedHotWorkChecklist){
      if(component.key === 'prePlanningApprovalPhase'){
        component.hidden = true;
      }
      
    }
  }
  if (!hasEditPermissions) {
    component.disabled = true;
    return component;
  }
  if (component.key === 'workOrder') {
    component.disabled = true;
  }

  if (formState === 'DRAFT') {
    return component;
  }


  // setting a display/layout/subform component to disabled has a side effect where it
  // breaks conditional 'viewWhen' logic
  if (['panel', 'well', 'columns', 'row', undefined, 'form'].includes(component.type)) {
    return component;
  }
  // A user should never be able to change the safe work permit number
  if (component.key === 'safeWorkPermitNumber') {
    component.disabled = true;
    return component;
  }

 //remove disable field in approved stage 
 if (formState === 'APPROVED' && component.label === "Print Name" && locationId === 210200) {
  component.disabled = false;
  console.log(component);
  return component;
}

if (formState === 'APPROVED' && locationId === 210200) { 
  if(component.label === "Time" || 
  component.label === "Oxygen" || 
  component.label === "LEL" || 
  component.label === "CO" || 
  component.label === "H2S" || 
  component.key === "lelInitialReadings" || 
  component.key === "H2SInitialReadings" ){
    component.disabled = false;
      return component;
  }
}

  // Operational data fields are editable during an in progress template
  if (formState === 'APPROVED' && component.isOperationalData) {
    return component;
  }

  if (siteId === 1) {
    // remove disable field in approved stage 
    if (formState === 'APPROVED' && component.label.includes("Additional Reading")) {
      component.disabled = false;
      return component;
    }
    if (formState === 'APPROVED' && component.label === "Time") {
      component.disabled = false;
      return component;
    }
    if (formState === 'APPROVED' && component.label === "Sampled by") {
      component.disabled = false;
      return component;
    }
    if (formState === 'APPROVED' && (component.label === "O2 (20–22%)" || component.label === "O2")) {
      component.disabled = false;
      return component;
    }
    if (formState === 'APPROVED' && (component.label === "LEL/LFL (LEL/LFL <10%)" || component.label === "LEL/LFL")) {
      component.disabled = false;
      return component;
    }
    if (formState === 'APPROVED' && (component.label === "CO (<25 ppm)" || component.label === "CO")) {
      component.disabled = false;
      return component;
    }
    if (formState === 'APPROVED' && component.label === "Temperature") {
      component.disabled = false;
      return component;
    }
    if (formState === 'APPROVED' && component.label === "Other") {
      component.disabled = false;
      return component;
    }
    if (formState === 'APPROVED' && component.label === "Field Set") {
      component.disabled = false;
      return component;
    }
    if (formState === 'APPROVED' && component.label === "Table") {
      component.disabled = false;
      return component;
    }
    if (formState === 'APPROVED' && 
    (component.label === "Print Name" || 
    component.label === "In" || 
    component.label === "Out" || 
    component.label === "Personal Lock On?")) {
      component.disabled = false;
      return component;
    }

    // if (formState === 'APPROVED' && component.label.includes("Upload - Signed Paper Copy")){
    //     component.disabled = false;
    //     component.validate= {required: true};
    // }
    // else{
    //   component.validate= {required: false};
    // }
  }
  
  // Close out fields are still during work progress
  if (component.isCloseOut && ['APPROVED', 'TIMEOUT', 'CLOSING'].includes(formState)) {
    return component;
  }

  // The submit button is required when a permit is in progress so reliving can be updated
  if (formState === 'APPROVED' && component.input && component.key === 'submit') {
    return component;
  }

  // The approvalToProceed container being disabled overrides children components that are enabled
  // so we enable it so the reliving operator can be enabled
  if (formState === 'APPROVED' && component.key === 'approvalToProceed') {
    return component;
  }

  // Enable the reliving operator fields for an approved/active permit
  if (formState === 'APPROVED' && _get(component, 'properties.ApprovalRelief', '') === 'true') {
    return component;
  }

  // Otherwise, unless the permit has not been approved all fields should be disabled
  if (!['PRE-APPROVAL', 'PENDING'].includes(formState)) {
    component.disabled = true;
  }

  return component;
};

/**
 * Populates any fields related to the workOrder in the permit form.
 * These fields have an entry in component.properties where { ErpMapping: workOrderFieldName }
 * the workOrderFieldName value is used as the match key in the form tree
 *
 * @param {Form} form - Reference to FormIo form
 * @param {FormChangeEvent} event - The onChange event callback response
 * @return void
 */
export const hideComponent = (form,event) => {
  // if (form && event.changed){
    if (form && event.changed){
    const components = form.getComponent("safeWork") ? form.getComponent("safeWork") : undefined;
     const prePlanningApproval = form?.getComponent("prePlanningApprovalPhase");
     const restrictedHotWorkChecklistGeismar = components?._data?.restrictedHotWorkChecklistGeismar;
    if(restrictedHotWorkChecklistGeismar && prePlanningApproval){
      prePlanningApproval.component.hidden = components._data.prePlanningApprovalChecklist;
      console.log(prePlanningApproval);
      prePlanningApproval.rebuild();
    }
    if(components?._data.confinedSpace1){
      const confinedSpaceEntryPanelGeismar = form.getComponent("confinedSpaceEntryPanelGeismar");
      let safetyDepartmentApproval = confinedSpaceEntryPanelGeismar.getComponent("authorization2");
      if(components?._data.classBConfinedSpace){
        safetyDepartmentApproval.component.hidden = true;
      }
      if(components?._data.classAConfinedSpace){
        safetyDepartmentApproval.component.hidden = false;
      }
    }
  }

}

/**
 * Disables all components in the tree
 * @return {(Component) => Component)} - A function the takes a component ref and returns the ref modified or not
 */
export const disableAllFields = () => (component) => {
  // setting a panel to disabled has a side effect where it breaks conditional 'viewWhen' logic
  if (component.type === 'panel') {
    return component;
  }

  component.disabled = true;
  return component;
};


/**
 * Populates the work orders dropdown
 * @param {WorkOrder} workOrder - selected work order
 * @param {string} selected - The workOrderId that is to be selected
 * @return {(Component) => Component)} - A function the takes a component ref and returns the ref modified or not
 */
export const populateWorkOrderFields = (workOrder) => (component) => {
  if (workOrder) {
    if (component.key === 'workOrder') {
      component.data.json = Array.from([workOrder])
      component.defaultValue = workOrder.workOrderNumber;
      component.value = workOrder.workOrderNumber;
      component.dataValue = workOrder.workOrderNumber;
      // component.disabled = true;
    }

    if (component.key === 'workOrderSubOperations' && Array.isArray(workOrder.operations)) {
      component.data.json = [...workOrder.operations];
    }
  }

  return component;
};

/**
 * Populates the safe work permit number in the different form resrouces (subforms)
 * @param {string[]} fields - List of work orders
 * @param {string|number} permitNumber - The safe work permit number
 * @return {(Component) => Component)} - A function the takes a component ref and returns the ref modified or not
 */
export const populateSafeWorkPermitNumber = (fields, permitNumber) => (component) => {
  if (component && fields.includes(component.key)) {
    component.defaultValue = Number(permitNumber);
    component.disabled = true;
  }
  return component;
};

/**
 * Handles the population of operations fields in the permit form
 *
 * @param {Form} form - Reference to FormIo form
 * @param {FormChangeEvent} event - The onChange event callback response
 * @param {formio.SelectInput} operationsDropdown - The formio operations dropdown
 * @param {ErpField[]} erpFields - Collection of Erp fields to use for mapping to a work order field
 * @param {WorkOrder} workOrder - The selected work order from the work order dropdown
 *
 * @return void
 */
export const populateOperationsFields = (form, event, operationsDropDown, erpFields) => {
  const components = form.flattenComponents();

  Object.keys(components).forEach((key) => {
    const component = components[key];
    if (!component) return;

    const mapping = _get(component.component.properties, 'ErpMapping');
    if (mapping) {
      const selectedOperation = operationsDropDown.choices.getValue(true);
      if (!selectedOperation) return;
      const operation = operationsDropDown.component.data.json.find((o) => o.operationNumber === selectedOperation);
      const field = erpFields.find((e) => e.PropertyValue === mapping);

      if (field && operation && operation[field.PropertyValue]) {
        if (field.PropertyValue === 'operationDescription') {
          component.setValue(`${operation.operationNumber} - ${operation.operationDescription}`);
        } else {
          component.setValue(operation[field.PropertyValue]);
        }
      }
    }
  });
};

/**
 * When a permit is attached, set any ErpMapping field values form the selected work order
 *
 * @param {Form} form - Reference to FormIo form
 * @param {FormChangeEvent} event - The onChange event callback response
 * @param {ErpField[]} erpFields - Collection of Erp fields to use for mapping to a work order field
 * @return void
 */
export const populateWorkOrderFieldsOnFormAttach = (form, event, erpFields, workOrder) => {
  if (event.changed && _get(event.changed.component, 'properties.TogglesPermitDisplay')) {
 
    const selectedWorkOrder = _get(event.data, 'workOrder');

   if (!selectedWorkOrder || !workOrder) return;

    const permitDataKey = _get(event.changed.component, 'properties.PermitDataKey');
    const subFormComponent = FormioUtils.getComponent(form.components, permitDataKey);
    if (!subFormComponent) return;

    const formPromise = subFormComponent.subForm
      ? Promise.resolve(subFormComponent.subForm)
      : subFormComponent.createSubForm();

    formPromise.then((subForm) => {
      const components = subForm.flattenComponents();
      subFormComponent.setValue(subFormComponent.subForm.submission);

      Object.keys(components).forEach((key) => {
        const component = components[key];

        if (!component) return;
        
        const mapping = _get(component.component.properties, 'ErpMapping');

        if (mapping) {
          const field = erpFields.find((e) => e.PropertyValue === mapping);
          if (field) {
            component.setValue(workOrder[field.PropertyValue], { resetValue: true });
          }
        }
      });
    }).then(() => {
      const operationsDropDown = form.getComponent('workOrderSubOperations');

      if (operationsDropDown && operationsDropDown.dataValue) {
        populateOperationsFields(form, event, operationsDropDown, erpFields, workOrder);
      }
    });
    

  }
};

/**
 * Populates any fields related to the workOrder in the permit form.
 * These fields have an entry in component.properties where { ErpMapping: workOrderFieldName }
 * the workOrderFieldName value is used as the match key in the form tree
 *
 * @param {Form} form - Reference to FormIo form
 * @param {FormChangeEvent} event - The onChange event callback response
 * @return void
 */
export const updateFieldsOnWorkOrderChange = (form, event, erpFields) => {
  // ensure all the information we need is here and it was the workOrder dropdown that changed
  if (form && erpFields && event.changed && event.changed.component.key === 'workOrder' && event.changed.value) {
    // find the selected work order from the dropdown

    if (!Array.isArray(event.changed.component.data.json)) {
      return
    }

    const workOrder = event.changed.component.data.json.find((w) => w.workOrderNumber === String(event.changed.value));
    const componentMap = form.flattenComponents();

    // loop through the fields in the form looking for fields that have the custom property key "ErpMapping".
    // For fields that have this key, find the associated field by the PathPointer available in the erpFields param
    for (const componentKey in componentMap) {
      const component = componentMap[componentKey];

      // Create the subform, and then loop through it's components
      // and set the value based on the erpFields.
      // Otherwise it is a regular, loaded component so we perform a simple look up
      // to see if there is a matching erpField
      if (component.type === 'form' && component.visible) {
        // Depending on if the panel containing the subform is expaned, it will have a populated
        // subform. If it doesn't have a subform, create it. Once created, flatten the subform components
        // and loop through performing the same action to find ErpMapping fields and set their values.
        let formPromise = component.subForm
          ? Promise.resolve(component.subForm)
          : component.createSubForm();

        formPromise.then(subForm => {
          const components = subForm.flattenComponents();

          Object.keys(components).forEach(key => {
            const component = components[key];
            if (!component) return

            const mapping = _get(component.component.properties, 'ErpMapping');

            if (mapping) {
              const field = erpFields.find(e => e.PropertyValue === mapping);
              field && component.setValue(workOrder[field.PropertyValue])
            }
          });
        })
      } else {
        const mapping = _get(component.component.properties, 'ErpMapping');

        if (mapping) {
          const field = erpFields.find(e => e.PropertyValue === mapping);
          field && component.setValue(workOrder[field.PropertyValue])
        }
      }
    }
  }
}

/**
 * Populates any fields related to the workOrder in the permit form.
 * These fields have an entry in component.properties where { ErpMapping: workOrderFieldName }
 * the workOrderFieldName value is used as the match key in the form tree
 *
 * @param {Form} form - Reference to FormIo form
 * @param {FormChangeEvent} event - The onChange event callback response
 * @return void
 */
export const updateLinkedPermitFields = async (form, event, locationId) => {
  if (form && event.changed && _get(event.changed.component, 'properties.SelectFromPermitType')) {
    const permitDataKey = _get(event.changed.component, 'properties.PermitDataKey');
    const component = form.getComponent(permitDataKey);

    if (['', 'createNewPermit'].includes(event.changed.value)) {
      component.options = {
        ...component.options,
        readOnly: false,
      };
      if (component.subForm) {
        component.subForm.submission = {
          data: {},
        };
        component.dataValue = {};
        component.subForm.disabled = false;
        component.subForm.options.readOnly = false;
        component.subForm.rebuild();
      }
    } else {
      // Fetch the content of the permit to attach
      const { Content, PermitNumber } = await api.get(`/permitcontent?PermitInstanceId=${event.changed.value}`);
      const linkedPermitData = Content.data[permitDataKey];

      if (linkedPermitData) {
        if(component.key === 'excavationPermit'){
          linkedPermitData.data.weatherConditions = "";
          linkedPermitData.data.Weather = "";
          linkedPermitData.data.wetWeatherCondition = false;
          linkedPermitData.data.dryWeatherCondition = false;
        }
        const permitKeys = getFormKeysForSiteByType(locationId, PermitTypes.WorkPermit);

        // rebuild the data and the submission object and update the form.
        const structuredPermitData = {
          metadata: linkedPermitData.metadata,
          data: {
            ...linkedPermitData.data,
            permitNumber: PermitNumber,
            safeWorkPermitNumber: _get(form.submission, `${permitKeys.form}.data.safeWorkPermitNumber`, undefined),
          },
        };

        if (component.subForm) {
          component.subForm.disabled = true;
          if(component.key === 'excavationPermit'){
            component.subForm.component.components.map((component) => {
              if(component.key === "columns26"){
                let col = form.getComponent("columns26");
                col.disabled = false;
              }
            })
          }

          
        }

        if(locationId === 17 && PermitNumber && form.getComponent("authorizationNumberConfinedSpace")){
          form.getComponent("authorizationNumberConfinedSpace").setValue(PermitNumber);
        }
        
        component.options = {
          ...component.options,
          readOnly: true,
        };

        component.setValue(structuredPermitData);
        if (component.subForm) {
          component.subForm.redraw();
        }
      }
    }
  }else{
    if(form && form.getComponent("selectExcavationPermitToBeAttached")){
      let selectExcavationPermitToBeAttached = form.getComponent("selectExcavationPermitToBeAttached");
      if(selectExcavationPermitToBeAttached.dirty){
        let subForm = form.getComponent("excavationPermitPanel").component.components[0];
        subForm.disabled = true;
        subForm.components.map((component) => {
          if(component.key !== "columns26" && component !== "content14"){
            component.hidden = false;
          }
        })
      }
    }
  }
};

/**
 * Populates the operations dropdown when teh selected work order changes
 *
 * @param {Form} form - Reference to FormIo form
 * @param {FormChangeEvent} event - The onChange event callback response
 * @param {formio.SelectInput} operationsDropdown - The formio operations dropdown
 * @param {ErpField[]} erpFields - Collection of Erp fields to use for mapping to a work order field
 * @param {WorkOrder} workOrder - selected work order
 *
 * @return void
 */
export const populatedOperationsSelect = (form, event, erpFields, workOrder) => {
  if (form && event.changed && workOrder && _get(event.changed.component, 'key') === 'workOrder') {
    const operationsDropDown = form.getComponent('workOrderSubOperations');

    if (!operationsDropDown) {
      return;
    }
    operationsDropDown.setValue('');
    operationsDropDown.choices.clearChoices();

    if (!workOrder || !Array.isArray(workOrder.operations)) {
      return;
    }

    operationsDropDown.component.data = {
      json: [...workOrder.operations],
    };

    operationsDropDown.addCurrentChoices(workOrder.operations);
    populateOperationsFields(form, event, operationsDropDown, erpFields, workOrder);
  }
};

/**
 * Updates the permit form fields when the selected operation changes
 *
 * @param {Form} form - Reference to FormIo form
 * @param {FormChangeEvent} event - The onChange event callback response
 * @param {formio.SelectInput} operationsDropdown - The formio operations dropdown
 * @param {ErpField[]} erpFields - Collection of Erp fields to use for mapping to a work order field
 * @param {WorkOrder} workOrder - selected work order
 *
 * @return void
 */
export const updateOperationsFields = (form, event, erpFields, workOrder) => {
  if (form && event.changed && _get(event.changed.component, 'key') === 'workOrderSubOperations') {
    const operationsDropDown = form.getComponent('workOrderSubOperations');

    if (operationsDropDown && workOrder) {
      populateOperationsFields(form, event, operationsDropDown, erpFields, workOrder);
    }
  }
};

/**
 * Updates the work order related select boxes in a form
 *
 * @param {Form} form - Reference to FormIo form
 * @param {WorkOrder} workOrder - selected work order
 *
 * @return void
 */
export const updateSelectedWorkOrderInForm = (form, workOrder) => {
  const workOrderComponent = form.getComponent('workOrder');
  if (workOrderComponent) {
    workOrderComponent.choices.clearChoices();

    if (workOrder) {
      workOrderComponent.addCurrentChoices(Array.from([workOrder]));
      workOrderComponent.component.data.json = Array.from([workOrder])
      workOrderComponent.setValue(workOrder.workOrderNumber);
    }
    else {
      workOrderComponent.component.data.json = [];
      workOrderComponent.setValue(undefined);
    }

    const operationsDropDown = form.getComponent('workOrderSubOperations');

    if (!operationsDropDown) {
      return
    }

    operationsDropDown.choices.clearChoices();
    operationsDropDown.setValue(undefined);

    if (workOrder && Array.isArray(workOrder.operations)) {
      operationsDropDown.addCurrentChoices(Array.from(workOrder.operations));
      operationsDropDown.component.data.json = Array.from(workOrder.operations);
      operationsDropDown.disabled = false;
    } else {
      operationsDropDown.component.data.json = [];
      operationsDropDown.disabled = true;
    }
  }
}

/**
 * Disable permit form for attached permit
 *
 * @param {Form} form - Reference to FormIo form
 * @param {contentData} contentData - selected permit data
 *
 * @return void
 */
export const applyDisableEffectToPermit = (form, contentData) => {

  if(!contentData || !form) return;

  const components = form.flattenComponents();
  Object.keys(components).forEach((key) => {
    const component = components[key];
    if (!component) return;

    if (_get(component.component, 'properties.SelectFromPermitType')) {
      const permitDataKey = _get(component.component, 'properties.PermitDataKey');
      if (contentData[component.component.key]) {
        const targetComponent = form.getComponent(permitDataKey);
        if (targetComponent.component) {
          targetComponent.component.disabled = true;
        }
      }
    }
  });
};

export const updateFormFieldToSubForm = async (form, event) => {
  if(!event.changed) return;
  const broadcastProperties = _get(event.changed.component, 'properties.broadcastValueToSubscriber')
  if (event.changed && broadcastProperties) {

    form.everyComponent(c => {
      const subscribeProperties = _get(c.component, 'properties.subscribeValueFromSource');
      if (subscribeProperties === broadcastProperties) {
          c.setValue(event.changed.value)
      }

    });
  };

  
  if (_get(event.changed.component, 'properties.TogglesPermitDisplay')) {

    const permitDataKey = _get(event.changed.component, 'properties.PermitDataKey');
    const subFormComponent = FormioUtils.getComponent(form.components, permitDataKey);
    if (!subFormComponent) return;

    const formPromise = subFormComponent.subForm
      ? Promise.resolve(subFormComponent.subForm)
      : subFormComponent.createSubForm();
    formPromise.then((subForm) => {

      const components = subForm.flattenComponents();
      subFormComponent.setValue(subFormComponent.subForm.submission);
      Object.keys(components).forEach((key) => {
        const component = components[key];
        if (!component) return;

        const subscribeProperties = _get(component.component.properties, 'subscribeValueFromSource');

        if (subscribeProperties) {
          form.everyComponent(c => {
            const broadcastProperties = _get(c.component, 'properties.broadcastValueToSubscriber');
            if (subscribeProperties === broadcastProperties) {
                const value = form._data[c.path]
                component.setValue(value)
            }
          })
        }

      });
    });

    if(subFormComponent && subFormComponent.redraw) {
      subFormComponent.redraw()
    }
    
  }

}

export const setInitValueInForm = (form) => {
  if (!form) return;
  form.everyComponent(comp => {
    const broadcastProperties = _get(comp.component, 'properties.broadcastValueToSubscriber');
    if (broadcastProperties) {
      form.everyComponent(c => {
        const subscribeProperties = _get(c.component, 'properties.subscribeValueFromSource');
        if (subscribeProperties === broadcastProperties) {
            const value = form._data[comp.path]
            c.setValue(value)
        }
      })
    }
  });
}

export const getErrorList= (form) => {
  const error = [];
  let count = 0;
  try {
    form.components.forEach((data) => {
      if (data.components && data.errors.length) {
        const errors = data.errors.map((e) => {
          return { 
            label: e.message, 
            key: e.component.key, 
            name: e.component.name,
            id:  e.component.id,
            inputType: e.component.inputType, 
            path: e.formattedKeyOrPath
          }
        });
        const errorMessage = [];
        errors.forEach(data => {
          if (data.inputType === 'radio') {
            if (!errorMessage.find(a => a.name === data.name)) {
              const msg = `${data?.name?.replace(/([a-z])([A-Z])/g, '$1 $2')} is required`.toLowerCase();
              errorMessage.push({
                ...data,
                label: msg.charAt(0).toUpperCase() + msg.slice(1)
              })
            }
          } else {
            errorMessage.push(data)
          }
        })
        count = count + errorMessage.length;
        error.push({ label: data.errorLabel, count: errorMessage.length, errorMessage })
      }
    })
  } catch(error) {
    console.log(error)
  }
  return {
    total: count,
    result: error
  }
}