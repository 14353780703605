import React from 'react';
import { ResponsiveBar } from '@nivo/bar';
import { Paper } from '@material-ui/core';

import { PermitConfigMap } from '../../../../config/permits';

import { GraphStats } from '../query';
import { PermitState } from '../../../../types/permit';

type Props = {
  data: GraphStats;
  states: PermitConfigMap;
}

const PermitsCountGroupedByStateChart: React.FC<Props> = (props) => {
  const { data, states } = props;
  return (
    <Paper style={{ height: '400px', marginBottom: '2rem', paddingRight: '1rem' }}>
      <ResponsiveBar
        data={data}
        keys={Object.keys(states)}
        indexBy="label"
        margin={{ top: 50, right: 130, bottom: 50, left: 60 }}
        padding={0.3}
        colors={(bar) => states[bar.id as PermitState].color}
        valueScale={{ type: 'linear' }}
        defs={[
          {
            id: 'dots',
            type: 'patternDots',
            background: 'inherit',
            color: '#38bcb2',
            size: 4,
            padding: 1,
            stagger: true,
          },
          {
            id: 'lines',
            type: 'patternLines',
            background: 'inherit',
            color: '#eed312',
            rotation: -45,
            lineWidth: 6,
            spacing: 10,
          },
        ]}
        borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
        axisTop={null}
        axisRight={null}
        axisBottom={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: 'Location',
          legendPosition: 'middle',
          legendOffset: 32,
        }}
        axisLeft={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: 'Permits',
          legendPosition: 'middle',
          legendOffset: -40,
        }}
        labelSkipWidth={12}
        labelSkipHeight={12}
        labelTextColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
        legends={[
          {
            dataFrom: 'keys',
            anchor: 'bottom-right',
            direction: 'column',
            justify: false,
            translateX: 120,
            translateY: 0,
            itemsSpacing: 2,
            itemWidth: 105,
            itemHeight: 20,
            itemDirection: 'left-to-right',
            itemOpacity: 0.85,
            symbolSize: 20,
            effects: [
              {
                on: 'hover',
                style: {
                  itemOpacity: 1,
                },
              },
            ],
          },
        ]}
        animate
        motionStiffness={90}
        motionDamping={15}
      />
    </Paper>
  );
};

export default PermitsCountGroupedByStateChart;
