import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import {
  AppBar,
  Button,
  CircularProgress,
  Grid,
  IconButton,
  makeStyles,
  Toolbar,
  Typography,
  Tooltip,
} from '@material-ui/core';

import {
  ExpandLess as ExpandLessIcon,
  ExpandMore as ExpandMoreIcon,
} from '@material-ui/icons';

import useDraft from '../../../hooks/permits/useDraft';
import PermitStateChip from './PermitStateChip';

const useStyles = makeStyles((theme) => ({
  text: {
    padding: theme.spacing(2, 2, 0),
  },
  paper: {
    paddingBottom: 50,
  },
  list: {
    marginBottom: theme.spacing(2),
  },
  subheader: {
    backgroundColor: theme.palette.background.paper,
  },
  appBar: {
    top: 'auto',
    bottom: 0,
    display: 'flex',
    justifyContent: 'space-between',
  },
  grow: {
    flexGrow: 1,
  },
  leftSection: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },
  centerSection: {
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      justifyContent: 'center',
    },
  },
  rightSection: {
    alignItems: 'center',
    justifyContent: 'flex-end',
    display: 'flex',
    color: theme.palette.grey[200],
    '& > *': {
      marginLeft: '1.5rem',
    },
  },
  loadingSpinner: {
    color: theme.palette.grey[200],
    marginRight: theme.spacing(2),
  },
  createButton: {
    alignSelf: 'center',
    backgroundColor: theme.palette.grey[200],
    color: theme.palette.primary.main,
  },
}));

const PermitFooterBar = (props) => {
  const {
    handleSubmit,
    loading,
    onCollapsePermits,
    onExpandPermits,
    PermitInstanceReferenceId,
    State,
  } = props;

  const classes = useStyles();
  const { lastSaved, saving } = useDraft();

  const renderLastSaved = React.useMemo(
    () => (
      <>
        {lastSaved ? (
          <>
            {saving && <CircularProgress size="1.5rem" className={classes.loadingSpinner} />}
            <Typography variant="subtitle1">
              {saving ? 'saving draft...' : `draft saved ${moment(lastSaved).format('h:mm:ss a')}`}
            </Typography>
          </>
        ) : (
          <Typography variant="subtitle1">draft will be saved every 20s</Typography>
        )}
      </>
    ),
    [classes.loadingSpinner, lastSaved, saving],
  );

  const renderInstanceReferenceId = React.useMemo(() => (
    <Typography variant="subtitle1">
      <b>Permit # </b>
      {PermitInstanceReferenceId}
    </Typography>
  ), [PermitInstanceReferenceId]);

  return (
    <AppBar position="fixed" color="primary" className={classes.appBar}>
      <Toolbar className={classes.toolbar}>
        <Grid container>
          <Grid item md={4} className={classes.leftSection}>
            {!loading && !saving && (
              <>
                <Tooltip title="Collapse All Permits" aria-label="collapse-permits">
                  <IconButton variant="contained" aria-label="expand-all" onClick={onExpandPermits}>
                    <ExpandLessIcon fontSize="large" style={{ color: 'white' }} />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Expand All Permits" aria-label="expand-permits">
                  <IconButton variant="contained" aria-label="collapse-all" onClick={onCollapsePermits}>
                    <ExpandMoreIcon fontSize="large" style={{ color: 'white' }} />
                  </IconButton>
                </Tooltip>
              </>
            )}
          </Grid>
          <Grid item xs={6} md={4} className={classes.centerSection}>
            <Button
              variant="contained"
              className={classes.createButton}
              onClick={handleSubmit}
              disabled={loading}
            >
              {loading ? 'Saving' : 'Submit'}
              {' '}
              Permit
            </Button>
          </Grid>
          {!loading && (
            <Grid item xs={6} md={4} className={classes.rightSection}>
              {PermitInstanceReferenceId && <PermitStateChip state={State} />}
              {PermitInstanceReferenceId ? renderInstanceReferenceId : renderLastSaved}
            </Grid>
          )}
        </Grid>
      </Toolbar>
    </AppBar>
  );
};

PermitFooterBar.defaultProps = {
  loading: false,
  PermitInstanceReferenceId: null,
  State: null,
};

PermitFooterBar.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  onCollapsePermits: PropTypes.func.isRequired,
  onExpandPermits: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  PermitInstanceReferenceId: PropTypes.string,
  State: PropTypes.string,
};

export default PermitFooterBar;
