import React from 'react';
import PropTypes from 'prop-types';
import { ArrowDropDown, ArrowDropUp } from "@material-ui/icons";

import {
  Box,
  Typography,
  Button,
  Menu,
  makeStyles,
  FormControl,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core';

const useStyles = makeStyles(
  theme => ({
    label: {
      fontWeight: 'bold',
      margin: theme.spacing(1),
    },
    formControl: {
      padding: theme.spacing(1),
    }
  })
);

const FilterDropDownMultiSelect = (props) => {
  const { defaultValue, label, options, onSelect } = props;

  const classes = useStyles();
  const [isOpen, setIsOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [selected, setSelected] = React.useState(new Set(defaultValue));

  React.useEffect(() => {
    setSelected(new Set(defaultValue));
  }, [defaultValue])

  const renderArrow = () => isOpen ? <ArrowDropUp /> : <ArrowDropDown />;

  const handleOnSelect = (option) => {
    const nextSelected = new Set(selected);
    if (selected.has(option.value)) {
      nextSelected.delete(option.value)
    } else {
      nextSelected.add(option.value)
    }
    setSelected(nextSelected);
    onSelect(Array.from(nextSelected))
  }

  const formatLabel = () => {
    if (selected.size === 0) {
      return 'None';
    } else if (selected.size < 3) {
      let label = '';
      selected.forEach(val => label = label === '' ? val : `${label}, ${val}`)
      return label;
    } else {
      const values = Array.from(selected);
      return `${values[0]}, ${values[1]} + ${selected.size - 2}`;
    }
  }

  return (
    <Box p={2}>
      <Typography variant="subtitle1" className={classes.label}>{label}</Typography>
      <Button
        variant="outlined"
        ref={ref => setAnchorEl(ref)}
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={() => setIsOpen(!isOpen)}>
        {formatLabel()} {renderArrow()}
      </Button>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        defaultValue={defaultValue}
        open={isOpen}
        onClose={() => setIsOpen(!isOpen)}
      >
        <FormControl component="fieldset" className={classes.formControl}>
          <FormGroup>
            {options.map((option) => (
              <FormControlLabel
                key={option.value}
                control={
                  <Checkbox
                    checked={selected.has(option.value)}
                    onChange={() => handleOnSelect(option)}
                    value={option.value}
                  />
                }
                label={option.label || option.value}
              />
            ))}
          </FormGroup>
        </FormControl>
      </Menu>
    </Box>
  );
}

FilterDropDownMultiSelect.propTypes = {
  label: PropTypes.string.isRequired,
  defaultValue: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  onSelect: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string,
    })
  ).isRequired
};

export default FilterDropDownMultiSelect;
