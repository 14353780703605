import React from 'react';
import PropTypes from 'prop-types';
import { Chip, makeStyles } from '@material-ui/core';

type Props = {
  colors: { [state: string]: string };
  state: string;
}

const useStyles = makeStyles((theme) => ({
  chip: {
    backgroundColor: (props: Props) => props.state && props.colors[props.state],
    color: theme.palette.common.white,
  },
}));

const StateChip: React.FC<Props> = (props) => {
  const { state } = props
  const classes = useStyles(props);
  return (
    <Chip label={state} size="small" className={classes.chip} />
  );
}

StateChip.propTypes = {
  colors: PropTypes.objectOf(PropTypes.string.isRequired).isRequired,
  state: PropTypes.string.isRequired,
};

export default StateChip;
