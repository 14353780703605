import React from 'react';

import {
  Grid,
  Table,
  TableContainer,
  TableRow,
  TableCell,
  TableHead,
  TableBody,
  Paper,
  makeStyles,
  Tooltip,
} from '@material-ui/core';
import { Edit, Print, Visibility } from '@material-ui/icons';

import StateChip from '../../../shared/StateChip';
import InlineLoader from '../../../shared/InlineLoader';
import ExternalUsersTableRowMenu from './ExternalUsersTableRowMenu';

import { ExternalUsersStateColors, UserPermissions } from '../../../../types/external-user';
import useExternalUsers from '../../../../hooks/admin/useExternalUsers';
import { PERMISSIONS } from '../../../../shared/auth';

type Props = {}

const useStyles = makeStyles((theme) => ({
  wrapper: {
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  createButton: {
    marginLeft: theme.spacing(1),
  },
  statusCell: {
    '& > *': {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
  },
}));

const determineUserPermissionsFlags = (permissions?: UserPermissions[]) => {
  const flags = { CanPrint: false, CanEdit: false };

  if (Array.isArray(permissions)) {
    permissions.forEach(permission => {
      if (permission.PermissionTypeId === PERMISSIONS.Permits.Edit) {
        flags.CanEdit = permission.Locations.length > 0;
      }

      if (permission.PermissionTypeId === PERMISSIONS.Permits.Print) {
        flags.CanPrint = permission.Locations.length > 0;
      }
    })
  }

  return flags;
}

const ExternalUsersTable: React.FC<Props> = (props) => {
  const { loading, data } = useExternalUsers();
  const classes = useStyles();

  return (
    <TableContainer component={Paper} className={classes.wrapper}>
      {loading ? <InlineLoader /> : (
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Email Address</TableCell>
              <TableCell>Company</TableCell>
              <TableCell align="center">Permissions</TableCell>
              <TableCell align="right" />
              <TableCell align="right" />
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((user) => {
              const permissions = determineUserPermissionsFlags(user.Permissions);
              return (
                <TableRow key={user.PersonnelId}>
                  <TableCell>{user.FirstName} {user.LastName}</TableCell>
                  <TableCell>{user.EmailAddress}</TableCell>
                  <TableCell>{user.Company}</TableCell>
                  <TableCell align="center">
                    <Grid container>
                      <Grid item xs={4}>
                        <Tooltip title="Can View Permits" aria-label="can-view-permits">
                          <Visibility />
                        </Tooltip>
                      </Grid>
                      <Grid item xs={4}>
                        {permissions.CanEdit && (
                          <Tooltip title="Can Edit Permits" aria-label="can-edit-permits">
                            <Edit />
                          </Tooltip>
                        )}
                      </Grid>
                      <Grid item xs={4}>
                        {permissions.CanPrint && (
                          <Tooltip title="Can Print Permits" aria-label="can-view-permits">
                            <Print />
                          </Tooltip>
                        )}
                      </Grid>
                    </Grid>
                  </TableCell>
                  <TableCell align="right">
                    <StateChip
                      colors={ExternalUsersStateColors}
                      state={user.Status}
                    />
                  </TableCell>
                  <TableCell align="right">
                    <ExternalUsersTableRowMenu user={user} />
                  </TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      )}
    </TableContainer>
  );
};

export default ExternalUsersTable;
