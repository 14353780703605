import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { MoreVert as MoreVertIcon } from '@material-ui/icons';

import {
  Menu,
  MenuItem,
  IconButton,
} from '@material-ui/core';

import { Template, TemplateStateAction } from '../../../../../types/template';
import { PermitTemplate } from '../../../../../types/prop-types/template';
import TemplatesState from '../../../../../actions/admin/templates';

import ActionDialog from '../../shared/ActionDialog';
import DeleteDialog from '../../shared/DeleteDialog';

interface Props {
  template: Template;
}

type DialogType = TemplateStateAction | 'delete';

const TemplatesListMenu: React.FC<Props> = ({ template }) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const [anchorEl, setAnchorEl] = React.useState<Element>();
  const [openDialog, setOpenDialog] = React.useState<DialogType>();

  const handleClick: React.MouseEventHandler = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(undefined);
  };

  const handleEditClick = () => {
    history.push(`/admin/templates/${template.PermitTemplateId}/edit`);
    handleClose();
  };

  const handleViewClick = () => {
    history.push(`/admin/templates/${template.PermitTemplateId}`);
    handleClose();
  };

  const handleCopyClick = () => {
    dispatch(TemplatesState.actions.copyTemplate(template.PermitTemplateId));
  };

  const showDialog = (dialogType: DialogType) => {
    setOpenDialog(dialogType);
    handleClose();
  };

  return (
    <div>
      <IconButton onClick={handleClick}>
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {template.State === 'DRAFT' && <MenuItem onClick={handleEditClick}>Edit</MenuItem>}
        {template.State !== 'DRAFT' && <MenuItem onClick={handleViewClick}>View</MenuItem>}
        {template.State === 'DRAFT' && (
          <MenuItem onClick={() => showDialog(TemplateStateAction.Review)}>
            Submit for Review
          </MenuItem>
        )}
        {template.State === 'REVIEW' && (
          <MenuItem onClick={() => showDialog(TemplateStateAction.Reject)}>
            Reject
          </MenuItem>
        )}
        {template.State === 'REVIEW' && (
          <MenuItem onClick={() => showDialog(TemplateStateAction.Approve)}>
            Approve
          </MenuItem>
        )}
        {template.State === 'APPROVED' && (
          <MenuItem onClick={() => showDialog(TemplateStateAction.Discontinue)}>
            Discontinue
          </MenuItem>
        )}
        <MenuItem onClick={handleCopyClick}>Copy</MenuItem>
        {template.State === 'DRAFT' && <MenuItem onClick={() => showDialog('delete')}>Delete</MenuItem>}
      </Menu>

      {openDialog && openDialog !== 'delete' && (
        <ActionDialog
          action={openDialog}
          onClose={() => setOpenDialog(undefined)}
          PermitTemplateId={template.PermitTemplateId}
        />
      )}

      {openDialog === 'delete' && (
        <DeleteDialog
          onClose={() => setOpenDialog(undefined)}
          PermitTemplateId={template.PermitTemplateId}
        />
      )}
    </div>
  );
};

TemplatesListMenu.propTypes = {
  template: PermitTemplate.isRequired,
};

export default TemplatesListMenu;
