import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Delete as DeleteIcon } from '@material-ui/icons';
import { IconButton, CircularProgress, } from '@material-ui/core';

import Dialog from '../../shared/Dialog';
import PermitState from '../../../actions/permits';
import AppState from '../../../actions/app';
import { ActionStatus } from '../../../actions/shared/status';

const DeleteDraftButton = (props) => {
  const { draftId } = props;

  const dispatch = useDispatch();

  const [showConfirmDialog, setShowConfirmDialog] = React.useState(false);
  const deleteStatus = useSelector(AppState.selectors.status(PermitState.types.deleteDraft));

  const handleIconClick = () => {
    setShowConfirmDialog(true);
  };

  const handleDelete = () => {
    dispatch(PermitState.actions.deleteDraft(draftId));
    setShowConfirmDialog(false);
  };

  return (
    <>
      <IconButton
        aria-label="delete"
        color="secondary"
        onClick={() => handleIconClick()}
        disabled={deleteStatus === ActionStatus.pending}
      >
        {deleteStatus === ActionStatus.pending ? (
          <CircularProgress color="secondary" size="1em" />
        ) : (
          <DeleteIcon />
        )}
      </IconButton>

      {showConfirmDialog && (
        <Dialog
          visible
          onClose={() => setShowConfirmDialog(false)}
          title="Are you sure you want to delete this draft?"
          actions={[
            {
              text: 'Cancel',
              onClick: () => setShowConfirmDialog(false),
            },
            {
              text: 'Delete',
              color: 'secondary',
              onClick: () => handleDelete(),
            }]}
        />
      )}
    </>
  );
};

DeleteDraftButton.propTypes = {
  draftId: PropTypes.string.isRequired,
};

export default DeleteDraftButton;
