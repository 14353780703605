import React from 'react';
import { useSelector } from 'react-redux';
import { find as _find} from 'lodash';

import AppState from '../../actions/app';
import PermitsState from '../../actions/permits';
import { Location } from '../../types/location';

const useLocations = () => {
  const locations = useSelector(AppState.selectors.locations()) as Location[];
  const homeLocation = useSelector(AppState.selectors.homeLocation()) as Location;
  const selectedLocation = useSelector(PermitsState.selectors.location()) as Location;
  const selected = selectedLocation || homeLocation;

  const hasPlotPlanAtSite: boolean = React.useMemo(() => {
    const { PlotPlans = [], CurrentPlotPlan} = selectedLocation;
    if(CurrentPlotPlan === null || CurrentPlotPlan === undefined) return false;
    const plotplan = _find(PlotPlans, (data: any) => {
      return data.Version === CurrentPlotPlan
    }) as any;
    return Boolean(plotplan?.FileName);
  }, [selectedLocation]);

  return {
    locations,
    homeLocation,
    plotPlanEnabled: hasPlotPlanAtSite,
    selected,
  };
};

export default useLocations;
