import { PermitState } from '../types/permit';

export type PermitConfigMap = {
  [state in PermitState]: {
    label: string;
    color: string;
    openStatus?: boolean;
  }
};

const PermitConfig: PermitConfigMap = {
  DRAFT: { label: 'Draft', color: '#6f42c1', openStatus: true },
  'PRE-APPROVAL': { label: 'Pre approval', color: '#4ec3a9', openStatus: true },
  PENDING: { label: 'Pending', color: '#fd7e14', openStatus: true },
  REJECTED: { label: 'Rejected', color: '#dc3545' },
  APPROVED: { label: 'Approved', color: '#28a745', openStatus: true },
  COMPLETED: { label: 'Completed', color: '#007bff' },
  CLOSING: { label: 'Closing', color: '#343a40', openStatus: true },
  REVOKED: { label: 'Revoked', color: '#17a2b8' },
  TIMEOUT: { label: 'Timeout', color: 'gray', openStatus: true },
};

export const states = Object.keys(PermitConfig);

/**
 * filter util function to get filtered permit state
 * @param {PermitState} data - permit cache state
 * @return {PermitState[]}
 */
export const filteredStates = (data: PermitState[]) => {
  if (Array.isArray(data)) {
    return states.filter((state: string) => !data.includes(state as PermitState)) as PermitState[];
  }
  return [];
}

export default PermitConfig;
