import React from 'react';
import TreeItem from '@material-ui/lab/TreeItem';
import {
  makeStyles,
  Typography,
  createStyles,
  FormControlLabel,
  Checkbox
} from '@material-ui/core';


const useStyles = makeStyles((theme) => createStyles({
  root: {
    marginLeft: theme.spacing(2),
    backgroundColor: 'transparent',
    margin: theme.spacing(1, 0),
    '&.Mui-selected > $content > $label': {
      backgroundColor: theme.palette.grey[100]
    }
  },
  content: {
    fontWeight: theme.typography.fontWeightRegular,
  },
  group: {
    marginLeft: theme.spacing(1),
  },
  label: {
    fontWeight: 'inherit',
    color: 'inherit',
    paddingLeft: 0,
  },
  labelText: {
    padding: theme.spacing(1),
  },
  labelTextBold: {
    fontWeight: theme.typography.fontWeightBold,
    fontSize: 16,
    padding: theme.spacing(1),
  },
  checkboxWrapper: {
    padding: 0,
    marginLeft: 0,
    marginRight: theme.spacing(1),
    marginBottom: 0,
  },
}));

const LocationTreeItem = (props) => {
  const classes = useStyles();

  const {
    labelText,
    isParent,
    selected,
    data,
    handleSelect,
    indeterminate,
    disabled,
    name,
    color,
    ...other
  } = props;

  const handleClick = (event, checked, data) => {
    handleSelect && handleSelect(checked, data, event)
  };

  return (
    <TreeItem
      label={
        <FormControlLabel
          onClick={event => event.stopPropagation()}
          classes={{root: classes.checkboxWrapper}}
          label={<Typography variant="body2" className={isParent ? classes.labelTextBold : classes.labelText}>{labelText}</Typography>}
          control={
            <Checkbox
              indeterminate={indeterminate}
              checked={selected}
              disabled={!!disabled}
              onClick={event => event.stopPropagation()}
              onChange={(event, checked) => handleClick(event, checked, data)}
              name={name || labelText}
              color={color || "primary" }
              classes={{
                root: classes.checkboxWrapper
              }}
            />
          }
        />
      }
      classes={{
        root: classes.root,
        content: classes.content,
        group: classes.group,
        label: classes.label,
      }}
      {...other}
    />
  );
}

export default LocationTreeItem;
