import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store/RootState";
import { WorkOrder } from "../../types/work-order";

import WorkOrderState from "../../actions/work-orders";

const useWorkOrder = (workOrderNumber?: number | string) => {
  const dispatch = useDispatch();

  const [workOrder, setWorkOrder] = React.useState<WorkOrder>();
  const [loading, setLoading] = React.useState<boolean>(false);

  const workOrdersFromState = useSelector(
    (state: RootState) => state.workOrders.list
  );

  React.useEffect(() => {
    if (!workOrderNumber) {
      return;
    }

    const workOrder = workOrdersFromState.find(
      (wo) => Number(wo.workOrderNumber) === Number(workOrderNumber));

    if (workOrder) {
      setWorkOrder(workOrder);
    }
  }, [workOrdersFromState, workOrderNumber]);

  React.useEffect(() => {
    const fetchWorkOrder = async () => {
      const workOrder = workOrdersFromState.find(
        (wo) => Number(wo.workOrderNumber) === Number(workOrderNumber)
      );

      if (workOrder) {
        setWorkOrder(workOrder);
      } else {
        if (workOrderNumber) {
          setLoading(true);

          await dispatch(
            WorkOrderState.actions.getWorkOrderById(workOrderNumber as string)
            );

            setLoading(false);
          }
        }
    };

    if (workOrderNumber) {
      fetchWorkOrder();
    }
  }, [dispatch, workOrdersFromState, workOrderNumber]);

  return { loading, workOrder };
};

export default useWorkOrder;
