import React, { useEffect, useState, forwardRef, useImperativeHandle, useMemo } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import clsx from 'clsx';
import { lighten, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';

import Tooltip from '@material-ui/core/Tooltip';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import { Button, TextField } from '@material-ui/core';
import CircularProgress from "@material-ui/core/CircularProgress";
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import CheckCircleOutlineOutlinedIcon from '@material-ui/icons/CheckCircleOutlineOutlined';

import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";

function descendingComparator(a, b, orderBy, dataType) {
  let A = dataType === "number" ? Number(a[orderBy]) : a[orderBy];
  let B = dataType === "number" ? Number(b[orderBy]) : b[orderBy];
  if (B < A) {
    return -1;
  }
  if (B > A) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy, dataType) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy, dataType)
    : (a, b) => -descendingComparator(a, b, orderBy, dataType);
}

function stableSort(array, selected, order, dataType, comparator) {
  if (dataType === "selection") {
    const temp1 = [];
    const temp2 = [];
    array.forEach(data => {
      if (selected.includes(data.PermitInstanceId)) {
        temp1.push(data)
      } else {
        temp2.push(data)
      }
    })
    return order === 'asc' ? [...temp1, ...temp2] : [...temp2, ...temp1]
  }

  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function EnhancedTableHead(props) {
  const { classes, onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort, headCells } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell, i) => {
          return (
            <>{
              headCell.dataType === 'selection' ?
                <TableCell padding="checkbox"
                  key={headCell.id}
                  sortDirection={orderBy === headCell.id ? order : false}

                >
                  <div className={classes.displayFlex}>
                    <Checkbox
                      indeterminate={numSelected > 0 && numSelected < rowCount}
                      checked={rowCount > 0 && numSelected === rowCount}
                      onChange={onSelectAllClick}
                    />
                    <TableSortLabel
                      active={orderBy === headCell.id}
                      direction={orderBy === headCell.id ? order : 'asc'}
                      onClick={createSortHandler(headCell.id)}
                    >
                      {headCell.label}
                      {orderBy === headCell.id ? (
                        <span className={classes.visuallyHidden}>
                          {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                        </span>
                      ) : null}
                    </TableSortLabel>
                  </div>
                </TableCell>
                :
                <TableCell
                  key={headCell.id}
                  sortDirection={orderBy === headCell.id ? order : false}
                >
                  <TableSortLabel
                    active={orderBy === headCell.id}
                    direction={orderBy === headCell.id ? order : 'asc'}
                    onClick={createSortHandler(headCell.id)}
                  >
                    {headCell.label}
                    {orderBy === headCell.id ? (
                      <span className={classes.visuallyHidden}>
                        {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                      </span>
                    ) : null}
                  </TableSortLabel>
                </TableCell>
            }
            </>
          )
        }
        )}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
  headCells: PropTypes.array.isRequired
};

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === 'light'
      ? {
        color: theme.palette.secondary.main,
        backgroundColor: lighten(theme.palette.secondary.light, 0.85),
      }
      : {
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.secondary.dark,
      },
  title: {
    flex: '1 1 100%',
  },
}));

const EnhancedTableToolbar = (props) => {
  const classes = useToolbarStyles();
  const { numSelected, onSelectionAction } = props;

  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      {numSelected > 0 ? (
        <Typography className={classes.title} color="inherit" variant="subtitle1" component="div">
          {numSelected} selected
        </Typography>
      ) : (
        <Typography className={classes.title} variant="h6" id="tableTitle" component="div">
          Revoke Permits
        </Typography>
      )}

      {numSelected > 0 && (
        <Tooltip title="Revoke">
          <Button color="secondary" variant="contained" onClick={onSelectionAction}>Revoke</Button>
        </Tooltip>
      )}
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    position: 'relative'
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  displayFlex: {
    display: 'flex'
  },
  loading: {
    position: 'absolute;',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    top: '50%',
    right: '50%',
  },
  disableTable: {
    cursor: 'not-allowed'
  },
  colorRed: {
    color: theme.secondary
  },
  filterContainer: {
    padding: theme.spacing(2),
    width: '100%'
  }
}));

function EnhancedTable(props, ref) {
  const { rows, headCells, setShowRevokeDialog, loading, showRevokeResult } = props;
  const classes = useStyles();
  useImperativeHandle(ref, () => ({
    setSelectedRow
  }));
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('');
  const [filter, updateFilter] = useState('')
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const setSelectedRow = (data) => {
    setSelected(data);
  }

  const onSelectionAction = () => {
    setShowRevokeDialog(selected, true)
  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.PermitInstanceId);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);
  const onInputChange = (event) => {
    updateFilter(event.target.value)
  }
  const updatedData1 = useMemo(() => {
    const dataType = headCells.find(data => data.id === orderBy)?.dataType;
    return stableSort(rows, selected, order, dataType, getComparator(order, orderBy, dataType));
  }, [rows, headCells, selected, order, orderBy, filter]);

  const updatedData = useMemo(() => {
    if (!filter) return updatedData1
    return updatedData1.filter(data => {
      let hasValue = false;
      Object.keys(data).forEach(key => {
        if (String(data[key])?.includes(filter)) {
          hasValue = true;
          return;
        }
      })
      return hasValue;
    })
  }, [updatedData1, filter]);

  return (
    <div className={classes.root}>
      {loading && <CircularProgress className={classes.loading} />}
      <Paper className={classes.paper}>
        <EnhancedTableToolbar selected={selected} numSelected={selected.length} onSelectionAction={onSelectionAction} />
        <TextField
          value={filter}
          onChange={onInputChange}
          className={classes.filterContainer}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            )
          }}
        />
        <TableContainer className={loading ? classes.disableTable : ''}>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size={dense ? 'small' : 'medium'}
          >
            <EnhancedTableHead
              classes={classes}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
              headCells={headCells}
            />
            <TableBody>
              {
                updatedData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => {
                    const isItemSelected = isSelected(row.PermitInstanceId);
                    const labelId = `enhanced-table-checkbox-${index}`;

                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={row.PermitInstanceId}
                        selected={isItemSelected}
                      > {
                          showRevokeResult ? (
                            <>
                              {
                                row.ResultCode === 'FAIL' || row.ResultCode === 'OK' ?
                                  (
                                    <>
                                      <TableCell>
                                        {row.ResultCode === 'FAIL' ? <ErrorOutlineIcon color="secondary" /> : ''}
                                        {row.ResultCode === 'OK' ? <CheckCircleOutlineOutlinedIcon style={{ color: '#4caf50' }} /> : ''}
                                      </TableCell>
                                    </>
                                  ) : <TableCell>--</TableCell>
                              }
                              <TableCell className={classes.colorRed}>{row.ResultMessage || '--'}</TableCell>
                            </>
                          ) : <TableCell padding="checkbox">
                            <Checkbox
                              checked={isItemSelected}
                              onChange={(event) => handleClick(event, row.PermitInstanceId)}
                              inputProps={{ 'aria-labelledby': labelId }}
                            />
                          </TableCell>
                        }
                        <TableCell id={labelId} scope="row">
                          {row.PermitInstanceReferenceId}
                        </TableCell>
                        {
                          !showRevokeResult && (
                            <>
                              <TableCell>{row.workOrderNumber}</TableCell>
                              <TableCell>{moment(row.CreatedOn).calendar()}</TableCell>
                              <TableCell>{row.workLocation}</TableCell>
                              <TableCell>{row.permitIssuer}</TableCell>
                              <TableCell>{row.companyName}</TableCell>
                              <TableCell>{row.assetNumber}</TableCell>
                            </>
                          )
                        }
                      </TableRow>
                    );
                  })}
              {emptyRows > 0 && (
                <TableRow style={{ height: (dense ? 33 : 53) * emptyRows }}>
                  <TableCell colSpan={9} style={{ textAlign: 'center' }}>{ !loading ? "No Row found. Please update your filter and try again." : ""}</TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 50, 100, 200]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
      <FormControlLabel
        control={<Switch checked={dense} onChange={handleChangeDense} />}
        label="Dense padding"
      />
    </div>
  );
}

export default forwardRef(EnhancedTable);