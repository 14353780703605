import React from 'react';
import { useLocation, useParams } from 'react-router';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';

import { Typography } from '@material-ui/core';

// LocationAccordian is hided here as on navigation bar has already icon to set location. EPERMIT-642
// import LocationAccordian from './accordians/LocationAccordian';
import WorkOrderAccordian from './accordians/WorkOrderAccordian';
import WorkLocationAccordian, { Coordinates } from './accordians/WorkLocationAccordian';
import WorkPermitAccordian from './accordians/WorkPermitAccordian';

// import { Location } from '../../types/location';
import { WorkOrder } from '../../types/work-order';

import useLocations from '../../hooks/locations/useLocations';
import useSetPageName from '../../hooks/shared/useSetPageName';
import SavingOverlay from './shared/SavingOverlay';
import PermitFooterBar from './shared/PermitFooterBar';

import WorkOrdersState from '../../actions/work-orders';
import PermitsState from '../../actions/permits';
import useAnalytics from '../../hooks/shared/useAnalytics';
import { LogLevel } from '../../shared/analytics';

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0,
  },
  secondaryHeading: {
    color: theme.palette.text.secondary,
  },
  icon: {
    verticalAlign: 'bottom',
    height: 20,
    width: 20,
  },
  details: {
    alignItems: 'center',
  },
  column: {
    flexBasis: '33.33%',
  },
  helper: {
    borderLeft: `2px solid ${theme.palette.divider}`,
    padding: theme.spacing(1, 2),
  },
  link: {
    color: theme.palette.primary.main,
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  title: {
    marginBottom: theme.spacing(0.5)
  }
}),
);

type AccordianName = 'location'
  | 'workLocation'
  | 'workOrder'
  | 'workPermit'

const CreatePermit: React.FC = () => {
  useSetPageName('Create New Permit');

  const { draftId } = useParams<{ draftId: string }>();
  const classes = useStyles();
  const dispatch = useDispatch();
  const recordEvent = useAnalytics();
  const createPermitForm = React.useRef<any>();
  const { state: locationState } = useLocation<{ workOrder?: WorkOrder }>();
  const { homeLocation, plotPlanEnabled } = useLocations();
  const workOrdersEnabled = useSelector(WorkOrdersState.selectors.enabled());

  const [expanded, setExpanded] = React.useState<AccordianName[]>([plotPlanEnabled ? 'workLocation' : 'workPermit']);
  const [workLocation, setWorkLocation] = React.useState<Coordinates>({ lat: 0, lng: 0 });
  // const [permitLocation, setPermitLocation] = React.useState<Location>(homeLocation);
  const [workOrder, setWorkOrder] = React.useState<WorkOrder | undefined>(locationState?.workOrder as WorkOrder);
  const [formData, setFormData] = React.useState<any>({});
  const [submitting, setSubmitting] = React.useState(false);

  React.useEffect(() => {
    recordEvent(`Create Permit page loaded - draftId: [${draftId}]`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const openAccordian = (name: AccordianName) => {
    recordEvent(`Accordian opened [${name}]`);
    setExpanded(e => [...e, name]);
  };

  const closeAccordian = (name: AccordianName) => {
    recordEvent(`Accordian closed [${name}]`);
    setExpanded(expanded.filter(e => e !== name));
  };

  const toggleAccordian = (name: AccordianName) => {
    if (expanded.includes(name)) {
      closeAccordian(name);
    } else {
      openAccordian(name);
    }
  };

  const createPermitFormSubmit = async () => {
    setSubmitting(true);
    
    recordEvent({
      data: 'Create Permit: handleFormSubmit callback submitted from permit footer bar',
      draftId,
      formData,
      workLocation,
    })

    await dispatch(
      PermitsState.actions.createPermit(
        draftId,
        formData,
        workLocation,
      )
    );
    setSubmitting(false);
   }

  const handleFormSubmit = async () => {
    try {
      await createPermitForm.current.submitForm();
    } catch(error){
      recordEvent(`ComponentName: CreatePermit: handleFormSubmit error ${error}`);
    }
  };

  return (
    <div className={classes.root}>
      {submitting && <SavingOverlay />}
        <Typography variant="h6" component="h6" className={classes.title}>
          Location:
          {' '}
          <span>{homeLocation.Title}</span>
          {' '}
        </Typography>
      {/* <LocationAccordian
        expanded={expanded.includes('location')}
        name="location"
        toggleAccordian={() => toggleAccordian('location')}
        location={permitLocation}
        onLocationUpdate={(location: Location) => {
          recordEvent({
            data: 'Create Permit: Location Updated',
            location
          });

          setPermitLocation(location);
          closeAccordian('location');
        }}
      /> */}

      {plotPlanEnabled && (
        <WorkLocationAccordian
          name="workLocation"
          toggleAccordian={() => toggleAccordian('workLocation')}
          expanded={expanded.includes('workLocation')}
          onChange={(locationId: Coordinates) => {
            recordEvent({
              data: 'Create Permit: workLocation Updated',
              workLocation: locationId
            });

            setWorkLocation(locationId);
            closeAccordian('workLocation');
            openAccordian('workOrder');
          }}
          workLocation={workLocation}
        />
      )}

      {workOrdersEnabled && (
        <WorkOrderAccordian
          name="workOrder"
          toggleAccordian={() => toggleAccordian('workOrder')}
          expanded={expanded.includes('workOrder')}
          workOrder={workOrder}
          onChange={(workOrder: WorkOrder | undefined) => {
            setWorkOrder(workOrder)

            recordEvent({
              data: 'Create Permit: workOrder Updated',
              workOrder
            });

            // we don't want to toggle the different accordians
            // when the user clears the work order
            if (workOrder) {
              closeAccordian('workOrder');
              openAccordian('workPermit');
            }
          }}
        />
      )}

      <WorkPermitAccordian
        name="workPermit"
        toggleAccordian={() => toggleAccordian('workPermit')}
        expanded={expanded.includes('workPermit')}
        workOrder={workOrder}
        handleSubmit={createPermitFormSubmit}
        onChange={(formData: any) => {
          setFormData(formData)

          recordEvent({
            data: 'Create Permit: formData changed',
            formData
          }, LogLevel.DEBUG);
        }}
        permitLocation={homeLocation}
        ref={createPermitForm}
      />

      <PermitFooterBar
        // onCollapsePermits={() => collapseAllPanels(form)}
        // onExpandPermits={() => expandAllPanels(form)}
        onCollapsePermits={() => console.log('collapse')}
        onExpandPermits={() => console.log('expand')}
        handleSubmit={handleFormSubmit}
        loading={submitting}
      />
    </div>
  );
}
export default CreatePermit;