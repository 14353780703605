import React, { useEffect, useState } from 'react';
import Dialog from '../../../shared/Dialog';
import { ACTIONS } from '../../../../hooks/admin/useFetachGroupData';
import {
  Checkbox,
  Box,
  TextField,
  makeStyles,
  FormControlLabel
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  container: {
    "& > *": {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(2),
      display: 'block',
    }
  },
  checkbox: {
    marginLeft: -9,
    paddingRight: 30
  }
}));

const EDitGroup = ({ onCloseEditGroupDialog, selectedRow, onEditComplete, actionType }) => {
  const [ formData, updateFormData] = useState(actionType === ACTIONS.UPDATE ? {...selectedRow} : { Name: '', Description: '', SiteExpertUserFlag: false, CrewFlag: false })
  const { Name = '', Description = '', SiteExpertUserFlag, CrewFlag } = formData;
  const [error, updateError] = useState(false)

  const onFieldChange = e => {
    if(error && e.target.name === 'Name' && e.target.value) {
      updateError(false)
    }
    updateFormData({...formData, [e.target.name]: e.target.value})
  }

  const onCheckBoxChange = e => {
    updateFormData({...formData, [e.target.name]: e.target.checked})
  }

  useEffect(() => {
    updateFormData({...formData, ...selectedRow})
  }, [selectedRow]);

  const onSubmit = () => {
    if (!Name) {
      updateError(true)
    } else {
      onEditComplete(formData, actionType)
    }
  }


  const classes = useStyles();
  return (
    <Dialog
      visible={true}
      onCancel={onCloseEditGroupDialog}
      onClose={onCloseEditGroupDialog}
      title={ACTIONS.UPDATE === actionType ? 'Edit Group' : 'Add Group'}
      actions={[{
        text: ACTIONS.UPDATE === actionType ? 'Save' : 'Create',
        color: 'primary',
        onClick: onSubmit
      }]}
    >
      <Box
        component="form"
        noValidate
        className={classes.container}
      >
        <TextField
          fullWidth
          required
          error={error}
          label="Name"
          variant="standard"
          value={Name}
          name="Name"
          onChange={onFieldChange}
        />
        <TextField
          maxRows={2}
          fullWidth
          label="Description"
          variant="standard"
          value={Description}
          name="Description"
          onChange={onFieldChange}
        />
        <FormControlLabel
          className={classes.checkbox}
          value="end"
          control={
            <Checkbox
              name="CrewFlag"
              onChange={onCheckBoxChange}
              color='primary'
              checked={!!CrewFlag}
            />
          }
          label="Crew"
          labelPlacement="end"
        />
        <FormControlLabel
         className={classes.checkbox}
          value="end"
          control={
            <Checkbox
              name="SiteExpertUserFlag"
              onChange={onCheckBoxChange}
              color='primary'
              checked={!!SiteExpertUserFlag}
            />
          }
          label="Site Expert Users"
          labelPlacement="end"
        />
      </Box>
    </Dialog>
  )
}

export default EDitGroup;
