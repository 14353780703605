import React from 'react';
import { get as _get } from 'lodash';

import Collections from '../../store/indexeddb';

/**
 * There is a a case right after a permit is created where the permit issuer is only
 * available as the PersonnelId. This hook detects if the value is a number and if so
 * attempts to retrieve the name from the PermitInstanceMetadata of the permit
 *
 * @param {ESPermit} permit - An Elasticsearch permit instance
 * @returns {string} The name of the issuer from the instane store in Indexeddb
 */
const usePermitIssuer = (permit) => {
  const [issuerName, setIssuerName] = React.useState('');

  React.useEffect(() => {
    const { permitIssuer } = permit;

    // if the value of permit issuer is not a number set it to the provided value
    // and exity early
    if (typeof permitIssuer === 'string' || !new RegExp('^[0-9]+$').test(permitIssuer)) {
      setIssuerName(permitIssuer);
      return;
    }

    // it permitIssuer is a number, fetch the permit instance from Indexeddb
    // and get the user's name out of the PermitInstanceMetadata object
    const getPermit = async () => {
      const instance = await Collections.Permits.get(permit.PermitInstanceId);
      if (!instance) return;

      const usersList = _get(instance.instance.PermitInstanceMetadataJSON, 'UsersOfGroupList.0.UserFormattedList', []);
      if (!usersList) return;

      const issuer = usersList.find((u) => Number(u.value) === Number(permitIssuer));
      if (issuer) {
        setIssuerName(issuer.label);
      }
    };

    getPermit();
  }, [permit]);

  return issuerName;
};

export default usePermitIssuer;
