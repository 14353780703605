import { Route } from 'react-router-dom';
import { v4 as uuid } from 'uuid';
import { makeStyles } from '@material-ui/core';

import PermitList from './PermitList';
import EditPermit from './EditPermit';
import SearchPermits from './SearchPermits';
import PermitDrafts from './PermitDrafts';
import CreatePermit from './CreatePermit';
import RevokePermits from './RevokePermits';

const useStyles = makeStyles(() => ({
  wrapper: {
    position: 'relative'
  }
}));

const PermitsRoutes = () => {
  const classes = useStyles();

  return (
    <div className={classes.wrapper}>
      <Route path="/permits/list" component={PermitList} />
      <Route path="/permits/search" component={SearchPermits} />
      <Route path="/permits/drafts" component={PermitDrafts} />
      <Route path="/permits/revoke" component={RevokePermits} />
      <Route exact path="/permits/draft/:draftId" component={CreatePermit} />
      <Route
        exact
        path="/permits/create"
        render={(props) => {
          const { history, location } = props;
          const draftId = uuid();
          history.replace(
            `/permits/draft/${draftId}${location && location.search ? location.search : ''}`,
            location.state
          )
        }}
      />
      <Route
        path="/permits/:PermitInstanceId/edit"
        render={({ match }, props) => {
          // eslint-disable-next-line react/jsx-props-no-spreading
          return <EditPermit {...props} PermitInstanceId={match.params.PermitInstanceId} />;
        }}
      />
    </div>
  );
};

export default PermitsRoutes;
