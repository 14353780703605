import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch } from 'react-redux';

import {
  Card,
  CardContent,
  CardActions,
  Button,
  Typography,
} from '@material-ui/core';

import useWithUserPreferences from '../../hooks/auth/useWithUserPreferences';
import UpdateLocationDialog from '../shared/UpdateLocationDialog';
import AppState from '../../actions/app';

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: 275,
    padding: theme.spacing(1),
    minHeight: '100%',
  },
  title: {
    fontSize: 14,
    marginBottom: 12,
  },
  pos: {
    fontSize: 14,
  },
}));

const HomeLocationPreference = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { homeLocation } = useWithUserPreferences();

  const [dialogOpen, setDialogOpen] = React.useState(false);

  const handleLocationUpdate = (location) => {
    dispatch(AppState.actions.setHomeLocation(location));
  };

  return (
    <Card className={classes.root}>
      <CardContent>
        <Typography className={classes.title} color="primary" gutterBottom>
          Home Location
        </Typography>
        <Typography variant="h5" component="h2">
          {homeLocation.Title}
        </Typography>
        <Typography className={classes.pos} color="textSecondary">
          Default location of where you manage permits
        </Typography>
      </CardContent>
      <CardActions>
        <Button
          size="small"
          variant="contained"

          onClick={() => setDialogOpen(!dialogOpen)}
        >
          Update Home Location
        </Button>
      </CardActions>

      {dialogOpen && (
        <UpdateLocationDialog
          selectedLocation={homeLocation.LocationId}
          reduxActionType={AppState.types.setHomeLocation}
          onClose={() => setDialogOpen(false)}
          onUpdate={handleLocationUpdate}
        />
      )}
    </Card>
  );
};

export default HomeLocationPreference;
