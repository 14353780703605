const SideMenuItems = [
  { id: 0, label: 'Dashboard', pathName: '/', divider: true },
  { id: 1, label: 'Create New Permit', pathName: '/permits/create', divider: true },
  { id: 10, label: 'Draft Permits', pathName: '/permits/drafts' },
  { id: 2, label: 'List Current Permits', pathName: '/permits/list' },
  { id: 3, label: 'Open Permits', pathName: '/permits/list?state=PRE-APPROVAL,PENDING,APPROVED,CLOSING,TIMEOUT' },
  { id: 4, label: 'Completed Permits', pathName: '/permits/list?filter=allPermits&state=COMPLETED' },
  { id: 5, label: 'Search Permits', pathName: '/permits/search' },
  { id: 6, label: 'Revoke Permits', pathName: '/permits/revoke', divider: true },
  { id: 7, label: 'Current Workorders', pathName: '/workorders/list' },
  { id: 8, label: 'Search Workorders', pathName: '/workorders/search', divider: true },
  { id: 9, label: 'Admin', pathName: '/admin', divider: true },
  { id: 11, label: 'Reports', pathName: '/reports/open-permits', divider: true },
  { id: 12, label: 'Preferences', pathName: '/preferences', divider: true },
];

export default SideMenuItems;
