
import React from 'react';
import useWithUserPermissions from '../auth/useWithUserPermissions';
import useLocations from './useLocations';

import { Location } from '../../types/location'

export interface LocationTreeLocation extends Location {
  children?: LocationTreeLocation[];
  userHasAccess?: boolean;
}

const getChildrenOfLocation = (node: LocationTreeLocation): LocationTreeLocation[] => {
  const children: LocationTreeLocation[] = [];

  const traverseBranch = (leaf: LocationTreeLocation) => {
    children.push(leaf);

    if (Array.isArray(leaf.children) && leaf.children.length) {
      leaf.children.forEach((ch) => traverseBranch(ch));
    }
  };

  traverseBranch(node);

  return children;
}

const hasEnabledChildSites = (node: LocationTreeLocation): boolean => {
  let hasEnabledLeaf = false;

  const traverseBranch = (leaf: LocationTreeLocation) => {
    if (Array.isArray(leaf.children) && leaf.children.length) {
      leaf.children.forEach((ch) => traverseBranch(ch));
    } else if (leaf.EnabledFlag && leaf.userHasAccess) {
      hasEnabledLeaf = true;
    }
  };

  traverseBranch(node);

  return hasEnabledLeaf;
};

const buildLocationTree = (
  locationsUserCanView: number[],
  location: Location | LocationTreeLocation,
  locations: Location[],
  tree: Partial<LocationTreeLocation>
  ) => {
    if (Object.keys(tree).length === 0) {
      tree = location;
    }
    if (location.LocationId === 0) {
    tree.Title = 'Nutrien';
  }

  const nextLocation = location as LocationTreeLocation;

  if (locationsUserCanView.includes(location.LocationId)) {
    nextLocation.userHasAccess = true;
  } else {
    nextLocation.userHasAccess = false;
  }

  nextLocation.children = locations
    .filter((loc) => loc.LocationId !== 0 && loc.ParentLocationId === location.LocationId);

  if (nextLocation.children) {
    nextLocation.children.forEach((child) => buildLocationTree(locationsUserCanView, child, locations, tree));
  }
  return tree;
};


type HookResponse = {
  tree: LocationTreeLocation | null,
  utils: {
    getChildrenOfLocation: (node: LocationTreeLocation) => LocationTreeLocation[],
    hasEnabledChildSites: (node: LocationTreeLocation) => boolean
  }
}

const useLocationTree = (): HookResponse => {
  const [tree, setTree] = React.useState<LocationTreeLocation | null>(null);
  const { permissions } = useWithUserPermissions();
  const { locations, homeLocation } = useLocations();

  // Filter out locations where user does not have access
  const locationsUserCanView = React.useMemo(() => {
    const viewPermissions = permissions.find((p) => p.PermissionTypeId === 82);
    return viewPermissions ? viewPermissions.Locations : [];
  }, [permissions]);

  React.useEffect(() => {
    let builtTree = {} as Partial<LocationTreeLocation>;

    builtTree = buildLocationTree(
      locationsUserCanView,
      homeLocation,
      locations,
      {},
      );

    setTree(builtTree as LocationTreeLocation);
  }, [locations, locationsUserCanView, homeLocation]);

  return {
    tree,
    utils: { getChildrenOfLocation, hasEnabledChildSites }
  };
}

export default useLocationTree;
