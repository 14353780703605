/**
 * Generate a ampersand seperated string of key=value pairs
 * @param {{[key: string]: string}} obj - Object to create string from
 * @return {string} - String of key=value&key=value type pairs
 */

// eslint-disable-next-line import/prefer-default-export
export function formatObjectAsSearchQuery(obj) {
  return Object.keys(obj)
    .reduce(
      (string, key, i) => {
        if (!obj[key]) return string;
        const pair = `${key}=${obj[key]}`;
        return i === 0
          ? pair
          : `${string}&${pair}`;
      },
      '',
    )
    .trim();
}
