import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { get as _get } from 'lodash';
import { useHistory, useParams } from 'react-router-dom';
import { Backdrop, CircularProgress, makeStyles } from '@material-ui/core';

import { createFormDataFromTemplate } from '../../../../shared/templates';
import TemplatesState from '../../../../actions/admin/templates';

import { Template } from '../../../../types/template';
import { DefinitionForm } from '../../../../types/definition';

import useSetPageName from '../../../../hooks/shared/useSetPageName';
import useTemplate from '../../../../hooks/admin/useTemplate';
import useDefinition from '../../../../hooks/definitions/useDefinition';

import InlineLoader from '../../../shared/InlineLoader';
import ActionButtons from './ActionButtons';
import FooterBar from './FooterBar';
import CreateEditForm from './CreateEditForm';

type Props = {
  readOnly?: boolean;
}

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

const CreateEditTemplate: React.FC<Props> = (props) => {
  const { readOnly } = props;

  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const params = useParams<{ id: string }>();
  // const query = useQuery<{ type: TemplateType }>();

  const pageNamePrefix = (params.id ? 'Edit' : 'View');
  useSetPageName(`${readOnly ? 'View' : pageNamePrefix} Permit Template`);

  const { data: template, loading } = useTemplate(params.id);
  const [saving, setSaving] = React.useState<boolean>(false);
  const [updates] = React.useState<Partial<Template>>({});
  // const [type, setType] = React.useState<TemplateType>();
  // console.log('type:', type)

  // React.useEffect(() => {
  //   console.log('template:', type, template?.Type, query?.type);
  //   if (template && type !== template.Type) {
  //     setType(template.Type);
  //     return;
  //   }

  //   if (!template && type !== query.type) {
  //     setType(query.type);
  //   }
  // }, []);

  const { data: definitionData } = useDefinition();

  const definition = React.useMemo(() => {
    if (!definitionData) return {};
    return definitionData.definition;
  }, [definitionData]);

  const forms = React.useMemo(() => {
    return _get(definition, 'DefinitionMetadataJSON.Forms') as DefinitionForm[];
  }, [definition]);

  const onChange = (templateData: Partial<Template>) => {
    // setUpdates(templateData);
  };

  const onSubmit = async () => {
    setSaving(true);
    const selectedForm = (forms || []).find((fo: DefinitionForm) => fo.Name === updates.FormKey);
    const ContentJSON = createFormDataFromTemplate(selectedForm, updates.ContentJSON);

    const nextTemplate = {
      ...updates,
      ContentJSON,
      FormKey: selectedForm?.Name,
    };

    if (template) {
      await dispatch(TemplatesState.actions.updateTemplate(
        template.PermitTemplateId,
        nextTemplate,
      ));
    } else {
      await dispatch(TemplatesState.actions.createPermitTemplate(nextTemplate));
    }

    setSaving(false);
    history.push('/admin/templates');
  };

  const editAvailable = !readOnly && (!params.id || template?.State === 'DRAFT');
  return (
    <>
      {loading ? (
        <InlineLoader />
      ) : (
        <>
          {template && <ActionButtons template={template} mode={params.id ? 'edit' : 'view'} />}

          <CreateEditForm
            disabled={!editAvailable}
            definition={definitionData}
            forms={forms}
            onChange={onChange}
            template={template}
          />

          {editAvailable && updates.FormKey && (
            <FooterBar
              disabled={!editAvailable || !updates.FormKey || !updates.Name}
              State={template?.State}
              loading={!editAvailable}
              handleSubmit={onSubmit}
            />
          )}
          <Backdrop className={classes.backdrop} open={saving}>
            <CircularProgress color="inherit" />
          </Backdrop>
        </>
      )}
    </>
  );
};

CreateEditTemplate.defaultProps = {
  readOnly: false,
};

CreateEditTemplate.propTypes = {
  readOnly: PropTypes.bool,
};

export default CreateEditTemplate;
