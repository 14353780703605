import React from 'react';
import { get as _get } from 'lodash';

import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Grid, TextField, Paper, Typography } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';

import AppState from '../../actions/app';
import WorkOrdersState from '../../actions/work-orders';
import useSetPageName from '../../hooks/shared/useSetPageName';
import { queryElasticSearchWorkOrders } from '../../shared/work-orders';

import ListItemCard from './shared/ListItemCard';
import SearchWorkOrdersFilters from './shared/SearchWorkOrdersFilters';
import InlineLoader from '../shared/InlineLoader';

const useStyles = makeStyles((theme) => ({
  searchBoxWrapper: {
    backgroundColor: 'white',
    paddingTop: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingBottom: 0,
  },
  searchInput: {
    width: '100%',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  searchButton: {
    textAlign: 'right',
  },
  resultsText: {
    marginBottom: theme.spacing(4),
    marginLeft: theme.spacing(2),
    marginTop: theme.spacing(4),
  },
  linkDiv: {
    '&:hover': {
      cursor: 'pointer',
      textDecoration: 'underline',
    },
  },
}));

const SearchWorkOrders = () => {
  useSetPageName('Search Workorders');
  const styles = useStyles();
  const dispatch = useDispatch();
  const location = useSelector(AppState.selectors.homeLocation());

  const [searchTerm, setSearchTerm] = React.useState('');
  const [resultsCount, setResultsCount] = React.useState();
  const [results, setResults] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [filters, setFilters] = React.useState({});
  const [nextPage, setNextPage] = React.useState(0);
  const [searchPerformed, setSearchPerformed] = React.useState(false);

  const fetchSearchResults = async (loadMore = false) => {
    setSearchPerformed(true);
    setLoading(true);
    const nextPageForQuery = loadMore ? nextPage : 0;

    if (!loadMore) {
      setResults([]);
      setNextPage(nextPageForQuery);
    }

    const searchResults = await queryElasticSearchWorkOrders(
      searchTerm,
      filters,
      location.SiteId,
      nextPageForQuery,
    );

    setNextPage((currPage) => currPage + 20);
    setResultsCount(_get(searchResults, 'hits.total.value', 0));

    // eslint-disable-next-line no-underscore-dangle
    const workOrders = _get(searchResults, 'hits.hits', []).map((p) => p._source);
    setResults((existingWorkOrders) => [...existingWorkOrders, ...workOrders]);
    setLoading(false);

    dispatch(WorkOrdersState.actions.updateList(workOrders));
  };

  const updateFilters = (nextFilters) => {
    setFilters((f) => ({ ...f, ...nextFilters }));
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      fetchSearchResults();
    }
  };

  const handleLoadMore = () => {
    fetchSearchResults(true);
  };

  const renderWorkordersCount = () => {
    if (results.length > 0) {
      return (
        <div className={styles.resultsText}>
          <b>{resultsCount}</b>
          {' '}
          matches found
        </div>
      );
    }

    return (
      <Alert severity="warning">
        <Typography>No work orders matched the search query. Please adjust the filters and try again</Typography>
      </Alert>
    );
  };

  const renderNoSearchPerformed = () => (
    <Alert severity="info">
      <Typography>Perform a search using the filters in the sidebar and then press search.</Typography>
    </Alert>
  );

  const renderedWorkOrders = React.useMemo(() => results.map((workOrder) => (
    <ListItemCard key={workOrder.workOrderNumber} workOrder={workOrder} />
  )), [results]);

  return (
    <div className="workorder-search">
      <Grid container spacing={2}>
        <Grid item xs={12} md={3}>
          <Paper elevation={0} className={styles.searchBoxWrapper}>
            <TextField
              className={styles.searchInput}
              value={searchTerm}
              placeholder="Contains text"
              onChange={(e) => setSearchTerm(e.target.value)}
              onKeyDown={handleKeyDown}
              helperText="Eg: UREA/CO2 AREA or SOFT-101C"
            />
          </Paper>
          <Paper elevation={0} className={styles.searchBoxWrapper}>
            <SearchWorkOrdersFilters
              filters={filters}
              handleUpdate={() => fetchSearchResults()}
              onChange={updateFilters}
              onClearClick={() => {
                setFilters({});
                setSearchTerm('');
                setSearchPerformed(false);
              }}
              disabled={loading}
            />
          </Paper>
        </Grid>
        <Grid item xs={12} md={9}>
          {searchPerformed ? (
            <>
              {!loading && renderWorkordersCount()}
              {renderedWorkOrders}
              {loading && <InlineLoader />}
              {!loading && resultsCount && results.length < resultsCount ? (
                <Button
                  className={styles.searchButton}
                  variant="contained"
                  onClick={handleLoadMore}
                  size="medium"
                  color="primary"
                >
                  Load more
                </Button>
              ) : <></>}
            </>
          ) : renderNoSearchPerformed()}
        </Grid>
      </Grid>
    </div>
  );
};

export default SearchWorkOrders;
