import React from 'react';

import {
  Paper,
  Tab,
  Tabs,
  makeStyles,
} from '@material-ui/core';

import useTemplates from '../../../../hooks/admin/useTemplates';
import useSetPageName from '../../../../hooks/shared/useSetPageName';

import Breadcrumbs from '../../Breadcrumbs';
import TemplatesTable from './TemplatesTable';
import CreateTemplateMenu from './CreateTemplateMenu';
import InlineLoader from '../../../shared/InlineLoader';
import { TemplateType } from '../../../../types/template';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  createButton: {
    marginLeft: theme.spacing(1),
  },
}));

const TemplatesList: React.FC = () => {
  useSetPageName('Templates');

  const classes = useStyles();

  const [selectedTab, setSelectedTab] = React.useState<TemplateType>('permit');
  const { data, loading } = useTemplates(selectedTab);

  return (
    <>
      <Breadcrumbs crumbs={[{ label: 'Templates' }]} />
      <Paper elevation={1} className={classes.wrapper}>
        <CreateTemplateMenu />
      </Paper>

      <Paper>
        {loading ? <InlineLoader className={classes.wrapper} /> : (
          <>
            <Tabs
              value={selectedTab}
              indicatorColor="primary"
              textColor="primary"
              onChange={(event, newValue) => setSelectedTab(newValue)}
            >
              <Tab label="Work Templates" value="work" />
              <Tab label="Permit Templates" value="permit" />
            </Tabs>
            {selectedTab === 'permit' && <TemplatesTable templates={data} />}
            {selectedTab === 'work' && <TemplatesTable templates={data} />}
          </>
        )}
      </Paper>
    </>
  );
};

export default TemplatesList;
