import DefinitionsState from '../actions/definitions';

export const initialState = {
  /* The initial definition to use on the create form. This includes data for
     available approvals and permits for linking
  */
  initialDefinitionForSite: null,
  /* A dictionary of permit definitions using the PermitDefinitionId as the key */
  list: {},
  /* A dictionary of lists and list items to be used in the definition */
  lists: {},
  /* A dictionary of PermitDefinitionIds using the SiteId as the key */
  sites: {},
};

export const definitionsReducer = (state = initialState, action) => {
  const { types } = DefinitionsState;
  switch (action.type) {
    case types.setSiteDefinitionId:
      return {
        ...state,
        sites: {
          ...state.sites,
          [action.payload.siteId]: action.payload.PermitDefinitionId,
        },
      };
    case types.setDefinitionForSite:
      return {
        ...state,
        initialDefinitionForSite: action.payload,
      };
    case types.setLists:
      return {
        ...state,
        lists: action.payload,
      };
    case types.updateDefinition:
      return {
        ...state,
        list: {
          ...state.list,
          [action.payload.PermitDefinitionId]: action.payload,
        },
      };
    default:
      return state;
  }
};
