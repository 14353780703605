import React from 'react';
// import { useDispatch } from 'react-redux';
import { get as _get } from 'lodash';
// import { useHistory, useParams } from 'react-router-dom';
// import { Backdrop, CircularProgress, makeStyles } from '@material-ui/core';

import { DefinitionForm } from '../../../../types/definition';
import useDefinition from '../../../../hooks/definitions/useDefinition';
import WorkTemplateForm from '../shared/WorkTemplateForm';
import InlineLoader from '../../../shared/InlineLoader';

const CreateWorkTemplate: React.FC = () => {
  const { data: definitionData } = useDefinition();

  const definition = React.useMemo(() => {
    if (!definitionData) return {};
    return definitionData.definition;
  }, [definitionData]);

  const forms = React.useMemo(() => {
    return _get(definition, 'DefinitionMetadataJSON.Forms') as DefinitionForm[];
  }, [definition]);

  const loading = !forms;
  return (
    <div>
      {loading ? <InlineLoader /> : (
        <>
          <h1>Work Template</h1>
          <WorkTemplateForm forms={forms} />
        </>
      )}
    </div>
  );
};

export default CreateWorkTemplate;
