import React from 'react';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Paper, makeStyles, Button, Backdrop, CircularProgress, Typography, Card, CardContent, CardActions } from '@material-ui/core';
import { Alert } from '@material-ui/lab';

import { FormData, FormioForm } from '../../../../types/form-io';
import { ExternalUser } from '../../../../types/external-user';
import ExternalUsersState from '../../../../actions/admin/external-users';

import CreateEditForm from '../shared/CreateEditForm';
import Breadcrumbs from '../../Breadcrumbs';
import LocationPermissionsTree, { LocationPermissions } from '../shared/LocationPermissionsTree';
import EmailSearchInput from '../shared/EmailSearchInput';
import useLocations from '../../../../hooks/locations/useLocations';

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  error: {
    marginBottom: theme.spacing(2),
  },
  wrapper: {
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  marginTop: {
    marginTop: theme.spacing(2),
  }
}));

const CreateUser: React.FC = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const { homeLocation: { LocationId } } = useLocations();

  const [saving, setSaving] = React.useState<boolean>(false);
  const formRef = React.useRef<FormioForm>(null);
  const [error, setError] = React.useState<string>();

  const [existingUser, setExistingUser] = React.useState<ExternalUser | null>(null);
  const [emailAddress, setEmailAddress] = React.useState<string>('');

  const [permissions, setPermissions] = React.useState<LocationPermissions>({
    CanEdit: [],
    CanPrint: []
  });

  React.useEffect(() => {
    if (formRef.current) {
      formRef.current.readOnly = saving;
    }
  }, [saving]);

  const handleSubmit = React.useCallback(
    async (formData: FormData<ExternalUser>) => {
      try {
        setSaving(true);
        window.scrollTo(0, 0);
        await dispatch(
          ExternalUsersState.actions.inviteUser({
            ...formData.data,
            ...permissions,
            Preferences: {
              HomeLocationId: LocationId
            },
            EmailAddress: emailAddress
          } as ExternalUser
          ));
        history.push('/admin/external-users');
      } catch (e: any) {
        setError(e.message);
        setSaving(false);
      }
    },
    [dispatch, history, permissions, emailAddress],
  );

  const handleLocationTreeChange = React.useCallback(
    (permissions: LocationPermissions) => {
      setPermissions(permissions);
    }, []);

  const handleExistingUserRedirect = () => {
    history.push(`/admin/external-users/${existingUser?.PersonnelId}/edit`);
  };

  const handleExistingUserReset = () => {
    setExistingUser(null);
    setEmailAddress('');
  };

  const handleUnDeleteUser = async () => {
    if (existingUser?.PersonnelId) {
      await dispatch(ExternalUsersState.actions.undeleteUser(existingUser.PersonnelId));
    }
  }

  return (
    <>
      <Breadcrumbs
        crumbs={[
          { label: 'External Users', path: '/admin/external-users' },
          { label: 'Create New User' },
        ]}
      />
      <Paper className={classes.wrapper}>
        <Typography variant="h6">Email Address</Typography>
        <Typography variant="subtitle1">Enter the email address of the user you would like to invite</Typography>

        <EmailSearchInput
          inputValue={emailAddress}
          onInputValueChange={setEmailAddress}
          onChange={(value) => setExistingUser(value)}
        />
      </Paper>

      {existingUser ? (
        <>
          <Card className={classes.wrapper}>
            <Alert severity="info" className={classes.error}>
              <Typography variant="h6">User Exists</Typography>
              <Typography variant="subtitle1">A user already exists with this email address.</Typography>
            </Alert>
            <CardContent>
              <Typography gutterBottom variant="h5" component="h2">
                {existingUser.FirstName} {existingUser.LastName}
              </Typography>
              <Typography variant="body2" color="textSecondary" component="p">
                <b>{existingUser.Company}</b>
              </Typography>
              <Typography variant="body2" color="textSecondary" component="p">
                {existingUser.StreetAddress}
              </Typography>
              <Typography variant="body2" color="textSecondary" component="p">
                {existingUser.State}
              </Typography>
              <Typography variant="body2" color="textSecondary" component="p">
                {existingUser.Country}
              </Typography>
              <Typography variant="body2" color="textSecondary" component="p" gutterBottom>
                {existingUser.PostalCode}
              </Typography>
              {existingUser.AccessExpiresDate && (
                <Typography variant="body1" component="p" className={classes.marginTop}>
                  Expiry Date: <b>{moment(existingUser.AccessExpiresDate).format('YYYY-MM-DD')}</b>
                </Typography>
              )}
            </CardContent>
            <CardActions>
              <Button size="small" color="primary" onClick={handleExistingUserRedirect}>
                Update Existing User
              </Button>
              {
                existingUser?.DeleteFlag && (
                  <Button size="small" color="primary" onClick={handleUnDeleteUser}>
                    Undelete User
                  </Button>
                )
              }
              <Button size="small" color="default" onClick={handleExistingUserReset}>
                Reset
              </Button>
            </CardActions>
          </Card>
        </>
      ) : (
        <>
          <Paper className={classes.wrapper}>
            {error && <Alert severity="error" className={classes.error}>{error}</Alert>}

            <CreateEditForm
              formRef={formRef}
              onSubmit={handleSubmit}
            />
          </Paper>
          <Paper className={classes.wrapper}>
            <Typography variant="h6">Select user permissions</Typography>
            <Typography variant="subtitle1">All external users have access to view permits.</Typography>
            <LocationPermissionsTree onChange={handleLocationTreeChange} />
          </Paper>
          <Paper className={classes.wrapper}>
            <Button
              variant="contained"
              color="primary"
              size="small"
              onClick={() => {
                if (formRef.current) {
                  formRef.current.submit();
                }
              }}
            >
              Invite User
            </Button>

            <Backdrop className={classes.backdrop} open={saving}>
              <CircularProgress color="inherit" />
            </Backdrop>
          </Paper>
        </>
      )}
    </>
  );
};

export default CreateUser;
