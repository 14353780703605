import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Grid, Typography } from '@material-ui/core';

import PrintDialog from './PrintPermitDialog';
import AttachmentsDialog from './AttachmentsDialog';
import RejectPermitDialog from './RejectPermitDialog';
import RevokePermitDialog from './RevokePermitDialog';
import ApprovalsCountButton from './ApprovalsCountButton';
import PermitLocationSelection from './PermitLocationSelection';
import WorkLocationDialog from './WorkLocationDialog';
import ActionButton from './ActionButton';
import SearchWorkOrderDialog from '../../shared/SearchWorkOrderDialog';

import useLocations from '../../../hooks/locations/useLocations';
import useAbilities from '../../../hooks/auth/useAbilities';
import usePrintableStates from '../../../hooks/shared/usePrintableStates';

import PermitsState from '../../../actions/permits';
import WorkOrdersState from '../../../actions/work-orders';
import { WorkOrder } from '../../../types/work-order';
import { Location } from '../../../types/location';
import useLocationPermitSettings from '../../../hooks/shared/useLocationPermitSettings';

const useStyles = makeStyles((theme) => ({
  grid: {
    marginBottom: theme.spacing(2),
  },
  details: {
    marginLeft: theme.spacing(1),
  },
  changeWorkOrder: {
    marginLeft: -4,
    paddingLeft: 4,
    paddingRight: 4,
    paddingTop: 2,
    paddingBottom: 2,
    display: 'block',
    fontSize: 10,
    color: 'grey'
  },
  workOrder: {
    marginTop: theme.spacing(1),
    fontWeight: 'bold'
  },
}));

type Props = {
  PermitInstanceId: string;
  State: string;
  ReferenceId: string;
  LocationId: number;
  workLocation: any;
  onWorkOrderChange: (workOrder: WorkOrder) => void;
  workOrder: string;
}

const PermitActionsButtons = (props: Props) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { plotPlanEnabled } = useLocations();
  const abilities = useAbilities();
  const printableStates = usePrintableStates();
  const locationPermitSettings = useLocationPermitSettings();

  const [showAttachmentsDialog, setShowAttachmentsDialog] = React.useState(false);
  const [showPrintDialog, setShowPrintDialog] = React.useState(false);
  const [showRejectDialog, setShowRejectDialog] = React.useState(false);
  const [showRevokeDialog, setShowRevokeDialog] = React.useState(false);
  const [showWorkLocationDialog, setShowWorkLocationDialog] = React.useState(false);
  const [showWorkOrderDialog, setShowWorkOrderDialog] = React.useState(false);

  const workOrdersEnabled = useSelector(WorkOrdersState.selectors.enabled())

  const {
    PermitInstanceId,
    State,
    ReferenceId,
    LocationId,
    workLocation,
    onWorkOrderChange,
    workOrder
  } = props;

  const handleWorkOrderChange = (workOrder: WorkOrder) => {
    onWorkOrderChange(workOrder)
    setShowWorkOrderDialog(false);
  };

  const handleLocationChange = (location: Location) => {
    dispatch(PermitsState.actions.updatePermitLocation(PermitInstanceId, location.LocationId))
  };

  const changesDisabled = !abilities.permits.edit || !['DRAFT', 'PRE-APPROVAL', 'PENDING'].includes(State)

  return (
    <>
      <Grid container justify="space-between" alignItems="center" className={classes.grid}>
        <Grid item className={classes.details}>
          <PermitLocationSelection
            LocationId={LocationId}
            onUpdate={handleLocationChange}
            disabled={changesDisabled}
          />
          {workOrdersEnabled && (
            <Typography color="primary" className={classes.workOrder}>
              WO: &nbsp;
              {workOrder || 'No work order selected'}
              {!changesDisabled && (
                <Button
                  size="small"
                  className={classes.changeWorkOrder}
                  onClick={() => setShowWorkOrderDialog(true)}
                >
                  {workOrder ? 'change' : 'select'}
                  {' '}
                  Work Order
                </Button>
              )}
            </Typography>
          )}
        </Grid>
        <Grid item>
          {State !== 'DRAFT' && <ApprovalsCountButton />}

          {['PRE-APPROVAL', 'PENDING'].includes(State) && abilities.permits.edit && (
            <ActionButton
              secondary
              label="Reject"
              onClick={() => setShowRejectDialog((visible) => !visible)}
            />
          )}
          {State === 'APPROVED' && abilities.permits.edit && (
            <ActionButton
              secondary
              label="Revoke"
              onClick={() => setShowRevokeDialog((visible) => !visible)}
            />
          )}

          {
            printableStates.includes(State)
            && abilities.permits.print
            && PermitInstanceId && (
              <ActionButton
                label="Print"
                onClick={() => setShowPrintDialog((visible) => !visible)}
              />
            )
          }

          {PermitInstanceId && State !== 'DRAFT' && abilities.permits.edit && locationPermitSettings.copyEnabled && (
            <ActionButton
              label="Copy"
              loadingAction={PermitsState.types.copyPermit}
              onClick={() => dispatch(PermitsState.actions.copyPermit(PermitInstanceId))}
            />
          )}

          <ActionButton
            label="Attachments"
            onClick={() => setShowAttachmentsDialog((visible) => !visible)}
          />

          {plotPlanEnabled && (
            <ActionButton
              label="Work Location"
              onClick={() => setShowWorkLocationDialog((visible) => !visible)}
              disabled={!PermitInstanceId}
            />
          )}
        </Grid>
      </Grid>

      {
        showPrintDialog && (
          <PrintDialog
            visible
            ReferenceId={ReferenceId}
            PermitInstanceId={PermitInstanceId}
            onClose={() => setShowPrintDialog(false)}
          />
        )
      }

      {
        showAttachmentsDialog && (
          <AttachmentsDialog
            visible
            PermitInstanceId={PermitInstanceId}
            onClose={() => setShowAttachmentsDialog(false)}
          />
        )
      }

      {
        showRejectDialog && (
          <RejectPermitDialog
            visible
            ReferenceId={ReferenceId}
            PermitInstanceId={PermitInstanceId}
            onClose={() => setShowRejectDialog(false)}
            action={State === 'APPROVED' ? 'revoke' : 'reject'}
          />
        )
      }
      {
        showRevokeDialog && (
          <RevokePermitDialog
            visible
            ReferenceId={ReferenceId}
            PermitInstanceId={PermitInstanceId}
            onClose={() => setShowRevokeDialog(false)}
          />
        )
      }
      {
        plotPlanEnabled && showWorkLocationDialog && (
          <WorkLocationDialog
            visible
            ReferenceId={ReferenceId}
            PermitInstanceId={PermitInstanceId}
            workLocation={workLocation}
            onClose={() => setShowWorkLocationDialog(false)}
          />
        )
      }
      {showWorkOrderDialog && (
        <SearchWorkOrderDialog
          onChange={handleWorkOrderChange}
          workOrder={workOrder}
          disableClose={false}
          onClose={() => setShowWorkOrderDialog(false)}
        />
      )
      }
    </>
  );
};

PermitActionsButtons.defaultProps = {
  PermitInstanceId: '',
  State: 'DRAFT',
  ReferenceId: '',
  LocationId: 0,
  workLocation: {},
};

PermitActionsButtons.propTypes = {
  PermitInstanceId: PropTypes.string,
  State: PropTypes.string,
  ReferenceId: PropTypes.string,
  LocationId: PropTypes.number,
  workLocation: PropTypes.exact({
    lat: PropTypes.number,
    lng: PropTypes.number,
  }),
};

export default PermitActionsButtons;
