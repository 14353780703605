import { useDispatch, useSelector } from 'react-redux';

import PermitsState from '../../actions/permits';
import { ActionStatus } from '../../actions/shared/status';
import useThrottledDataLoad from '../shared/useThrottledDataLoad';
import usePermitListFilters from './usePermitListFilters';

const useGetPermits = (returnAll = false) => {
  const dispatch = useDispatch();
  const { selectors, types, actions } = PermitsState;

  const { loading, data } = useThrottledDataLoad(
    types.getPermits,
    actions.get,
    selectors.list,
  );

  const pagination = useSelector(selectors.pagination());
  const permits = usePermitListFilters(data);

  return {
    loading: loading === ActionStatus.pending,
    error: loading === ActionStatus.rejected,
    list: returnAll ? data : permits,
    pagination,
    fetchNextPage: () => dispatch(actions.getNextListPage()),
  };
};

export default useGetPermits;
