import React from 'react';
import { Grid } from '@material-ui/core';

import useSetPageName from '../../hooks/shared/useSetPageName';
import LocationPreference from './LocationPreference';
import LocationAbilities from './LocationAbilities';
import UserDetails from './UserDetails';

const Preferences = () => {
  useSetPageName('User Preferences');

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12} md={3}>
          <UserDetails />
        </Grid>
        <Grid item xs={12} md={9}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <LocationPreference />
            </Grid>
            <Grid item xs={12}>
              <LocationAbilities />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default Preferences;
