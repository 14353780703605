import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles((theme) => ({
  show: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    margin: theme.spacing(4),
  },
}));

type Props = {
  className?: string;
}

const InlineLoader: React.FC<Props> = (props) => {
  const { className } = props;

  const classes = useStyles();

  return (
    <div className={`${classes.show}  ${className}`}>
      <CircularProgress />
    </div>
  );
};

InlineLoader.defaultProps = {
  className: undefined,
};

InlineLoader.propTypes = {
  className: PropTypes.string,
};

export default InlineLoader;
