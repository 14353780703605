export const TemplateTypes = ['permit', 'work'];
export type TemplateType = typeof TemplateTypes[number];

export const TemplateStates = ['DRAFT', 'REVIEW', 'REJECTED', 'APPROVED', 'DISCONTINUED'];
export type TemplateState = typeof TemplateStates[number];

export const StateColors = {
  DRAFT: '#6f42c1',
  DISCONTINUED: '#17a2b8',
  REVIEW: '#4ec3a9',
  APPROVED: '#28a745',
  REJECTED: '#dc3545',
};

export const TemplateCommentTypes = [
  'General',
  'Review',
  'Approval',
  'Rejection',
  'Discontinue',
];
export type TemplateCommentType = typeof TemplateCommentTypes[number];

export const CommentTypeColors = {
  General: '#6f42c1',
  Review: '#4ec3a9',
  Approval: '#28a745',
  Rejection: '#dc3545',
  Discontinue: '#17a2b8',
};

export enum TemplateStateAction {
  Approve = 'Approval',
  Discontinue = 'Discontinue',
  Reject = 'Rejection',
  Review = 'Review',
}

type TemplatePerson = {
  EmailAddress: string,
  FirstName: string,
  LastName: string,
  PersonnelId: number,
};

export type TemplateComment = {
  Comment: string;
  CommentType: TemplateCommentType;
  CreatedOn: string;
  CreatedBy: TemplatePerson;
}

export type WorkPermitTemplate = {
  FormKey?: string
  T: string;
}

export interface Template {
  Comments?: TemplateComment[] | null;
  ApprovedBy?: TemplatePerson | null;
  ApprovedOn?: string | null;
  CreatedBy: TemplatePerson;
  CreatedOn: string;
  LocationId: number;
  Name: string;
  SiteId: number;
  PermitTemplateId: string;
  State: TemplateState;
  Type: TemplateType;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ContentJSON?: any;
  FormKey?: string;
  PermitTemplates?: WorkPermitTemplate[];
}
