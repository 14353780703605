import React from 'react';
import { Route } from 'react-router-dom';
import { Grid } from '@material-ui/core';

import Sidebar from './Sidebar';
import LandingPage from './LandingPage';
import Lists from './Lists';
import Templates from './templates';
import Forms from './forms';
import ExternalUsers from './external-users';
import ElasticsearchPermit from './permit-cache';
import GroupPermission from './group-permission';

const PermitsRoutes: React.FC = () => {
  return (
    <div className="admin">
      <Grid container spacing={3}>
        <Grid item  xs={12} md={2}>
          <Sidebar />
        </Grid>
        <Grid item xs={12} md={10}>
          <Route path="/admin" exact component={LandingPage} />
          <Route path="/admin/lists" component={Lists} />
          <Route path="/admin/forms" component={Forms} />
          <Route path="/admin/templates" component={Templates} />
          <Route path="/admin/external-users" component={ExternalUsers} />
          <Route path="/admin/permit-cache" component={ElasticsearchPermit} />
          <Route path="/admin/group-permission" component={GroupPermission} />
        </Grid>
      </Grid>
    </div>
  );
};

export default PermitsRoutes;