import React from 'react';

import BaseAccordian from './BaseAccordian';
import CreatePermitForm from '../CreatePermitForm';

import { WorkOrder } from '../../../types/work-order';
import { Location } from '../../../types/location';

type Props = {
  toggleAccordian: (name?: string) => any;
  expanded: boolean;
  name: string;
  permitLocation: Location;
  onChange: (formData: any) => void;
  handleSubmit: () => any;
  workOrder?: WorkOrder;
  ref?: any;
}

const WorkPermitAccordian: React.FC<Props> = React.forwardRef((props, ref) => {
  const {
    expanded,
    name,
    onChange,
    handleSubmit,
    permitLocation,
    toggleAccordian,
    workOrder
  } = props;

  return (
    <BaseAccordian
      expanded={expanded}
      name={name}
      heading="Permit"
      onChange={toggleAccordian}
    >
      <CreatePermitForm
        ref={ref}
        onChange={onChange}
        handleSubmit={handleSubmit}
        workOrder={workOrder}
        location={permitLocation}
      />
    </BaseAccordian>
  );
})

export default WorkPermitAccordian;
