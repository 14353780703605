import React from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import AppState from '../../actions/app';
import Config from '../../config/app-config';

const useThrottledDataLoad = (actionType, action, selector) => {
  const dispatch = useDispatch();

  const lastRun = useSelector(AppState.selectors.lastRun(actionType));
  const loading = useSelector(AppState.selectors.status(actionType));
  const data = useSelector(selector(), shallowEqual);

  React.useEffect(() => {
    let stale = false;

    if (lastRun) {
      const diff = new Date() - new Date(lastRun);
      if ((diff / 1000) > Number(Config.API.throttleLength)) {
        stale = true;
      }
    }

    if (!loading || stale) {
      dispatch(action());
    }
  }, [dispatch, actionType, action, loading, lastRun]);
  return { data, loading, lastRun };
};

export default useThrottledDataLoad;
