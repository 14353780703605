import React from 'react';
import moment from 'moment';

import {
  Typography,
  Divider,
  Button,
  makeStyles,
} from '@material-ui/core';

import Dialog from '../../../shared/Dialog';
import AddCommentForm from './AddCommentForm';

import { CommentTypeColors, Template } from '../../../../types/template';
import StateChip from '../../../shared/StateChip';

const useStyles = makeStyles((theme) => ({
  marginTop: {
    marginTop: theme.spacing(2),
  },
  divider: {
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
  },
  heading: {
    display: 'flex',
    flexDirection: 'row',
    '& > *': {
      marginRight: theme.spacing(2),
    },
  },
  timestamp: {
    color: theme.palette.grey[600],
    fontSize: theme.typography.fontSize,
  },
}));

type Props = {
  template: Template;
  onClose: () => void;
}

const CommentsDialog: React.FC<Props> = (props) => {
  const classes = useStyles();

  const { onClose, template } = props;

  const [formVisible, setFormVisible] = React.useState<boolean>(false);

  const comments = React.useMemo(() => {
    if (!Array.isArray(template.Comments)) {
      return [];
    }

    return template.Comments.sort((a, b) => {
      return moment(a.CreatedOn).isBefore(b.CreatedOn)
        ? 1
        : -1;
    });
  },
    [template.Comments]);

  return (
    <Dialog
      visible
      onClose={onClose}
      title="Comments"
      actions={[{
        text: 'Close',
        color: 'primary',
        onClick: onClose,
      }]}
    >
      {comments.map((comment) => (
        <div key={comment.CreatedOn}>
          <div className={classes.heading}>
            <h5>
              {`${comment.CreatedBy.FirstName} ${comment.CreatedBy.LastName}`}
            </h5>

            <StateChip
              colors={CommentTypeColors}
              state={comment.CommentType}
            />
          </div>
          <Typography className={classes.timestamp}>
            {moment(comment.CreatedOn).calendar()}
          </Typography>

          <Typography className={classes.marginTop}>{comment.Comment}</Typography>
          <Divider variant="fullWidth" className={classes.divider} />
        </div>
      ))}

      {formVisible ? (
        <AddCommentForm
          onCancel={() => setFormVisible(false)}
          onSave={() => setFormVisible(false)}
          PermitTemplateId={template.PermitTemplateId}
        />
      ) : (
        <Button
          onClick={() => setFormVisible(true)}
          variant="outlined"
          color="primary"
          size="small"
        >
          Add Comment
        </Button>
      )}
    </Dialog>
  );
};

export default CommentsDialog;
