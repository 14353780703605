import moment from 'moment';
import Api from './api';

// eslint-disable-next-line array-callback-return, consistent-return
export const workOrderHasStatus = (workOrder, statusList) => {
  const statuses = statusList.map((s) => s.toLowerCase());

  let found = false;

  // for loop used here as it is not currrently possible to break out
  // of a Array.map iteration
  for (let i = 0; i < statuses.length; i += 1) {
    const status = statuses[i];
    if (workOrder.workOrderStatus.some((s) => s.toLowerCase() === status)) {
      found = true;
      break;
    }
  }

  if (found) return workOrder;
};

export const filterWorkOrdersByStatus = (
  workOrders,
  statusList,
) => workOrders.filter((wo) => workOrderHasStatus(wo, statusList));

/**
 * Builds an Elasticsearch query and returns a Promise to call the search endpoint
 * @param {string} searchTerm - term to search
 * @param {[key: string]: any} filters - key/value par if filters to use in the query
 * @param {number} location - location to search
 * @param {number} page - the page to start at - aka where to start from in the result. Used for pagination
 * @return {ESWorkorderDocument[]} - List of work orders
 */
export const queryElasticSearchWorkOrders = async (
  searchTerm,
  filters = {},
  locationId,
  page = 0,
) => {
  const { fromDate, toDate } = filters;
  let { status } = filters;

  const filter = [];

  if (status && status.length) {
    status = status.map((s) => {
      let str = s.toLowerCase();

      if (str.indexOf('(') > -1 && str.indexOf(')') > -1) {
        str = str.split('(')[0].trim();
      }

      return str;
    });
  }

  const terms = {
    siteId: locationId,
    ...(status && status.length > 0 ? { workOrderStatus: status } : {}),
  };

  const termsKeys = Object.keys(terms).length;

  if (termsKeys === 1) {
    filter.push({ term: terms });
  } else if (termsKeys > 1) {
    filter.push({
      bool: {
        must: Object.keys(terms)
          .map((term) => {
            const key = Array.isArray(terms[term]) ? 'terms' : 'term';
            return { [key]: { [term]: terms[term] } };
          }),
      },
    });
  }

  if (fromDate || toDate) {
    const range = {
      ...(fromDate ? { gte: moment(fromDate).format('YYYY-MM-DD') } : {}),
      ...(toDate ? { lte: moment(toDate).format('YYYY-MM-DD') } : { lte: 'now' }),
    };

    filter.push({
      range: { scheduledStart: range },
    });
  }

  const escapeCharacters = (s) => {
    let result = '';
    for (let i = 0; i < s.length; i++) {
      const c = s.charAt(i);
      // These characters are part of the query syntax and must be escaped
      if (c === '\\' || c === '+' || c === '-' || c === '!' || c === '(' || c === ')' || c === ':'
        || c === '^' || c === '[' || c === ']' || c === '\"' || c === '{' || c === '}' || c === '~'
        || c === '*' || c === '?' || c === '|' || c === '&' || c === '/') {
        result = result.concat('\\');
      } else if(c !== '>' && c !== '<') { // removed from string
        result = result.concat(c);
      }
    }
    return result.toString();
  }

  const query = {
    from: page,
    size: 20,
    query:{
       bool:{
         ...(searchTerm ? {
          must:{
            query_string : {
              query: `*${escapeCharacters(searchTerm)}*`,
                fields: ["workOrderNumber^6", "workOrderDescription^5", "assetNumber^3"]
            }
          }
        } : {}),

       }
    },
    sort: {
      _score: {
        order: 'desc',
      },
    },
 }

  if (filter.length > 0) {
    query.query.bool.filter = filter;
  }

  return Api.post('/workorders/search', query);
};
