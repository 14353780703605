import React from 'react';
import { findKey as _findKey } from 'lodash';

import { Table, TableCell, TableBody, TableHead, TableRow, Button } from '@material-ui/core';

import { Template } from '../../../../types/template';
import TemplateTypeAheadSearch from './TemplateTypeAheadSearch';

// refactor add permit row to table
// handle form visible in here
// call on change prop with permits when it changes

const WorkTemplatePermitsTable: React.FC = () => {
  const [formVisible, setFormVisible] = React.useState<boolean>(false);
  const [permitTemplates, setPermitTemplates] = React.useState<Template[]>([]);

  const handleTemplateChange = (template: Template | undefined) => {
    setFormVisible(false);
    if (template) {
      const existing = _findKey(permitTemplates, (te: Template) => te.FormKey === template.FormKey);
      const nextTemplates = [...permitTemplates];

      if (existing) {
        nextTemplates[Number(existing)] = template;
      } else {
        nextTemplates.push(template);
      }

      setPermitTemplates(nextTemplates);
    }
  };

  const removePermitTemplate = (template: Template) => {
    setPermitTemplates((templates) => templates.filter(t => t.PermitTemplateId !== template.PermitTemplateId));
  };

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>Form</TableCell>
          <TableCell>Permit Name</TableCell>
          <TableCell />
        </TableRow>
      </TableHead>
      <TableBody>
        {permitTemplates.map((template) => (
          <TableRow key={template.PermitTemplateId}>
            <TableCell>{template.FormKey}</TableCell>
            <TableCell>{template.Name}</TableCell>
            <TableCell align="right">
              <Button
                color="secondary"
                size="small"
                onClick={() => removePermitTemplate(template)}
              >
                Remove
              </Button>
            </TableCell>
          </TableRow>
        ))}
        <TableRow>
          <TableCell colSpan={3}>
            {
              formVisible
                ? <TemplateTypeAheadSearch onChange={handleTemplateChange} />
                : (
                  <Button
                    size="small"
                    color="primary"
                    onClick={() => setFormVisible(true)}
                    variant="outlined"
                  >
                    Add Permit Template
                  </Button>
                )
            }
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
};

WorkTemplatePermitsTable.defaultProps = {

};

WorkTemplatePermitsTable.propTypes = {

};

export default WorkTemplatePermitsTable;
