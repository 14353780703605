import React from 'react';
import PropTypes from 'prop-types';

import { Typography, TextField } from '@material-ui/core';
import { useDispatch } from 'react-redux';

import Dialog from '../../../shared/Dialog';

import TemplatesState from '../../../../actions/admin/templates';
import InlineLoader from '../../../shared/InlineLoader';
import { TemplateStateAction } from '../../../../types/template';

type Props = {
  action: TemplateStateAction;
  onClose: () => void;
  PermitTemplateId: string;
}

const states = {
  [TemplateStateAction.Approve]: {
    button: 'Approve',
    commentRequired: false,
    title: 'Approve Template',
    helpText: 'Are you sure you want to approve this template?',
  },
  [TemplateStateAction.Discontinue]: {
    button: 'Discontinue',
    commentRequired: true,
    title: 'Discontinue Template',
    helpText: 'Are you sure you want to discontinue this template?',
  },
  [TemplateStateAction.Reject]: {
    button: 'Reject',
    commentRequired: true,
    title: 'Reject Template',
    helpText: 'Enter the reason for rejecting this template',
  },
  [TemplateStateAction.Review]: {
    button: 'Submit',
    commentRequired: false,
    title: 'Submit Template For Review',
    // eslint-disable-next-line max-len
    helpText: 'Are you sure you want to submit this template for review? You will not be able to make any further changes after it has been submitted.',
  },
};

const MenuActionDialog: React.FC<Props> = (props) => {
  const { action, onClose, PermitTemplateId } = props;
  const dispatch = useDispatch();

  const [commentText, setCommentText] = React.useState('');
  const [loading, setLoading] = React.useState<boolean>(false);

  const handlePerformActionClick = async () => {
    setLoading(true);

    await dispatch(TemplatesState.actions.changeState(
      PermitTemplateId,
      action,
      commentText,
    ));

    onClose();
    setLoading(false);
  };

  return (
    <Dialog
      visible
      onCancel={onClose}
      onClose={onClose}
      title={states[action].title}
      actions={[{
        text: states[action].button,
        color: 'primary',
        disabled: loading || (states[action].commentRequired && !commentText),
        onClick: handlePerformActionClick,
      }]}
    >
      {loading ? <InlineLoader /> : (
        <>
          <Typography gutterBottom>{states[action].helpText}</Typography>

          <Typography gutterBottom>
            Comment
            {!states[action].commentRequired && <> (optional)</>}
          </Typography>

          <TextField
            variant="outlined"
            multiline
            rows={2}
            style={{ width: '100%' }}
            value={commentText}
            onChange={(e) => setCommentText(e.target.value)}
          />
        </>
      )}
    </Dialog>
  );
};

MenuActionDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  PermitTemplateId: PropTypes.string.isRequired,
};

export default MenuActionDialog;
