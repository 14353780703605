import React from 'react';
import { isEmpty as _isEmpty } from 'lodash'
import { Utils as FormioUtils } from 'formiojs';
import { makeStyles, Paper, TextField } from '@material-ui/core';

import { Template } from '../../../../types/template';
import { FormIoComponentWithProperties } from '../../../../types/form-io';
import { DefinitionForm } from '../../../../types/definition';

import InlineLoader from '../../../shared/InlineLoader';
import FormSelect from '../shared/FormSelect';
import SubForm from './SubForm';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  createButton: {
    marginLeft: theme.spacing(1),
  },
}));

type Props = {
  disabled?: boolean;
  forms: DefinitionForm[];
  template?: Partial<Template>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  definition: any;
  onChange: (templateData: Partial<Template>) => void;
}

const CreateEditForm: React.FC<Props> = (props) => {
  const classes = useStyles();

  const { disabled, definition, forms, onChange, template = {} } = props;

  const initialFormData = React.useMemo(() => {
    if (!template.FormKey) {
      return {};
    }
    return { ...template.ContentJSON.data[template.FormKey] };
  }, [template.ContentJSON, template.FormKey]);

  const [templateData, setTemplateData] = React.useState<Partial<Template>>({});

  React.useEffect(() => {
    if (_isEmpty(templateData) && !_isEmpty(template)) {
      setTemplateData({ FormKey: template.FormKey, Name: template.Name });
    }
  }, [template, templateData]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [formData, setFormData] = React.useState<any>({ initialFormData });

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const updateFormData = (nextFormData: any) => {
    setFormData(nextFormData);
  };

  const updateFieldValue = (field: 'Name' | 'FormKey', value: string) => {
    setTemplateData((data) => ({ ...data, [field]: value }));
  };

  React.useEffect(() => {
    onChange({
      ...templateData,
      ...(Object.keys(formData).length > 0 ? { ContentJSON: formData } : {}),
    } as Partial<Template>);
  }, [formData, onChange, templateData]);

  const selectedForm = React.useMemo(() => {
    if (!forms || !templateData.FormKey) return undefined;
    return (forms || []).find((form) => form.Name === templateData.FormKey);
  }, [forms, templateData.FormKey]);

  const components = React.useMemo(() => {
    if (!selectedForm || !definition || !definition.form) return [];

    const result = FormioUtils.searchComponents(definition.form.components, {
      key: selectedForm.Name,
    });

    const selectedFormComponents = result ? result[0].components : [];

    // fields that get cleared on copy should should not be able to be set
    // in a template. As such, we need to loop through the components and disable
    // any component with this custom property
    if (selectedFormComponents.length > 0) {
      FormioUtils.eachComponent(
        selectedFormComponents,
        (component: FormIoComponentWithProperties) => {
          if (component.properties && component.properties.ClearOnCopy) {
            component.disabled = true;
          }
        },
      );
    }

    return selectedFormComponents;
  }, [definition, selectedForm]);

  return (
    <>
      <Paper elevation={1} className={classes.wrapper}>
        {forms ? (
          <>
            <TextField
              disabled={disabled}
              label="Template Name"
              value={templateData.Name}
              onChange={(event) => updateFieldValue('Name', event.target.value)}
              style={{ marginLeft: '1rem', marginRight: '1rem', maxWidth: '50%' }}
            />
            <FormSelect
              disabled={disabled || template.FormKey !== undefined}
              label="Permit Type"
              options={
                forms && forms.reduce((all: any, form: any) => [
                  ...all,
                  { id: form.Name, label: form.PermitType },
                ], [])
              }
              selected={templateData.FormKey || undefined}
              onChange={(selected) => updateFieldValue('FormKey', selected)}
            />
          </>
        ) : <InlineLoader />}
      </Paper>

      {templateData.FormKey && (
        <SubForm
          disabled={disabled}
          components={components}
          initialFormData={initialFormData}
          permitType={selectedForm?.PermitType}
          onChange={updateFormData}
        />
      )}
    </>
  );
};

CreateEditForm.defaultProps = {

};

CreateEditForm.propTypes = {

};

export default CreateEditForm;
