import AppState from '../../actions/app';

export const lastRunRegistryMiddleware = () => (next) => (action) => {
  let result = next(action);
  if (action.type === AppState.types.setStatus) {
    result = next({
      type: AppState.types.setLastRun,
      payload: { type: action.payload.action, date: (new Date()).toISOString() },
    });
  }
  return result;
};

export default [
  lastRunRegistryMiddleware,
];
