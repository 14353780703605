import React from 'react';
import PropTypes from 'prop-types';

import {
  makeStyles,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 320,
  },
}));

interface Props {
  disabled?: boolean;
  label: string;
  onChange: (id: any) => void;
  options: {
    label: string;
    id: string;
  }[];
  selected?: string;
}

const FormSelect: React.FC<Props> = (props) => {
  const classes = useStyles();

  const {
    disabled,
    label,
    onChange,
    options,
    selected,
  } = props;

  return (
    <FormControl variant="outlined" className={classes.formControl} style={{ maxWidth: '50%', marginTop: '2rem' }}>
      <InputLabel id="formSelectLabel">{label}</InputLabel>
      <Select
        disabled={disabled}
        labelId="formSelectLabel"
        id="formSelect"
        value={selected || ''}
        onChange={(e) => onChange(e.target.value)}
        label={label}
      >
        <MenuItem value="">
          <em>None</em>
        </MenuItem>
        {options && options.map((option) => (
          <MenuItem value={option.id} key={option.id}>{option.label}</MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

FormSelect.defaultProps = {
  selected: undefined,
};

FormSelect.propTypes = {
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    }).isRequired,
  ).isRequired,
  selected: PropTypes.string,
};

export default FormSelect;
