import React from 'react';
import { Route, Switch } from 'react-router-dom';

import GroupTable from './GroupTable';

const GroupPermissionPage: React.FC = () => (
  <Switch>
    <Route path="/admin/group-permission" exact component={GroupTable} />
  </Switch>
);

export default GroupPermissionPage;