import { useSelector } from 'react-redux';

import AuthState from '../../actions/auth';
import { Permission } from '../../types/auth';

const useWithUserPermissions = () => {
  const isAdmin = useSelector(AuthState.selectors.isAdmin()) as boolean;
  const permissions = useSelector(AuthState.selectors.permissions()) as Permission[]
  return { isAdmin, permissions };
};

export default useWithUserPermissions;
