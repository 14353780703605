import React from 'react';
import PropTypes from 'prop-types';

import {
  AppBar,
  Button,
  Grid,
  makeStyles,
  Toolbar,
} from '@material-ui/core';

import StateChip from '../../../shared/StateChip';
import { StateColors, TemplateState, TemplateStates } from '../../../../types/template';

const useStyles = makeStyles((theme) => ({
  text: {
    padding: theme.spacing(2, 2, 0),
  },
  paper: {
    paddingBottom: 50,
  },
  list: {
    marginBottom: theme.spacing(2),
  },
  subheader: {
    backgroundColor: theme.palette.background.paper,
  },
  appBar: {
    top: 'auto',
    bottom: 0,
    display: 'flex',
    justifyContent: 'space-between',
  },
  grow: {
    flexGrow: 1,
  },
  leftSection: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },
  centerSection: {
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      justifyContent: 'center',
    },
  },
  rightSection: {
    alignItems: 'center',
    justifyContent: 'flex-end',
    display: 'flex',
    color: theme.palette.grey[200],
    '& > *': {
      marginLeft: '1.5rem',
    },
  },
  loadingSpinner: {
    color: theme.palette.grey[200],
    marginRight: theme.spacing(2),
  },
  createButton: {
    alignSelf: 'center',
    backgroundColor: theme.palette.grey[200],
    color: theme.palette.primary.main,
  },
}));

type Props = {
  disabled?: boolean;
  handleSubmit: () => void;
  loading?: boolean;
  State?: TemplateState;
}

const FooterBar: React.FC<Props> = (props) => {
  const {
    disabled,
    handleSubmit,
    loading,
    State,
  } = props;

  const classes = useStyles();

  return (
    <AppBar position="fixed" color="primary" className={classes.appBar}>
      <Toolbar>
        <Grid container>
          <Grid item md={4} className={classes.leftSection} />
          <Grid item xs={6} md={4} className={classes.centerSection}>
            <Button
              variant="contained"
              className={classes.createButton}
              onClick={handleSubmit}
              disabled={disabled || loading}
            >
              Save Template
            </Button>
          </Grid>
          {!loading && (
            <Grid item xs={6} md={4} className={classes.rightSection}>
              {State && <StateChip state={State} colors={StateColors} />}
            </Grid>
          )}
        </Grid>
      </Toolbar>
    </AppBar>
  );
};

FooterBar.defaultProps = {
  disabled: false,
  loading: false,
};

FooterBar.propTypes = {
  disabled: PropTypes.bool,
  handleSubmit: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  State: PropTypes.oneOf(TemplateStates).isRequired,
};

export default FooterBar;
