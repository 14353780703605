import React, { useEffect } from 'react';
import { get as _get } from 'lodash';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import { RESET_STATE } from '@redux-offline/redux-offline/lib/constants';
import { Alert } from '@material-ui/lab';

import {
  AppBar,
  Button,
  IconButton,
  Toolbar,
  Typography,
  Tooltip,
  Avatar,
  Menu,
  MenuItem,
  Box
} from '@material-ui/core';

import {
  Sync as SyncIcon,
  SyncDisabled as SyncDisabledIcon,
  Menu as MenuIcon,
  Wifi as WifiIcon,
  SignalWifiOff as SignalWifiOffIcon,
  NoteAdd as AddIcon,
  MoreVert as MoreIcon
} from '@material-ui/icons';

import AppState from '../../actions/app';
import LocationsState from '../../actions/locations';
import PermitsState from '../../actions/permits';
import WorkOrdersState from '../../actions/work-orders';
import AuthState from '../../actions/auth';
import NavBarLocationIndicator from './NavBarLocationIndicator';

import useOffline from '../../hooks/shared/useOffline';
import useAbilities from '../../hooks/auth/useAbilities';
import useAnalytics from '../../hooks/shared/useAnalytics';

const useStyles = makeStyles((theme) => ({
  flex: {
    flex: 1,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingRight: '5px',
    [theme.breakpoints.down('sm')]: {
      paddingRight: 0,
     },
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20,
    [theme.breakpoints.down('sm')]: {
      marginRight: 10,
    },
  },
  toolbarMargin: theme.mixins.toolbar,
  createPermitButton: {
    backgroundColor: 'white',
    color: theme.palette.primary.main,
    marginRight: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
      marginRight: 0,
      minWidth: 50,
     },
  },
  responseText: {
    [theme.breakpoints.down('sm')]: {
     fontSize: '1.2rem',
    },
  },
  mobile: {
    display: 'none',
    [theme.breakpoints.down('sm')]: {
      display: 'inline-block',
    },
  },
  mobileIconText: {
    paddingLeft: theme.spacing(1),
    fontSize: '1.1rem',
    color: "inherit",
  },
  nonMobile: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'inline-block',
    },
  },
  noHover: {
    "&:hover": {
      backgroundColor: 'transparent'
    },
  },
  noActions: {
    "&:hover": {
      backgroundColor: 'transparent',
      cursor: 'default',
    },
  },
  profileAvatar: {
    backgroundColor: 'white',
    color: theme.palette.primary.main,
    marginLeft: theme.spacing(2)
  },
  userDetails: {
    fontSize: '1.1rem',
    marginLeft: theme.spacing(1),
    "&:hover": {
      backgroundColor: 'transparent',
      cursor: 'default',
    },
  },
  titleContainer: {
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    maxWidth: 275,
    overflow: 'hidden'
  }
}));

const Title = ({ profile, homeLocation, titleContainer }) => (
  <>
    {profile && profile.givenName && profile.surname && <div className={titleContainer}>Name : {`${profile.givenName} ${profile.surname}`}</div>}
    {homeLocation && homeLocation.Title && <div className={titleContainer}>Location : {homeLocation.Title}</div>}
  </>
)
const useDataRefresh = () => {
  const dispatch = useDispatch();
  const homeLocation = useSelector(AppState.selectors.homeLocation());

  return React.useCallback(() => {
    dispatch(PermitsState.actions.get());
    dispatch(WorkOrdersState.actions.get(homeLocation.SiteId, true));
    dispatch(LocationsState.actions.getLocationMetadata(homeLocation.LocationId));
    dispatch({ type: AppState.types.refreshData });
    dispatch({ type: RESET_STATE });
  }, [dispatch, homeLocation.LocationId]);
};

const NavigationBar = (props) => {
  const { pageName, outageWarningBanner, outageBanner } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const { online } = useOffline();
  const abilities = useAbilities();
  const recordEvent = useAnalytics();
  const refreshData = useDataRefresh();
  const profile = useSelector(AuthState.selectors.profile());
  const homeLocation = useSelector(AppState.selectors.homeLocation());

  const hasSomeAbilities = abilities && Object.keys(abilities).length > 0;

  const recordActionClick = (item) => {
    recordEvent({
      data: 'NAVIGATION_BAR_CLICK',
      item
    });
  }

  const handleRefreshData = () => {
    recordActionClick('Refresh Data');
    refreshData();
    handleMobileMenuClose();
  }

  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);

  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const updateDimensions = () => {
    if (window.innerWidth >= 960) {
      setMobileMoreAnchorEl(null);
    }
  }

  useEffect(() => {
    window.addEventListener("resize", updateDimensions);
    return () => {
      window.removeEventListener("resize", updateDimensions)
    }
  }, [])

  const mobileMenuId = 'primary-search-account-menu-mobile';
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      {
        online && <MenuItem><NavBarLocationIndicator onClose={handleMobileMenuClose} /></MenuItem>
      }
      {
        hasSomeAbilities &&
        <MenuItem>
          <IconButton className={classes.noHover} onClick={handleRefreshData} aria-label="network status" edge="end" color="inherit" size="medium" disabled={!online}>
            {online ? <SyncIcon /> : <SyncDisabledIcon />}
            <span className={classes.mobileIconText}>Refresh Data</span>
          </IconButton>
        </MenuItem>
      }
      {
        hasSomeAbilities &&
        <MenuItem className={classes.noActions}>
          <IconButton className={classes.noActions} aria-label="network status" edge="end" aria-label={online ? 'Online': 'Offline'} color="inherit" size="medium">
            {online ? <WifiIcon /> : <SignalWifiOffIcon />}
            <span className={classes.mobileIconText}>{online ? 'Online': 'Offline'}</span>
          </IconButton>
        </MenuItem>
      }
      <MenuItem className={classes.noActions}>
        <Typography variant="h5" color="inherit" className={classes.userDetails}>
          <Title profile={profile} homeLocation={homeLocation} titleContainer={classes.titleContainer} />
        </Typography>
      </MenuItem>
    </Menu>
  );

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="fixed" color={online ? 'primary' : 'secondary'}>
        <Toolbar>
          {hasSomeAbilities && (
            <IconButton
              className={classes.menuButton}
              color="inherit"
              aria-label="Menu"
              onClick={() => dispatch(AppState.actions.toggleLeftMenuDrawer())}
            >
              <MenuIcon />
            </IconButton>
          )}
          <Typography variant="h5" color="inherit" className={classes.flex}>
            <div className={classes.responseText}>
              {pageName}
              {online ? '' : ' (Working Offline)'}
            </div>
            <div className={classes.mobile}>
              {_get(abilities, 'permits.edit') && (
            <Button
              className={classes.createPermitButton}
              onClick={() => {
                recordActionClick('Create Permit');
                history.push(`/permits/create`);
              }}
              variant="contained"
            >
              <span className={classes.mobile}><AddIcon /></span>
          </Button>
                )}
            </div>
          </Typography>
          <Box sx={{ display: { xs: "none", md: "flex" }, alignItems: 'center' }}>

            {_get(abilities, 'permits.edit') && (

              <Button
                className={classes.createPermitButton}
                onClick={() => {
                  recordActionClick('Create Permit');
                  history.push(`/permits/create`);
                }}
                variant="contained"
              >
                <span className={classes.nonMobile}>Create Permit</span>
              </Button>
            )}

            {online && <NavBarLocationIndicator />}

            {hasSomeAbilities && (
              <>
                <IconButton onClick={handleRefreshData} aria-label="network status" edge="end" color="inherit" disabled={!online}>
                  {online ? <SyncIcon /> : <SyncDisabledIcon />}
                </IconButton>
                <IconButton aria-label="network status" edge="end" color="inherit">
                  {online ? <WifiIcon /> : <SignalWifiOffIcon />}
                </IconButton>
              </>
            )}
            {
              profile && profile.givenName && profile.surname && (
                <Tooltip title={<Title profile={profile} homeLocation={homeLocation} />} arrow>
                  <Avatar className={classes.profileAvatar}>{`${profile.givenName.charAt(0)}${profile.surname.charAt(0)}`}</Avatar>
                </Tooltip>
              )
            }
            <Typography variant="h5" color="inherit" className={classes.userDetails}>
              <Title profile={profile} homeLocation={homeLocation} titleContainer={classes.titleContainer} />
            </Typography>
          </Box>
          <Box sx={{ display: { xs: "flex", md: "none" }, alignItems: 'center' }}>
            <IconButton
              size="medium"
              aria-label="show more"
              aria-controls={mobileMenuId}
              aria-haspopup="true"
              onClick={handleMobileMenuOpen}
              color="inherit"
            >
              <MoreIcon />
            </IconButton>
          </Box>
        </Toolbar>
      </AppBar>
      {renderMobileMenu}
      <div className={classes.toolbarMargin} />
      {outageWarningBanner?.display === true && (
          <Alert severity="info">{outageWarningBanner?.message}</Alert>
        )}
      {outageBanner?.display === true && (
        <Alert severity="error">{outageBanner?.message}</Alert>
      )}
      <div className={classes.toolbarMargin} />
    </Box>
  );
};

NavigationBar.propTypes = {
  pageName: PropTypes.string.isRequired,
  outageWarningBanner: { display: Boolean, message: Boolean | React.ReactChild | React.ReactFragment | React.ReactPortal | null | undefined},
  outageBanner: { display: Boolean, message: Boolean | React.ReactChild | React.ReactFragment | React.ReactPortal | null | undefined}
};

export default NavigationBar;
