import LocationsState from '../actions/locations';

export const initialState = {
  /* A dictionary of plot plan image blob urls using the locationId as the key */
  plotPlanUrls: {},
};

const locationsReducer = (state = initialState, action) => {
  const { types } = LocationsState;
  switch (action.type) {
    case types.setPlotPlanImage:
      return {
        ...state,
        plotPlanUrls: {
          ...state.plotPlanUrls,
          [action.payload.locationId]: action.payload.url,
        },
      };
    default:
      return state;
  }
};

export default locationsReducer;
