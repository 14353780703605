import React from 'react';
import PropTypes from 'prop-types';

import {
  makeStyles,
  MenuItem as MuiMenuItem,
  Typography,

} from '@material-ui/core';
import { useHistory, useLocation } from 'react-router-dom';

const useStyles = makeStyles(() => ({
  active: {
    fontWeight: 'bold',
  },
}));

type Props = {
  label: string;
  path: string;
}

const MenuItem: React.FC<Props> = (props) => {
  const { label, path } = props;

  const history = useHistory();
  const { pathname } = useLocation();
  const classes = useStyles();

  const handleOnClick = () => {
    history.push(path);
  };

  const isActive = pathname.startsWith(path);

  return (
    <MuiMenuItem onClick={handleOnClick}>
      <Typography className={isActive ? classes.active : undefined}>
        {label}
      </Typography>
    </MuiMenuItem>
  );
};

MenuItem.defaultProps = {};

MenuItem.propTypes = {
  label: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
};

export default MenuItem;
