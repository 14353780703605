import React, { forwardRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import { get as _get } from 'lodash';
import moment from 'moment';
import dateIoMoment from '@date-io/moment';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import { Button, FormControl, InputAdornment, Paper, Input, FormControlLabel, Radio, RadioGroup, FormLabel, IconButton  } from '@material-ui/core';
import { ArrowDropDown as ArrowDropDownIcon } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';

import FilterDropDownMultiSelect from './MultiSelectInput';
import LocationFilterDialog from './LocationFilterDialog';
import WorkOrderTypeAheadInput from '../../shared/WorkOrderTypeAheadInput';

import { states } from '../../../config/permits';
import { Location } from '../../../types/prop-types';

import usePermitTypes from '../../../hooks/definitions/usePermitTypes';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    padding: theme.spacing(1),
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  input: {
    marginBottom: theme.spacing(2),
  },
  dateShortcutsWrapper: {
    marginBottom: theme.spacing(3),
    '& > *': {
      marginRight: theme.spacing(1),
      padding: '0px 10px',
      flexGrow: -1
    }
  },
  locationInputRoot: {
    color: theme.palette.text.primary,
    opacity: 1,
    marginTop: theme.spacing(2),
  },
  locationInputInput: {
    cursor: 'pointer',
    '&::placeholder': {
      color: '#757575',
      opacity: 1,
    },
  },
  updateButton: {
    marginBottom: theme.spacing(1)
  }
}));

const SearchPermitsFilters = forwardRef((props, ref) => {
  const { loading: typesLoading, types: permitTypes } = usePermitTypes(true);

  const classes = useStyles();
  const {
    filters,
    onChange,
    disabled,
    handleUpdate,
    onClearClick,
    onSaveAndSearch,
    onCreateAndSearch,
    filterLoaded,
    totalFilter,
    fromReporting
  } = props;

  const { dateType } = filters;


  const [locationDialogOpen, setLocationDialogOpen] = React.useState();

  const handleFilterUpdate = (filter, value) => {
    const type = {};
    if (filter === 'fromDate' || filter === 'toDate') {
      type.dateType = 'custom'
    }
    onChange({ ...filters, [filter]: value, ...type });
  };

  const onChnageHandler = (data) => {
    handleFilterUpdate('workOrderNumber', data ? data.workOrderNumber : undefined)
  }

  
  const onDateSelection = (event) => {
    if (event.target.value === 'today') {
      onChange({ ...filters, fromDate: moment(), toDate: moment(), dateType: event.target.value });
    } else if (event.target.value === 'week') {
      onChange({ ...filters, fromDate: moment().startOf('week'), toDate: moment().endOf('week'), dateType: event.target.value });
    } else if (event.target.value === 'month') {
      onChange({ ...filters, fromDate:moment().startOf('month'), toDate: moment().endOf('month'), dateType: event.target.value});
    } else {
      onChange({...filters, dateType: event.target.value})
    }
  };

  return (
    <Paper elevation={0} className={classes.wrapper}>
      <FormControl component="fieldset">
        <FormLabel component="legend">Select Date</FormLabel>
        <RadioGroup row name="row-radio-buttons-group" onChange={onDateSelection}>
          <FormControlLabel size="small" checked={dateType === 'today'} value="today" control={<Radio />} label="Today" />
          <FormControlLabel size="small" checked={dateType === 'week'} value="week" control={<Radio />} label="This Week" />
          <FormControlLabel size="small" checked={dateType === 'month'} value="month" control={<Radio />} label="This Month" />
          <FormControlLabel 
            checked={dateType === 'custom'} 
            value="custom" control={<Radio />}
            label={'Range'}
          />
        </RadioGroup>
      </FormControl>
      <MuiPickersUtilsProvider utils={dateIoMoment}>
        <KeyboardDatePicker
          className={classes.input}
          value={filters.fromDate || null}
          placeholder="From"
          label="From"
          onChange={(date) => handleFilterUpdate('fromDate', date)}
          format="YYYY-MM-DD"
        />
        <KeyboardDatePicker
          className={classes.input}
          value={filters.toDate || null}
          placeholder="To"
          label="To"
          onChange={(date) => handleFilterUpdate('toDate', date)}
          format="YYYY-MM-DD"
        />
      </MuiPickersUtilsProvider>

      <FilterDropDownMultiSelect
        disabled={disabled}
        className={classes.input}
        label="State"
        defaultValue={filters.State}
        onSelect={(state) => handleFilterUpdate('State', state)}
        options={states.map((state) => ({ value: state }))}
      />

      <FormControl variant="outlined" className={classes.input} onClick={() => setLocationDialogOpen(true)}>
        <Input
          classes={{ root: classes.locationInputRoot, input: classes.locationInputInput }}
          endAdornment={(
            <InputAdornment position="end">
              <ArrowDropDownIcon classes={{ root: classes.locationInputInput }} />
            </InputAdornment>
          )}
          spellCheck={false}
          placeholder="Location"
          value={_get(filters, 'Location.Title', '')}
        />
      </FormControl>

      {locationDialogOpen && (
        <LocationFilterDialog
          location={filters.Location}
          onChange={(date) => handleFilterUpdate('Location', date)}
          onClose={() => setLocationDialogOpen(false)}
        />
      )}

      <FilterDropDownMultiSelect
        disabled={typesLoading || disabled}
        className={classes.input}
        label="Permit Type"
        defaultValue={filters.PermitType}
        onSelect={(types) => handleFilterUpdate('PermitType', types)}
        options={permitTypes.map((type) => ({
          label: type.PermitType,
          value: type.Name,
        }))}
      />

      <WorkOrderTypeAheadInput
        ref={ref}
        onChange={onChnageHandler}
        defaultValue={filters.workOrderNumber}
      />

      <Button
        variant="contained"
        color="primary"
        className={classes.updateButton}
        onClick={handleUpdate}
        disabled={disabled}
      >
        Search
      </Button>
      {
        filterLoaded && totalFilter && !fromReporting && (
          <>
            <Button
              variant="contained"
              color="primary"
              className={classes.updateButton}
              onClick={onSaveAndSearch}
              disabled={disabled}
            >
              Update and Search
            </Button>
          </>
        )
      }
      {
        totalFilter < 10 && !fromReporting && (
          <Button
            variant="contained"
            color="primary"
            className={classes.updateButton}
            onClick={onCreateAndSearch}
            disabled={disabled}
          >
            Create and Search
          </Button>
        ) 
      }
      <Button
        color="primary"
        onClick={onClearClick}
        disabled={disabled}
      >
        Clear Filters
      </Button>
    </Paper>
  );
});

SearchPermitsFilters.defaultProps = {
  disabled: false,
  filters: { State: [] },
};

SearchPermitsFilters.propTypes = {
  filters: PropTypes.shape({
    Location,
    State: PropTypes.arrayOf(PropTypes.string),
    PermitType: PropTypes.arrayOf(PropTypes.string),
    fromDate: PropTypes.string,
    workOrderNumber: PropTypes.string,
    toDate: PropTypes.string,
  }),
  handleUpdate: PropTypes.func.isRequired,
  onClearClick: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

export default SearchPermitsFilters;
