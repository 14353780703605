import React from 'react';
import PropTypes from 'prop-types';

import {
  Button,
  makeStyles,
  TableCell,
  TableRow,
  TextField,
  CircularProgress,
} from '@material-ui/core';

import { ListItem } from '../../../../types/list-item';

const useStyles = makeStyles(() => ({
  wrapper: {},
  textField: {
    margin: 0,
    width: '100%',
  },
}));

type Props = {
  existingValues: string[];
  forceEdit?: boolean;
  item: ListItem;
  onChange: (item: ListItem, initialItemValue?: string) => void;
  onCancel?: () => void;
  onDelete?: (item: ListItem) => void;
}

const ListItemsListRow: React.FC<Props> = (props) => {
  const {
    existingValues,
    forceEdit,
    item,
    onCancel,
    onChange,
    onDelete,
  } = props;
  const classes = useStyles();
  const [mode, setMode] = React.useState(forceEdit ? 'edit' : 'view');
  const [confirmDeleteActive, setConfirmDeleteOpen] = React.useState(false);

  // we need to keep track of the initial item value and provide it
  // to the onChange function in the case where the value is updated so
  // that the stale item can be filtered from the list of items in state.
  const initialItemValue = React.useRef<string>(item.Value);

  const [formValues, setFormValues] = React.useState(item);
  const [loading, setLoading] = React.useState(false);
  const [errors, setErrors] = React.useState<any>({});

  const handleDeleteClick = async (itemForDeletion: ListItem) => {
    if (onDelete) {
      setLoading(true);
      await onDelete(itemForDeletion);
      setLoading(false);
    }
  };

  const updateFieldValue = (field: string, value: string) => {
    if (field === 'value' && existingValues) {
      if (value !== item.Value && existingValues.includes(value)) {
        setErrors((e: any) => ({ ...e, value: 'An item with this value already exists' }));
      } else {
        // eslint-disable-next-line no-shadow
        const { value, ...rest } = errors;
        setErrors(rest);
      }
    }

    setFormValues((values) => ({ ...values, [field]: value }));
  };

  const handleItemSave = async () => {
    setLoading(true);
    await onChange(formValues, initialItemValue.current);
    setLoading(false);

    if (!forceEdit) {
      setMode('view');
    }
  };

  const handleCancelClick = () => {
    if (onCancel) {
      onCancel();
    }

    if (!forceEdit) {
      setMode('view');
    }
  };

  const renderEditButtons = () => (
    <>
      <Button
        size="small"
        color="primary"
        onClick={handleItemSave}
        disabled={Object.keys(errors).length !== 0}
      >
        Save
      </Button>
      <Button size="small" onClick={handleCancelClick}>
        Cancel
      </Button>
    </>
  );
  const renderDeleteButtons = () => (
    <>
      <Button size="small" color="secondary" onClick={() => handleDeleteClick(item)}>
        Delete
      </Button>
      <Button size="small" onClick={() => setConfirmDeleteOpen(false)}>
        Cancel
      </Button>
    </>
  );

  const renderViewButtons = () => (
    <>
      <Button size="small" color="primary" onClick={() => setMode('edit')}>
        Edit
      </Button>
      <Button size="small" color="secondary" onClick={() => setConfirmDeleteOpen(true)}>
        Delete
      </Button>
    </>
  );

  const renderViewOrEditButtons = () => {
    return mode === 'view' ? renderViewButtons() : renderEditButtons();
  };

  return (
    <TableRow style={{ width: '100%' }}>
      {loading ? (
        <TableCell scope="row" colSpan={3}>
          <CircularProgress />
        </TableCell>
      ) : (
        <>
          <TableCell component="th" scope="row">
            {mode === 'view' ? (
              <>{item.Value}</>
            ) : (
              <TextField
                required
                error={errors.Value !== undefined}
                helperText={errors.Value || ' '}
                size="small"
                placeholder="Value"
                defaultValue={item.Value}
                variant="outlined"
                className={classes.textField}
                onChange={(event) => updateFieldValue('Value', event.target.value)}
              />
            )}
          </TableCell>
          <TableCell component="th" scope="row">
            {mode === 'view' ? (
              <>{item.Label}</>
            ) : (
              <TextField
                required
                size="small"
                placeholder="Label"
                defaultValue={item.Label}
                helperText={errors.Label || ' '}
                variant="outlined"
                className={classes.textField}
                onChange={(event) => updateFieldValue('Label', event.target.value)}
              />
            )}
          </TableCell>
          <TableCell align="right">
            {confirmDeleteActive ? renderDeleteButtons() : renderViewOrEditButtons()}
          </TableCell>
        </>
      )}
    </TableRow>
  );
};

ListItemsListRow.defaultProps = {
  onDelete: undefined,
  onCancel: undefined,
  forceEdit: false,
};

ListItemsListRow.propTypes = {
  existingValues: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  item: PropTypes.shape({
    Label: PropTypes.string.isRequired,
    Value: PropTypes.string.isRequired,
  }).isRequired,
  onChange: PropTypes.func.isRequired,
  onDelete: PropTypes.func,
  onCancel: PropTypes.func,
  forceEdit: PropTypes.bool,
};

export default ListItemsListRow;
