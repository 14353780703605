import React from 'react';
import PropTypes from 'prop-types';

import Dialog from '../../shared/Dialog';
import LocationSelectTree from '../../shared/LocationSelectTree';
import { Location } from '../../../types/prop-types';

const LocationFilterDialog = (props) => {
  const { onChange, onClose, location } = props;
  const [selected, setSelected] = React.useState(location);

  const handleSelectionClick = () => {
    onChange(selected);
    onClose();
  };

  const handleClearClick = () => {
    onChange(undefined);
    onClose();
  };

  return (
    <Dialog
      visible
      title="Location Filter"
      onClose={onClose}
      actions={[
        {
          onClick: handleClearClick,
          text: 'Clear',
        },
        {
          onClick: () => onClose(),
          text: 'Close',
        },
        {
          color: 'primary',
          disabled: !selected || selected.LocationId === 0,
          onClick: handleSelectionClick,
          text: 'Select Location',
        },
      ]}
    >
      <LocationSelectTree
        defaultSelected={location && location.LocationId}
        onChange={(lo) => setSelected(lo)}
      />
    </Dialog>
  );
};

LocationFilterDialog.defaultProps = {
  location: null,
};

LocationFilterDialog.propTypes = {
  onChange: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  location: Location,
};

export default LocationFilterDialog;
