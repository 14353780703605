import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import AppState from '../../actions/app';
import PermitsState from '../../actions/permits';
import { ActionStatus } from '../../actions/shared/status';

import { definitionsInStateEqual, locationsEqual } from '../../shared/equality';

const useGetPermitDefinitionForLocation = () => {
  const dispatch = useDispatch();
  const { actions, types } = PermitsState;

  const selectedLocation = useSelector(PermitsState.selectors.location(), locationsEqual);
  const homeLocation = useSelector(AppState.selectors.homeLocation(), locationsEqual);
  const location = selectedLocation || homeLocation;
  const data = useSelector(PermitsState.selectors.selected(), definitionsInStateEqual);
  const loading = false;

  React.useEffect(() => {
    dispatch(actions.getInitialPermitForSite(location.SiteId));
    return () => {
      dispatch(AppState.actions.resetStatus(types.getInitialPermitForSite));
    };
    // disabling as it should only ever execute exactly once
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.SiteId]);

  return {
    definition: data,
    error: loading === ActionStatus.rejected,
    loading: loading === ActionStatus.pending,
  };
};

export default useGetPermitDefinitionForLocation;
