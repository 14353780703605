import React from 'react';

import {
  Paper,
  MenuList,
  makeStyles,
  Typography,
  Divider,
} from '@material-ui/core';

import MenuItem from './MenuItem';
import useLocations from '../../../hooks/locations/useLocations';

const useStyles = makeStyles((theme) => ({
  wrapper: {},
  locationWrapper: {
    margin: theme.spacing(2),
    paddingTop: theme.spacing(2),
  },
}));

const Sidebar: React.FC = () => {
  const { homeLocation } = useLocations();
  const classes = useStyles();

  return (
    <Paper>
      <div className={classes.locationWrapper}>
        <Typography variant="h6" gutterBottom>{homeLocation.Title}</Typography>
        <Typography color="textSecondary">
          <b>SiteId</b>
          {' '}
          {homeLocation.SiteId}
        </Typography>
        <Typography color="textSecondary">
          <b>LocationId</b>
          {' '}
          {homeLocation.LocationId}
        </Typography>
      </div>

      <Divider />

      <MenuList>
        <MenuItem label="External Users" path="/admin/external-users" />
        <MenuItem label="Forms" path="/admin/forms" />
        <MenuItem label="Lists" path="/admin/lists" />
        <MenuItem label="Permit Cache" path="/admin/permit-cache" />
        <MenuItem label="Group Permissions" path="/admin/group-permission" />
        {/* <MenuItem label="Templates" path="/admin/templates" /> */}
      </MenuList>
    </Paper>
  );
};

export default Sidebar;
