/* eslint-disable default-case */
import { get as _get } from 'lodash';
import { push } from 'connected-react-router';
import moment from 'moment';
import { Storage } from 'aws-amplify';
import * as jsonPatch from 'fast-json-patch';
import { Utils } from 'formiojs';

import {
  setApproversInDefinition,
  setAvailablePermitsInDefinition,
  setListItemsInDefinition,
} from '../../shared/form-io';

import { formatObjectAsSearchQuery } from '../../shared/url';
import Api from '../../shared/api';
import toast from '../../shared/toast';

import { pending, resolve, reject } from '../shared/status';
import DefinitionsState from '../definitions';
import AppState from '../app';
import AuthState from '../auth';

import WorkOrdersState from '../work-orders';

import { initialState } from '../../reducers/permits';
import Collections from '../../store/indexeddb';

import { PERMISSIONS } from '../../shared/auth';
import { getDescendantsByLocation } from '../../shared/location';
import { recordEvent } from '../../shared/analytics';

const TYPES = {
  // single draft
  clearDraft: 'PERMITS_DRAFT_CLEAR',
  deleteDraft: 'PERMITS_DRAFT_DELETE',
  getDraft: 'PERMITS_DRAFT_GET',
  saveDraft: 'PERMITS_DRAFT_SAVE',
  setDraft: 'PERMITS_DRAFT_SET',

  // drafts list
  getDrafts: 'PERMITS_DRAFTS_GET',
  setDraftsList: 'PERMITS_DRAFTS_LIST_SET',
  clearDraftsList: 'PERMITS_DRAFTS_LIST_CLEAR',

  // permits list
  getPermits: 'PERMITS_LIST_GET',
  setList: 'PERMITS_LIST_SET',
  getAllPermitsListByState: 'PERMITS_LIST_BY_STATE_GET',
  setAllPermitsListByState: 'PERMITS_LIST_BY_STATE_SET',
  setAllPermitsListByStateLoading: 'PERMITS_LIST_BY_STATE_SET_LOADING',
  setAllPermitsListByStateError: 'PERMITS_LIST_BY_STATE_SET_ERROR',

  // permit actions
  copyPermit: 'PERMITS_COPY_ONE',

  // fetch single permit
  getSinglePermit: 'PERMITS_GET_ONE',

  updateOne: 'PERMITS_UPDATE_ONE',
  updatePermitState: 'PERMIT_UPDATE_SINGLE_STATE',
  setApprovals: 'PERMITS_APPROVALS_SET',
  createOne: 'PERMITS_CREATE_ONE',
  createPermitOptimistically: 'PERMITS_CREATE_OPTIMISTICALLY',
  createPermitSucceeded: 'PERMITS_CREATE_SUCCESS',
  createPermitFailed: 'PERMITS_CREATE_FAILED',
  setSelected: 'PERMITS_SET_SELECTED',
  clearSelected: 'PERMITS_CLEAR_SELECTED',
  clearList: 'PERMITS_CLEAR_LIST',
  filter: 'PERMITS_SET_FILTER',
  clearFilters: 'PERMITS_FILTER_CLEAR',
  setAttachmentsList: 'PERMITS_ATTACHMENTS_LIST_SET',

  // Functionality enabled toggles from location metadata
  setPrintMergeDefault: 'PERMITS_PRINT_MERGE_DEFAULT_VALUE_SET',
  setPermitCopyEnabled: 'PERMITS_COPY_ENABLED_SET',

  // permit location
  // update the selected location
  setLocation: 'PERMITS_LOCATION_SET',
  // persisted update to the location of an existing permit
  updatePermitLocation: 'PERMIT_LOCATIONS_UPDATE',

  // approvals
  setApprovalsRequiredList: 'PERMITS_APPROVALS_REQUIRED_LIST_SET',
  clearApprovalsRequiredList: 'PERMITS_APPROVALS_REQUIRED_LIST_CLEAR',
  getInitialPermitForSite: 'PERMITS_INITIAL_PERMIT_SITE_GET',
  updateWorkLocation: 'PERMITS_WORK_LOCATION_UPDATE',
  updatePermitTypesInListPermit: 'PERMITS_LIST_UPDATE_TYPES_IN_PERMIT',

  // attachment
  fileUploadStart: 'FILE_UPLOAD_START',
  fileUploadSuccess: 'FILE_UPLOAD_SUCCESS',
  fileUploadfailure: 'FILE_UPLOAD_FAILURE'
};

/**
 * Update the list filters
 * @param {{[key: string]}} filters - Updates to the filters
 * @param {boolean} fromDefault - Use initial state to base the update one,
 * used to reset all filters not provided in [filters] to their inital state
 * @return {void}
 */
export const setAllFilters = (filters, fromDefault = false) => {
  return (dispatch, getState) => {
    const { permits } = getState();

    const newFilters = {
      ...(fromDefault ? initialState.filters : permits.filter),
      ...filters,
    };

    if (newFilters !== permits.filters) {
      const filterString = formatObjectAsSearchQuery(newFilters);
      // window.history.pushState('', '', `${window.location.pathname}?${filterString}`);
      dispatch({
        type: TYPES.filter,
        payload: filters,
      });
    }
  };
};

export const getAllPermits = (filters = {}) => {
  return async (dispatch, getState) => {
    try {
      dispatch(pending(TYPES.getPermits));
      const { appState, auth } = getState();
      const { homeLocation, locations } = appState;

      // get the locations where user has view access
      const locationsWithPermissions = auth.permissions.find((p) => p.PermissionTypeId === PERMISSIONS.Permits.View);

      // get all the descendant locationss from the user's home location
      const descendantLocations = getDescendantsByLocation(locations, homeLocation, true);

      // Filter out any descendant locations the user does not have permissions to.
      const allowedLocations = descendantLocations.filter((l) => locationsWithPermissions.Locations.includes(l));

      if (Object.keys(filters).length) {
        dispatch(setAllFilters(filters));
      }

      const query = {
        from: 0,
        size: 5000,
        query: {
          bool: {
            filter: [
              { terms: { LocationId: [homeLocation.LocationId, ...allowedLocations] } },
              {
                range: {
                  CreatedOn: {
                    gte: moment().subtract(15, 'days').format('YYYY-MM-DD'),
                    lte: 'now',
                  },
                },
              },
            ],
          },
        },
        sort: [{ CreatedOn: { order: 'desc' } }],
      };

      const [esResponse, needsMyApprovals] = await Promise.all([
        Api.post('/permits/search', query),
        Api.get('/me/approvalsrequired'),
      ]);

      const permitsList = esResponse && esResponse.hits ? esResponse
        .hits
        .hits.map((permit) => {
          return {
            // eslint-disable-next-line no-underscore-dangle
            ...permit._source,
            // eslint-disable-next-line no-underscore-dangle
            approvalRequired: needsMyApprovals.find((ap) => ap.PermitInstanceId === permit._source.PermitInstanceId),
          };
        }) : [];

      dispatch({
        type: TYPES.setList,
        payload: [...permitsList],
      });

      dispatch(resolve(TYPES.getPermits));
    } catch (error) {
      dispatch(reject(TYPES.getPermits, error));
    }
  };
};

export const getPermitsData = (searchTerms, filters = {}) => {
  return async (dispatch, getState) => {
    try {
      
      const { appState, auth, permits: permitsState, definitions } = getState();
      const { initialDefinitionForSite, list } = definitions;
      const { homeLocation, locations } = appState;
      const filterTerm = list[initialDefinitionForSite.PermitDefinitionId]?.filterTerm || [];

      const { CreatedBy, fromDate, workOrderNumber, PermitType, toDate, State } = filters;

      dispatch({
        type: TYPES.setAllPermitsListByStateLoading,
        payload: true
      });
      // get the locations where user has view access
      const locationsWithPermissions = auth.permissions.find((p) => p.PermissionTypeId === PERMISSIONS.Permits.View);

      // get all the descendant locationss from the user's home location
      const descendantLocations = getDescendantsByLocation(locations, filters.Location || homeLocation, true);

      // Filter out any descendant locations the user does not have permissions to.
      const allowedLocations = descendantLocations.filter((l) => locationsWithPermissions.Locations.includes(l));

      // get the base location to include in the search
      const baseLocationId = filters.Location ? filters.Location.LocationId : homeLocation.LocationId;

      // this is a good example of a fully built out bool query
      // https://stackoverflow.com/a/57626279
      const filter = [];
      const must = [];

      const terms = {
        LocationId: [baseLocationId, ...allowedLocations],
        ...(State && State.length > 0 ? { 'State.keyword': State } : {}),
        ...(CreatedBy ? { 'CreatedBy.keyword': CreatedBy } : {}),
        ...(PermitType && PermitType.length > 0 ? { 'permitTypes.Name.keyword' : PermitType.map((type) =>  type ) } : {} ),
        ...(workOrderNumber ? { workOrderNumber: Number(workOrderNumber) } : {}),
      };

      filter.push({
        bool: {
          must: Object.keys(terms)
            .map((term) => {
              const termValue = (terms)[term];
              const key = Array.isArray(termValue) ? 'terms' : 'term';
              return { [key]: { [term]: termValue } };
            }),
        },
      });

      if (fromDate || toDate) {
        const range = {
          ...(fromDate ? { gte: moment(fromDate).startOf('day').format() } : {}),
          ...(toDate ? { lte: moment(toDate).endOf('day').format() } : { lte: moment.utc().endOf('day') }),
        };
    
        filter.push({
          range: {
            workDate: range,
          },
        });
      }

      const totalFilters = {
        'PermitInstanceReferenceId': ['PermitInstanceReferenceId^6'],
        'workLocation': ['workLocation^5'],
        'permitIssuer': ['permitIssuer^4'],
        'assetNumber': ['assetNumber^3'],
        'workDescription': ['workDescription.workDescription^2', 'workDescription.parentForm'],
        'assetDescription': ['assetDescription^1'],
        'companyName': ['companyName'],
        'workOrderNumber': ['workOrderNumber']
      }
    
      const fields = [ ...Object.values(totalFilters).flat(), 'fullText'];
      
      filterTerm.forEach((item) => {
        if(!totalFilters[item]){
          fields.push(item)
        }
      })
    
      if (Array.isArray(searchTerms)) {
        searchTerms.forEach((term) => {
          must.push({
            multi_match: {
              query: `${term}`,
              operator: 'and',
              type: 'phrase_prefix',
              fields       
            },
          });
        });
      }

      const query = {
        from: 0, 
        size: 5000,
        query: {
          bool: { ...(must.length ? { must } : {}) },
        },
        sort: {
          _score: {
            order: 'desc',
          },
        },
      };
    
      if (filter.length > 0) {
        query.query.bool.filter = filter;
      }

      const esResponse = await Api.post('/permits/search', query);

      const permitsList = esResponse && esResponse.hits ? esResponse
        .hits
        .hits.map((permit) => {
          return {
            // eslint-disable-next-line no-underscore-dangle
            ...permit._source,
          };
        }) : [];

      dispatch({
        type: TYPES.setAllPermitsListByState,
        payload: [...permitsList],
      });
      dispatch({
        type: TYPES.setAllPermitsListByStateLoading,
        payload: false
      });
    } catch (error) {
      dispatch({ type: TYPES.setAllPermitsListByStateError, payload: error});
      dispatch({
        type: TYPES.setAllPermitsListByStateLoading,
        payload: false
      });
    }
  };
};

const getNextListPage = () => {
  return async (dispatch, getState) => {
    const { pagination } = getState().permits;
    dispatch(getAllPermits({}, pagination.offset + pagination.limit));
  };
};

const setApprovals = (approvals) => ({
  type: TYPES.setApprovals,
  payload: approvals,
});

const setSelectedPermit = (instance, dirty = false) => {
  return async (dispatch, getState) => {
    try {
      dispatch(DefinitionsState.actions.getDefinition(instance.PermitDefinitionId))
        .then(async ({ definition, form }) => {
          setApproversInDefinition(
            form,
            definition.DefinitionMetadataJSON.Approvals,
            instance.PermitInstanceMetadataJSON.UsersOfGroupList,
            definition.DefinitionMetadataJSON.KeysList,
          );

          setAvailablePermitsInDefinition(
            form,
            instance.PermitInstanceMetadataJSON.AvailablePermits,
          );

          // check to make sure that the if there is a selected work order that it
          // has been fetched from Elasticsearch. If it has not, fetch it so it is
          // available in the form.
          const workOrderNumber = _get(instance.ContentJSON, 'data.workOrder');

          if (workOrderNumber) {
            const workOrder = getState().workOrders.list.find((wo) => wo.workOrderNumber === String(workOrderNumber));

            if (!workOrder) {
              await dispatch(WorkOrdersState.actions.getWorkOrderById(workOrderNumber));
            }
          }

          const { lists } = getState().definitions;
          setListItemsInDefinition(form, lists);

          dispatch({
            type: TYPES.setSelected,
            payload: {
              selected: {
                dirty,
                instance,
                permitFormDefinition: form,
                erpFields: definition
                  .DefinitionMetadataJSON
                  .CustomFieldPathList
                  .filter((f) => f.PropertyName === 'ErpMapping'),
              },
            },
          });
        });
    } catch (error) {
      dispatch(reject('selectSinglePermit', error));
    }
  };
};

export const getPermitById = (PermitInstanceId, fetchApprovals) => {
  return async (dispatch, getState) => {
    try {
      dispatch(pending(TYPES.getSinglePermit));
      const { online } = getState().offline;

      let approvals;
      let permit = await Collections.Permits.get(PermitInstanceId);

      if (online) {
        if (fetchApprovals) {
          approvals = await Api.get(`/permitinstancestatus?PermitInstanceId=${PermitInstanceId}`);
        }

        if (!permit) {
          const permitInstance = await Api.get(`/permitinstance?PermitInstanceId=${PermitInstanceId}`);
          permit = {
            instance: {
              ...permitInstance,
              ContentJSON: JSON.parse(permitInstance.ContentJSON),
              PermitInstanceMetadataJSON: JSON.parse(permitInstance.PermitInstanceMetadataJSON),
            },
          };
        }

        await Collections.Permits.set(permit.instance.PermitInstanceId, permit);
      }

      dispatch(setSelectedPermit(permit.instance));

      if (online && approvals && Object.keys(approvals).length > 0) {
        dispatch(setApprovals(approvals));
      }

      dispatch(resolve(TYPES.getSinglePermit));
    } catch (error) {
      dispatch(reject(TYPES.getSinglePermit, error));
    }
  };
};

export const get_initial_permit_for_site = (siteId) => {
  return async (dispatch, getState) => {
    try {
      dispatch(pending(TYPES.getInitialPermitForSite));

      const { definitions, permits } = getState();
      const { selected: existingSelected } = permits;

      if (!existingSelected.instance.PermitInstanceId) {
        dispatch(setSelectedPermit(definitions.initialDefinitionForSite));
      }

      dispatch(resolve(TYPES.getInitialPermitForSite));
    } catch (error) {
      dispatch(reject(TYPES.getInitialPermitForSite, error));
    }
  };
};

export const clear_permit = () => {
  return (dispatch, getState) => {
    // default the selected location back to the home location
    const { homeLocation } = getState().appState;
    dispatch(set_selected_location(homeLocation.LocationId));
    dispatch(AppState.actions.resetLastRun(TYPES.getInitialPermitForSite));
    dispatch({ type: TYPES.clearSelected });
  };
};

/**
 * Update a permit instance
 * @param {string} permitInstanceId - Id of permit instance to update
 * @param {{[key: string]}} updates - updates to the permit instance
 * @return {void}
 */
export const updatePermit = (PermitInstanceId, updates) => {
  return async () => {
    return Api.put(
      '/permitinstance',
      { PermitInstanceId, ...updates },
    );
  };
};

/**
 * Update work location for permit
 * @param {string} PermitInstanceId - Id of permit instance
 * @param {number} Latitude - latitude
 * @param {number} Longitude - longitude
 * @return void
 */
export const updateWorkLocation = (PermitInstanceId, Latitude, Longitude) => {
  return async (dispatch) => {
    try {
      dispatch(pending(TYPES.updateWorkLocation));
      await dispatch(updatePermit(PermitInstanceId, { Latitude, Longitude }));

      dispatch({
        type: TYPES.updateWorkLocation,
        payload: { PermitInstanceId, Latitude, Longitude },
      });

      toast.success('Work location updated successfully');
      dispatch(resolve(TYPES.updateWorkLocation));
    } catch (error) {
      dispatch(reject(TYPES.updateWorkLocation, error));
    }
  };
};

/**
 * Update permit Location
 * @param {string} PermitInstanceId - Id of permit instance
 * @param {number} LocationId - location of permit
 * @return void
 */
export const updatePermitLocation = (PermitInstanceId, LocationId) => {
  return async (dispatch) => {
    try {
      dispatch(pending(TYPES.updatePermitLocation));
      await dispatch(updatePermit(PermitInstanceId, { LocationId }));

      dispatch({
        type: TYPES.updatePermitLocation,
        payload: { instance: { PermitInstanceId, LocationId } },
      });

      toast.success('Location updated successfully');
      dispatch(resolve(TYPES.updatePermitLocation));
    } catch (error) {
      dispatch(reject(TYPES.updatePermitLocation, error));
    }
  };
};

/**
 * Update the content of a permit instance
 * @param {string} permitInstanceId - Id of permit instance to update
 * @param {{[key: string]}} submission - Submission body
 * @return {{[key: string]}} - update response
 */
export const updatePermitContent = (permitInstanceId, submission) => {
  return async (dispatch, getState) => {
    try {
      dispatch(pending('updatePermitContent'));

      const { offline, permits } = getState();
      const { instance } = permits.selected;
      let approvals;

      if (offline.online) {
        const response = await Api.patch(`/permitcontent?PermitInstanceId=${permitInstanceId}`, submission);
        approvals = await Api.get(`/permitinstancestatus?PermitInstanceId=${permitInstanceId}`);

        if (response.State) instance.State = response.State;
      }

      //const patchedContentJSON = jsonPatch.applyPatch(instance.ContentJSON, submission).newDocument;
      // Apply the patch to the original document.
      const patchedContentJSON = JSON.parse(JSON.stringify(instance.ContentJSON));
      for (const operation of submission) {
        switch (operation.op) {
          case "add":
            patchedContentJSON[operation.path] = operation.value;
            break;
          case "replace":
            patchedContentJSON[operation.path] = operation.value;
            break;
          case "remove":
            delete patchedContentJSON[operation.path];
            break;
        }      
      }
      instance.ContentJSON = patchedContentJSON;
      instance.DateChanged = moment().format();

      dispatch({
        type: TYPES.updateOne,
        payload: { instance, approvals, meta: { dirty: !offline.online } },
      });

      dispatch(resolve('updatePermitContent'));
    } catch (error) {
      dispatch(reject('updatePermitContent', error));
    }
  };
};

/**
 * Create a new permit instance
 * @param {string} draftId - Id of permit draft
 * @param {{[key: string]}} submission - Submission body
 * @param {{ lat: number; lng: number}} workLocation - coordinates for the work location
 * @return {{[key: string]}} - update response
 */
export const createPermit = (draftId, submission, workLocation = {}) => {
  return async (dispatch, getState) => {
    try {
      dispatch(pending(TYPES.createPermitOptimistically));

      const { definitions, permits } = getState();
      const { location } = permits;

      // there is a condition that I cannot track down where the workOrder field
      // will randomly set the workorder field to be an empty object instead of
      // undefined. To avoid rendering issues, unset that empty object
      const workOrder = _get(submission, 'data.workOrder');
      if (workOrder && typeof workOrder === 'object') {
        delete submission.data.workOrder;
      }

      submission.metadata = {
        timezone: Utils.currentTimezone()
      };

      const instance = {
        ...definitions.initialDefinitionForSite,
        PermitInstanceId: draftId,
        PermitInstanceReferenceId: 'TBD',
        ContentJSON: submission,
        LocationId: location.LocationId,
        SiteId: location.SiteId,
        State: 'DRAFT',
        EffectiveDate: (new Date()).toISOString(),
        Latitude: workLocation.lat,
        Longitude: workLocation.lng,
      };

      recordEvent(getState(), {
        event: 'Create permit action dispatched',
        instance
      })

      dispatch({
        type: TYPES.createPermitOptimistically,
        payload: { instance },
        meta: {
          offline: {
            // the network action to execute
            effect: {
              url: '/permitcontentofdef',
              method: 'POST',
              body: { ...instance, ContentJSON: JSON.stringify(instance.ContentJSON) },
            },
            // action to dispatch when effect succeeds:
            commit: { type: TYPES.createPermitSucceeded, meta: { instance } },
            // action to dispatch if network action fails permanently:
            rollback: { type: TYPES.createPermitFailed, meta: { instance } },
          },
        },
      });
      dispatch(push(`/permits/${instance.PermitInstanceId}/edit?redirectFromCreate=true`));
      dispatch(resolve(TYPES.createPermitOptimistically))
    
    } catch (error) {
      dispatch(reject(TYPES.createPermitOptimistically, error));
    }
  };
};

/**
 * Print Permit
 * @param {string} permitInstanceId - Id of permit instance to print
 * @param {string} printerId - Id of printer to send print job to
 * @param {boolean} Merge - merge permits into a single print job
 * @param {boolean} makeDefault - Update the user's preferences to make this printer their default printer
 * @return void
 */
export const printPermit = (PermitInstanceId, PrinterId, Merge = false, makeDefault = false, Color = false) => {
  return async (dispatch, getState) => {
    try {
      dispatch(pending('printPermit'));
      dispatch(AppState.actions.setPrintingResponse(null));
      recordEvent(getState(), {
        event: 'Print permit action dispatched',
        PermitInstanceId,
        PrinterId,
        Merge,
        makeDefault,
        Color
      });

      const response = await Api.post('/print', {
        Merge,
        PermitInstanceId,
        PrinterId,
        Color
      });

      if (makeDefault) {
        dispatch(AuthState.actions.updateUserPreference('DefaultPrinterId', Number(PrinterId)));
      }

      if (response) {
        if (response.Result === "OK" && response.PrinterState === "Idle") {
          response.severity = 'success';
          response.Result = 'Your permit has been sent to the printer';
          toast.success('Your permit has been sent to the printer.');
        } else if (response.Result === "OK" && response.PrinterState === "Processing") {
          response.severity = 'success';
          response.Result = 'Printer is printing another job and your permit has been sent to the printer in queue.';
          toast.success('Printer is printing another job and your permit has been sent to the printer in queue.');
        } else if (response.Result === "OK" && response.PrinterState !== "Stopped") {
          response.severity = 'success';
          toast.success(response.Result)
        } else if (response.PrinterState === "Offline" || response.PrinterState === "Stopped") {
          response.severity = 'error';
          toast.error(response.Result);
        } else {
          response.severity = 'error';
          toast.error(response.Result);
        }
      }
      dispatch(AppState.actions.setPrintingResponse(response));
      dispatch(resolve('printPermit'));
    } catch (error) {
      dispatch(reject('printPermit', error));
      toast.error('There was a problem printing this permit, please try again.');
    }
  };
};

/**
 * Set default configuration from the location metadata for whether or not
 * the print job should merge the permit PDFs into one print job or not
 * @param {string} mergePermits - Id of permit instance to print
 * @return {Action}
 */
export const setPrintMergeDefault = (mergePermits) => {
  return ({
    type: TYPES.setPrintMergeDefault,
    payload: mergePermits,
  });
};

/**
 * Set if copying a permit is enabled
 *
 * @param {boolean} copyEnabled - Is copying enabled
 * @return {Action}
 */
export const setPermitCopyEnabled = (copyEnabled) => {
  return ({
    type: TYPES.setPermitCopyEnabled,
    payload: copyEnabled,
  });
};




/**
 * Copy Permit
 * @param {string} permitInstanceId - Id of permit instance to copy
 * @return void
 */
export const copyPermit = (PermitInstanceId) => async (dispatch) => {
  try {
    dispatch(pending(TYPES.copyPermit));

    const { PermitInstance, DefinitionIsLatest } = await Api.post(
      '/permitinstance/copy',
      { PermitInstanceId },
    );

    // eslint-disable-next-line max-len
    const url = `/permits/${PermitInstance.PermitInstanceId}/edit${!DefinitionIsLatest ? '?staleDefinition=true' : ''}`;
    dispatch(push(url));

    dispatch(resolve(TYPES.copyPermit));
    toast.success('Your have successfully copied this permit');
  } catch (error) {
    toast.error('There was a problem copying this permit, please try again.');
    dispatch(reject(TYPES.copyPermit, error));
  }
};

/**
 * Reject Permit
 * @param {string} permitInstanceId - Id of permit instance to reject
 * @param {string} reason - The reason the permit is being rejected
 * @return void
 */
export const reject_permit = (PermitInstanceId, reason) => {
  return async (dispatch) => {
    try {
      dispatch(pending('rejectPermit'));

      await Api.post('/permitinstance/reject', {
        PermitInstanceId,
        reason,
      });

      toast.success('Your have successfully rejected this permit');

      dispatch({
        type: TYPES.updatePermitState,
        payload: {
          PermitInstanceId,
          state: 'REJECTED',
        },
      });

      dispatch(push('/permits/list'));
      dispatch(resolve('rejectPermit'));
    } catch (error) {
      toast.error('There was a problem rejecting this permit, please try again.');
      dispatch(reject('rejectPermit', error));
    }
  };
};

/**
 * Revoke Permits
 * @param {string[]} permitInstanceIds - set of permit instances to revoke
 * @param {string} reason - The reason the permits are being revoked
 * @return void
 */
export const revoke_permits = (PermitInstanceIds, reason) => {
  return async (dispatch) => {
    try {
      dispatch(pending('revokePermits'));

      await Api.post('/permitinstance/revoke', {
        PermitInstanceIds,
        reason,
      });

      PermitInstanceIds.forEach((PermitInstanceId) => {
        dispatch({
          type: TYPES.updatePermitState,
          payload: {
            PermitInstanceId,
            state: 'REVOKED',
          },
        });
      });

      dispatch(push('/permits/list'));
      toast.success(`Your have successfully revoked ${PermitInstanceIds.length === 1 ? 'this permit' : 'these permits'}`);
      dispatch(resolve('revokePermits'));
    } catch (error) {
      toast.error('There was a problem rejecting this permit, please try again.');
      dispatch(reject('revokePermits', error));
    }
  };
};

export const revoke_all_permits = (PermitInstanceIds, reason) => {
  return async (dispatch) => {
    try {
      dispatch(pending('revokeAllPermit'));
      dispatch({
        type: TYPES.setAllPermitsListByState,
        payload: [],
      });
     const response = await Api.post('/permitinstance/revoke', {
        PermitInstanceIds,
        reason,
      });
      dispatch({
        type: TYPES.setAllPermitsListByState,
        payload: response.PermitInstanceList
      });
      dispatch(resolve('revokeAllPermit'))
    } catch (error) {
      dispatch(reject('revokeAllPermit', error))
    }
  };
};

const uploadFile = async(action, resolve, reject) => {
  try {
    const { PermitInstanceId, file } = action.payload;
    const attachmentData = await Api.post('/permitattachment', {
      PermitInstanceId,
      MimeType: file.type,
      FileName: file.name,
    });

    const [prefix, fileName] = attachmentData.AttachmentKey.split('/');
    await Storage.put(fileName, file, {
      level: 'public',
      customPrefix: { public: `${prefix}/` },
      mimeType: file.type,
    });
    toast.success('Your attachment has been uploaded successfully');
    resolve();
  } catch (e) {
    toast.error('Uploading your attachment failed. Please try again');
    reject(e);
  }
}

/**
 * Upload an attachment to a permit
 * @param {string} permitInstanceId - Id of permit instance
 * @param {File} file - Binary file to upload
 * @return void
 */
export const uploadAttachment = (PermitInstanceId, file) => {
  return async (dispatch, getState) => {
    try {
      dispatch(pending('uploadAttachment'));

      if (!getState().offline.online && file) {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = (e) => {
          const offlineFileData = {
            PermitInstanceId,
            MimeType: file.type,
            FileName: file.name,
            fileSrcData: e.target.result,
            isOfflineSaved: true,
            rowFileData: file
          }
          dispatch({
            type: TYPES.setAttachmentsList,
            payload: [
              ...getState().permits.attachments,
              offlineFileData,
            ],
          });
        };
        dispatch(resolve('uploadAttachment'));
        dispatch({
          type: TYPES.fileUploadStart,
          payload: { PermitInstanceId, file },
          meta: {
            offline: {
              effect: {
                customFnToRun: uploadFile
              },
              commit: { type: TYPES.fileUploadSuccess, meta: { PermitInstanceId, file } },
              rollback: { type: TYPES.fileUploadfailure, meta: { PermitInstanceId, file } }
            }
          }
        })
        return;
      }
      const attachmentData = await Api.post('/permitattachment', {
        PermitInstanceId,
        MimeType: file.type,
        FileName: file.name,
      });

      const [prefix, fileName] = attachmentData.AttachmentKey.split('/');

      await Storage.put(fileName, file, {
        level: 'public',
        customPrefix: { public: `${prefix}/` },
        mimeType: file.type,
      });

      dispatch({
        type: TYPES.setAttachmentsList,
        payload: [
          ...getState().permits.attachments,
          attachmentData,
        ],
      });

      toast.success('Your attachment has been uploaded successfully');
      dispatch(resolve('uploadAttachment'));
    } catch (error) {
      toast.error('Uploading your attachment failed. Please try again');
      dispatch(reject('uploadAttachment', error));
    }
  };
};

/**
 * Delete an attachment
 * @param {string} PermitInstanceId - Id of permit instance
 * @param {string} PermitAttachmentId - Id of permit attachment
 * @return void
 */
export const delete_permit_attachment = (PermitInstanceId, PermitAttachmentId) => {
  return async (dispatch, getState) => {
    try {
      dispatch(pending('getPermitAttachments'));
      await Api.delete(`/permitattachment?PermitInstanceId=${PermitInstanceId}&PermitAttachmentId=${PermitAttachmentId}`);

      dispatch({
        type: TYPES.setAttachmentsList,
        payload: getState().permits
          .attachments
          .filter((att) => att.PermitAttachmentId !== PermitAttachmentId),
      });

      toast.success('Your attachment has been deleted successfully');
      dispatch(resolve('getPermitAttachments'));
    } catch (error) {
      toast.error('Deleteing your attachment failed. Please try again');
      dispatch(reject('getPermitAttachments', error));
    }
  };
};

/**
 * Get attachments for permit
 * @param {string} PermitInstanceId - Id of permit instance
 * @return void
 */
export const get_permit_attachments = (PermitInstanceId) => {
  return async (dispatch) => {
    try {
      dispatch(pending('getPermitAttachments'));
      const attachments = await Api.get(`/permitattachment?PermitInstanceId=${PermitInstanceId}`);

      dispatch({
        type: TYPES.setAttachmentsList,
        payload: attachments.PermitAttachmentList,
      });

      dispatch(resolve('getPermitAttachments'));
    } catch (error) {
      dispatch(reject('getPermitAttachments', error));
    }
  };
};

export const set_selected_location = (locationId) => {
  return (dispatch, getState) => {
    dispatch(pending(TYPES.setLocation));
    const { appState, definitions } = getState();
    const { locations } = appState;
    const { sites } = definitions;
    const newLocation = locations.find((l) => l.LocationId === locationId);

    if (newLocation) {
      if (!sites[newLocation.SiteId]) {
        dispatch(get_initial_permit_for_site(newLocation.SiteId));
      }
      dispatch({
        type: TYPES.setLocation,
        payload: newLocation,
      });
      dispatch(resolve(TYPES.setLocation));
    } else {
      dispatch(resolve(TYPES.setLocation));
    }
  };
};

export const reset_selected_location = () => {
  return (dispatch, getState) => {
    const { homeLocation } = getState().app;
    dispatch({
      type: TYPES.setLocation,
      payload: homeLocation,
    });
  };
};

export const toggleAvailableOffline = (PermitInstanceId) => {
  return async (dispatch) => {
    try {
      dispatch(pending('toggleAvailableOffline'));

      if (await Collections.Permits.get(PermitInstanceId)) {
        await Collections.Permits.delete(PermitInstanceId);
        dispatch(resolve('toggleAvailableOffline'));
        return false;
      }

      const [instance, approvals] = await Promise.all([
        Api.get(`/permitinstance?PermitInstanceId=${PermitInstanceId}`),
        Api.get(`/permitinstancestatus?PermitInstanceId=${PermitInstanceId}`),
      ]);

      instance.ContentJSON = JSON.parse(instance.ContentJSON);
      instance.PermitInstanceMetadataJSON = JSON.parse(instance.PermitInstanceMetadataJSON);
      Collections.Permits.set(instance.PermitInstanceId, { instance, approvals });

      dispatch(resolve('toggleAvailableOffline'));
      return true;
    } catch (error) {
      dispatch(reject('toggleAvailableOffline', error));
      return false;
    }
  };
};

export const update_cached_permit = (PermitInstanceId) => {
  return async (dispatch) => {
    try {
      dispatch(pending('updateCachedPermit'));

      const [instance, approvals] = await Promise.all([
        Api.get(`/permitinstance?PermitInstanceId=${PermitInstanceId}`),
        Api.get(`/permitinstancestatus?PermitInstanceId=${PermitInstanceId}`),
      ]);

      instance.ContentJSON = JSON.parse(instance.ContentJSON);
      instance.PermitInstanceMetadataJSON = JSON.parse(instance.PermitInstanceMetadataJSON);
      Collections.Permits.set(instance.PermitInstanceId, { instance, approvals });

      dispatch({
        type: TYPES.updateOne,
        payload: { instance, approvals, backgroundUpdate: true },
      });

      dispatch(resolve('updateCachedPermit'));
      return true;
    } catch (error) {
      dispatch(reject('updateCachedPermit', error));
    }
  };
};

/**
 * Save a permit draft
 * @param {string} draftId - Id of permit draft
 * @param {PermitContent} content - content of permit in draft state
 * @param {boolean} silent - Update the draft without modifying state. This is useful
 *  when we have formIo watching the draft and we don't want to trigger a rerender
 * @return void
 */
export const saveDraft = (draftId, content, silent = true) => {
  return async (dispatch, getState) => {
    try {
      recordEvent(getState(), {
        data: 'Save draft action dispatched',
        draftId,
        content,
        silent
      });

      if (!silent) dispatch(pending(TYPES.saveDraft));

      const { location, selected } = getState().permits;

      await Api.post(
        `/permits/draft/${draftId}`,
        {
          PermitContent: JSON.stringify(content),
          PermitDefinitionId: selected.instance.PermitDefinitionId,
          PermitInstanceId: draftId,
          LocationId: location.LocationId,
          SiteId: location.SiteId,
        },
      );

      if (!silent) {
        dispatch({
          type: TYPES.setDraft,
          payload: {

            draftId,
            content,
            lastSaved: moment().format(),
          },
        });

        dispatch(resolve(TYPES.saveDraft));
      }

    } catch (error) {
      dispatch(reject(TYPES.saveDraft, error));
    }
  };
};

/**
 * Get a permit draft
 * @param {string} draftId - Id of permit draft
 * @return void
 */
export const getDraft = (draftId) => {
  return async (dispatch, getState) => {
    try {
      dispatch(pending(TYPES.getDraft));

      recordEvent(getState(), {
        data: 'Get draft action dispatched',
        draftId,
      });

      const draft = await Api.get(`/permits/draft/${draftId}`);

      recordEvent(getState(), {
        data: 'Draft recieved from api',
        draftId,
        draft
      });

      let payload = { draftId: draft.PermitInstanceId };

      if (draft) {
        payload = {
          ...payload,
          content: JSON.parse(draft.PermitContent),
          lastSaved: draft.LastUpdatedOn,
        };
      }

      dispatch({
        type: TYPES.setDraft,
        payload,
      });

      dispatch(resolve(TYPES.getDraft));
    } catch (error) {
      dispatch(reject(TYPES.getDraft, error));
    }
  };
};

/**
 * Get a user's drafts
 * @return void
 */
export const getDrafts = () => {
  return async (dispatch) => {
    try {
      dispatch(pending(TYPES.getDrafts));

      const drafts = await Api.get('/permits/draft');

      dispatch({
        type: TYPES.setDraftsList,
        payload: drafts.map((draft) => ({
          ...draft,
          PermitContent: JSON.parse(draft.PermitContent),
        })),
      });

      dispatch(resolve(TYPES.getDrafts));
    } catch (error) {
      dispatch(reject(TYPES.getDrafts, error));
    }
  };
};

/**
 * Delete a permit draft
 * @param {string} draftId - Id of permit draft
 * @return void
 */
export const deleteDraft = (draftId) => {
  return async (dispatch) => {
    try {
      dispatch(pending(TYPES.deleteDraft));

      await Api.delete(`/permits/draft/${draftId}`);

      dispatch({
        type: TYPES.deleteDraft,
        payload: draftId,
      });

      dispatch(resolve(TYPES.deleteDraft));
      toast.success('Your draft has been deleted');
    } catch (error) {
      dispatch(reject(TYPES.deleteDraft, error));
    }
  };
};

export const clearDraft = () => (dispatch) => dispatch({
  type: TYPES.clearDraft,
});

export const clearDraftsList = () => (dispatch) => dispatch({
  type: TYPES.clearDraftsList,
});

export const clearFilters = () => (dispatch) => dispatch({
  type: TYPES.clearFilters,
});

export const clearList = () => (dispatch) => dispatch({
  type: TYPES.clearList,
});

export const selector = (path) => {
  return (state) => _get(state.permits, path);
};

export default {
  actions: {
    clearFilters,
    clearList,
    clearDraft,
    clearDraftsList,
    clearSelected: clear_permit,
    copyPermit,
    createPermit,
    deleteAttachment: delete_permit_attachment,
    deleteDraft,
    filter: setAllFilters,
    get: getAllPermits,
    getPermitsData,
    getDraft,
    getDrafts,
    getNextListPage,
    getAttachments: get_permit_attachments,
    getPermitById,
    getInitialPermitForSite: get_initial_permit_for_site,
    printPermit,
    setPrintMergeDefault,
    rejectPermit: reject_permit,
    revokePermits: revoke_permits,
    revokeAllPermits: revoke_all_permits,
    saveDraft,
    setLocation: set_selected_location,
    setPermitCopyEnabled,
    updatePermitLocation,
    updatePermitContent,
    uploadAttachment,
    updatePermit,
    toggleAvailableOffline,
    updateCachedPermit: update_cached_permit,
    updateWorkLocation,
  },
  selectors: {
    approvals: () => selector('approvals'),
    attachments: () => selector('attachments'),
    draft: () => selector('draft'),
    draftsList: () => selector('draftsList'),
    filters: () => selector('filter'),
    list: () => selector('list'),
    location: () => selector('location'),
    pagination: () => selector('pagination'),
    permits: () => selector('permits'),
    selected: () => selector('selected'),
    printMergeDefault: () => selector('printMergeDefault'),
  },
  types: TYPES,
};
