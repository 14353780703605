import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, TextField, Grid } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';

import FilterDropDownMultiSelect from './FilterDropDownMultiSelect';
import WorkOrdersState from '../../../actions/work-orders';

const useStyles = makeStyles((theme) => ({
  filtersWrapper: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    backgroundColor: 'white',
  },
  filtersItemWrapper: {
    margin: theme.spacing(1),
  },
}));

const WorkOrdersFilters = () => {
  const { selectors, actions } = WorkOrdersState;

  const classes = useStyles();
  const dispatch = useDispatch();
  const { text, status } = useSelector(selectors.filters());
  const statuses = useSelector(selectors.statuses());

  const handleSearch = (value, filterBy) => {
    dispatch(actions.filter(value, filterBy));
  };

  return (
    <Grid container className={classes.filtersWrapper}>
      <Grid item xs={12} md={3}>
        <Box p={2} pt={5}>
          <TextField
            label="Contains Text"
            value={text}
            id="text-search"
            onChange={(event) => handleSearch(event.target.value, 'text')}
          />
        </Box>
      </Grid>
      <Grid item xs={12} md={5}>
        <FilterDropDownMultiSelect
          label="Status"
          defaultValue={status}
          onSelect={(statusValue) => handleSearch(statusValue, 'status')}
          options={statuses.map((state) => ({ value: state }))}
        />
      </Grid>
    </Grid>
  );
};

export default WorkOrdersFilters;
